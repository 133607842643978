import {useState} from 'react';
import {FrequencyColumnsChartProps} from './FrequencyColumnsChart';
import FrequencyGroupByTabs from './FrequencyGroupByTabs';
import {WeekdayFrequencyHeatmapChart} from './WeekdayFrequencyHeatmapChart';
import {FrequencyHeatmapChart} from './FrequencyHeatmapChart';

interface FrequencyHeatmapChartWrapperProps extends FrequencyColumnsChartProps {}

export function FrequencyHeatmapChartWrapper({data, timeRange}: FrequencyHeatmapChartWrapperProps) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <div>
      <FrequencyGroupByTabs
        title="Frequency detection"
        selectedTabIndex={selectedTabIndex}
        onChange={setSelectedTabIndex}
      />
      {selectedTabIndex === 0 && <FrequencyHeatmapChart data={data} timeRange={timeRange} />}
      {selectedTabIndex === 1 && <WeekdayFrequencyHeatmapChart data={data} timeRange={timeRange} />}
    </div>
  );
}
