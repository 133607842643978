import {ROUTES} from '@shared/lib/constants';
import styles from './HeaderLogo.module.scss';
import {Link} from 'react-router-dom';
import {CustomLogo} from '../CustomLogo/CustomLogo';

export function HeaderLogo() {
  return (
    <Link to={ROUTES.ROOT.path} className={styles.container}>
      <CustomLogo />
    </Link>
  );
}
