import {getTimezoneOffset} from 'date-fns-tz';

function padStart2(num: number) {
  return `${num}`.padStart(2, '0');
}

export function toISOStringWithOffset(date: Date, timezone?: string) {
  const tzoInMin = timezone ? getTimezoneOffset(timezone) / (1000 * 60) : -date.getTimezoneOffset();
  const dif = tzoInMin >= 0 ? '+' : '-';

  return (
    date.getFullYear() +
    '-' +
    padStart2(date.getMonth() + 1) +
    '-' +
    padStart2(date.getDate()) +
    'T' +
    padStart2(date.getHours()) +
    ':' +
    padStart2(date.getMinutes()) +
    ':' +
    padStart2(date.getSeconds()) +
    (date.getMilliseconds() ? `.${date.getMilliseconds()}` : '') +
    dif +
    padStart2(Math.floor(Math.abs(tzoInMin) / 60)) +
    ':' +
    padStart2(Math.abs(tzoInMin) % 60)
  );
}
