import {FindingsSummary} from '@shared-app/types/StatusReportDetails';
import classNames from 'classnames';
import {capitalizeFirstLetter} from '@shared/lib/utils';
import styles from './SummaryChip.module.scss';

interface SummaryChipProps {
  summary: FindingsSummary;
  status?: 'active' | 'inactive';
}

export function SummaryChip({summary, status}: SummaryChipProps) {
  return (
    <div className={styles.chip}>
      {status && (
        <div className={classNames(styles.status, {[styles[`status__${status}`]]: !!status})}></div>
      )}
      {capitalizeFirstLetter(summary.message)}
      {typeof summary.value === 'number' ? (
        <span className={styles.chipCount}>{summary.value}</span>
      ) : null}
    </div>
  );
}
