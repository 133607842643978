import {BorderButton} from '@shared/ui/Button';
import {CloudUploadIcon} from '@shared/ui/icons';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {useUploadCoverImage} from '@entities/project/lib/hooks';
import {LinearProgress} from '@shared/ui/Loader';
import {Project} from '@shared/types/Project';
import styles from './ProjectCoverImageUpload.module.scss';

interface Props {
  project: Project;
}

export function ProjectCoverImageUpload({project}: Props) {
  const {t} = useTranslation('project');

  const {isUploading, uploadCoverImage} = useUploadCoverImage(project);

  const handleCoverImageDrop = (files: File[]) => {
    const file = files[0];
    uploadCoverImage(file);
  };

  return (
    <Dropzone
      onDrop={handleCoverImageDrop}
      maxFiles={1}
      accept={{'image/jpeg': [], 'image/png': []}}
      disabled={isUploading}
    >
      {({getRootProps, getInputProps, isDragActive}) => (
        <div
          {...getRootProps()}
          className={classNames(styles.coverImage, {
            [styles.coverImage__dragging]: isDragActive
          })}
        >
          <div>{t('Drag and drop or upload an image png, jpg')}</div>
          <input {...getInputProps({accept: 'image/png,image/jpeg,image/jpg'})} />
          {isUploading ? (
            <LinearProgress className={styles.progress} />
          ) : (
            <BorderButton startIcon={<CloudUploadIcon />}>
              {t('Upload', {ns: 'translation'})}
            </BorderButton>
          )}
        </div>
      )}
    </Dropzone>
  );
}
