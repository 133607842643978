import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {UserService} from '../services/User.service';
import {makeListQueryKey} from './utils/user';
import {useTranslation} from 'react-i18next';
import {useCurrentProject} from '@entities/project';

export function useUsers() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('user');
  const {data, isLoading, isError, error} = useQuery({
    queryKey: makeListQueryKey(projectId),
    queryFn: () =>
      UserService.getUsers(projectId).catch(() => {
        throw new Error(t('Error occurred while loading the Users.'));
      })
  });

  const errorMessage = isError && error.message;
  useEffect(() => {
    if (errorMessage) {
      ToastService.error(errorMessage);
    }
  }, [errorMessage]);

  return {
    users: data || [],
    isLoading
  };
}
