import SelectCamera from '@features/selectCamera';
import {Project} from '@shared/types/Project';
import SelectDateRange from '@features/selectDateRange';
import styles from './ProjectFilter.module.scss';
import {SelectTimeFrame} from '@features/selectTimeFrame';
import {SearchRelativeDateSelect} from '@features/searchRelativeDateSelect';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import {useProjectTimezone} from '@entities/project';

interface ProjectFilterProps {
  project: Project;
}

export function ProjectFilter({project}: ProjectFilterProps) {
  const {
    setDateRangePreset,
    dateRangePreset,
    dateRange,
    milestoneRange,
    setDateRange,
    setTimeRange,
    timeRange
  } = useSmartSearchFilter();
  const timezone = useProjectTimezone();

  return (
    <div className={styles.root}>
      <SelectCamera className={styles.camera} project={project} />
      <div className={styles.datetime}>
        <SearchRelativeDateSelect
          onChange={setDateRangePreset}
          value={dateRangePreset}
          timezone={timezone}
        />
        <SelectDateRange
          onChange={setDateRange}
          dateRange={dateRange}
          milestoneRange={milestoneRange}
          maxDateRange={183}
          timezone={timezone}
          allowMilestoneCreation
        />
        <SelectTimeFrame value={timeRange} onChange={setTimeRange} />
      </div>
    </div>
  );
}
