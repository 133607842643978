import {Tag} from '@shared/types/Tag';

export enum TagOperator {
  And = '$and',
  Or = '$or'
}

export interface SearchQuery {
  operator: TagOperator;
  tags: Tag[];
}
