export const availableIcons = [
  'dump-truck',
  'dump',
  'mobile-crane',
  'truck',
  'bulldozer',
  'compactor',
  'tractor',
  'tower-crane',
  'pump-boom',
  'concrete-pump-boom',
  'concrete-pump-hose',
  'concrete-pump-truck',
  'construction-forklift',
  'pickup',
  'safety-vests',
  'van',
  'waste-container',
  'frame-formwork',
  'slab-formwork',
  'wet-concrete',
  'barricade-barrier',
  'chute',
  'concrete-vibrator',
  'drum-container',
  'fall-harness',
  'generator',
  'helmet',
  'jersey-barrier',
  'pipe',
  'plate-compactor',
  'pump-hose',
  'rebar-bender',
  'rebar-bundle',
  'safety-vest',
  'tramping-rammer',
  'aggregate-pile',
  'concrete-bucket',
  'crawler-crane',
  'fork-lift',
  'liquid-tank',
  'portable-toilet',
  'scraper',
  'terrain',
  'welding-machine',
  'asphalt-paver',
  'concrete-chute',
  'curing-foil',
  'formwork-panel-pile',
  'loader',
  'power-screed',
  'screed',
  'traffic-sign',
  'window',
  'asphalt',
  'concrete-cutter',
  'debris-pile',
  'formwork-slab',
  'material-pile',
  'power-trowel',
  'shotcrete',
  'trench',
  'background',
  'concrete-dry',
  'deck',
  'formwork-wall',
  'mixer-truck',
  'pylon',
  'silo',
  'trencher',
  'batching-plant',
  'concrete-mixer',
  'drilling-rig',
  'framing',
  'mobile-batching-plant',
  'question_mark',
  'site-fencing',
  'trolley',
  'beam',
  'concrete-paver',
  'dumpster',
  'front-dumper',
  'pavement',
  'rebar',
  'rebars',
  'sky',
  'truss',
  'boom-lift',
  'concrete-wet',
  'dust-net',
  'garbage-truck',
  'person',
  'roofing-panel',
  'slab-concrete',
  'tugger',
  'booth',
  'conex',
  'earth-wall',
  'grader',
  'personal-hoist',
  'safety-sign',
  'solar-panel',
  'wall',
  'car',
  'continuous-mixer',
  'erosion-net',
  'guardrail',
  'pickup-truck',
  'scaffolding',
  'telescopic-handler',
  'waste-bin',
  'column',
  'crate',
  'excavator',
  'jib-crane',
  'pipeline',
  'scissor-lift',
  'telescopic-screed',
  'welding-helmet',
  'person-ppe-compliant',
  'person-ppe-violation'
];
