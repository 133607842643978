export function ExitIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M4.85 13H16C16.2833 13 16.5208 12.9042 16.7125 12.7125C16.9042 12.5208 17 12.2833 17 12C17 11.7167 16.9042 11.4792 16.7125 11.2875C16.5208 11.0958 16.2833 11 16 11H4.85L5.7 10.15C5.9 9.95 5.99583 9.71667 5.9875 9.45C5.97917 9.18333 5.88333 8.95 5.7 8.75C5.5 8.55 5.2625 8.44583 4.9875 8.4375C4.7125 8.42917 4.475 8.525 4.275 8.725L1.7 11.3C1.5 11.5 1.4 11.7333 1.4 12C1.4 12.2667 1.5 12.5 1.7 12.7L4.275 15.275C4.475 15.475 4.7125 15.5708 4.9875 15.5625C5.2625 15.5542 5.5 15.45 5.7 15.25C5.88333 15.05 5.97917 14.8167 5.9875 14.55C5.99583 14.2833 5.9 14.05 5.7 13.85L4.85 13ZM10 8V5H20V19H10V16C10 15.7167 9.90417 15.4792 9.7125 15.2875C9.52083 15.0958 9.28333 15 9 15C8.71667 15 8.47917 15.0958 8.2875 15.2875C8.09583 15.4792 8 15.7167 8 16V19C8 19.55 8.19583 20.0208 8.5875 20.4125C8.97917 20.8042 9.45 21 10 21H20C20.55 21 21.0208 20.8042 21.4125 20.4125C21.8042 20.0208 22 19.55 22 19V5C22 4.45 21.8042 3.97917 21.4125 3.5875C21.0208 3.19583 20.55 3 20 3H10C9.45 3 8.97917 3.19583 8.5875 3.5875C8.19583 3.97917 8 4.45 8 5V8C8 8.28333 8.09583 8.52083 8.2875 8.7125C8.47917 8.90417 8.71667 9 9 9C9.28333 9 9.52083 8.90417 9.7125 8.7125C9.90417 8.52083 10 8.28333 10 8Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
