import {useEffect} from 'react';
import {
  DialogContainer,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogCloseButton
} from '@shared/ui/Dialog';
import {useTranslation} from 'react-i18next';
import {Button} from '@shared/ui/Button';
import {CircularLoader} from '@shared/ui/Loader';
import {ToastService} from '@shared/lib/services';
import {useDeleteMilestone} from '@entities/milestone/lib/hooks';
import {Milestone} from '@shared/types/Milestone';
import {DATE_FORMATS} from '@shared/lib/constants';
import styles from './DeleteMilestoneDialog.module.scss';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';

interface Props {
  milestone: Milestone;
  onSuccess: () => void;
  onClose: () => void;
}

export function DeleteMilestoneDialog({milestone, onSuccess, onClose}: Props) {
  const {t} = useTranslation(['milestone', 'translation']);
  const timezone = useProjectTimezone();
  const {deleteMilestone, isDeleting, isDeleted} = useDeleteMilestone();

  const handleDelete = () => {
    deleteMilestone(milestone.id);
  };

  useEffect(() => {
    if (isDeleted) {
      ToastService.success(t('Milestone was successfully deleted.'));
      onClose();
      onSuccess();
    }
  }, [isDeleted, onClose, t, onSuccess]);

  return (
    <DialogContainer onClose={onClose} fullWidth open>
      <DialogTitle className={styles.title} variant="h5">
        {t('Delete Milestone', {ns: 'milestone'})}
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <div>{t('Are you sure want to delete a milestone?')}</div>
        <div>{t('This action can not be reverted.')}</div>
        <div className={styles.milestoneInfo}>
          <div>{milestone.name}</div>
          <div>{formatInTimeZone(milestone.date, timezone, DATE_FORMATS.DATE)}</div>
        </div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={onClose} variant="contained">
          {t('Cancel', {ns: 'translation'})}
        </Button>
        <Button
          onClick={handleDelete}
          variant="outlined"
          color="error"
          disabled={isDeleting}
          endIcon={isDeleting ? <CircularLoader size="1em" color="inherit" /> : undefined}
        >
          {t('Delete', {ns: 'translation'})}
        </Button>
      </DialogActions>
    </DialogContainer>
  );
}
