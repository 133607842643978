import {percentage, sum} from '@conxai/react-kit';
import {ComplianceInfo} from '../../types/ComplianceInfo';

export function getTotalNonComplianceByType(complianceInfo: ComplianceInfo[], isRelative: boolean) {
  const totalByType: {
    noVest: [number, number][];
    noHelmet: [number, number][];
    noPPE: [number, number][];
  } = {
    noVest: [],
    noHelmet: [],
    noPPE: []
  };
  complianceInfo.forEach(info => {
    const noVestTotal = sum(info.noVest);
    const noHelmetTotal = sum(info.noHelmet);
    const noPPETotal = sum(info.noPPE);
    const ppeTotal = sum(info.ppe);

    const total = noVestTotal + noHelmetTotal + noPPETotal + ppeTotal;

    const timestamp = info.date.getTime();
    totalByType.noHelmet.push([
      timestamp,
      isRelative ? percentage(noHelmetTotal, total) : noHelmetTotal
    ]);
    totalByType.noVest.push([timestamp, isRelative ? percentage(noVestTotal, total) : noVestTotal]);
    totalByType.noPPE.push([timestamp, isRelative ? percentage(noPPETotal, total) : noPPETotal]);
  });

  return totalByType;
}
