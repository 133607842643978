import Skeleton from '@shared/ui/Skeleton';
import styles from './SavedSearchListSkeleton.module.scss';

export function SavedSearchListSkeleton() {
  return (
    <div className={styles.container}>
      {Array.from({length: 2}).map((_, i: number) => (
        <Skeleton key={i} height={'4.8rem'} />
      ))}
    </div>
  );
}
