import {useInfiniteQuery} from '@tanstack/react-query';
import SmartImageService from '@entities/smartImage/lib/services/SmartImage.service';
import {DateRange} from '@shared/ui/Date';
import {SmartImage} from '@shared/types/SmartImage';
import {TimeRange} from '@shared/ui/TimeRange';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {SearchQuery} from '@entities/smartSearchFilter';
import {filterImagesWithinTimeRange} from '../utils/filterImagesWithinTimeRange';

const FETCH_LIMIT = 500;
const VIEW_LIMIT = 48;

interface Params {
  cameras: string[];
  dateRange: DateRange;
  timeRange: TimeRange;
  query: SearchQuery;
}

function useGetPaginatedSmartSearchImages({cameras, dateRange, timeRange, query}: Params) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const timezone = useProjectTimezone();
  const {data, isFetching, fetchNextPage, hasNextPage} = useInfiniteQuery<SmartImage[]>({
    queryKey: ['smart-search-result', projectId, cameras, dateRange, query, timezone],
    getNextPageParam: (lastPage, pages) =>
      lastPage.length < FETCH_LIMIT ? undefined : pages.length * FETCH_LIMIT,
    queryFn: params => {
      if (params.pageParam === undefined) return;
      const offset = params.pageParam as number;
      return cameras.length > 0
        ? SmartImageService.getImages(
            projectId,
            {
              cameras,
              dateRange,
              offset,
              limit: FETCH_LIMIT,
              query
            },
            timezone
          )
        : [];
    },
    initialPageParam: 0
  });

  const allImages = useMemo(() => data?.pages.flat(1) ?? [], [data]);

  const filteredImages = useMemo(
    () => filterImagesWithinTimeRange(allImages, timeRange, timezone),
    [timeRange, allImages, timezone]
  );

  return {
    images: filteredImages,
    isFetching,
    fetchMoreImages: fetchNextPage,
    doesServerHaveMoreImages: hasNextPage
  };
}

export function useGetSmartSearchImages({
  cameras,
  dateRange,
  timeRange,
  query,
  hasScrolledToEnd
}: Params & {hasScrolledToEnd: boolean}) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const infiniteScrollTriggerElementRef = useRef();
  const [maxImagesToShow, setMaxImagesToShow] = useState(0);

  const {
    images: availableImages,
    isFetching,
    fetchMoreImages,
    doesServerHaveMoreImages
  } = useGetPaginatedSmartSearchImages({cameras, dateRange, timeRange, query});

  const doesClientHaveEnoughImages = availableImages.length >= maxImagesToShow;
  const areMoreImagesAvailable = doesServerHaveMoreImages || doesClientHaveEnoughImages;
  const shouldFetchImagesFromServer = doesServerHaveMoreImages && !doesClientHaveEnoughImages;

  useEffect(() => {
    setMaxImagesToShow(0);
  }, [projectId, cameras, dateRange, timeRange, query]); // this array should always match with react-query key

  useEffect(() => {
    if (hasScrolledToEnd && areMoreImagesAvailable) {
      setMaxImagesToShow(maxImages => maxImages + VIEW_LIMIT);
    }
  }, [hasScrolledToEnd, areMoreImagesAvailable]);

  useEffect(() => {
    if (!isFetching && shouldFetchImagesFromServer) fetchMoreImages();
  }, [fetchMoreImages, shouldFetchImagesFromServer, isFetching]);

  // wait for multiples of `VIEW_LIMIT` before showing to user, else UI will keep on jumps for every API call
  const images = useMemo(
    () =>
      shouldFetchImagesFromServer
        ? availableImages.slice(0, maxImagesToShow - VIEW_LIMIT)
        : availableImages.slice(0, maxImagesToShow),
    [availableImages, shouldFetchImagesFromServer, maxImagesToShow]
  );

  return {
    images: images,
    allImages: availableImages,
    isFetching: isFetching || shouldFetchImagesFromServer,
    hasMoreImages: areMoreImagesAvailable,
    ref: infiniteScrollTriggerElementRef
  };
}
