import {DATE_FORMATS} from '@shared/lib/constants';
import {setEndDayHours, setStartDayHours} from '@shared/lib/utils';
import {DateRange} from '@shared/ui/Date';
import {formatInTimeZone, utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';

export function getZonedDateRangeISOWithOffset(dateRange: DateRange, timezone: string) {
  const startDate = utcToZonedTime(dateRange.startDate, timezone);
  const tzAdjustedStartDate = setStartDayHours(startDate);

  const endDate = utcToZonedTime(dateRange.endDate, timezone);
  const tzAdjustedEndDate = setEndDayHours(endDate);

  const startDateISOWithOffset = formatInTimeZone(
    zonedTimeToUtc(tzAdjustedStartDate, timezone),
    timezone,
    DATE_FORMATS.ISO
  );
  const endDateISOWithOffset = formatInTimeZone(
    zonedTimeToUtc(tzAdjustedEndDate, timezone),
    timezone,
    DATE_FORMATS.ISO
  );

  return {
    startDate: startDateISOWithOffset,
    endDate: endDateISOWithOffset
  };
}
