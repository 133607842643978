import {Button} from '@shared/ui/Button';
import {useTranslation} from 'react-i18next';
import {AddCalibrationPointDialog} from '@entities/occupancy/ui/AddCalibrationPointDialog';
import {useOccupancyFilters} from '@features/occupancy/Filters';
import {useCurrentProject} from '@entities/project';
import {useBoolean} from '@conxai/react-kit';

interface Props {
  refetchEvents: () => void;
}

export function AddCalibrationPoint({refetchEvents}: Props) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {regionId} = useOccupancyFilters();
  const [isOpened, , openDialog, closeDialog] = useBoolean(false);
  const {t} = useTranslation('occupancy');
  return (
    <>
      <Button onClick={openDialog} variant={`contained`}>
        {t(`Add Calibration Point`)}
      </Button>
      <AddCalibrationPointDialog
        projectId={projectId}
        regionId={regionId}
        onClose={closeDialog}
        onSuccess={() => {
          setTimeout(() => refetchEvents(), 1000);
          closeDialog();
        }}
        open={isOpened}
      />
    </>
  );
}
