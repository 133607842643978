import {FeatureFlagForm, useGetFeatureFlags, useUpdateFeatureFlags} from '@entities/featureFlag';
import {useCurrentProject} from '@entities/project';
import {ToastService} from '@shared/lib/services';
import {groupMapBy} from '@conxai/react-kit';
import {CircularLoader} from '@shared/ui/Loader';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

export function FeatureFlags() {
  const {t} = useTranslation();
  const {project} = useCurrentProject();
  const {featureFlags, isLoading} = useGetFeatureFlags(project.id);
  const {updateFeatureFlags, isSaving, isSaved} = useUpdateFeatureFlags();

  const handleSubmit = (newFeatureFlags: Record<string, boolean>) => {
    updateFeatureFlags(
      Object.entries(newFeatureFlags)
        .filter(([, enabled]) => enabled)
        .map(([key]) => key)
    );
  };

  const defaultValue = groupMapBy(
    featureFlags,
    flag => flag.key,
    enabledArray => enabledArray[0].enabled
  );

  useEffect(() => {
    if (isSaved) {
      ToastService.success(t('Settings saved successfully'));
    }
  }, [isSaved, t]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <FeatureFlagForm
      featureFlags={featureFlags}
      defaultValue={defaultValue}
      onSubmit={handleSubmit}
      isSaving={isSaving}
    />
  );
}
