import {useEffect} from 'react';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import styles from './ProjectsPage.module.scss';
import ProjectsGrid from '@widgets/projects/projectsGrid';
import {useCurrentProject} from '@entities/project';

export default function ProjectsPage() {
  const {reset: resetSmartSearchFilter} = useSmartSearchFilter();
  const {setCurrentProject} = useCurrentProject();

  useEffect(() => {
    // filters are project specific but they are global currently
    // Until we fix it properly, below is the workaround to reset filters whenever there is project switch
    resetSmartSearchFilter();

    // we need to set this undefined because deep down we are using currentProject.cameraIds before new project being set
    // reseting here so that we don't endup using previous project on first re-render
    setCurrentProject(undefined);
  }, [resetSmartSearchFilter, setCurrentProject]);

  return (
    <div className={styles.container}>
      <ProjectsGrid />
    </div>
  );
}
