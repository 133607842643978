import {plainToInstance} from 'class-transformer';
import {EventModel} from '../model/Event.model';
import {entryexitAPI} from '@shared/api';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import {DateRangeRelative} from '@shared/ui/Date';
import {dateRangeRelativeToDateRange} from '@shared/lib/utils';

export class EventService {
  static async getEvents(
    projectId: string,
    regionId: string,
    {
      dateRangeRelative,
      timezone
    }: {
      dateRangeRelative: DateRangeRelative;
      timezone: string;
    }
  ) {
    const {startDate, endDate} = dateRangeRelativeToDateRange(dateRangeRelative);
    const start = formatInTimeZone(startDate, timezone, DATE_FORMATS.ISO);
    const end = formatInTimeZone(endDate, timezone, DATE_FORMATS.ISO);

    const events = (await entryexitAPI
      .get(`${projectId}/regions/${regionId}/events`, {
        searchParams: {
          start,
          end
        }
      })
      .json()) as unknown[];

    return plainToInstance(EventModel, events);
  }

  static async getVideoUrl(cameraId: string, videoId: string) {
    const {video_url: videoUrl} = (await entryexitAPI
      .get(`${cameraId}/video/${videoId}`)
      .json()) as unknown as {
      video_url: string;
    };

    return videoUrl;
  }
}
