import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import tags from './en/tag-translation';
import translation from './en/translation.json';
import search from './en/search-translation.json';
import milestone from './en/milestone-translation.json';
import user from './en/user-translation.json';
import project from './en/project-translation.json';
import ppeDashboard from './en/ppe-dashboard.json';
import statusReport from './en/status-report.json';
import occupancy from './en/occupancy-translation.json';

i18next.use(initReactI18next).init({
  lng: 'en', // if you're using a language detector, do not define the lng option
  debug: process.env.NODE_ENV === 'development',
  resources: {
    en: {
      translation,
      tags,
      milestone,
      search,
      user,
      project,
      ppeDashboard,
      statusReport,
      occupancy
    }
  }
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
});
