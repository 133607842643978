import {RefObject, useEffect, useState} from 'react';
import {useIsVisible} from '@conxai/react-kit';
import SmartImageService from '@entities/smartImage/lib/services/SmartImage.service';
import {imagePlaceholderUrl} from '@shared/lib/constants';
import {SmartImage} from '@shared/types/SmartImage';

export function useOnVisibleThumbnailUrl<T extends Element>(ref: RefObject<T>, image: SmartImage) {
  const [isLoading, setIsLoading] = useState(true);
  const isVisible = useIsVisible(ref);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();

  useEffect(() => {
    if (isVisible && !thumbnailUrl) {
      SmartImageService.getImageByUrl(image.presignedUrls.image_thumb_high)
        .catch(() => imagePlaceholderUrl)
        .then(imageUrl => {
          const img = new Image();
          img.src = imageUrl;

          img.onload = () => {
            setThumbnailUrl(imageUrl);
            setIsLoading(false);
          };

          img.onerror = () => {
            setThumbnailUrl(imagePlaceholderUrl);
            setIsLoading(false);
          };
        });
    }
  }, [isVisible]);

  return {
    thumbnailUrl,
    isLoading
  };
}
