import styles from './ProjectsGrid.module.scss';
import classNames from 'classnames';
import Skeleton from '@shared/ui/Skeleton';

export default function ProjectsGridSkeleton() {
  return (
    <div className={styles.container}>
      {Array.from({length: 2}).map((_, index) => (
        <div key={`projects-grid-skeleton-${index}`} className={classNames(styles.item)}>
          <Skeleton className={styles.item__skeleton} animation={'wave'} variant="rectangular" />
        </div>
      ))}
    </div>
  );
}
