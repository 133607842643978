interface Props {
  className?: string;
}

export function SearchIcon({className}: Props) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3844 16.9762L12.4948 13.0866M12.4948 13.0866C13.1191 12.4623 13.6143 11.7211 13.9522 10.9054C14.29 10.0897 14.4639 9.21545 14.4639 8.33255C14.4639 7.44965 14.29 6.5754 13.9522 5.7597C13.6143 4.94401 13.1191 4.20285 12.4948 3.57855C11.8705 2.95424 11.1293 2.45902 10.3136 2.12115C9.49791 1.78327 8.62366 1.60937 7.74076 1.60938C6.85786 1.60938 5.9836 1.78327 5.16791 2.12115C4.35221 2.45902 3.61106 2.95424 2.98675 3.57855C1.72591 4.83939 1.01758 6.54945 1.01758 8.33255C1.01758 10.1157 1.72591 11.8257 2.98675 13.0866C4.24759 14.3474 5.95766 15.0557 7.74076 15.0557C9.52385 15.0557 11.2339 14.3474 12.4948 13.0866Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
