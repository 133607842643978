import {Expose} from 'class-transformer';
import {Region} from '../types/Region';

export class RegionModel implements Region {
  @Expose({name: 'name'})
  public name: string;

  @Expose({name: 'uuid'})
  public id: string;
}
