import {DATE_FORMATS} from '@shared/lib/constants';
import {AccessTimeIcon} from '@shared/ui/icons';
import {formatInTimeZone} from 'date-fns-tz';
import {useTranslation} from 'react-i18next';
import styles from './Tooltip.module.scss';

interface TooltipOptions {
  series: number[][];
  seriesIndex: number;
  dataPointIndex: number;
  ctx: {
    data: {
      twoDSeriesX: number[];
    };
  };
}

interface TooltipProps {
  options: TooltipOptions;
  timezone: string;
}

export function Tooltip({options, timezone}: TooltipProps) {
  const {series, dataPointIndex, ctx} = options;
  const {
    data: {twoDSeriesX: timestamps}
  } = ctx;
  const {t} = useTranslation('occupancy');
  const data = series[0];

  if (dataPointIndex === 0 || dataPointIndex === data.length - 1) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.time}>
        <AccessTimeIcon />
        {formatInTimeZone(timestamps[dataPointIndex], timezone, DATE_FORMATS.TIME_24_WIDE)}
      </div>
      {t('Occupancy')}: <span className={styles.value}>{data[dataPointIndex]}</span>
    </div>
  );
}
