import {SmartSearchFilterState} from '../state/useSmartSearchFilter';
import {plainToInstance} from 'class-transformer';
import {SearchTimeRangeModel} from '@entities/savedSearch/model/SavedSearch.model';
import {Search} from '@shared/types/Search';
import {makeTagQueryForSearch} from '@shared-app/lib';

export const getSavedSearchDataFromFilters = (filters: SmartSearchFilterState): Search => {
  const {dateRange, query, cameras, milestoneRange, timeRange: hours, dateRangePreset} = filters;
  const {operator, tags} = query;

  const timeRange = plainToInstance(
    SearchTimeRangeModel,
    {
      dateFrom: !dateRangePreset ? dateRange.startDate : undefined,
      dateTo: !dateRangePreset ? dateRange.endDate : undefined,
      milestoneFrom: !dateRangePreset ? milestoneRange.startMilestoneId : undefined,
      milestoneTo: !dateRangePreset ? milestoneRange.endMilestoneId : undefined,
      timeFrom: hours.startTime,
      timeTo: hours.endTime,
      relativeDateRange: dateRangePreset
    },
    {ignoreDecorators: true}
  );

  return {
    includedTags: [],
    excludedTags: [],
    cameras: cameras,
    timeRange: timeRange,
    query: {[operator]: tags.map(makeTagQueryForSearch)}
  };
};
