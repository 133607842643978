import {useEffect, useState} from 'react';
import {AuthService} from '@shared/lib/services';
import {AuthUser} from '@shared/types/User';

export function useAuth() {
  const [user, setUser] = useState<AuthUser>();

  const getUser = async () => {
    const user = await AuthService.currentUser();
    setUser(user);
  };

  useEffect(() => {
    getUser();
  }, []);

  return {
    user,
    logout: AuthService.logout
  };
}
