import {StateStorage, createJSONStorage} from 'zustand/middleware';

const getUrlSearch = () => {
  return window.location.search.slice(1);
};

const getPersistentStorage = (
  isSearchParamStorageEnabled: (u: string) => boolean
): StateStorage => ({
  getItem: key => {
    if (getUrlSearch() && isSearchParamStorageEnabled(window.location.pathname)) {
      const searchParams = new URLSearchParams(getUrlSearch());
      const storedValue = searchParams.get(key);
      return JSON.parse(storedValue as string);
    }
  },
  setItem: (key, newValue): void => {
    if (!isSearchParamStorageEnabled(window.location.pathname)) {
      return;
    }
    const searchParams = new URLSearchParams(getUrlSearch());
    searchParams.set(key, JSON.stringify(newValue));
    window.history.replaceState(null, '', `?${searchParams.toString()}`);
  },
  removeItem: (key): void => {
    if (!isSearchParamStorageEnabled(window.location.pathname)) {
      return;
    }
    const searchParams = new URLSearchParams(getUrlSearch());
    searchParams.delete(key);
    window.location.search = searchParams.toString();
  }
});

export const createSearchParamStorage = <T>(isSearchParamStorageEnabled: (u: string) => boolean) =>
  createJSONStorage<T>(() => getPersistentStorage(isSearchParamStorageEnabled), {
    reviver: (key, value: unknown) => {
      if (value && ['startDate', 'endDate'].includes(key)) {
        return new Date(value as string);
      }
      return value;
    },
    replacer: (key, value) => {
      return value instanceof Date ? value.toISOString() : value;
    }
  });
