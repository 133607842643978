import {Button} from '@shared/ui/Button';
import {Project} from '@shared/types/Project';
import {StatusReportDetails} from '@shared-app/types/StatusReportDetails';
import {useTranslation} from 'react-i18next';
import styles from './DetailSubHeader.module.scss';

interface DetailSubHeaderProps {
  project: Project;
  report: StatusReportDetails;
  onViewReport: () => void;
}

export function DetailSubHeader({project, report, onViewReport}: DetailSubHeaderProps) {
  const {t} = useTranslation('statusReport');
  return (
    <div className={styles.sourceAndViewBtn}>
      <div className={styles.header}>
        <div className={styles.source}>{t('Source')}</div>
        <div className={styles.graybg}>
          {project.getCameraById(report.sources.at(0))?.name || t('N/A', {ns: 'translation'})}
        </div>
      </div>
      <Button onClick={onViewReport} variant="contained" size="small">
        {t('View Report')}
      </Button>
    </div>
  );
}
