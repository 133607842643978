import {Event} from '@entities/occupancy';
import {ONE_DAY_IN_MS, ONE_HOUR_IN_MS} from '@shared/lib/constants';
import {DateRange} from '@shared/ui/Date';
import {addMilliseconds} from 'date-fns';
import {roundToNearest} from '@shared/lib/utils';
import {differenceInMilliseconds} from 'date-fns';
import {roundDateRangeToNearHour} from '@shared-app/lib';

function rangeDate(startDate: Date, stopDate: Date, stepInMS: number) {
  const result = new Array<Date>();
  let currentDate = startDate;
  while (currentDate < stopDate) {
    result.push(new Date(currentDate));
    currentDate = addMilliseconds(currentDate, stepInMS);
  }
  return result;
}

function isEntryEvent(event: Event) {
  return event.type === 1;
}

function getEventsWithinDateRange(events: Event[], startDate: Date, endDate: Date) {
  return events.filter(({timestamp}) => timestamp >= startDate && timestamp < endDate);
}

function getEntryExitCounts(events: Event[]) {
  return events.reduce(
    (counts, event) => {
      if (isEntryEvent(event)) {
        counts[0] += 1;
      } else {
        counts[1] += 1;
      }
      return counts;
    },
    [0, 0] satisfies [number, number]
  );
}

export function getHourlyEntryExitData(
  events: Event[],
  dateRange: DateRange,
  timezone: string,
  selectedGate?: string
) {
  const {startDate, endDate, gap} = getDateRangeForSeries(dateRange, timezone);
  const seriesTimestamps = rangeDate(startDate, endDate, gap);

  const filteredEvents = selectedGate
    ? events.filter(event => event.gateId === selectedGate)
    : events;

  const data = seriesTimestamps.map<
    [number, {entryCount: number; exitCount: number; netFlow: number}]
  >(startTimestamp => {
    const endTimestamp = addMilliseconds(startTimestamp, gap);
    const eventsInRange = getEventsWithinDateRange(filteredEvents, startTimestamp, endTimestamp);

    const [entryCount, exitCount] = getEntryExitCounts(eventsInRange);
    const netFlow = entryCount - exitCount;

    return [startTimestamp.getTime(), {entryCount, exitCount, netFlow}];
  });

  return {
    entryCount: data.map(([, {entryCount}]) => entryCount),
    exitCount: data.map(([, {exitCount}]) => exitCount),
    netFlow: data.map(([, {netFlow}]) => netFlow),
    time: data.map(([time]) => time)
  };
}

function getXAxisLabelGap(timeframeInMS: number) {
  if (timeframeInMS <= ONE_DAY_IN_MS) {
    return ONE_HOUR_IN_MS;
  } else if (timeframeInMS <= 7 * ONE_DAY_IN_MS) {
    return 3 * ONE_HOUR_IN_MS;
  } else if (timeframeInMS <= 15 * ONE_DAY_IN_MS) {
    return 6 * ONE_HOUR_IN_MS;
  } else if (timeframeInMS <= 30 * ONE_DAY_IN_MS) {
    return 12 * ONE_HOUR_IN_MS;
  } else {
    return ONE_DAY_IN_MS;
  }
}

export function getDateRangeForSeries(dateRange: DateRange, timezone: string) {
  const dateRangeRounded = roundDateRangeToNearHour(dateRange, timezone);
  const {startDate, endDate} = dateRangeRounded;

  const chartTimeframe = differenceInMilliseconds(endDate, startDate);
  const xAxisLabelGap = getXAxisLabelGap(chartTimeframe);
  const roundedTimeframe = roundToNearest(chartTimeframe, xAxisLabelGap);
  const endDateRounded = addMilliseconds(startDate, roundedTimeframe);

  return {
    startDate: startDate,
    endDate: endDateRounded,
    gap: xAxisLabelGap
  };
}
