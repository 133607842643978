import {Expose, Transform, Type} from 'class-transformer';
import {SmartImageMasks} from '@shared/types/SmartImageMasks';
import {SmartImageMask} from '@shared/types/SmartImageMask';
import OriginalSmartImageSizeModel from '@entities/smartImage/model/OriginalSmartImageSize.model';
import {ImageDimensions} from '@shared/types/ImageDimentions';
import {SmartImageMaskModel} from '@entities/smartImage';
import {TagEnum} from '@shared/types/TagEnum';

export default class SmartImageMasksModel implements SmartImageMasks {
  @Expose({name: 'annotations'})
  @Type(() => SmartImageMaskModel)
  public masks: SmartImageMask[];

  @Expose({name: 'image'})
  @Type(() => OriginalSmartImageSizeModel)
  public originalImageSize: ImageDimensions;

  @Expose({name: 'categories'})
  @Transform(({value}) => transformCategories(value))
  public tags: {[key: number]: TagEnum};

  @Expose({name: 'isLegacy'})
  @Transform(({value}) => value ?? false, {toClassOnly: true})
  public isLegacy: boolean;

  public getMaskTagIdByKey(tagKey: TagEnum) {
    return +Object.keys(this.tags).find((key: string) => this.tags[+key] === tagKey);
  }

  public getMasksTags(): TagEnum[] {
    return this.masks.reduce((acc, mask) => {
      const tagKey = this.tags[mask.tagId];

      if (acc.indexOf(tagKey) < 0) {
        acc.push(tagKey);
      }

      return acc;
    }, []);
  }

  public getMasksTagsCount(): {[key: string]: number} {
    return this.masks.reduce((acc: {[key: string]: number}, mask) => {
      const tagKey = this.tags[mask.tagId];

      if (acc[tagKey]) {
        acc[tagKey]++;
      } else {
        acc[tagKey] = 1;
      }

      return acc;
    }, {});
  }
}

function transformCategories(categories: {id: number; name: string}[]) {
  return categories.reduce((acc: {[key: number]: string}, item: {id: number; name: string}) => {
    acc[item.id] = item.name;
    return acc;
  }, {});
}
