import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

interface SmartSearchFilterState {
  show: boolean;
  toggleVisibility: () => void;
  setVisibility: (isVisible: boolean) => void;
}

export const useSmartSearchFilterPanel = create<SmartSearchFilterState>()(
  devtools(set => ({
    show: false,
    toggleVisibility: () => {
      return set(state => ({show: !state.show}));
    },
    setVisibility: isVisible => {
      return set(() => ({show: isVisible}));
    }
  }))
);
