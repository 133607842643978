import {useCallback} from 'react';
import {useUpdateProject} from './useUpdateProject';
import {Project} from '@shared/types/Project';
import {instanceToInstance} from 'class-transformer';

export function useDeleteCoverImage(project: Project) {
  const {update, isUpdated, isUpdating} = useUpdateProject(updateCacheWithoutImage);

  const deleteCoverImage = useCallback(() => {
    const updatedProject = instanceToInstance(project, {ignoreDecorators: true});
    updatedProject.coverImage = undefined;
    update(updatedProject);
  }, [update, project]);

  return {deleteCoverImage, isDeleted: isUpdated, isDeleting: isUpdating};
}

function updateCacheWithoutImage(prevProject: Project): Project {
  const updatedProject = instanceToInstance(prevProject, {ignoreDecorators: true});
  updatedProject.coverImage = undefined;
  return updatedProject;
}
