import {Expose, Transform, Type} from 'class-transformer';
import {Milestone} from '@shared/types/Milestone';

const getDateWithoutTime = ({value}: {value: Date}) => new Date(value.toDateString());

export class MilestoneModel implements Milestone {
  @Expose({name: 'uuid'})
  public id: string;

  @Expose({name: 'name'})
  public name: string;

  @Expose({name: 'date'})
  @Type(() => Date)
  @Transform(getDateWithoutTime)
  public date: Date;
}
