import {
  CSSProperties,
  MutableRefObject,
  UIEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import {debounce, useDidMount, useElementSize} from '@conxai/react-kit';
import styles from './Window.module.scss';

interface Props<T> {
  data: T[];
  itemSize: number;
  children: (p: {item: T; index: number; items: T[]; style: CSSProperties}) => JSX.Element;
  className: string;
  height: number;
  getItemKey: (item: T) => string;
  onStartEdgeReach: () => void;
  onEndEdgeReach: () => void;
  onStartItemChange: (index: number) => void;
  onEndItemChange: (index: number) => void;
  isStartLoading?: boolean;
  isEndLoading?: boolean;
}

export type ScrollPlacement = 'end' | 'center';

export interface WindowRef {
  scrollByItems: (n: number) => void;
  scrollToItem: (i: number, p: ScrollPlacement) => void;
}

function WindowInner<T>(
  {
    data,
    children,
    itemSize,
    className,
    height,
    getItemKey,
    onEndEdgeReach,
    onStartEdgeReach,
    isStartLoading,
    isEndLoading,
    onStartItemChange,
    onEndItemChange
  }: Props<T>,
  ref: MutableRefObject<WindowRef>
) {
  const numOfItems = data.length;

  const {ref: windowRef, width} = useElementSize<HTMLDivElement>();
  const totalScrollSize = numOfItems > 0 ? numOfItems * itemSize - width : 0;

  const firstImageRef = useRef<{
    firstImageKey?: string;
    firstImageOffsetFromStart: number;
  }>();
  const [scrollPosition, setScrollPosition] = useState<number>();
  const debouncedSetScrollPositionRef = useRef(debounce(setScrollPosition, 200));

  const scrollingContainerStyles = useMemo(
    () => ({width: numOfItems * itemSize, height}),
    [numOfItems, itemSize, height]
  );

  const handleScroll = useCallback<UIEventHandler<HTMLDivElement>>(
    e => {
      // Not sure if DOM gives more that actual scroll, so added min-check of computed total vs what DOM said for safety
      const scrollPosition = Math.min((e.target as HTMLDivElement).scrollLeft, totalScrollSize);

      const firstNonoverlappingItemIndex = Math.ceil(scrollPosition / itemSize);
      const firstNonoverlappingItem = data[firstNonoverlappingItemIndex];
      if (!firstNonoverlappingItem) {
        console.error(`Bug: ${firstNonoverlappingItemIndex} not found in data`);
        return;
      }
      const firstNonoverlappingItemKey = getItemKey(firstNonoverlappingItem);
      const firstOverlappingItemPositionFromEdge = scrollPosition % itemSize;
      const firstNonoverlappingItemPositionFromEdge =
        firstOverlappingItemPositionFromEdge > 0
          ? itemSize - firstOverlappingItemPositionFromEdge
          : 0;

      firstImageRef.current = {
        firstImageKey: firstNonoverlappingItemKey,
        firstImageOffsetFromStart: firstNonoverlappingItemPositionFromEdge
      };

      debouncedSetScrollPositionRef.current(scrollPosition);
    },
    [getItemKey, itemSize, data, totalScrollSize]
  );

  const scrollByItems = useCallback(
    (itemsToScroll: number) => {
      const newScrollPosition = getScrollPosition(
        scrollPosition,
        itemsToScroll,
        itemSize,
        totalScrollSize
      );
      windowRef.current.scrollLeft = newScrollPosition;
    },
    [scrollPosition, itemSize, totalScrollSize, windowRef]
  );

  const scrollToItem = useCallback(
    (itemIndex: number, placement: ScrollPlacement) => {
      let scrollToPosition = itemIndex * itemSize;
      if (placement === 'end') {
        scrollToPosition = scrollToPosition - (width - itemSize);
      } else if (placement === 'center') {
        scrollToPosition = scrollToPosition - Math.floor(width / 2 - itemSize / 2);
      }
      windowRef.current.style.scrollBehavior = 'auto';
      windowRef.current.scrollLeft = scrollToPosition;
      windowRef.current.style.scrollBehavior = 'smooth';
    },
    [itemSize, width, windowRef]
  );

  useEffect(() => {
    windowRef.current.scrollLeft = scrollPosition;
  }, [scrollPosition, windowRef]);

  useEffect(() => {
    if (isStartLoading) {
      // loader pushes start item by `itemSize`,
      // so new position of start item must be adjusted to prevent jump while prepending data
      firstImageRef.current.firstImageOffsetFromStart = itemSize;
    }
  }, [isStartLoading, itemSize]);

  useEffect(() => {
    if (isEndLoading) {
      windowRef.current.scrollLeft = totalScrollSize + itemSize;
    }
  }, [isEndLoading, itemSize, totalScrollSize, windowRef]);

  useLayoutEffect(() => {
    const {firstImageKey, firstImageOffsetFromStart = 0} = firstImageRef.current || {};
    const scrollContainer = windowRef.current;
    if (data.length === 0 || !firstImageKey || !scrollContainer) return;

    const firstImageIndex = data.findIndex(item => getItemKey(item) === firstImageKey);
    if (firstImageIndex === -1) return;

    const expectedFirstImageScrollPosition = firstImageIndex * itemSize - firstImageOffsetFromStart;

    scrollContainer.style.scrollBehavior = 'auto';
    scrollContainer.scrollLeft = expectedFirstImageScrollPosition;
    scrollContainer.style.scrollBehavior = 'smooth';
  }, [data, getItemKey, itemSize, windowRef]);

  const isReachedStartEdge = scrollPosition === 0;
  const isReachedEndEdge = scrollPosition === totalScrollSize;

  useDidMount(() => {
    if (isReachedStartEdge) {
      // scrollPosition = 0 doesn't mean scroll fully happened due to smoothness
      // lets wait for scroll to complete before firi
      onStartEdgeReach();
    }
  }, [isReachedStartEdge, onStartEdgeReach]);

  useDidMount(() => {
    if (isReachedEndEdge) {
      onEndEdgeReach();
    }
  }, [isReachedEndEdge, onEndEdgeReach]);

  useEffect(() => {
    ref.current = {
      scrollByItems,
      scrollToItem
    };
  }, [scrollByItems, scrollToItem, ref]);

  const startOverlappingItemIndex = Math.floor(scrollPosition / itemSize);

  // when fully scrolled, `(scrollPosition + width) / itemSize` returns num of items not last index
  // so added check `scrollPosition === totalScrollSize`
  const endOverlappingItemIndex =
    scrollPosition === totalScrollSize
      ? data.length - 1
      : Math.floor((scrollPosition + width) / itemSize);

  useEffect(() => {
    onStartItemChange(startOverlappingItemIndex);
  }, [onStartItemChange, startOverlappingItemIndex]);

  useEffect(() => {
    onEndItemChange(endOverlappingItemIndex);
  }, [onEndItemChange, endOverlappingItemIndex]);

  return (
    <div
      ref={windowRef}
      className={classNames(styles.container, className)}
      onScroll={handleScroll}
    >
      <div className={styles.scrollingContainer} style={scrollingContainerStyles}>
        {isStartLoading && <div style={getItemStyles(0)}>loading...</div>}
        {data.map(renderChildren)}
        {isEndLoading && <div style={getItemStyles(data.length)}>loading...</div>}
      </div>
    </div>
  );

  function getItemStyles(index: number): CSSProperties {
    return {
      position: 'absolute',
      left: index * itemSize,
      top: 0,
      width: itemSize,
      height
    };
  }

  function renderChildren(item: T, index: number, items: T[]) {
    return children({style: getItemStyles(index + (isStartLoading ? 1 : 0)), item, index, items});
  }
}

export const Window = forwardRef(WindowInner) as <T>(
  props: Props<T> & {ref?: MutableRefObject<{scrollByItems: (n: number) => void}>}
) => ReturnType<typeof WindowInner>;

// selecting image not reflecting when image is selected
// wrong time is selected
// selecting image causing vertical jump
// showing fitlers on top

function getScrollPosition(
  scrollPosition: number,
  itemsToScroll: number,
  itemSize: number,
  totalScrollSize: number
) {
  const newScrollPosition = scrollPosition + itemsToScroll * itemSize;

  if (newScrollPosition < 0) {
    return 0;
  }
  if (newScrollPosition > totalScrollSize) {
    return totalScrollSize;
  }

  return newScrollPosition;
}
