import {Expose, Type} from 'class-transformer';
import {Event} from '../types/Event';

export class EventModel implements Event {
  @Expose({name: 'timestamp'})
  @Type(() => Date)
  public timestamp: Date;

  @Expose({name: 'action'})
  public type: 1 | -1 | 0;

  @Expose({name: 'gate'})
  public gateId: string;

  @Expose({name: 'count'})
  public count: number;

  @Expose({name: 'video'})
  public videoId: string;
}
