import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {useCurrentProject} from '@entities/project';
import {useTranslation} from 'react-i18next';
import {GateService} from '../services/GateService.service';

export function useGates(regionId?: string) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('occupancy');
  const {data, isLoading, isError, error} = useQuery({
    queryKey: ['gates', projectId, regionId],
    queryFn: () => GateService.getGates(projectId, regionId),
    enabled: !!regionId
  });

  const errorMessage = isError && error.message;
  useEffect(() => {
    if (errorMessage) {
      ToastService.error(t('Failed to fetch gates'));
    }
  }, [errorMessage, t]);

  return {
    gates: data || [],
    isFetching: isLoading,
    isError
  };
}
