import {customerAPI} from '@shared/api';
import {plainToInstance} from 'class-transformer';
import {UserModel} from '@entities/user';

export class UserService {
  public static async getUsers(projectId: string) {
    const users = (await customerAPI.get(`users/${projectId}`).json()) as unknown[];

    return plainToInstance(UserModel, users);
  }

  public static async inviteUser(projectId: string, email: string) {
    await customerAPI
      .post('users/add-to-project', {
        json: {
          project_id: projectId,
          user_email: email
        }
      })
      .json();
  }

  public static async removeUser(projectId: string, userId: string) {
    return await customerAPI.delete(`users/${projectId}/${userId}`).json();
  }
}
