import {Expose} from 'class-transformer';
import {Camera} from '@shared/types/Camera';

export class CameraModel implements Camera {
  @Expose({name: 'uuid'})
  public id: string;

  @Expose({name: 'external_id'})
  public externalId: string;

  @Expose({name: 'name'})
  public name: string;
}
