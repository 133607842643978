import {useMemo} from 'react';
import {ComplianceInfo} from '@entities/ppeDashboard';
import {useTranslation} from 'react-i18next';
import {AxisChartOptions, TimeSeriesAggregation, TimeSeriesChart} from '@shared-app/ui';
import {TFunction} from 'i18next';
import styles from './NonComplianceByDateChart.module.scss';
import {getTotalNonComplianceHoursByDate} from '@entities/ppeDashboard';
import {NON_COMPLIANCE_LINE_CHART} from '@shared-app/lib';

interface Props {
  complianceInfo: ComplianceInfo[];
  isRelative?: boolean;
}

export function NonComplianceByDateChart({complianceInfo, isRelative}: Props) {
  const {t} = useTranslation('ppeDashboard');

  const manHourSeries = useMemo(
    () => getTotalNonComplianceHoursByDate(complianceInfo, isRelative),
    [complianceInfo, isRelative]
  );

  const shouldShowWeekly = manHourSeries.length >= 21;

  return (
    <TimeSeriesChart
      type="line"
      title={shouldShowWeekly ? t('PPE Non-Compliance By Week') : t('PPE Non-Compliance By Date')}
      series={[{name: isRelative ? t('% of man-hour') : t('man-hour'), data: manHourSeries}]}
      options={getChartOptions(t, isRelative)}
      aggregation={manHourSeries.length >= 21 ? TimeSeriesAggregation.WEEKLY : undefined}
      isRelative={isRelative}
    />
  );
}

function getChartOptions(t: TFunction, isRelative: boolean): AxisChartOptions {
  return {
    stroke: {
      width: 3
    },
    colors: NON_COMPLIANCE_LINE_CHART.COLORS,
    legend: {
      customLegendItems: [t('All Non-Compliance value')]
    },
    markers: {
      size: 4,
      colors: NON_COMPLIANCE_LINE_CHART.MARKER_FILL_COLOR,
      strokeColors: NON_COMPLIANCE_LINE_CHART.MARKER_STROKE_COLOR,
      strokeWidth: 1,
      hover: {
        sizeOffset: 1
      }
    },
    yaxis: {
      title: (
        <div className={styles.yaxisTitle}>
          <div>{isRelative ? `(${t('% of man-hour')})` : `(${t('man-hour')})`}</div>
          <div>{t('Non-compliance amount')}</div>
        </div>
      )
    }
  };
}
