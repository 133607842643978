import {ProtectedLayoutWrapper} from '@entities/auth';
import ProjectLayout from './ProjectLayout';

export function ProjectLayoutProtected() {
  return (
    <ProtectedLayoutWrapper>
      <ProjectLayout />
    </ProtectedLayoutWrapper>
  );
}
