import {BaseSelectProps, Select as SelectMui, SelectProps as MuiSelectProps} from '@mui/material';
import styles from './Select.module.scss';
import classNames from 'classnames';
import {KeyboardArrowDownIcon} from '../icons';

interface RoundedSelectProps<T> extends Omit<BaseSelectProps<T>, 'variant'> {
  variant: 'rounded';
}

export type SelectProps<T = unknown> = MuiSelectProps<T> | RoundedSelectProps<T>;

export function Select({className, variant, classes, ...restProps}: SelectProps) {
  const {icon: iconClassName, ...restClasses} = classes || {};
  return (
    <SelectMui
      className={classNames(className, styles.root, {
        [styles.variant__rounded]: variant === 'rounded'
      })}
      IconComponent={KeyboardArrowDownIcon}
      classes={{icon: classNames(styles.icon, iconClassName), ...restClasses}}
      variant={variant === 'rounded' ? 'outlined' : variant}
      disableUnderline={variant === 'filled'}
      {...restProps}
    />
  );
}
