import {useTranslation} from 'react-i18next';
import {useMutation} from '@shared/lib/hooks';
import {useCurrentProject} from '@entities/project';
import {StatusReport} from '@shared-app/types';
import {StatusReportService} from '../services/StatusReport.service';
import {makeReportListQueryKey} from './utils/makeReportListQueryKey';
import {HTTPError} from 'ky';

export function useGenerateStatusReport() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('statusReport');
  const listQueryOptions = {queryKey: makeReportListQueryKey(projectId)};

  const {
    mutate: generateReport,
    isLoading: isInProgress,
    isSuccess: isSaved,
    data: report
  } = useMutation<{cameraId: string; date: Date; name: string}, StatusReport>({
    mutationFn: ({cameraId, date, name}) => {
      return StatusReportService.generateReport(projectId, {cameraId, date, name}).catch(
        (err: HTTPError) => {
          if (err.response.status === 404) {
            throw new Error(
              t('No images found, please try to select another date or image source')
            );
          }
          throw new Error(
            t('Error occurred while generating the report. Please try to generate again.')
          );
        }
      );
    },
    options: listQueryOptions
  });

  return {
    generateReport,
    isInProgress,
    isSuccess: isSaved && report,
    report
  };
}
