import {useCallback, useEffect, useMemo} from 'react';
import {useProjectTimezone} from '@entities/project';
import {RegionSelect, useRegions} from '@entities/occupancy';
import {useOccupancyFilters} from '../lib/hook/useOccupancyFilters';
import {useTranslation} from 'react-i18next';
import {IconButton} from '@shared/ui/Button';
import {ReplayIcon} from '@shared/ui/icons';
import {FiltersSkeleton} from './FiltersSkeleton';
import {DateTimeInput} from '@shared/ui/Date';
import {
  dateRangeRelativeToDateRange,
  setZonedStartOfDay,
  setZonedEndOfDay
} from '@shared/lib/utils';
import {addDays, addMilliseconds, differenceInDays, isAfter, subMilliseconds} from 'date-fns';
import styles from './Filters.module.scss';
import {ONE_DAY_IN_MS} from '@shared/lib/constants';

const FILTER_ALLOWED_RANGE_IN_DAYS = 30;

export function Filters() {
  const {t} = useTranslation();
  const timezone = useProjectTimezone();
  const {regionId, dateRange, setDateRange, setRegionId} = useOccupancyFilters();

  const {startDate, endDate} = useMemo(() => dateRangeRelativeToDateRange(dateRange), [dateRange]);

  const handleStartDateChange = useCallback(
    (startDate: Date) => {
      const endDateValidated =
        isAfter(startDate, endDate) ||
        differenceInDays(endDate, startDate) >= FILTER_ALLOWED_RANGE_IN_DAYS
          ? addMilliseconds(startDate, ONE_DAY_IN_MS - 1)
          : dateRange.endDate;
      setDateRange({startDate, endDate: endDateValidated});
    },
    [endDate, setDateRange, dateRange.endDate]
  );

  const setWholeDay = useCallback(
    (date: Date) => {
      const now = new Date();
      const endOfEndDate = setZonedEndOfDay(date, timezone);
      const endDateValidated = isAfter(endOfEndDate, now) ? now : endOfEndDate;
      setDateRange({
        startDate: setZonedStartOfDay(date, timezone),
        endDate: endDateValidated
      });
    },
    [setDateRange, timezone]
  );

  const handleStartDateWholeDayClick = useCallback(
    () => setWholeDay(startDate),
    [setWholeDay, startDate]
  );

  const handleEndDateWholeDayClick = useCallback(
    () => setWholeDay(endDate),
    [endDate, setWholeDay]
  );

  const reset = useCallback(() => {
    setDateRange({
      startDate: setZonedStartOfDay(new Date(), timezone),
      endDate: 'now'
    });
  }, [setDateRange, timezone]);

  const endPickerMaxDate = useMemo(() => {
    const endDate = subMilliseconds(addDays(startDate, FILTER_ALLOWED_RANGE_IN_DAYS), 1);
    const now = new Date();
    return isAfter(endDate, now) ? now : endDate;
  }, [startDate]);

  useEffect(() => {
    useOccupancyFilters.persist.rehydrate();
    reset();
  }, [setDateRange, timezone, reset]);

  const {regions, isFetching: isFetchingRegions} = useRegions();

  const initialRegionId = regions[0]?.id;
  useEffect(() => {
    if (!regionId && initialRegionId) {
      setRegionId(initialRegionId);
    }
  }, [regionId, initialRegionId, setRegionId]);

  if (isFetchingRegions) {
    return <FiltersSkeleton />;
  }

  return (
    <div className={styles.filters}>
      <RegionSelect regions={regions} onChange={setRegionId} value={regionId} />
      <DateTimeInput
        label={t('Start')}
        value={startDate}
        timezone={timezone}
        onChange={handleStartDateChange}
        maxDateTime={new Date()}
        leftActionLabel={t('Whole day')}
        onLeftActionClick={handleStartDateWholeDayClick}
      />
      <DateTimeInput
        label={t('End')}
        value={endDate}
        timezone={timezone}
        onChange={endDate => setDateRange({startDate, endDate})}
        minDate={startDate}
        minDateTime={startDate}
        maxDateTime={endPickerMaxDate}
        leftActionLabel={t('Whole day')}
        onLeftActionClick={handleEndDateWholeDayClick}
      />
      <IconButton className={styles.resetBtn} onClick={reset} color="primary">
        <ReplayIcon />
      </IconButton>
    </div>
  );
}
