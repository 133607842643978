import {DATE_FORMATS} from '@shared/lib/constants';
import {AccessTimeIcon, EntryIcon, ExitIcon} from '@shared/ui/icons';
import {formatInTimeZone} from 'date-fns-tz';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import styles from './Tooltip.module.scss';

interface TooltipOptions {
  series: number[][];
  seriesIndex: number;
  dataPointIndex: number;
}

interface TooltipProps {
  options: TooltipOptions;
  timestamps: number[];
  timezone: string;
}

export function Tooltip({options, timestamps, timezone}: TooltipProps) {
  const {series, dataPointIndex} = options;
  const {t} = useTranslation('occupancy');

  const gap = timestamps[1] - timestamps[0];

  const formattedTimeRange = [timestamps[dataPointIndex], timestamps[dataPointIndex] + (gap - 1)]
    .map(time => formatInTimeZone(time, timezone, DATE_FORMATS.DATE_TIME_24))
    .join(' - ');

  return (
    <div className={styles.root}>
      <div className={styles.time}>
        <AccessTimeIcon />
        {formattedTimeRange}
      </div>
      <div className={styles.countWrapper}>
        {<EntryIcon className={styles.entryIcon} />} {t('Entries')}
        <span className={styles.value}>{series[1][dataPointIndex]}</span>
      </div>
      <div className={styles.countWrapper}>
        {<ExitIcon />} {t('Exits')}
        <span className={styles.value}>{-series[2][dataPointIndex]}</span>
      </div>
      <div className={classNames(styles.countWrapper, styles.netflow)}>
        {t('Net Flow')}
        <span className={styles.value}>{series[0][dataPointIndex]}</span>
      </div>
    </div>
  );
}
