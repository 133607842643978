import {DateRange} from '@shared/ui/Date';
import {sitelensAPI} from '@shared/api';
import {plainToInstance} from 'class-transformer';
import {FrequencyStatistic} from '@shared/types/FrequencyStatistic';
import FrequencyStatisticModel from '../../model/FrequencyStatistic.model';
import {SearchQuery} from '@entities/smartSearchFilter';
import {getZonedDateRangeISOWithOffset, makeTagQueryForSearch} from '@shared-app/lib';

export default class FrequencyStatisticService {
  public static async getStatistic(
    projectId: string,
    {
      query,
      cameras,
      dateRange
    }: {
      query: SearchQuery;
      cameras: string[];
      dateRange: DateRange;
    },
    timezone: string
  ): Promise<FrequencyStatistic[]> {
    const {operator, tags} = query;

    const {startDate, endDate} = getZonedDateRangeISOWithOffset(dateRange, timezone);

    const statistic = (await sitelensAPI
      .post(`${projectId}/dashboard/frequency`, {
        json: {
          tags_out: [],
          query: {[operator]: tags.map(makeTagQueryForSearch)},
          cameras,
          time_range: [{from: startDate, to: endDate}]
        }
      })
      .json()) as unknown[];

    return plainToInstance(FrequencyStatisticModel, statistic);
  }
}
