import {Typography as TypographyMui} from '@mui/material';
import React from 'react';
import styles from './Typogpaphy.module.scss';
import classNames from 'classnames';

interface TypographyProps {
  classes?: string;
  children: React.ReactNode;
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'subtitle1' | 'subtitle2' | 'body1';
  gutterBottom?: boolean;
}

export function Typography({children, classes, variant, gutterBottom}: TypographyProps) {
  return (
    <TypographyMui
      variant={variant}
      className={classNames(styles[variant], classes)}
      gutterBottom={gutterBottom}
    >
      {children}
    </TypographyMui>
  );
}
