import {plainToInstance} from 'class-transformer';
import {GateModel} from '../model/Gate.model';
import {entryexitAPI} from '@shared/api';

export class GateService {
  static async getGates(projectId: string, regionId: string) {
    const gates = (await entryexitAPI
      .get(`${projectId}/regions/${regionId}/gates`)
      .json()) as unknown[];

    return plainToInstance(GateModel, gates);
  }
}
