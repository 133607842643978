import Skeleton from '@shared/ui/Skeleton';
import styles from './MilestoneListSkeleton.module.scss';
import classNames from 'classnames';

export function MilestoneListSkeleton() {
  return (
    <div className={styles.container}>
      <Skeleton
        className={styles.search}
        animation={'wave'}
        height={'4rem'}
        variant="rectangular"
      />
      {Array.from({length: 3}).map((_, index) => (
        <div key={index} className={classNames(styles.item)}>
          <Skeleton
            className={styles.listItem}
            height={'3rem'}
            animation={'wave'}
            variant="rectangular"
          />
        </div>
      ))}
    </div>
  );
}
