import {customerAPI} from '@shared/api';
import {plainToInstance} from 'class-transformer';
import FeatureFlagModel from '../model/FeatureFlag.model';

export class FeatureFlagService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async getFlags(projectId: string) {
    const {features: data} = (await customerAPI.get(`project/${projectId}/features`).json()) as {
      features: unknown[];
    };

    const flags = plainToInstance(FeatureFlagModel, data);

    return flags.filter(flag => !!flag.key);
  }

  static async updateFlags(projectId: string, enabledFlags: string[]) {
    const flags = (await customerAPI
      .put(`project/features`, {json: {project_id: projectId, features: enabledFlags}})
      .json()) as unknown[];

    return plainToInstance(FeatureFlagModel, flags);
  }
}
