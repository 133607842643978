import {useTranslation} from 'react-i18next';
import {MilestoneService} from '../services/Milestone.service';
import {makeListQueryKey} from './utils/milestone';
import {useMutation} from '@shared/lib/hooks';
import {Milestone} from '@shared/types/Milestone';
import {useCurrentProject} from '@entities/project';

type UnsavedMilestone = Omit<Milestone, 'id'>;

export function useCreateMilestone() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation(['milestone']);
  const listQueryOptions = {queryKey: makeListQueryKey(projectId)};

  const {
    mutate: createMilestone,
    isLoading: isSaving,
    isSuccess: isSaved,
    data: savedMilestone
  } = useMutation<UnsavedMilestone, Milestone>({
    mutationFn: data =>
      MilestoneService.createMilestone(projectId, data).catch(() => {
        throw new Error(t('Error occurred. Please try to create again.'));
      }),
    options: listQueryOptions
  });

  return {
    createMilestone,
    isSaving,
    isSaved: isSaved && savedMilestone,
    savedMilestone
  };
}
