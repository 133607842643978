import ProjectPage from '@pages/project';
import {createBrowserRouter, RouteObject} from 'react-router-dom';
import {Layout, ProjectLayout, SettingsLayout} from '@pages/layout';
import ProjectsPage from '@pages/projects';
import ErrorPage from '@pages/error';
import DashboardPage from '@pages/dashboard';
import {ROUTES} from '@shared/lib/constants';
import SmartImageDetailsPage from '@pages/smartImageDetail';
import {PPEDashboardPage} from '@pages/ppeDashboard';
import {CameraSettingsPage} from '@pages/settings/cameraSettings';
import {GeneralSettingsPage} from '@pages/settings/generalSettings';
import {TaxonomyPage} from '@pages/settings/taxonomy';
import {MilestoneSettingsPage} from '@pages/settings/milestoneSettings';
import {FeatureFlagsPage} from '@pages/settings/featureFlags';
import {UserSettingsPage} from '@pages/settings/userSettings';
import {StatusReportList} from '@pages/statusReport/statusReportList';
import {StatusReportDetailsPage} from '@pages/statusReport/statusReportDetails';
import {OccupancyDashboardPage} from '@pages/occupancy';

const routes: RouteObject[] = [
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ROUTES.ROOT.path,
        element: <ProjectsPage />
      }
    ]
  },
  {
    path: ROUTES.PROJECT.ROOT.path,
    element: <ProjectLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ROUTES.PROJECT.DASHBOARD.ROOT.path,
        element: <DashboardPage />
      },
      {
        path: ROUTES.PROJECT.PPE_DASHBOARD.ROOT.path,
        element: <PPEDashboardPage />
      },
      {
        path: ROUTES.PROJECT.IMAGE.LIST.path,
        element: <ProjectPage />
      },
      {
        path: ROUTES.PROJECT.CAMERAS.TIMELINE.path,
        element: <ProjectPage />
      },
      {
        path: ROUTES.PROJECT.IMAGE.DETAIL.path,
        element: <SmartImageDetailsPage />
      },
      {
        path: ROUTES.PROJECT.STATUS_REPORT.LIST.path,
        element: <StatusReportList />
      },
      {
        path: ROUTES.PROJECT.STATUS_REPORT.DETAIL.path,
        element: <StatusReportDetailsPage />
      },
      {
        path: ROUTES.PROJECT.OCCUPANCY.ROOT.path,
        element: <OccupancyDashboardPage />
      },
      {
        path: ROUTES.PROJECT.SETTINGS.ROOT.path,
        element: <SettingsLayout />,
        children: [
          {path: ROUTES.PROJECT.SETTINGS.GENERAL.ROOT.path, element: <GeneralSettingsPage />},
          {path: ROUTES.PROJECT.SETTINGS.TAXONOMY.ROOT.path, element: <TaxonomyPage />},
          {path: ROUTES.PROJECT.SETTINGS.MILESTONE.ROOT.path, element: <MilestoneSettingsPage />},
          {path: ROUTES.PROJECT.SETTINGS.USER.ROOT.path, element: <UserSettingsPage />},
          {path: ROUTES.PROJECT.SETTINGS.MILESTONE.ROOT.path, element: <MilestoneSettingsPage />},
          {path: ROUTES.PROJECT.SETTINGS.CAMERA.ROOT.path, element: <CameraSettingsPage />},
          {path: ROUTES.PROJECT.SETTINGS.FEATURE_FLAG.ROOT.path, element: <FeatureFlagsPage />}
        ]
      }
    ]
  }
];

const router = createBrowserRouter(routes);

export default router;
