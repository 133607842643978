export function RestoreIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.11657 16.5156C6.08524 16.5156 4.3149 15.8523 2.80557 14.5256C1.29624 13.2003 0.42257 11.5303 0.18457 9.51562H1.19557C1.46757 11.2356 2.25124 12.6656 3.54657 13.8056C4.84324 14.9456 6.36657 15.5156 8.11657 15.5156C10.0666 15.5156 11.7206 14.8366 13.0786 13.4786C14.4366 12.1206 15.1159 10.4663 15.1166 8.51562C15.1166 6.56563 14.4372 4.91129 13.0786 3.55263C11.7199 2.19396 10.0659 1.51496 8.11657 1.51562C7.0819 1.51562 6.10924 1.73429 5.19857 2.17163C4.28857 2.60896 3.48524 3.21096 2.78857 3.97762H5.26957V4.97762H1.11657V0.823625H2.11657V3.21163C2.8899 2.36296 3.7959 1.70162 4.83457 1.22762C5.87324 0.752958 6.96724 0.515625 8.11657 0.515625C9.22524 0.515625 10.2646 0.724292 11.2346 1.14162C12.2046 1.55896 13.0519 2.12996 13.7766 2.85463C14.5012 3.57929 15.0726 4.42696 15.4906 5.39762C15.9079 6.36763 16.1166 7.40696 16.1166 8.51562C16.1166 9.62429 15.9079 10.6636 15.4906 11.6336C15.0732 12.6036 14.5019 13.4513 13.7766 14.1766C13.0519 14.9013 12.2046 15.4723 11.2346 15.8896C10.2646 16.307 9.22524 16.5156 8.11657 16.5156ZM11.3196 12.3696L7.67357 8.72363V3.51562H8.67357V8.30762L12.0276 11.6616L11.3196 12.3696Z"
        fill="currentColor"
      />
    </svg>
  );
}
