import {
  QueryClient,
  QueryClientProvider as TanStackQueryClientProvider
} from '@tanstack/react-query';
import {ReactNode} from 'react';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

const oneHourInMs = 1000 * 60 * 60;

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: oneHourInMs,
      retry: false
    }
  }
});

type QueryClientProviderProps = {
  children: ReactNode;
};

export default function QueryClientProvider({children}: QueryClientProviderProps): ReactNode {
  return (
    <TanStackQueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </TanStackQueryClientProvider>
  );
}
