import {FindingsSummary} from '@shared-app/types/StatusReportDetails';
import {SummaryChip} from '@features/statusReport/SummaryChip';
import styles from './SummaryCardContent.module.scss';

interface SummaryCardContentProps {
  summary: FindingsSummary[];
  emptyMessage: string;
}

export function SummaryCardContent({summary, emptyMessage}: SummaryCardContentProps) {
  if (summary.length === 0) {
    return <div className={styles.empty}>{emptyMessage}</div>;
  }
  return (
    <div className={styles.summaries}>
      {summary.map(summary => (
        <SummaryChip key={summary.message} summary={summary} />
      ))}
    </div>
  );
}
