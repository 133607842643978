import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {CalendarViewMonthIcon, CameraIcon} from '@shared/ui/icons';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {ViewType} from '../lib/constants/ViewType';
import styles from './CameraListViewSwitch.module.scss';

interface CameraListViewSwitchProps {
  onChange: (v: ViewType) => void;
  viewType: ViewType;
}

export function CameraListViewSwitch({onChange, viewType}: CameraListViewSwitchProps) {
  const {t} = useTranslation('project');

  useEffect(() => {
    onChange(viewType);
  }, [viewType, onChange]);

  return (
    <div className={styles.container}>
      <div className={styles.helpText}>
        {viewType === ViewType.CAMERAS &&
          t('Click the camera to browse through images in a timeline view')}
      </div>
      <ToggleButtonGroup value={viewType} onChange={(e, value) => onChange(value)} exclusive>
        <ToggleButton value={ViewType.CAMERAS}>
          <CameraIcon className={styles.cameraIcon} />
        </ToggleButton>
        <ToggleButton value={ViewType.IMAGES}>
          <CalendarViewMonthIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
