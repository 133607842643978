import Skeleton from '@shared/ui/Skeleton';
import styles from './ProjectChartsSkeleton.module.scss';

export default function ProjectChartsSkeleton() {
  return (
    <div>
      <div>
        <Skeleton
          className={styles.header}
          animation={'wave'}
          variant="rectangular"
          width="20rem"
          height="2.4rem"
        />
        <Skeleton className={styles.chart} animation={'wave'} variant="rectangular" height="8rem" />
        <Skeleton
          className={styles.header}
          animation={'wave'}
          variant="rectangular"
          width="20rem"
          height="2.4rem"
        />
        <Skeleton
          className={styles.chart}
          animation={'wave'}
          variant="rectangular"
          height="36rem"
        />
      </div>
    </div>
  );
}
