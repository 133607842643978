import {useCurrentProject} from '@entities/project';
import {StatusReportService} from '../services/StatusReport.service';
import {useMutation} from '@shared/lib/hooks';
import {useTranslation} from 'react-i18next';
import {makeReportListQueryKey} from './utils/makeReportListQueryKey';

export function useDeleteStatusReport() {
  const {t} = useTranslation();
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const listQueryOptions = {queryKey: makeReportListQueryKey(projectId)};

  const {
    mutate: deleteReport,
    isLoading: isDeleting,
    isSuccess: isDeleted
  } = useMutation({
    mutationFn: (reportId: string) =>
      StatusReportService.deleteStatusReport(projectId, reportId).catch(() => {
        throw new Error(t('Error occurred while deleting the report. Please try to delete again.'));
      }),
    options: listQueryOptions
  });

  return {
    deleteReport,
    isDeleting,
    isDeleted
  };
}
