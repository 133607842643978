import {NavLink} from 'react-router-dom';
import styles from './SideNavItem.module.scss';

interface SideNavItemProps {
  to: string;
  icon: JSX.Element;
  label: string;
}

export function SideNavItem({to, icon, label}: SideNavItemProps) {
  return (
    <div className={styles.navItem}>
      <NavLink to={to} className={({isActive}) => (isActive ? styles.navItem__active : '')}>
        <div className={styles.navItem__icon}>{icon}</div>
        <div className={styles.navItem__title}>{label}</div>
      </NavLink>
    </div>
  );
}
