import {format} from 'date-fns';

export function minuteToTime(minutes: number, pattern: string): string {
  const date = new Date();
  date.setHours(0, 0, 0, 0);

  const time = format(new Date(date.getTime() + minutes * 60000), pattern);

  return time;
}
