import * as React from 'react';
import {useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import {Select, SelectChangeEvent} from '@shared/ui/Select';
import Checkbox from '@mui/material/Checkbox';
import SelectOption from '@shared/types/SelectOption';
import styles from './MultipleSelectCheckmarks.module.scss';
import classNames from 'classnames';
import {Button} from '@shared/ui/Button';
import {useTranslation} from 'react-i18next';

interface Props {
  id?: string;
  label?: string;
  options: SelectOption[];
  selected: string[];
  onChange?: (selected: string[]) => void;
  emptySelectionLabel?: string;
  enableSelectAll?: boolean;
  selectClassName?: string;
  className?: string;
  readOnly?: boolean;
  renderValue?: (value: string[]) => React.ReactNode;
}

const SELECT_ALL = `select-all`;
const UNSELECT_ALL = `unselect-all`;

export default function MultipleSelectCheckmarks({
  id,
  label,
  options,
  onChange,
  selected,
  renderValue,
  enableSelectAll,
  className,
  readOnly
}: Props) {
  const {t} = useTranslation();
  const [value, setValue] = React.useState<string[]>(selected);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: {value}
    } = event;

    let selected = typeof value === 'string' ? value.split(',') : value;

    if (selected.includes(SELECT_ALL)) {
      selected = options.map(item => item.value);
    }

    if (selected.includes(UNSELECT_ALL)) {
      selected = [];
    }

    onChange?.(selected);
    setValue(selected);
  };

  const areSomeSelected = selected.some(Boolean);

  return (
    <div>
      <FormControl
        className={`con-multiple-select ${styles.formControl} ${className}`}
        variant="filled"
      >
        <InputLabel id={`${id}-label`} shrink>
          {label}
        </InputLabel>
        <Select
          className={styles.select}
          label={label}
          labelId={`${id}-label`}
          id={id}
          multiple
          displayEmpty
          value={value}
          onChange={!readOnly ? handleChange : undefined}
          renderValue={renderValue}
          MenuProps={{classes: {list: styles.selectMenu}, autoFocus: false}}
          variant="filled"
        >
          {!readOnly && enableSelectAll ? (
            <MenuItem
              className={classNames(styles.menuItem, styles.selectAllMenuItem)}
              value={areSomeSelected ? UNSELECT_ALL : SELECT_ALL}
            >
              <Button variant="text">
                {areSomeSelected ? t('Deselect All') : t('Select All')}
              </Button>
            </MenuItem>
          ) : null}
          {options.map(item => (
            <MenuItem className={styles.menuItem} key={item.value} value={item.value}>
              <Checkbox checked={value.indexOf(item.value) > -1} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
