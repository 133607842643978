import {Region} from '../../lib/types';
import {Select} from '@shared/ui';
import {FormControl, FormHelperText, InputLabel} from '@shared/ui/Form';
import {MenuItem} from '@shared/ui/Menu';
import {SelectChangeEvent, SelectProps} from '@shared/ui/Select';
import {useTranslation} from 'react-i18next';
import styles from './RegionSelect.module.scss';

interface RegionSelectProps extends Omit<SelectProps, 'onChange'> {
  regions: Region[];
  errorText?: string;
  onChange: (v: string) => void;
}

export function RegionSelect({regions, errorText, onChange, ...restProps}: RegionSelectProps) {
  const {t} = useTranslation('occupancy');

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl variant="filled" placeholder={restProps.placeholder}>
      <InputLabel shrink>{t('Region')}</InputLabel>
      <Select
        {...restProps}
        className={styles.select}
        variant="filled"
        onChange={handleChange}
        required
        fullWidth
      >
        {regions.map(region => (
          <MenuItem className={styles.menuItem} key={region.id} value={region.id}>
            {region.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!errorText}>{errorText || ' '}</FormHelperText>
    </FormControl>
  );
}
