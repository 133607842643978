import {Filters, useOccupancyFilters} from '@features/occupancy/Filters';
import styles from './OccupancyDashboardPage.module.scss';
import {OccupancyCharts} from '@widgets/occupancy/OccupancyCharts';

export function OccupancyDashboardPage() {
  const {regionId} = useOccupancyFilters();
  return (
    <div className={styles.root}>
      <Filters />
      {regionId && <OccupancyCharts />}
    </div>
  );
}
