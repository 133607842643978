import {Finding} from '@shared-app/types/StatusReportDetails';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {FindingIndicator} from './FindingIndicator';
import {capitalizeFirstLetter} from '@shared/lib/utils';
import styles from './FindingsList.module.scss';

interface FindingsListProps {
  findings: Finding[];
  className?: string;
  indicatorClassName?: string;
}

export function FindingsList({findings, className, indicatorClassName}: FindingsListProps) {
  const {t} = useTranslation('statusReport');

  if (findings.length === 0) {
    return <div className={classNames(styles.list, className)}>{t('No important findings')}</div>;
  }

  return (
    <ul className={classNames(styles.list, className)}>
      {findings.map(finding => (
        <li key={finding.message}>
          <FindingIndicator className={indicatorClassName} indicator={finding.indicator} />
          {capitalizeFirstLetter(finding.message)}

          {finding.children.length > 0 && (
            <ul>
              {finding.children.map(childFinding => (
                <li key={childFinding.message}>
                  <FindingIndicator
                    indicator={childFinding.indicator}
                    className={indicatorClassName}
                  />
                  {capitalizeFirstLetter(childFinding.message)}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}
