import {Expose, Transform, Type} from 'class-transformer';
import LocationModel from './Location.model';
import {Location} from '@shared/types/Location';
import {ProjectSettings} from '@shared/types/ProjectSettings';

const returnNullIfDateInvalid = ({value}: {value: Date | undefined | null}) =>
  value && !isNaN(value.getTime()) ? value : null;

export default class ProjectSettingsModel implements ProjectSettings {
  @Expose({name: 'location'})
  @Type(() => LocationModel)
  public location: Location;

  @Expose({name: 'start_date'})
  @Type(() => Date)
  @Transform(returnNullIfDateInvalid)
  public startedOn: Date;
}
