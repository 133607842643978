import {useTranslation} from 'react-i18next';
import {UserService} from '../services/User.service';
import {makeListQueryKey} from './utils/user';
import {useMutation} from '@shared/lib/hooks';
import {useCurrentProject} from '@entities/project';

export function useRemoveUser() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('user');
  const listQueryOptions = {queryKey: makeListQueryKey(projectId)};

  const {
    mutate: removeUser,
    isLoading: isRemoving,
    isSuccess: isRemoved
  } = useMutation({
    mutationFn: (userId: string) =>
      UserService.removeUser(projectId, userId).catch(() => {
        throw new Error(t('Error occurred. Please try to delete again.'));
      }),
    options: listQueryOptions
  });

  return {
    removeUser,
    isRemoving,
    isRemoved
  };
}
