import {plainToInstance} from 'class-transformer';
import {RegionModel} from '../model/Region.model';
import {entryexitAPI} from '@shared/api';

export class RegionService {
  static async getRegions(projectId: string) {
    const regions = (await entryexitAPI.get(`${projectId}/regions`).json()) as unknown[];

    return plainToInstance(RegionModel, regions);
  }
}
