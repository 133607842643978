import {useProjectTimezone} from '@entities/project';
import {DATE_FORMATS} from '@shared/lib/constants';
import {formatInTimeZone} from 'date-fns-tz';
import styles from './LastEventTime.module.scss';

interface LastEventTimeProps {
  timestamp?: Date;
}

export function LastEventTime({timestamp}: LastEventTimeProps) {
  const timezone = useProjectTimezone();
  return (
    <div>
      {timestamp ? formatInTimeZone(timestamp, timezone, DATE_FORMATS.DATE) : '--'}
      <span className={styles.time}>
        {timestamp ? formatInTimeZone(timestamp, timezone, DATE_FORMATS.TIME_24_WIDE) : '--'}
      </span>
    </div>
  );
}
