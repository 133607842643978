import {Expose, Transform, Type} from 'class-transformer';
import {ProjectImageStatistics} from '@shared/types/ProjectImageStatistics';
import {CameraImageStatisticsModel} from './CameraImageStatistics.model';

export class ProjectImageStatisticsModel implements ProjectImageStatistics {
  @Expose({name: 'uuid'})
  public id: string;

  @Expose({name: 'createdOn'})
  @Type(() => Date)
  public createdOn: Date;

  @Expose({name: 'startedOn'})
  @Type(() => Date)
  public startedOn: Date;

  @Expose({name: 'total_processed'})
  public totalProcessedImages: number;

  @Expose({name: 'statistic_by_cameras'})
  @Type(() => CameraImageStatisticsModel)
  @Transform(({value}) => value || [], {toClassOnly: true})
  public camerasStatistics: CameraImageStatisticsModel[];
}
