import {TagEnum} from '@shared/types/TagEnum';
import {Switch} from '@shared/ui';
import TagIcon from '@shared/ui/TagIcon';
import styles from './PPEAttributeSwitch.module.scss';
import {ChangeEvent} from 'react';

interface Props {
  onChange: (enabled: boolean) => void;
  tagId: TagEnum;
  label: string;
  enabled: boolean;
}

export function PPEAttributeSwitch({enabled, onChange, tagId, label}: Props) {
  return (
    <div className={styles.container}>
      <Switch
        checked={enabled}
        onChange={(ev: ChangeEvent, checked: boolean) => onChange(checked)}
        size="small"
      />
      <div className={styles.labelAndIcon}>
        <TagIcon className={styles.tagIcon} stringId={tagId} />
        <div>{label}</div>
      </div>
    </div>
  );
}
