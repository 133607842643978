import styles from './TimelineChart.module.scss';
import React, {useCallback, useState} from 'react';
import {ChartTooltip} from '@shared/ui/TimelineChart/types/ChartTooltip';
import TimelineSVG, {TimelineSVGProps} from '@shared/ui/TimelineChart/ui/TimelineSVG';
import TimelineTooltip from '@shared/ui/TimelineChart/ui/TimelineTooltip';
import {useDebounce, useElementSize} from '@conxai/react-kit';

export interface Props {
  series: TimelineSVGProps['series'];
  options: TimelineSVGProps['options'];
  hideEmpty?: TimelineSVGProps['hideEmpty'];
  onSelect?: TimelineSVGProps['onSelect'];
  selectedTime?: Date;
  timezone?: string;
}

export default function TimelineChart({series, options, hideEmpty, selectedTime, onSelect}: Props) {
  const [tooltip, setTooltip] = useState<ChartTooltip>({
    show: false,
    title: '',
    x: 0,
    y: 0,
    width: 0,
    height: 0
  });
  const debouncedTooltip = useDebounce<ChartTooltip>(tooltip, 100);
  const {ref: chartParentRef, width: chartParentWidth} = useElementSize<HTMLDivElement>();
  const showTooltip = useCallback((chartTooltip?: ChartTooltip) => {
    if (chartTooltip === undefined) {
      setTooltip(prevState => ({...prevState, show: false}));
    } else {
      setTooltip({...chartTooltip, show: true});
    }
  }, []);

  return (
    <div className={styles.container} ref={chartParentRef}>
      <TimelineTooltip tooltip={debouncedTooltip} containerWidth={chartParentWidth} />
      <TimelineSVG
        selectedTime={selectedTime}
        onSelect={onSelect}
        width={chartParentWidth}
        tooltipCallback={showTooltip}
        series={series}
        options={options}
        hideEmpty={hideEmpty}
      />
    </div>
  );
}
