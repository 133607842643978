import styles from './SmartImageOverlayLabel.module.scss';

interface SmartImageOverlayLabelProps {
  children: React.ReactNode;
  className?: string;
}

export function SmartImageOverlayLabel({
  children,
  className = ''
}: SmartImageOverlayLabelProps): React.ReactNode {
  return <div className={`${className} ${styles.smartImageOverlayLabel}`}>{children}</div>;
}
