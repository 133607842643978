import {TagEnum} from '@shared/types/TagEnum';
import {TagTypeEnum} from '@shared/types/TagTypeEnum';

function tagTranslations() {
  return {
    tag: {
      [TagEnum.car]: 'Car',
      [TagEnum.concrete_bucket]: 'Concrete Bucket',
      [TagEnum.concrete_mixer]: 'Concrete Mixer',
      [TagEnum.concrete_pump_boom]: 'Concrete Pump Boom',
      [TagEnum.concrete_pump_hose]: 'Concrete Pump Hose',
      [TagEnum.concrete_pump_truck]: 'Concrete Pump Truck',
      [TagEnum.construction_forklift]: 'Construction Forklift',
      [TagEnum.dump]: 'Dump',
      [TagEnum.excavator]: 'Excavator',
      [TagEnum.helmet]: 'Helmet',
      [TagEnum.loader]: 'Loader',
      [TagEnum.mobile_crane]: 'Mobile Crane',
      [TagEnum.person]: 'Person',
      [TagEnum.pickup]: 'Pickup',
      [TagEnum.portable_toilet]: 'Portable Toilet',
      [TagEnum.safety_vests]: 'Safety Vests',
      [TagEnum.truck]: 'Truck',
      [TagEnum.van]: 'Van',
      [TagEnum.waste_container]: 'Waste Container',
      [TagEnum.frame_formwork]: 'Frame Formwork',
      [TagEnum.rebar]: 'Rebar',
      [TagEnum.slab_formwork]: 'Slab Formwork',
      [TagEnum.wet_concrete]: 'Wet Concrete',
      [TagEnum.person_ppe_compliant]: 'Person PPE',
      [TagEnum.person_ppe_violation]: 'Safety Violation',
      [TagEnum.person_helmet_vest]: 'Person / PPE',
      [TagEnum.person_no_helmet_no_vest]: 'Person / No PPE',
      [TagEnum.person_no_helmet_vest]: 'Person / No Helmet',
      [TagEnum.person_helmet_no_vest]: 'Person / No Vest',

      [TagEnum.person_helmet]: 'Person / Helmet',
      [TagEnum.person_vest]: 'Person / Vest',
      [TagEnum.person_no_helmet]: 'Person / No Helmet',
      [TagEnum.person_no_vest]: 'Person / No Vest',

      [TagEnum.bulldozer]: 'Bulldozer',
      [TagEnum.compactor]: 'Compactor',
      [TagEnum.dump_truck]: 'Dump Truck',
      [TagEnum.grader]: 'Grader',
      [TagEnum.mixer_truck]: 'Mixer Truck',
      [TagEnum.pickup_truck]: 'Pickup Truck',
      [TagEnum.scraper]: 'Scraper',
      [TagEnum.telescopic_handler]: 'Telescopic Handler',
      [TagEnum.tower_crane]: 'Tower Crane',
      [TagEnum.tractor]: 'Tractor',
      [TagEnum.conex]: 'Conex',
      [TagEnum.dumpster]: 'Dumpster',
      [TagEnum.pylon]: 'Pylon',
      [TagEnum.rebar_bundle]: 'Rebar Bundle',
      [TagEnum.safety_vest]: 'Safety Vest',
      [TagEnum.silo]: 'Silo',
      [TagEnum.waste_bin]: 'Waste Bin',
      [TagEnum.guardrail]: 'Guardrail',
      [TagEnum.scaffolding]: 'Scaffolding',
      [TagEnum.formwork_slab]: 'Formwork Slab',
      [TagEnum.concrete_wet]: 'Concrete Wet',
      [TagEnum.formwork_wall]: 'Formwork Wall',
      [TagEnum.rebars]: 'Rebars'
    },
    description: {
      [TagEnum.car]: 'A car',
      [TagEnum.concrete_bucket]:
        'A large metal container used to transport and pour concrete at a construction site',
      [TagEnum.concrete_mixer]:
        'A device that homogeneously combines cement, aggregate such as sand or gravel, and water to form concrete',
      [TagEnum.concrete_pump_boom]:
        'A large, articulating arm mounted on a truck, used to deliver concrete to hard-to-reach areas',
      [TagEnum.concrete_pump_hose]:
        'A flexible hose used to transport concrete from a pump to the pouring location',
      [TagEnum.concrete_pump_truck]:
        'A truck equipped with a machine to transfer liquid concrete through a pump using pipes and hoses',
      [TagEnum.construction_forklift]:
        'A powerful industrial vehicle with a pronged device in front for lifting and carrying heavy loads at construction sites',
      [TagEnum.dump]:
        'A truck with a large container that can tilt to unload its contents, commonly used for transporting loose material',
      [TagEnum.excavator]:
        'A heavy construction equipment consisting of a boom, dipper, bucket, and cab on a rotating platform, used for digging',
      [TagEnum.helmet]: 'A protective helmet worn by construction workers to prevent head injuries',
      [TagEnum.loader]:
        'A heavy equipment machine used in construction to move aside or load materials such as asphalt, demolition debris, dirt, snow, feed, gravel, logs, raw minerals, recycled material, rock, sand, woodchips, etc',
      [TagEnum.mobile_crane]:
        'A crane capable of traveling over roads, designed to easily transport to a site and use for lifting heavy objects',
      [TagEnum.person]: 'An individual human being',
      [TagEnum.pickup]: 'A light motor vehicle with an open-top rear cargo area',
      [TagEnum.portable_toilet]:
        'A mobile toilet that can be easily moved around, often used at construction sites and outdoor events',
      [TagEnum.safety_vests]:
        'High-visibility vests worn by construction workers for safety and to be easily seen',
      [TagEnum.truck]: 'A large, heavy motor vehicle used for transporting goods or materials',
      [TagEnum.van]:
        'A type of vehicle used for transporting goods or people, smaller than a truck and larger than a car',
      [TagEnum.waste_container]:
        'A container for temporarily storing waste, commonly seen in various sizes at construction sites',
      [TagEnum.frame_formwork]:
        'A temporary mold into which concrete is poured to form structural elements like walls and columns',
      [TagEnum.rebar]: 'Steel bars or mesh used to reinforce concrete in construction',
      [TagEnum.slab_formwork]:
        'Molds used to shape and support concrete until it hardens, specifically for horizontal surfaces like floors and bridges',
      [TagEnum.wet_concrete]:
        'Freshly mixed concrete before it hardens, used for construction purposes',
      [TagEnum.bulldozer]: 'Bulldozer',
      [TagEnum.compactor]: 'Compactor',
      [TagEnum.dump_truck]: 'Dump Truck',
      [TagEnum.grader]: 'Grader',
      [TagEnum.mixer_truck]: 'Mixer Truck',
      [TagEnum.pickup_truck]: 'Pickup Truck',
      [TagEnum.scraper]: 'Scraper',
      [TagEnum.telescopic_handler]: 'Telescopic Handler',
      [TagEnum.tower_crane]: 'Tower Crane',
      [TagEnum.tractor]: 'Tractor',
      [TagEnum.conex]: 'Conex',
      [TagEnum.dumpster]: 'Dumpster',
      [TagEnum.pylon]: 'Pylon',
      [TagEnum.rebar_bundle]: 'Rebar Bundle',
      [TagEnum.safety_vest]: 'Safety Vest',
      [TagEnum.silo]: 'Silo',
      [TagEnum.waste_bin]: 'Waste Bin',
      [TagEnum.guardrail]: 'Guardrail',
      [TagEnum.scaffolding]: 'Scaffolding',
      [TagEnum.formwork_slab]: 'Formwork Slab',
      [TagEnum.concrete_wet]: 'Concrete Wet',
      [TagEnum.formwork_wall]: 'Formwork Wall',
      [TagEnum.rebars]: 'Rebars',
      [TagEnum.person_ppe_compliant]: 'Person PPE',
      [TagEnum.person_ppe_violation]: 'Safety Violation',
      [TagEnum.person_helmet_vest]: 'Person / PPE',
      [TagEnum.person_no_helmet_no_vest]: 'Person / No PPE',
      [TagEnum.person_no_helmet_vest]: 'Person / No Helmet',
      [TagEnum.person_helmet_no_vest]: 'Person / No Vest',

      [TagEnum.person_helmet]: 'Person / Helmet',
      [TagEnum.person_vest]: 'Person / Vest',
      [TagEnum.person_no_helmet]: 'Person / No Helmet',
      [TagEnum.person_no_vest]: 'Person / No Vest'
    },
    type: {
      [TagTypeEnum.equipment]: 'Equipment',
      [TagTypeEnum.objectsAndTools]: 'Objects & tools',
      [TagTypeEnum.structures]: 'Structures',
      [TagTypeEnum.person]: 'Person',
      [TagTypeEnum.hoisting]: 'Hoisting',
      [TagTypeEnum.vehicle]: 'Vehicle'
    },
    Helmet: 'Helmet',
    'Safety Vest': 'Safety Vest',
    'Personal Protective Equipment': 'Personal Protective Equipment',
    'PPE Settings': 'PPE Settings',
    'Person wearing all selected attributes is considered to be using PPE':
      'Person wearing all selected attributes is considered to be using PPE',
    'Person not wearing any of selected attributes is considered to be violating safety protocol':
      'Person not wearing any of selected attributes is considered to be violating safety protocol'
  };
}

export default tagTranslations();
