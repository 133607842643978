import {addMilliseconds} from 'date-fns';

export function rangeDates(startDate: Date, stopDate: Date, stepInMS: number) {
  const result = new Array<Date>();
  let currentDate = startDate;
  while (currentDate < stopDate) {
    result.push(new Date(currentDate));
    currentDate = addMilliseconds(currentDate, stepInMS);
  }
  return result;
}
