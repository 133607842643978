import {useGetSavedSearches} from '@entities/savedSearch/hooks';
import {SavedSearchCard} from '../savedSearchCard/SavedSearchCard';
import styles from './SavedSearchList.module.scss';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SavedSearch} from '@shared/types/SavedSearch';
import {getFiltersFromSavedSearch, useSmartSearchFilter} from '@entities/smartSearchFilter';
import {SavedSearchListSkeleton} from './SavedSearchListSkeleton';
import {useGetMilestones} from '@entities/milestone/lib/hooks';
import {DeleteSavedSearchDialog} from './DeleteSavedSearchDialog';
import {tags} from '@entities/tag';

export function SavedSearchList() {
  const {t} = useTranslation('search');

  const {
    set: setSmartSearchFilters,
    currentSavedSearchId,
    lastSavedSearchId
  } = useSmartSearchFilter();
  const {milestones, isLoading: areMilestonesLoading} = useGetMilestones();
  const {savedSearches, isFetching: areSavedSearchesLoading} = useGetSavedSearches();

  const [savedSearchToDelete, setSavedSearchToDelete] = useState<string>();

  const handleApplySavedSearch = (savedSearch: SavedSearch) => {
    setSmartSearchFilters(getFiltersFromSavedSearch(savedSearch, tags, milestones));
  };

  if (areSavedSearchesLoading || areMilestonesLoading) {
    return <SavedSearchListSkeleton />;
  }

  if (savedSearches.length === 0) {
    return (
      <div className={styles.nodata}>{t('No searches have been saved yet', {ns: 'search'})}</div>
    );
  }

  return (
    <div className={styles.container}>
      {savedSearches.map(savedSearch => (
        <SavedSearchCard
          key={savedSearch.id}
          savedSearch={savedSearch}
          onDelete={setSavedSearchToDelete}
          onApply={handleApplySavedSearch}
          isSelected={savedSearch.id === currentSavedSearchId}
          isLastUsed={savedSearch.id === lastSavedSearchId}
        />
      ))}

      {savedSearchToDelete && (
        <DeleteSavedSearchDialog
          onClose={() => setSavedSearchToDelete(undefined)}
          savedSearch={savedSearches.find(({id}) => savedSearchToDelete === id)}
        />
      )}
    </div>
  );
}
