import {useCurrentProject, useProjectImageStatistics, useProjectTimezone} from '@entities/project';
import {CameraImageStatisticsModel} from '@entities/project/model/CameraImageStatistics.model';
import {DATE_FORMATS} from '@shared/lib/constants';
import {Camera} from '@shared/types/Camera';
import {ColumnDef, SortItem, Table} from '@shared/ui/Table';
import {TFunction} from 'i18next';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CameraListSkeleton} from './CameraListSkeleton';
import styles from './CameraSettingsPage.module.scss';
import {IngestImageButton} from '@features/cameraImageIngest';
import {CameraNameUpdate} from '@features/cameraNameUpdate';
import {formatInTimeZone} from 'date-fns-tz';

export function CameraSettingsPage() {
  const {project} = useCurrentProject();

  const {
    data: projectImageStatistics,
    isFetching: isFetchingProjectImageStatistics,
    isError
  } = useProjectImageStatistics();
  const timezone = useProjectTimezone();
  const {t} = useTranslation(['project']);

  const [sortModel, setSortModel] = useState<SortItem[]>([{field: 'date', sort: 'desc'}]);

  const camerasObject = useMemo(() => project.getCamerasObject(), [project]);

  const columns = useMemo(
    () => makeColumns(t, camerasObject, timezone),
    [t, camerasObject, timezone]
  );

  if (isFetchingProjectImageStatistics) {
    return (
      <div className={styles.container}>
        <CameraListSkeleton />
      </div>
    );
  }

  if (isError) {
    return t('Something went wrong', {ns: 'translation'});
  }

  return (
    <div className={styles.container}>
      <Table
        rows={projectImageStatistics.camerasStatistics}
        columns={columns}
        disableRowSelectionOnClick
        hideFooter
        hideFooterSelectedRowCount
        hideFooterPagination
        onSortModelChange={setSortModel}
        sortModel={sortModel}
      />
    </div>
  );
}

const makeColumns = (
  t: TFunction,
  camerasObject: {[k in string]: Camera},
  timezone: string
): ColumnDef[] => [
  {
    field: 'id',
    headerName: t('Camera Name'),
    flex: 1,
    renderCell: ({row: {id}}: {row: CameraImageStatisticsModel}) => (
      <CameraNameUpdate cameraId={id} camerasObject={camerasObject} />
    ),
    sortComparator: (id1, id2) => {
      const cameraName1 = camerasObject[id1]?.name || '';
      const cameraName2 = camerasObject[id2]?.name || '';
      return cameraName1.toLowerCase().localeCompare(cameraName2.toLowerCase());
    }
  },
  {
    field: 'externalId',
    headerName: t('ID'),
    flex: 2,
    renderCell: ({row: {id}}) => {
      const externalId = camerasObject[id]?.externalId;
      return <div className={styles.externalId}>{externalId}</div>;
    }
  },
  {
    field: 'firstImageOn',
    headerName: t('First Image'),
    flex: 1,
    valueFormatter: firstImageOn =>
      firstImageOn ? formatInTimeZone(firstImageOn, timezone, DATE_FORMATS.DATE_TIME) : undefined
  },
  {
    field: 'lastImageOn',
    headerName: t('Last Image'),
    flex: 1,
    valueFormatter: lastImageOn =>
      lastImageOn ? formatInTimeZone(lastImageOn, timezone, DATE_FORMATS.DATE_TIME) : undefined
  },
  {
    field: 'ingest',
    headerName: t('Ingest'),
    renderCell: ({row: {id}}) => {
      const externalId = camerasObject[id]?.externalId;
      if (!externalId) return null;
      return <IngestImageButton cameraId={externalId} />;
    },
    flex: 1
  },
  {
    field: 'totalImages',
    headerName: t('Total Images'),
    align: 'right',
    flex: 1
  }
];
