import {useQuery} from '@tanstack/react-query';
import {ProjectService} from '@entities/project';
import {ToastService} from '@shared/lib/services';
import {useEffect} from 'react';

export function useProjectCameras(projectId?: string) {
  const {data, isFetching, isError, error} = useQuery({
    queryKey: ['project-cameras', projectId],
    queryFn: projectId ? () => ProjectService.getProjectCameras(projectId) : undefined,
    enabled: !!projectId
  });

  const errorMessage = isError && error.message;

  useEffect(() => {
    if (errorMessage) {
      ToastService.error(errorMessage);
    }
  }, [errorMessage]);

  return {
    cameras: isError ? [] : data,
    isError,
    isFetching
  };
}
