import {useEffect, useState} from 'react';
import {
  useQueryClient,
  QueryStatus,
  InvalidateQueryFilters,
  QueryClient
} from '@tanstack/react-query';

import {ToastService} from '@shared/lib/services';

interface Options extends InvalidateQueryFilters {
  skipInvalidation?: boolean;
}

interface Params<T, D> {
  mutationFn: (p: T) => Promise<D>;
  onSuccess?: (c: QueryClient, data: D) => void;
  options: Options;
}

export function useMutation<T, D = unknown>({
  mutationFn,
  onSuccess,
  options: {skipInvalidation, ...invalidateOptions}
}: Params<T, D>) {
  const queryClient = useQueryClient();

  const [{status, data, error}, setMutationStatus] = useState<{
    data?: D;
    status?: QueryStatus;
    error?: Error;
  }>({});

  const mutate = (params: T) => {
    setMutationStatus({status: 'pending'});

    mutationFn(params)
      .then(data => {
        setMutationStatus({status: 'success', data});
        if (!skipInvalidation) queryClient.invalidateQueries(invalidateOptions);
        onSuccess?.(queryClient, data);
      })
      .catch(err => {
        setMutationStatus({status: 'error', error: err});
      });
  };

  useEffect(() => {
    if (error) {
      ToastService.error(error.message);
    }
  }, [error]);

  return {
    data,
    mutate,
    isLoading: status === 'pending',
    isSuccess: status === 'success',
    isError: status === 'error',
    error
  };
}
