import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useBoolean} from '@conxai/react-kit';
import {ToastService} from '@shared/lib/services';
import {ReportImageSkeleton} from './ReportImageSkeleton';
import {useSmartImage, useSmartImageData} from '@entities/smartImage';
import {useCurrentProject} from '@entities/project';
import {SmartImageWithEnlarge} from '@features/smartImageWithEnlarge';
import ProjectImageModal from '@widgets/project/projectImageModal';
import styles from './ReportImage.module.scss';

export function ReportImage({imageId}: {imageId: string}) {
  const {data: image, isFetching, error} = useSmartImage(imageId);
  const {t} = useTranslation('statusReport');
  const {project} = useCurrentProject();
  const {data: masks, isFetching: isImageDataFetching} = useSmartImageData(image);
  const [shouldShowImageDialog, , showActiveImageDialog, closeActiveImageDialog] = useBoolean();

  useEffect(() => {
    if (error) {
      ToastService.error(t('Error while getting image for report'));
    }
  }, [error, t]);

  if (isFetching || isImageDataFetching || !image) {
    return <ReportImageSkeleton />;
  }

  return (
    <>
      <SmartImageWithEnlarge
        className={styles.smartImage}
        masks={masks}
        onEnlarge={showActiveImageDialog}
        activeImage={image}
      />
      {shouldShowImageDialog ? (
        <ProjectImageModal
          image={image}
          onClose={closeActiveImageDialog}
          cameraName={project.getCameraById(image.cameraId)?.name}
        />
      ) : null}
    </>
  );
}
