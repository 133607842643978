import {Expose} from 'class-transformer';
import {Location} from '@shared/types/Location';

export default class LocationModel implements Location {
  @Expose({name: 'lat'})
  public lat: number;

  @Expose({name: 'long'})
  public long: number;

  @Expose({name: 'name'})
  public name: string;

  @Expose({name: 'timezone_name'})
  public timezone: string;
}
