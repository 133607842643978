import styles from './LoadingPagePlaceholder.module.scss';
import PlaceholderLogo from '../assets/placeholder-logo.svg';

export function LoadingPagePlaceholder() {
  return (
    <div className={styles.container}>
      <PlaceholderLogo className={styles.logo} />
    </div>
  );
}
