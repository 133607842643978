import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {SavedSearchService} from '../services/SavedSearch.service';
import {makeListQueryKey} from './utils/savedSearch';
import {useCurrentProject} from '@entities/project';

export function useGetSavedSearches() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {data, isLoading, isError, error} = useQuery({
    queryKey: makeListQueryKey(projectId),
    queryFn: () => SavedSearchService.getSavedSearches(projectId)
  });

  useEffect(() => {
    if (isError) {
      ToastService.error(error.message);
    }
  }, [isError, error]);

  return {
    savedSearches: data || [],
    isFetching: isLoading
  };
}
