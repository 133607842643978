import Skeleton from '@shared/ui/Skeleton';
import styles from './ChartsSkeleton.module.scss';

export function ChartsSkeleton() {
  return (
    <div className={styles.root}>
      <Skeleton className={styles.select} width={'40rem'} />
      <div className={styles.charts}>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
      </div>
    </div>
  );
}
