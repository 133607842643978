import {FormControl, InputLabel} from '@mui/material';
import {DatePresetSelect, DateRange, PresetKey} from '@shared/ui/Date';
import {useTranslation} from 'react-i18next';
import styles from './SearchRelativeDateSelect.module.scss';

interface Props {
  value: PresetKey;
  onChange: (preset: PresetKey, dateRange: DateRange) => void;
  timezone: string;
}

export function SearchRelativeDateSelect({onChange, value, timezone}: Props) {
  const {t} = useTranslation();

  return (
    <FormControl variant="filled" className={styles.root}>
      <InputLabel className={styles.label} shrink>
        {t('Date Period')}
      </InputLabel>
      <DatePresetSelect
        onChange={onChange}
        value={value}
        variant="filled"
        className={styles.dateSelect}
        timezone={timezone}
      />
    </FormControl>
  );
}
