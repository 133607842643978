import {useState} from 'react';
import {useGetMilestones} from '@entities/milestone/lib/hooks';
import {DATE_FORMATS} from '@shared/lib/constants';
import {ColumnDef, Table, SortModel, SortItem} from '@shared/ui/Table';
import {TFunction} from 'i18next';
import {Trans, useTranslation} from 'react-i18next';
import {Button, IconButton} from '@shared/ui/Button';
import {Milestone} from '@shared/types/Milestone';
import {CreateMilestoneDialog, DeleteMilestoneDialog} from '@entities/milestone/ui';
import styles from './MilestoneSettingsPage.module.scss';
import {MilestoneAddIcon} from '@shared/ui/MilestoneAddIcon';
import {Typography} from '@shared/ui/Typography';
import {TextField} from '@shared/ui/TextField';
import {CloseIcon} from '@shared/ui/CloseIcon';
import {DeleteIcon} from '@shared/ui/icons';
import {MilestoneListSkeleton} from './MilestoneListSkeleton';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';
import {useBoolean} from '@conxai/react-kit';

export function MilestoneSettingsPage() {
  const {t} = useTranslation(['milestone']);
  const timezone = useProjectTimezone();
  const [sortModel, setSortModel] = useState<SortItem[]>([{field: 'date', sort: 'desc'}]);
  const [isCreateMilestoneDialogOpen, , showCreateMilestone, hideCreateMilestone] =
    useBoolean(false);
  const [
    shouldUseSearchTextToCreateMilestone,
    ,
    useSearchTextToCreateMilestone,
    dontUseSearchTextToCreateMilestone
  ] = useBoolean(false);

  const {milestones, isLoading} = useGetMilestones();
  const [milestoneBeingDeleted, setMilestoneBeingDeleted] = useState<Milestone>();
  const [searchText, setSearchText] = useState('');

  const handleMilestoneEditClose = () => setMilestoneBeingDeleted(undefined);

  const columns = makeColumns(t, setMilestoneBeingDeleted, timezone);

  const handleSortChange = (sortModel: SortModel) => {
    setSortModel(sortModel);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearchText(searchText);
  };

  const clearSearch = () => {
    setSearchText('');
  };

  const handleAddMilestoneWithSearchText = () => {
    showCreateMilestone();
    useSearchTextToCreateMilestone();
  };

  const handleCreateMilestoneDialogClose = () => {
    hideCreateMilestone();
    dontUseSearchTextToCreateMilestone();
  };

  const filteredMilestones = milestones.filter(({name}) =>
    name.toLowerCase().includes(searchText.trim().toLowerCase())
  );

  return (
    <div className={styles.milestoneSettings}>
      <div className={styles.header}>
        <Typography variant="h2">{t('Milestones')}</Typography>
        <Button
          startIcon={<MilestoneAddIcon className={styles.addIcon} />}
          variant="contained"
          onClick={showCreateMilestone}
        >
          {t('Add New Milestone')}
        </Button>
      </div>
      <TextField
        className={styles.search}
        onChange={handleSearch}
        InputProps={{
          endAdornment: <CloseIcon className={styles.endAdornment} onClick={clearSearch} />
        }}
        placeholder={t('Start typing to filter milestones')}
        value={searchText}
      />
      {isLoading && <MilestoneListSkeleton />}
      {!isLoading && filteredMilestones.length === 0 && (
        <>
          <div>{t('No milestones found.')}</div>
          {milestones.length > 0 && (
            <div>
              <Trans i18nKey={'pressHereToAddMilestone'} t={t} ns={'milestone'}>
                Press here to
                <Button
                  onClick={handleAddMilestoneWithSearchText}
                  className={styles.addRequestedMilestoneBtn}
                  variant="text"
                >
                  Add New Milestone
                </Button>
                with requested name.
              </Trans>
            </div>
          )}
        </>
      )}
      {filteredMilestones.length > 0 && (
        <Table
          rows={filteredMilestones}
          loading={isLoading}
          columns={columns}
          disableRowSelectionOnClick
          hideFooter
          hideFooterSelectedRowCount
          hideFooterPagination
          onSortModelChange={handleSortChange}
          sortModel={sortModel}
        />
      )}
      {milestoneBeingDeleted && (
        <DeleteMilestoneDialog
          milestone={milestoneBeingDeleted}
          onSuccess={handleMilestoneEditClose}
          onClose={handleMilestoneEditClose}
        />
      )}
      {isCreateMilestoneDialogOpen && (
        <CreateMilestoneDialog
          onClose={handleCreateMilestoneDialogClose}
          defaultValue={{name: shouldUseSearchTextToCreateMilestone ? searchText : ''}}
          onSuccess={hideCreateMilestone}
        />
      )}
    </div>
  );
}

interface ActionsProps {
  onDelete: () => void;
}

function Actions({onDelete}: ActionsProps) {
  return (
    <IconButton onClick={onDelete} color="primary">
      <DeleteIcon className={styles.deleteIcon} />
    </IconButton>
  );
}

const makeColumns = (
  t: TFunction,
  onDelete: (milestone: Milestone) => void,
  timezone: string
): ColumnDef[] => [
  {field: 'name', headerName: t('Milestone Name'), flex: 8},
  {
    field: 'date',
    headerName: t('Date', {ns: 'translation'}),
    flex: 3,
    valueFormatter: value => formatInTimeZone(value, timezone, DATE_FORMATS.DATE)
  },
  {
    field: '',
    align: 'center',
    flex: 1,
    sortable: false,
    renderCell: ({row: milestone}) => <Actions onDelete={() => onDelete(milestone)} />
  }
];
