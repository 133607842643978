import {Expose, Transform, Type} from 'class-transformer';
import {SmartImageMask} from '@shared/types/SmartImageMask';
import CocoRLEModel from '@entities/smartImage/model/CocoRLE.model';
import {CocoRLE} from '@shared/types/CocoRLE';
import {BoundingBoxCoordinates} from '@shared/types/BoundingBoxCoordinates';

export default class SmartImageMaskModel implements SmartImageMask {
  @Expose({name: 'id'})
  public id: string;

  @Expose({name: 'bbox'})
  @Transform(
    ({value}): BoundingBoxCoordinates => ({
      xOffset: value[0],
      yOffset: value[1],
      width: value[2],
      height: value[3]
    })
  )
  public coordinates: BoundingBoxCoordinates;

  @Expose({name: 'category_id'})
  public tagId: number;

  @Expose({name: 'segmentation'})
  @Type(() => CocoRLEModel)
  public segmentation: CocoRLE;
}
