import {useBoolean} from '@conxai/react-kit';
import {useSmartImageData} from '@entities/smartImage';
import {useEffect, useState} from 'react';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {useTranslation} from 'react-i18next';
import {SmartImage} from '@shared/types/SmartImage';
import {Tag} from '@shared/types/Tag';
import {DATE_FORMATS} from '@shared/lib/constants';
import {SmartImageTags} from '@features/smartImageTags';
import {Button} from '@shared/ui/Button';
import {SmartImageWithTagsSkeleton} from './SmartImageWithTagsSkeleton';
import {CreateMilestoneDialog} from '@entities/milestone/ui';
import {formatInTimeZone} from 'date-fns-tz';
import {SmartImageWithEnlarge} from '@features/smartImageWithEnlarge';
import styles from './SmartImageWithTags.module.scss';

interface SmartImageWithTagsProps {
  activeImage: SmartImage;
  onEnlarge: () => void;
}

export function SmartImageWithTags({activeImage, onEnlarge}: SmartImageWithTagsProps) {
  const {t} = useTranslation('project');
  const {project} = useCurrentProject();
  const timezone = useProjectTimezone();
  const [activeTag, setActiveTag] = useState<Tag>();

  const {data: masks, isFetching} = useSmartImageData(activeImage);
  const [isCreateMilestoneDialogOpen, , showCreateMilestone, hideCreateMilestone] =
    useBoolean(false);
  const cameraName = project.getCameraById(activeImage.cameraId)?.name;

  useEffect(() => {
    setActiveTag(undefined);
  }, [activeImage]);

  if (isFetching) {
    return <SmartImageWithTagsSkeleton />;
  }

  return (
    <div className={styles.imageContainer}>
      <SmartImageWithEnlarge
        masks={masks}
        activeImage={activeImage}
        activeTag={activeTag}
        onEnlarge={onEnlarge}
      />
      <div className={styles.leftSection}>
        <div className={styles.info}>
          <div>
            <div className={styles.label}>{t('Camera', {ns: 'translation'})}</div>
            <div>{cameraName}</div>
          </div>
          <div>
            <div className={styles.label}>{t('Timestamp', {ns: 'translation'})}</div>
            <div>{formatInTimeZone(activeImage.dateTime, timezone, DATE_FORMATS.DATE_TIME)}</div>
          </div>
          <div>
            <div className={styles.label}>{t('Detected Tags')}</div>
            <SmartImageTags
              smartImageMasks={masks}
              activeTag={activeTag}
              setActiveTag={setActiveTag}
            />
          </div>
        </div>
        <div className={styles.createMilestoneBtn}>
          <Button variant="contained" size="small" onClick={showCreateMilestone}>
            {t('Create A Milestone')}
          </Button>
          {isCreateMilestoneDialogOpen && (
            <CreateMilestoneDialog
              onClose={hideCreateMilestone}
              defaultValue={{date: activeImage.dateTime}}
              disableDateChange
            />
          )}
        </div>
      </div>
    </div>
  );
}
