import {useEffect} from 'react';
import {CameraSelect, useCurrentProject, useProjectTimezone} from '@entities/project';
import {SearchRelativeDateSelect} from '@features/searchRelativeDateSelect';
import SelectDateRange from '@features/selectDateRange';
import {SelectTimeFrame} from '@features/selectTimeFrame';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import styles from './Filters.module.scss';

export function Filters() {
  const {
    cameras,
    setCameras,
    setDateRangePreset,
    dateRangePreset,
    dateRange,
    milestoneRange,
    setDateRange,
    setTimeRange,
    timeRange
  } = useSmartSearchFilter();

  useEffect(() => {
    useSmartSearchFilter.persist.rehydrate();
  }, []);

  const {project} = useCurrentProject();
  const timezone = useProjectTimezone();
  return (
    <div className={styles.filters}>
      <CameraSelect
        options={project.getCameraOptions()}
        selected={cameras || []}
        onChange={setCameras}
        className={styles.cameraSelect}
        enableSelectAll
      />
      <SearchRelativeDateSelect
        onChange={setDateRangePreset}
        value={dateRangePreset}
        timezone={timezone}
      />
      <SelectDateRange
        onChange={setDateRange}
        dateRange={dateRange}
        milestoneRange={milestoneRange}
        timezone={timezone}
        maxDateRange={183}
      />
      <SelectTimeFrame value={timeRange} onChange={setTimeRange} />
    </div>
  );
}
