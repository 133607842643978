import {ROUTES} from '@shared/lib/constants';
import styles from './HeaderShortLogo.module.scss';
import {Menu, MenuItem} from '@shared/ui/Menu';
import {Button} from '@shared/ui/Button';
import {useTranslation} from 'react-i18next';
import {ExpandMoreOutlined} from '@mui/icons-material';
import classNames from 'classnames';
import {useAnchorElement} from '@conxai/react-kit';
import {useNavigate} from 'react-router-dom';
import {CustomLogoShort} from '../CustomLogoShort/CustomLogoShort';

export function HeaderShortLogo() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {anchor, setAnchor, clearAnchor} = useAnchorElement();
  const open = Boolean(anchor);

  return (
    <>
      <Button onClick={setAnchor} className={classNames(styles.button, {[styles.active]: open})}>
        <CustomLogoShort />
        <span className={styles.expand}>
          <ExpandMoreOutlined fontSize={'large'} />
        </span>
      </Button>

      <Menu id="logo-menu" anchorEl={anchor} open={open} onClose={clearAnchor}>
        <MenuItem
          onClick={() => {
            navigate(ROUTES.ROOT.path);
          }}
        >
          {t('Back to Projects')}
        </MenuItem>
      </Menu>
    </>
  );
}
