import {FormControl as MuiFormControl, FormControlProps} from '@mui/material';
import classNames from 'classnames';
import styles from './FormControl.module.scss';

interface Props extends FormControlProps {
  children: React.ReactNode;
}

export function FormControl({className, children, ...restProps}: Props) {
  return (
    <MuiFormControl className={classNames(styles.root, className)} {...restProps}>
      {children}
    </MuiFormControl>
  );
}
