import {MouseEvent, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {SmartImage} from '@shared/types/SmartImage';
import styles from './ProjectImagesGrid.module.scss';
import {ROUTES} from '@shared/lib/constants';
import {Project} from '@shared/types/Project';
import {getImagesGroupedByDate} from '../utils/getImagesGroupedByDate';
import {ProjectImageSection} from '../../projectImageSection/ProjectImageSection';
import {useProjectTimezone} from '@entities/project';

interface Props {
  setActiveImage: (image: SmartImage | undefined) => void;
  project: Project;
  images: SmartImage[];
  onDateChange: (date: Date) => void;
}

export function ProjectImagesSection({setActiveImage, project, images, onDateChange}: Props) {
  const navigate = useNavigate();
  const timezone = useProjectTimezone();
  const {id: projectId} = project;
  const cameraById = useMemo(() => project.getCamerasObject(), [project]);
  const imagesByDate = useMemo(() => getImagesGroupedByDate(images, timezone), [images, timezone]);

  const onClickImageHandler = (image: SmartImage) => {
    setActiveImage(image);
  };

  const handleImageCardClick = (image: SmartImage) => (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.ctrlKey || event.metaKey) {
      navigate(ROUTES.PROJECT.IMAGE.DETAIL.withParams({projectId, imageId: image.id}));
    } else {
      onClickImageHandler(image);
    }
  };

  return (
    <div className={styles.imagesSection}>
      {Object.entries(imagesByDate).map(([date, images]) => (
        <ProjectImageSection
          onDateChange={onDateChange}
          cameraById={cameraById}
          key={date}
          images={images}
          date={date}
          onImageClick={handleImageCardClick}
        />
      ))}
    </div>
  );
}
