import {IconButton} from '@mui/material';
import {CloseIcon} from '@shared/ui/CloseIcon';
import styles from './DialogCloseButton.module.scss';
import classNames from 'classnames';

interface Props {
  onClick: () => void;
  className?: string;
}

export function DialogCloseButton({onClick, className}: Props) {
  return (
    <IconButton
      className={classNames(styles.closeBtn, className)}
      aria-label="close"
      onClick={onClick}
    >
      <CloseIcon />
    </IconButton>
  );
}
