import {MutableRefObject, useCallback, useEffect, useState} from 'react';

const checkVideoPlaying = (video: HTMLVideoElement) =>
  !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > HTMLMediaElement.HAVE_CURRENT_DATA
  );

interface VideoState {
  currentTime: number;
  duration: number;
  status: 'playing' | 'paused';
}

export function useVideo(videoRef: MutableRefObject<HTMLVideoElement>) {
  const [state, setState] = useState<VideoState>(INITIAL_STATE);

  const playPause = () => {
    const video = videoRef.current;
    if (!video) return;

    if (state.status === 'playing') {
      video.pause();
    } else {
      video.play();
    }
  };

  const handleVideoLoad = useCallback(() => {
    videoRef.current.currentTime = 0;
  }, [videoRef]);

  const seek = useCallback(
    (time: number) => {
      setState(state => ({
        ...state,
        currentTime: time
      }));
      videoRef.current.currentTime = time;
    },
    [videoRef]
  );

  const updateVideoState = useCallback((): NodeJS.Timeout => {
    const video = videoRef.current;
    if (!video) return;
    if (!checkVideoPlaying(video)) {
      setState(state => ({
        ...state,
        currentTime: video.ended ? state.duration : state.currentTime,
        status: 'paused'
      }));
      return;
    }
    setState({
      status: 'playing',
      currentTime: video.currentTime,
      duration: video.duration
    });
  }, [videoRef]);

  useEffect(() => {
    updateVideoState();
    const timerId = setInterval(updateVideoState, 250);
    return () => clearInterval(timerId);
  }, [updateVideoState]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    // gets fired when current video replaced by other video url
    video.addEventListener('emptied', handleVideoLoad);

    return () => {
      video.removeEventListener('emptied', handleVideoLoad);
    };
  });

  return {...state, playPause, seek};
}

const INITIAL_STATE: VideoState = {
  currentTime: 0,
  duration: 0,
  status: 'playing'
};
