import {Filters} from '@features/ppeDashboard/Filters';
import {Charts} from '@widgets/ppeDashboard';
import styles from './PPEDashboardPage.module.scss';

export function PPEDashboardPage() {
  return (
    <div className={styles.container}>
      <Filters />
      <Charts />
    </div>
  );
}
