import {Expose, Transform, Type} from 'class-transformer';
import {CameraModel} from './Camera.model';
import SelectOption from '@shared/types/SelectOption';
import {Project} from '@shared/types/Project';
import {Camera} from '@shared/types/Camera';
import {keyBy} from '@conxai/react-kit';
import {ProjectSettings} from '@shared/types/ProjectSettings';
import ProjectSettingsModel from './ProjectSettings.model';

const returnNullIfDateInvalid = ({value}: {value: Date | undefined | null}) =>
  value && !isNaN(value.getTime()) ? value : null;

export default class ProjectModel implements Project {
  @Expose({name: 'uuid'})
  public id: string;

  @Expose({name: 'name'})
  public name: string;

  @Expose({name: 'company_uuid'})
  public companyUuid: string;

  @Expose({name: 'settings'})
  @Type(() => ProjectSettingsModel)
  public settings: ProjectSettings;

  @Expose({name: 'created_at'})
  @Type(() => Date)
  @Transform(returnNullIfDateInvalid)
  public createdOn: Date;

  @Expose({name: 'totalProcessedImages'})
  public totalProcessedImages: number;

  @Expose({name: 'cameras'})
  @Type(() => CameraModel)
  @Transform(({value}) => value || [])
  public cameras: Camera[];

  @Expose({name: 'image_cover'})
  public coverImage: string;

  public getCameraOptions(): SelectOption[] {
    return this.cameras.map(camera => ({
      value: camera.id,
      label: camera.name
    }));
  }

  public getCameraIds(): string[] {
    return this.cameras.map(camera => camera.id);
  }

  public getCamerasObject() {
    return keyBy(this.cameras, 'id');
  }

  public getCameraById(cameraId: string) {
    return this.cameras.find(camera => camera.id === cameraId);
  }
}
