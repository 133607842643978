import {createRoot} from 'react-dom/client';
import React from 'react';
import './i18n/config';
import 'reflect-metadata';

import {RouterProvider} from 'react-router-dom';
import router from '@app/router';
import '@shared/scss/main.scss';
import {StyledEngineProvider} from '@mui/material';

import {ErrorBoundary} from 'react-error-boundary';
import ErrorPage from '@pages/error';
import QueryClientProvider from './providers/QueryClientProvider';
import {Toaster} from '@shared/ui/Toaster';
import './config/Amplify';
import './config/Sentry';
import './config/DateFns';

const root = document.getElementById('root');

if (!root) {
  throw new Error('root not found');
}

const container = createRoot(root);

container.render(
  // <React.StrictMode>
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <QueryClientProvider>
      <StyledEngineProvider injectFirst>
        <RouterProvider router={router} />
        <Toaster />
      </StyledEngineProvider>
    </QueryClientProvider>
  </ErrorBoundary>
  // </React.StrictMode>
);
