import {useEffect} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {Breadcrumbs, Link} from '@mui/material';
import {useSmartImage} from '@entities/smartImage';
import {ToastService} from '@shared/lib/services';
import {SmartImageWithTags} from '@widgets/project/smartImageWithTags';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import styles from './SmartImageDetail.module.scss';

export default function SmartImageDetailPage() {
  const {imageId} = useParams();
  const {data: image, isFetching, error} = useSmartImage(imageId);

  useEffect(() => {
    if (error) {
      ToastService.error(error.message);
    }
  }, [error]);

  if (isFetching) {
    return 'Loading...';
  }

  if (error) {
    return 'Something went wrong';
  }

  return (
    <div className={styles.smartImageDetailPage}>
      <Breadcrumbs className={styles.breadcrumbs} aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{display: 'flex', alignItems: 'center'}}
          color="inherit"
          to="../"
          relative="path"
          component={NavLink}
        >
          <NavigateBeforeIcon fontSize="large" />
          Back to Images
        </Link>
      </Breadcrumbs>
      <div className={styles.container}>
        <SmartImageWithTags image={image} />
      </div>
    </div>
  );
}
