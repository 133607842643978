import {useEffect, useState} from 'react';
import SmartImageService from '@entities/smartImage/lib/services/SmartImage.service';
import {imagePlaceholderUrl} from '@shared/lib/constants';
import {SmartImage} from '@shared/types/SmartImage';

export function usePlaceholderThumbnailUrl(image: SmartImage) {
  const [placeholderThumbnailUrl, setPlaceholderThumbnailUrl] = useState<string | undefined>();

  useEffect(() => {
    setPlaceholderThumbnailUrl(undefined);
  }, [image]);

  useEffect(() => {
    if (!placeholderThumbnailUrl) {
      SmartImageService.getImageByUrl(image.presignedUrls.image_thumb_low)
        .then(imageUrl => {
          setPlaceholderThumbnailUrl(imageUrl);
        })
        .catch(() => {
          setPlaceholderThumbnailUrl(imagePlaceholderUrl);
        });
    }
  }, [image.presignedUrls.image_thumb_low, placeholderThumbnailUrl]);

  return {
    placeholderThumbnailUrl
  };
}
