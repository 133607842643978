import {sitelensAPI} from '@shared/api';
import {plainToInstance} from 'class-transformer';
import ComplianceInfoModel from '../model/ComplianceInfo.model';
import {DateRange} from '@shared/ui/Date';
import {getZonedDateRangeISOWithOffset} from '@shared-app/lib';

interface GetComplianceInfoParams {
  cameras: string[];
  dateRange: DateRange;
}

export class PPEDashboardService {
  public static async getComplianceInfo(
    projectId: string,
    params: GetComplianceInfoParams,
    timezone: string
  ) {
    const {dateRange, cameras} = params;

    const {startDate, endDate} = getZonedDateRangeISOWithOffset(dateRange, timezone);

    const data = (await sitelensAPI
      .post(`${projectId}/ppe/dashboard`, {
        json: {
          cameras: cameras,
          time_range: [
            {
              from: startDate,
              to: endDate
            }
          ]
        }
      })
      .json()) as unknown[];
    return plainToInstance(ComplianceInfoModel, data);
  }
}
