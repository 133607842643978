import Logo from '@widgets/header/assets/logo-short.svg';
import BauwatchShortLogo from '@widgets/header/assets/bauwatch-short.png';
import {useAuth} from '@entities/auth';

export function CustomLogoShort() {
  const {user} = useAuth();

  if (user?.email.endsWith('@bauwatch.com')) {
    return <img width={60} src={BauwatchShortLogo} alt="Bauwatch logo" />;
  }

  return <Logo width={38} height={38} />;
}
