import Skeleton from '@shared/ui/Skeleton';
import styles from './CameraListSkeleton.module.scss';

export function CameraListSkeleton() {
  return (
    <div className={styles.container}>
      {Array.from({length: 24}).map((_, index) => (
        <Skeleton key={index} className={styles.skeleton} />
      ))}
    </div>
  );
}
