import {Event, Gate} from '@entities/occupancy';
import {useTranslation} from 'react-i18next';
import styles from './EventVideo.module.scss';
import {useEventVideo} from '../lib/hooks/useEventVideo';
import {CircularLoader} from '@shared/ui/Loader';
import classNames from 'classnames';
import {useCallback, useEffect, useRef} from 'react';
import {Video} from '@shared-app/ui/Video/ui/Video';
import {useDetectionsBoundingBox} from '../lib/hooks/useDetectionsBoundingBox';

interface EventVideoProps {
  event: Event;
  gates: Gate[];
}

export function EventVideo({event, gates}: EventVideoProps) {
  const {t} = useTranslation('occupancy');

  return (
    <div className={styles.container}>
      {event ? <EventVideoInner event={event} gates={gates} /> : t('Select event to play')}
    </div>
  );
}

function EventVideoInner({event, gates}: EventVideoProps) {
  const fullscreenElRef = useRef<HTMLSpanElement>();

  const cameraId = gates.find(gate => event.gateId === gate.id)?.cameraId;

  const {ref: videoRef, isFetching} = useEventVideo(cameraId, event.videoId);
  const boxPositions = useDetectionsBoundingBox(videoRef, isFetching);

  const handleFullscreen = useCallback(() => {
    fullscreenElRef.current.requestFullscreen();
  }, []);

  useEffect(() => {
    if (!isFetching && videoRef.current) {
      // I belive HLSJS causing events to fire late. by the time `isFechting` becomes false, video played few seconds already
      // So, instead of using `autoPlay` manual starting video by resetting `currentTime`
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  }, [isFetching, videoRef]);

  return (
    <>
      <span
        className={classNames(styles.videoWrapper, {
          [styles.videoWrapper__loading]: isFetching
        })}
        ref={fullscreenElRef}
      >
        <Video ref={videoRef} onFullscreen={handleFullscreen}></Video>
        {boxPositions.map((position, i) => (
          <span key={i} style={position} className={styles.box}></span>
        ))}
      </span>
      {isFetching && <CircularLoader className={styles.spinner} />}
    </>
  );
}
