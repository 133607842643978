import styles from './SmartImageCard.module.scss';
import Skeleton from '@shared/ui/Skeleton';

export default function SmartImageCardSkeleton() {
  return (
    <div className={styles.card}>
      <figure className={styles.cover}>
        <Skeleton
          className={styles.cover__img}
          animation={'wave'}
          variant="rectangular"
          width="100%"
          height="100%"
        />
      </figure>
    </div>
  );
}
