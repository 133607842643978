import {RoutePath} from '@conxai/react-kit';

const ROOT = new RoutePath('');

//Project
const PROJECT_ROOT = ROOT.makeSubRoute('project');
const PROJECT_DETAIL = PROJECT_ROOT.makeSubRoute(':projectId');

const DASHBOARD_ROOT = PROJECT_DETAIL.makeSubRoute('dashboard');
const IMAGE_ROOT = PROJECT_DETAIL.makeSubRoute('images');
const IMAGE_DETAIL = IMAGE_ROOT.makeSubRoute(':imageId');
const CAMERA_TIMELINE = PROJECT_DETAIL.makeSubRoute('cameras/:cameraId');

const SETTINGS_ROOT = PROJECT_DETAIL.makeSubRoute('settings');
const TAXONOMY_ROOT = SETTINGS_ROOT.makeSubRoute('taxonomy');
const MILESTONE_SETTING_ROOT = SETTINGS_ROOT.makeSubRoute('milestone');
const USER_SETTINGS_ROOT = SETTINGS_ROOT.makeSubRoute('users');
const GENERAL_SETTINGS_ROOT = SETTINGS_ROOT.makeSubRoute('general');
const CAMERA_SETTINGS_ROOT = SETTINGS_ROOT.makeSubRoute('cameras');
const FEATURE_FLAGS_ROOT = SETTINGS_ROOT.makeSubRoute('feature-flags');

const PPE_DASHBOARD_ROOT = PROJECT_DETAIL.makeSubRoute('ppe-dashboard');

const STATUS_REPORT_ROOT = PROJECT_DETAIL.makeSubRoute('status-report');
const STATUS_REPORT_DETAIL = STATUS_REPORT_ROOT.makeSubRoute(':reportId');

const OCCUPANCY_ROOT = PROJECT_DETAIL.makeSubRoute('occupancy-dashboard');

const ROUTES = {
  ROOT,
  PROJECT: {
    ROOT: PROJECT_ROOT,
    LIST: PROJECT_ROOT,
    CAMERAS: {
      TIMELINE: CAMERA_TIMELINE
    },
    DASHBOARD: {
      ROOT: DASHBOARD_ROOT
    },
    IMAGE: {
      LIST: IMAGE_ROOT,
      DETAIL: IMAGE_DETAIL
    },
    SETTINGS: {
      ROOT: SETTINGS_ROOT,
      TAXONOMY: {
        ROOT: TAXONOMY_ROOT
      },
      MILESTONE: {
        ROOT: MILESTONE_SETTING_ROOT
      },
      USER: {
        ROOT: USER_SETTINGS_ROOT
      },
      GENERAL: {
        ROOT: GENERAL_SETTINGS_ROOT
      },
      CAMERA: {
        ROOT: CAMERA_SETTINGS_ROOT
      },
      FEATURE_FLAG: {
        ROOT: FEATURE_FLAGS_ROOT
      }
    },
    PPE_DASHBOARD: {
      ROOT: PPE_DASHBOARD_ROOT
    },
    STATUS_REPORT: {
      LIST: STATUS_REPORT_ROOT,
      DETAIL: STATUS_REPORT_DETAIL
    },
    OCCUPANCY: {
      ROOT: OCCUPANCY_ROOT
    }
  }
};

export default ROUTES;
