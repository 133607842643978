import {useEffect, useState} from 'react';
import SmartImageService from '@entities/smartImage/lib/services/SmartImage.service';
import {imagePlaceholderUrl} from '@shared/lib/constants';
import {useCurrentProject} from '@entities/project';
import {SmartImage} from '@shared/types/SmartImage';

export function useOriginalImageUrl(image: SmartImage) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const [imageUrl, setImageUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (image) {
      setIsLoading(true);
      SmartImageService.getImageByUrl(image.presignedUrls.image_original)
        .then(imageUrl => {
          const img = new Image();
          img.src = imageUrl;

          img.onload = () => {
            setImageUrl(imageUrl);
          };
          img.onerror = () => {
            setImageUrl(imagePlaceholderUrl);
            setIsError(true);
          };
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [image, projectId]);

  return {
    imageUrl,
    isLoading,
    isError
  };
}
