import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {StatusReportService} from '../services/StatusReport.service';
import {useCurrentProject} from '@entities/project';

export function useStatusReportDetails(reportId: string) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {data, isLoading, isError, error} = useQuery({
    queryKey: ['status-report-detail', projectId, reportId],
    queryFn: () => StatusReportService.getReportDetails(projectId, reportId)
  });

  useEffect(() => {
    if (isError) {
      ToastService.error(error.message);
    }
  }, [isError, error]);

  return {
    report: data,
    isFetching: isLoading
  };
}
