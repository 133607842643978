import {DATE_FORMATS} from '@shared/lib/constants';
import {formatInTimeZone} from 'date-fns-tz';
import styles from './getTimelineChartOptions.module.scss';

export function getTimelineChartOptions(timezone: string) {
  return {
    xaxis: {
      labels: {
        formatter: (timestamp: Date) => formatInTimeZone(timestamp, timezone, DATE_FORMATS.DATE)
      }
    },
    tooltip: {
      formatter: (seriesItem: [Date, number]) => {
        const [from, detectedHours] = seriesItem;
        const to = new Date(from);
        to.setHours(from.getHours() + Math.abs(detectedHours));
        return (
          <div className={styles.tooltip}>
            <div>{formatInTimeZone(from, timezone, DATE_FORMATS.DATE)}</div>
            <div className={styles.tooltipTimerange}>
              {formatInTimeZone(from, timezone, DATE_FORMATS.TIME)} -
              {formatInTimeZone(to, timezone, DATE_FORMATS.TIME)}
            </div>
          </div>
        );
      }
    }
  };
}
