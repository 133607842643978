export enum TagEnum {
  car = 'car',
  concrete_bucket = 'concrete_bucket',
  concrete_mixer = 'concrete_mixer',
  concrete_pump_boom = 'concrete_pump_boom',
  concrete_pump_hose = 'concrete_pump_hose',
  concrete_pump_truck = 'concrete_pump_truck',
  construction_forklift = 'construction_forklift',
  dump = 'dump',
  helmet = 'helmet',
  loader = 'loader',
  mobile_crane = 'mobile_crane',
  person = 'person',
  pickup = 'pickup',
  portable_toilet = 'portable_toilet',
  safety_vests = 'safety_vests',
  truck = 'truck',
  van = 'van',
  waste_container = 'waste_container',
  frame_formwork = 'frame_formwork',
  rebar = 'rebar',
  slab_formwork = 'slab_formwork',
  wet_concrete = 'wet_concrete',
  person_helmet_vest = 'person_helmet_vest',
  person_ppe_compliant = 'person_ppe_compliant',
  person_ppe_violation = 'person_ppe_violation',
  person_helmet = 'person_helmet',
  person_vest = 'person_vest',
  person_no_helmet = 'person_no_helmet',
  person_no_vest = 'person_no_vest',
  person_helmet_no_vest = 'person_helmet_no_vest',
  person_no_helmet_vest = 'person_no_helmet_vest',
  person_no_helmet_no_vest = 'person_no_helmet_no_vest',
  bulldozer = 'bulldozer',
  compactor = 'compactor',
  dump_truck = 'dump_truck',
  grader = 'grader',
  mixer_truck = 'mixer_truck',
  pickup_truck = 'pickup_truck',
  scraper = 'scraper',
  telescopic_handler = 'telescopic_handler',
  tower_crane = 'tower_crane',
  tractor = 'tractor',
  excavator = 'excavator',
  conex = 'conex',
  dumpster = 'dumpster',
  pylon = 'pylon',
  rebar_bundle = 'rebar_bundle',
  safety_vest = 'safety_vest',
  silo = 'silo',
  waste_bin = 'waste_bin',
  guardrail = 'guardrail',
  scaffolding = 'scaffolding',
  formwork_slab = 'formwork_slab',
  concrete_wet = 'concrete_wet',
  formwork_wall = 'formwork_wall',
  rebars = 'rebars'
}
