import {ROUTES} from '@shared/lib/constants';

export function isFiltersSearchParamStorageEnabled(urlpath: string) {
  return (
    ROUTES.PROJECT.PPE_DASHBOARD.ROOT.isMatch(urlpath) ||
    ROUTES.PROJECT.DASHBOARD.ROOT.isMatch(urlpath) ||
    ROUTES.PROJECT.IMAGE.LIST.isMatch(urlpath) ||
    ROUTES.PROJECT.CAMERAS.TIMELINE.isMatch(urlpath)
  );
}
