import {ROUTES} from '@shared/lib/constants';
import {List, ListItem, ListItemButton} from '@shared/ui/List';
import {useTranslation} from 'react-i18next';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import styles from './SettingsLayout.module.scss';
import {useCurrentProject} from '@entities/project';
import {useEffect} from 'react';

export function SettingsLayout() {
  const {t} = useTranslation('milestone');
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const isInSettingsRootPage = ROUTES.PROJECT.SETTINGS.ROOT.isMatch(pathname);
  const defaultSettingsPageUrl = ROUTES.PROJECT.SETTINGS.GENERAL.ROOT.withParams({projectId});

  useEffect(() => {
    if (isInSettingsRootPage) {
      navigate(defaultSettingsPageUrl);
    }
  }, [navigate, isInSettingsRootPage, defaultSettingsPageUrl]);

  return (
    <div className={styles.container}>
      <List className={styles.menu}>
        <ListItem disablePadding>
          <ListItemButton
            className={styles.menuButton}
            selected={ROUTES.PROJECT.SETTINGS.GENERAL.ROOT.isMatch(pathname)}
            LinkComponent={Link}
            to={ROUTES.PROJECT.SETTINGS.GENERAL.ROOT.withParams({projectId})}
          >
            {t('General', {ns: 'translation'})}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className={styles.menuButton}
            selected={ROUTES.PROJECT.SETTINGS.USER.ROOT.isMatch(pathname)}
            LinkComponent={Link}
            to={ROUTES.PROJECT.SETTINGS.USER.ROOT.withParams({projectId})}
          >
            {t('Users', {ns: 'translation'})}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className={styles.menuButton}
            selected={ROUTES.PROJECT.SETTINGS.MILESTONE.ROOT.isMatch(pathname)}
            LinkComponent={Link}
            to={ROUTES.PROJECT.SETTINGS.MILESTONE.ROOT.withParams({projectId})}
          >
            {t('Milestones', {ns: 'milestone'})}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className={styles.menuButton}
            selected={ROUTES.PROJECT.SETTINGS.TAXONOMY.ROOT.isMatch(pathname)}
            LinkComponent={Link}
            to={ROUTES.PROJECT.SETTINGS.TAXONOMY.ROOT.withParams({projectId})}
          >
            {t('Taxonomy', {ns: 'translation'})}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className={styles.menuButton}
            selected={ROUTES.PROJECT.SETTINGS.CAMERA.ROOT.isMatch(pathname)}
            LinkComponent={Link}
            to={ROUTES.PROJECT.SETTINGS.CAMERA.ROOT.withParams({projectId})}
          >
            {t('Cameras', {ns: 'translation'})}
          </ListItemButton>
        </ListItem>
      </List>
      <div className={styles.main}>
        <Outlet />
      </div>
    </div>
  );
}
