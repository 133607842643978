import styles from './EventList.module.scss';
import {Event, Gate} from '@entities/occupancy';
import {useProjectTimezone} from '@entities/project';
import {useMemo, useState} from 'react';
import {sortBy} from '@conxai/react-kit';
import {DateChange} from './DateChange';
import {addDays} from 'date-fns';
import {EventListTable} from './EventListTable';
import {useTranslation} from 'react-i18next';

interface EventListProps {
  events: Event[];
  gates: Gate[];
  setEventToPlay: (e: Event) => void;
  eventBeingPlayed?: Event;
  minDate: Date;
  maxDate: Date;
}

export function EventList({
  events,
  gates,
  setEventToPlay,
  eventBeingPlayed,
  minDate,
  maxDate
}: EventListProps) {
  const {t} = useTranslation('occupancy');
  const timezone = useProjectTimezone();
  const [selectedDate, setSelectedDate] = useState<Date>(minDate);

  const selectedDateEvents = useMemo(
    () =>
      events.filter(
        event => event.timestamp >= selectedDate && event.timestamp < addDays(selectedDate, 1)
      ),
    [events, selectedDate]
  );
  const sortedEvents = useMemo(
    () => sortBy(selectedDateEvents, event => event.timestamp, 'asc'),
    [selectedDateEvents]
  );

  return (
    <div className={styles.container}>
      <DateChange
        date={selectedDate}
        onChange={setSelectedDate}
        timezone={timezone}
        min={minDate}
        max={maxDate}
      />
      {sortedEvents.length ? (
        <EventListTable
          events={sortedEvents}
          gates={gates}
          setEventToPlay={setEventToPlay}
          eventBeingPlayed={eventBeingPlayed}
        />
      ) : (
        <div className={styles.empty}>{t('No events present for selected date')}</div>
      )}
    </div>
  );
}
