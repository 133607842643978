import {useEffect, useMemo} from 'react';
import {DialogContainer, DialogActions, DialogContent, DialogTitle} from '@shared/ui/Dialog';
import {TextField} from '@shared/ui/TextField';
import {useTranslation} from 'react-i18next';
import {BorderButton, Button, IconButton} from '@shared/ui/Button';
import {CloseIcon} from '@shared/ui/CloseIcon';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import {Camera} from '@shared/types/Camera';
import {useCreateSavedSearch} from '@entities/savedSearch/hooks';
import {CircularLoader} from '@shared/ui/Loader';
import {ToastService} from '@shared/lib/services';
import styles from './SaveSearchDialog.module.scss';
import {getSavedSearchDataFromFilters} from '@entities/smartSearchFilter';
import {TFunction} from 'i18next';
import {Formik} from 'formik';

interface Props {
  onClose: () => void;
  cameras: Camera[];
}

export function SaveSearchDialog({onClose}: Props) {
  const {t} = useTranslation(['search', 'translation']);

  const filters = useSmartSearchFilter();

  const {createSavedSearch, isSaving, saved} = useCreateSavedSearch();

  const handleSubmit = (formValues: FormValues) => {
    createSavedSearch({
      name: formValues.name,
      data: getSavedSearchDataFromFilters(filters)
    });
  };

  useEffect(() => {
    if (saved) {
      ToastService.success(t('Search successfully saved.'));
      onClose();
    }
  }, [saved, t, onClose]);

  const validate = useMemo(() => getValidator(t), [t]);

  return (
    <DialogContainer onClose={onClose} fullWidth open>
      <DialogTitle className={styles.title} variant="h5">
        {t('Save Search')}
        <IconButton className={styles.closeBtn} aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik onSubmit={handleSubmit} validate={validate} initialValues={{name: ''}}>
        {({handleSubmit, handleChange, handleBlur, touched, values, errors, isValid}) => (
          <>
            <DialogContent className={styles.content}>
              <form id="saveSearch" onSubmit={handleSubmit} noValidate>
                <TextField
                  name="name"
                  className={styles.searchName}
                  label={t('Search Name')}
                  error={touched.name && !!errors.name}
                  helperText={(touched.name && errors.name) || ' '}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  required
                  fullWidth
                />
              </form>
            </DialogContent>
            <DialogActions className={styles.actions}>
              <BorderButton onClick={onClose}>{t('Cancel', {ns: 'translation'})}</BorderButton>
              <Button
                type="submit"
                form="saveSearch"
                variant="contained"
                disabled={isSaving || !isValid}
                endIcon={isSaving ? <CircularLoader size="1em" color="inherit" /> : undefined}
              >
                {t('Save', {ns: 'translation'})}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </DialogContainer>
  );
}

interface FormValues {
  name: string;
}
interface FormErrors extends Partial<Record<keyof FormValues, string>> {}

function getValidator(t: TFunction) {
  return function (values: Partial<FormValues>) {
    const errors: FormErrors = {};

    if (!values.name) {
      errors.name = t('Please enter saved search name');
    }

    return errors;
  };
}
