export function StatusReportIcon() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M6.27758 21C5.78875 21 5.37027 20.8042 5.02216 20.4125C4.67405 20.0208 4.5 19.55 4.5 19V5C4.5 4.45 4.67405 3.97917 5.02216 3.5875C5.37027 3.19583 5.78875 3 6.27758 3H18.7206C19.2095 3 19.6279 3.19583 19.9761 3.5875C20.3242 3.97917 20.4982 4.45 20.4982 5V19C20.4982 19.55 20.3242 20.0208 19.9761 20.4125C19.6279 20.8042 19.2095 21 18.7206 21H6.27758ZM6.27758 19H18.7206V6.56168V5H16.9431H6.27758V19Z" />
        <ellipse cx="8.3364" cy="8.0325" rx="1.1528" ry="1.07327" />
        <ellipse cx="8.3364" cy="12.179" rx="1.1528" ry="1.07327" />
        <ellipse cx="8.3364" cy="16.3256" rx="1.1528" ry="1.07327" />
      </g>
    </svg>
  );
}
