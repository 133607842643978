import {FrequencyStatistic} from '@shared/types/FrequencyStatistic';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {TimeRange} from '@shared/ui/TimeRange';
import {useMemo} from 'react';
import {padAround} from '@conxai/react-kit';
import {WEEK_DAYS} from '@shared/lib/constants';
import {getDetectionHoursPerWeek, getColumnChartTickAmount} from '../lib/utils';
import {formatHours} from '../lib/utils/formatHours';
import {getHourRangeFromTimeRange} from '@shared-app/lib';

export interface WeekDayFrequencyColumnsChartProps {
  data: FrequencyStatistic[];
  timeRange: TimeRange;
}

export function WeekDayFrequencyColumnsChart({data, timeRange}: WeekDayFrequencyColumnsChartProps) {
  const series = useMemo(() => getSeriesFromData(data, timeRange), [data, timeRange]);

  const maxHoursInSeries = useMemo(
    () => Math.max(...series.map(({data}) => Math.max(...data))),
    [series]
  );

  const options = useMemo(() => getOptions(maxHoursInSeries), [maxHoursInSeries]);

  return <Chart series={series} options={options} type={'bar'} height={300} />;
}

// we will add empty values to data so that chart can align in center with smaller bars
// Just showing 7 bars in full-width, makes each bar very bigger
const REQUIRED_SERIES_LENGTH = 12;

function getSeriesFromData(data: FrequencyStatistic[], timeRange: TimeRange) {
  const [startHour, endHour] = getHourRangeFromTimeRange(timeRange);
  const totalHoursPerWeek = getDetectionHoursPerWeek(data, startHour, endHour);
  return [
    {
      name: '',
      data: padAround(totalHoursPerWeek, REQUIRED_SERIES_LENGTH, 0)
    }
  ];
}

const getOptions = (yAxisMaxValue: number): ApexOptions => ({
  chart: {
    id: 'weekday-frequency-statistic-columns-chart',
    selection: {
      enabled: true,
      type: 'x'
    },
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '75%'
    }
  },
  yaxis: {
    title: {text: 'Detected time (hours)'},
    ...getColumnChartTickAmount(yAxisMaxValue),
    labels: {
      minWidth: 20,
      formatter: formatHours
    },
    min: 0
  },
  xaxis: {
    tickPlacement: 'on',
    categories: padAround(WEEK_DAYS, REQUIRED_SERIES_LENGTH, '')
  },
  dataLabels: {
    enabled: false
  }
});
