export function PPEIcon() {
  return (
    <svg
      width="1.3em"
      height="1.3em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7561_79761)">
        <g clipPath="url(#clip1_7561_79761)">
          <path d="M7.75001 19.3766H2.95626C2.33978 19.3761 1.7487 19.131 1.31278 18.695C0.876867 18.2591 0.631753 17.668 0.631256 17.0516V8.79531C0.631256 7.87656 0.970006 7.02094 1.50313 6.32281L1.50438 6.32094C2.41324 5.14009 2.99563 3.74102 3.19313 2.26406C3.31188 1.35594 4.05876 0.632812 5.01876 0.632812H13.9838C14.3369 0.632812 14.7519 0.632812 15.1369 0.639062C16.0844 0.642187 16.8344 1.37344 16.945 2.28094C17.1252 3.71599 17.674 5.07988 18.5381 6.23969L18.54 6.24219C19.0494 6.93094 19.3813 7.77094 19.3813 8.67031V17.0516C19.3808 17.668 19.1356 18.2591 18.6997 18.695C18.2638 19.131 17.6727 19.3761 17.0563 19.3766H12.275C11.8394 19.377 11.4094 19.2792 11.0168 19.0905C10.6242 18.9018 10.2792 18.627 10.0075 18.2866C9.73816 18.6272 9.39506 18.9022 9.00403 19.091C8.613 19.2798 8.18423 19.3775 7.75001 19.3766ZM6.25626 1.88281V13.7516H9.37501V8.13156L7.18126 2.06406C7.15946 2.00141 7.13228 1.94077 7.10001 1.88281H6.25626ZM12.9131 1.88281C12.8819 1.94031 12.8538 2.00094 12.8313 2.06406L10.635 8.13156V13.7516H13.7563V1.88281H12.9131ZM5.00626 1.88281C4.72126 1.88906 4.47501 2.10219 4.43251 2.42656C4.20645 4.12117 3.5387 5.72653 2.49626 7.08156C2.10438 7.59531 1.88126 8.18969 1.88126 8.79531V13.7516H5.00626V1.88281ZM1.88126 15.0016V17.0516C1.88126 17.6441 2.36376 18.1266 2.95626 18.1266H7.75001C8.16089 18.1274 8.55686 17.9727 8.85832 17.6935C9.15977 17.4143 9.34437 17.0313 9.37501 16.6216V15.0016H1.88126ZM10.635 16.6203C10.6689 17.0316 10.8566 17.415 11.1605 17.6941C11.4644 17.9732 11.8623 18.1276 12.275 18.1266H17.0563C17.6488 18.1266 18.1313 17.6441 18.1313 17.0516V15.0016H10.635V16.6203ZM18.1313 13.7516V8.67031C18.1313 8.08281 17.9138 7.49844 17.5356 6.98656C16.5424 5.65356 15.9115 4.08596 15.7044 2.43656V2.43281C15.665 2.10531 15.4056 1.88906 15.1313 1.88906H15.1213C15.0829 1.88837 15.0446 1.88775 15.0063 1.88719V13.7516H18.1313Z" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7561_79761">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_7561_79761">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
