import {SmartImage} from '@shared/types/SmartImage';
import {DialogCloseButton, DialogContainer, DialogContent, DialogTitle} from '@shared/ui/Dialog';
import {SmartImageWithTags} from '@widgets/project/smartImageWithTags';
import styles from './ProjectImageModal.module.scss';
import {Chip} from '@shared/ui/Chip';
import {CameraIcon} from '@shared/ui/icons';

interface Props {
  onClose: () => void;
  image: SmartImage | undefined;
  cameraName: string;
}

export default function ProjectImageModal({image, onClose, cameraName}: Props) {
  return (
    <DialogContainer classes={{paper: styles.dialogPaper}} onClose={onClose} fullWidth open>
      <DialogTitle className={styles.title} variant="h5">
        <Chip icon={<CameraIcon />} label={cameraName} variant="outlined" />
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <SmartImageWithTags image={image} cameraName={cameraName} />
      </DialogContent>
    </DialogContainer>
  );
}
