import {WorkersInsideCard} from '@features/occupancy/WorkersInsideCard';
import {Event, useEvents, useGates} from '@entities/occupancy';
import {useOccupancyFilters} from '@features/occupancy/Filters';
import {OccupancyChartsSkeleton} from './OccupancyChartsSkeleton';
import {OccupancyOverTimeChart} from '@features/occupancy/OccupancyOverTimeChart';
import {EventList} from '@features/occupancy/EventList';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {useEffect, useMemo, useState} from 'react';
import {useProjectTimezone} from '@entities/project';
import {Alert} from '@shared/ui/Alert';
import {EventVideo} from '@features/occupancy/EventVideo';
import {ShowLatestCalibrationPoint} from '@features/occupancy/showLatestCalibrationPoint';
import {AddCalibrationPoint} from '@features/occupancy/addCalibrationPoint';
import {HourlyEntryExitContainer} from './HourlyEntryExitContainer';
import {dateRangeRelativeToDateRange, setZonedStartOfDay} from '@shared/lib/utils';
import styles from './OccupancyCharts.module.scss';

export function OccupancyCharts() {
  const {t} = useTranslation('occupancy');
  const timezone = useProjectTimezone();
  const {regionId, dateRange: dateRangeRelative} = useOccupancyFilters();
  const dateRange = useMemo(
    () => dateRangeRelativeToDateRange(dateRangeRelative),
    [dateRangeRelative]
  );
  const isLive = dateRangeRelative.endDate === 'now';
  const {events, isFetching, isError, refetchEvents} = useEvents({
    regionId,
    dateRangeRelative,
    timezone,
    isLive
  });
  const eventsWithoutCalibrationPoints = useMemo(
    () => events.filter(event => event.type !== 0),
    [events]
  );
  const {gates, isFetching: isFetchingGates, isError: isErrorFetchingGates} = useGates(regionId);
  const [eventBeingPlayed, setEventToPlay] = useState<Event>();

  useEffect(() => {
    if (isFetching) {
      setEventToPlay(undefined);
    }
  }, [isFetching]);

  if (isFetching || isFetchingGates) {
    return <OccupancyChartsSkeleton />;
  }

  if (isError || isErrorFetchingGates) {
    return t('Something went wrong', {ns: 'translation'});
  }

  if (events.length === 0) {
    return (
      <Alert severity="info">
        {t(
          "We couldn't find any information. Please try selecting a different region, date or time range.",
          {ns: 'occupancy'}
        )}
      </Alert>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.calibrationSection}>
          <ShowLatestCalibrationPoint events={events} />
          <AddCalibrationPoint refetchEvents={refetchEvents} />
        </div>
        <WorkersInsideCard events={eventsWithoutCalibrationPoints} isLive={isLive} />
        <OccupancyOverTimeChart events={events} dateRange={dateRange} />
      </div>
      <HourlyEntryExitContainer
        events={eventsWithoutCalibrationPoints}
        gates={gates}
        dateRange={dateRange}
      />
      <div className={classNames(styles.container, styles.videoSection)}>
        <EventList
          events={eventsWithoutCalibrationPoints}
          gates={gates}
          setEventToPlay={setEventToPlay}
          eventBeingPlayed={eventBeingPlayed}
          minDate={setZonedStartOfDay(dateRange.startDate, timezone)}
          maxDate={setZonedStartOfDay(dateRange.endDate, timezone)}
        />
        <EventVideo event={eventBeingPlayed} gates={gates} />
      </div>
    </>
  );
}
