import {SmartImageCardSkeleton} from '@entities/smartImage';
import classNames from 'classnames';
import styles from '@widgets/project/projectImagesGrid/ui/ProjectImagesGrid.module.scss';

export function ProjectImagesGridSkeleton() {
  return (
    <div className={styles.container}>
      {Array.from({length: 4}).map((_, index) => (
        <div
          className={classNames(styles.imageContainer, 'imagesGridItem')}
          key={`project-image-skeleton-${index}`}
        >
          <SmartImageCardSkeleton key={`image-card-skeleton-${index}`} />
        </div>
      ))}
    </div>
  );
}
