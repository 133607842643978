import {Expose} from 'class-transformer';
import {Gate} from '../types/Gate';

export class GateModel implements Gate {
  @Expose({name: 'gate_uuid'})
  public id: string;

  @Expose({name: 'name'})
  public name: string;

  @Expose({name: 'region_uuid'})
  public regionId: string;

  @Expose({name: 'camera_uuid'})
  public cameraId: string;
}
