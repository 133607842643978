import {DialogTitle as MuiDialogTitle, DialogTitleProps} from '@mui/material';
import classNames from 'classnames';
import styles from './DialogTitle.module.scss';

export function DialogTitle({children, className, ...props}: DialogTitleProps) {
  return (
    <MuiDialogTitle className={classNames(className, styles.root)} {...props}>
      {children}
    </MuiDialogTitle>
  );
}
