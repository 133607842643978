import {Checkbox} from '@mui/material';
import {FeatureFlag} from '@shared-app/types';
import {FeatureFlagKey} from '@shared-app/types/FeatureFlag';
import {Button} from '@shared/ui/Button';
import {FormControlLabel} from '@shared/ui/Form';
import {CircularLoader} from '@shared/ui/Loader';
import {Formik} from 'formik';
import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface FeatureFlagFormProps {
  featureFlags: FeatureFlag[];
  onSubmit: (formValues: Record<string, boolean>) => void;
  defaultValue: Record<string, boolean>;
  isSaving: boolean;
}

export function FeatureFlagForm({
  featureFlags,
  onSubmit,
  defaultValue,
  isSaving
}: FeatureFlagFormProps) {
  const {t} = useTranslation();

  const labels: Record<FeatureFlagKey, string> = useMemo(() => getLabels(t), [t]);

  return (
    <Formik initialValues={defaultValue} onSubmit={onSubmit}>
      {({handleChange, handleBlur, handleSubmit, values}) => (
        <form id="feature-flag-form" onSubmit={handleSubmit} noValidate>
          <div>
            {featureFlags.map(featureFlag => (
              <FormControlLabel
                key={featureFlag.key}
                control={
                  <Checkbox
                    name={featureFlag.key}
                    checked={values[featureFlag.key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></Checkbox>
                }
                label={labels[featureFlag.key]}
              />
            ))}
          </div>
          <Button
            type="submit"
            variant="contained"
            startIcon={isSaving ? <CircularLoader size="1em" /> : undefined}
            disabled={isSaving}
          >
            {t('Save')}
          </Button>
        </form>
      )}
    </Formik>
  );
}

function getLabels(t: TFunction) {
  return {
    [FeatureFlagKey.TAGS]: t('Search & Statistics'),
    [FeatureFlagKey.SAFETY]: t('Safety'),
    [FeatureFlagKey.OCCUPANCY]: t('Occupancy'),
    [FeatureFlagKey.STATUS_REPORT]: t('Status Report')
  };
}
