import {useMemo} from 'react';
import {TimeRange} from '@shared/ui/TimeRange';
import TimelineChart from '@shared/ui/TimelineChart';
import {Typography} from '@shared/ui/Typography';
import {useTranslation} from 'react-i18next';
import {useProjectTimezone} from '@entities/project';
import {getTimelineChartOptions} from '@shared-app/lib';

interface Props {
  series: [Date, 1 | -1][];
  timeRange: TimeRange;
  onSelect: (t: Date) => void;
  selectedTime: Date;
}

export function CameraTimelineChart({series, onSelect, selectedTime}: Props) {
  const {t} = useTranslation('project');
  const timezone = useProjectTimezone();

  const chartOptions = useMemo(() => getTimelineChartOptions(timezone), [timezone]);

  return (
    <div>
      <Typography variant={'h4'}>{t('Detection Timeline')}</Typography>
      <div>
        <TimelineChart
          series={series}
          options={chartOptions}
          hideEmpty={false}
          onSelect={onSelect}
          selectedTime={selectedTime}
          timezone={timezone}
        />
      </div>
    </div>
  );
}
