import styles from './SmartImageCard.module.scss';
import {SmartImage as ImageType} from '@shared/types/SmartImage';
import {useRef} from 'react';
import {SmartImageOverlayLabel} from '../SmartImageOverlayLabel/SmartImageOverlayLabel';
import {DATE_FORMATS} from '@shared/lib/constants';
import {useOnVisibleThumbnailUrl} from '@entities/smartImage/lib/hooks/useOnVisibleThumbnailUrl';
import {usePlaceholderThumbnailUrl} from '@entities/smartImage/lib/hooks/usePlaceholderThumbnailUrl';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';
import {Image} from '@shared/ui';

interface ImageProps {
  image: ImageType;
  cameraName: string;
}

export default function SmartImageCard({image, cameraName}: ImageProps) {
  const ref = useRef<HTMLDivElement>();
  const timezone = useProjectTimezone();

  const {placeholderThumbnailUrl} = usePlaceholderThumbnailUrl(image);
  const {thumbnailUrl, isLoading} = useOnVisibleThumbnailUrl(ref, image);

  return (
    <div className={styles.card} ref={ref}>
      <figure className={styles.cover}>
        <Image
          src={isLoading ? placeholderThumbnailUrl : thumbnailUrl}
          className={styles.cover__img}
          alt={image.caption}
          isLoading={isLoading}
        />
      </figure>
      <div className={styles.description}>
        <SmartImageOverlayLabel className={styles.description__date}>
          {formatInTimeZone(image.dateTime, timezone, DATE_FORMATS.DATE)}
          <span className={styles.time}>
            {formatInTimeZone(image.dateTime, timezone, DATE_FORMATS.TIME)}
          </span>
        </SmartImageOverlayLabel>
      </div>
      {cameraName && (
        <SmartImageOverlayLabel className={styles.camera}>{cameraName}</SmartImageOverlayLabel>
      )}
    </div>
  );
}
