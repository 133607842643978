import {useMutation} from '@shared/lib/hooks';
import {ProjectService} from '../services/Project.service';
import {useTranslation} from 'react-i18next';
import {LIST_QUERY_KEY} from './constants/project';
import {useCallback} from 'react';
import {useCurrentProject} from '../state/useCurrentProject';
import {Project} from '@shared/types/Project';

const QUERY_OPTIONS = {
  queryKey: LIST_QUERY_KEY,

  // invalidating projects cause full refresh of app because projects state is at very top level.
  // So skipping invalidation and updating cache
  skipInvalidation: true
};

export function useUpdateProject(onSuccess: (p: Project, n: Project) => Project) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('project');

  const updateProject = useCallback(
    (project: Project) =>
      ProjectService.updateProject(projectId, project).catch(() => {
        throw new Error(t('Error occurred. Please try to upload an image again.'));
      }),
    [t, projectId]
  );

  const {
    mutate: update,
    isLoading: isUpdating,
    isSuccess: isUpdated
  } = useMutation<Project>({
    mutationFn: updateProject,
    onSuccess: (client, data: Project) => {
      const projectId = data.id;
      const projects: Project[] = client.getQueryData(LIST_QUERY_KEY);
      const updatedProjects = projects.reduce((acc, project) => {
        const updatedProject = project.id === projectId ? onSuccess(project, data) : project;
        acc.push(updatedProject);
        return acc;
      }, []);
      client.setQueryData(LIST_QUERY_KEY, updatedProjects);
    },
    options: QUERY_OPTIONS
  });

  return {
    update,
    isUpdating,
    isUpdated
  };
}
