import {TagChip} from '@entities/tag';
import {MenuItem} from '@shared/ui/Menu';
import {Typography} from '@shared/ui/Typography';
import {useTranslation} from 'react-i18next';
import {Tag} from '@shared/types/Tag';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import {TagOperator} from '@entities/smartSearchFilter';
import {Select} from '@shared/ui';
import {Fragment} from 'react';
import {getEnumFromStringValue} from '@conxai/react-kit';
import {SelectChangeEvent} from '@mui/material';
import classNames from 'classnames';
import {IconButton} from '@shared/ui/Button';
import {CloseIcon} from '@shared/ui/icons';
import styles from './DetectionQuery.module.scss';

export function DetectionQuery() {
  const {t} = useTranslation('search');
  const {toggleTagInQuery, setQueryOperator, query, removeAllTagsInQuery} = useSmartSearchFilter();
  const {operator, tags} = query;

  const handleChange = (event: SelectChangeEvent<TagOperator>) => {
    const selectedPresetKeyStr = event.target.value;
    const selectedPresetKey = getEnumFromStringValue(TagOperator, selectedPresetKeyStr);
    if (selectedPresetKey) {
      setQueryOperator(selectedPresetKey);
    }
  };

  return (
    <div className={styles.root}>
      <Typography classes={styles.sectionTitle} variant="body1">
        {t('Detection')}
      </Typography>
      <div className={styles.tagsRow}>
        <Select
          className={styles.operatorSelect}
          value={operator}
          onChange={handleChange}
          variant="rounded"
        >
          <MenuItem value={TagOperator.And}>{t('All from')}</MenuItem>
          <MenuItem value={TagOperator.Or}>{t('Any from')}</MenuItem>
        </Select>
        <div
          className={classNames(styles.tagsContainer, {
            [styles.tagsContainer__active]: !!tags.length
          })}
        >
          {tags.length === 0 ? (
            <span className={styles.empty}>{t('Start selecting Tags')}</span>
          ) : (
            <>
              <div className={styles.tags}>{renderTags(tags)}</div>
              <IconButton onClick={removeAllTagsInQuery} className={styles.clearTagsBtn}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </div>
      </div>
    </div>
  );

  function renderTags(query: Tag[]) {
    return query.map((tag: Tag, index: number) => {
      return (
        <Fragment key={tag.key}>
          <TagChip
            onDelete={() => {
              toggleTagInQuery(tag);
            }}
            tag={tag}
            selected={true}
          />
          {index < tags.length - 1 && (
            <span className={styles.tagSeparator}>
              {operator === TagOperator.And ? t('AND') : t('OR')}
            </span>
          )}
        </Fragment>
      );
    });
  }
}
