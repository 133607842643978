import {useMemo} from 'react';
import {WEEK_DAYS} from '@shared/lib/constants';
import {ComplianceInfo, getWeeklyComplianceHours} from '@entities/ppeDashboard';
import {AxisChart, AxisChartOptions} from '@shared-app/ui';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import {getWeeklyNonComplianceHours} from '@entities/ppeDashboard';
import styles from './NonComplianceByWeekDayChart.module.scss';
import {NON_COMPLIANCE_WEEKLY_STACK_CHART} from '@shared-app/lib';

export interface Props {
  complianceInfo: ComplianceInfo[];
  isRelative: boolean;
}

export function NonComplianceByWeekDayChart({complianceInfo, isRelative}: Props) {
  const {t} = useTranslation('ppeDashboard');
  const series = useMemo(
    () => getSeriesFromData(complianceInfo, t, isRelative),
    [complianceInfo, t, isRelative]
  );

  const options = useMemo(() => getOptions(isRelative, t), [isRelative, t]);

  return (
    <AxisChart
      title={t('PPE Non-Compliance Week Days')}
      type="bar"
      series={series}
      options={options}
    />
  );
}

function getSeriesFromData(data: ComplianceInfo[], t: TFunction, isRelative: boolean) {
  const totalHoursPerWeek = getWeeklyNonComplianceHours(data, isRelative);
  const series = [
    {
      name: t('PPE Non-Compliance'),
      data: totalHoursPerWeek
    }
  ];

  if (!isRelative) {
    series.push({
      name: t('Wearing PPE'),
      data: getWeeklyComplianceHours(data)
    });
  }

  return series;
}

const getOptions = (isRelative: boolean, t: TFunction): AxisChartOptions => ({
  chart: {
    stacked: true
  },
  colors: isRelative
    ? NON_COMPLIANCE_WEEKLY_STACK_CHART.COLORS.RELATIVE
    : NON_COMPLIANCE_WEEKLY_STACK_CHART.COLORS.ABSOLUTE,
  legend: {
    customLegendItems: [t('PPE Non-Compliance'), ...(!isRelative ? [t('Wearing PPE')] : [])]
  },
  yaxis: {
    title: (
      <div className={styles.yaxisTitle}>
        <div>{isRelative ? `(${t('% of man-hour')})` : `(${t('man-hour')})`}</div>
        <div>{t('Non-compliance amount')}</div>
      </div>
    )
  },
  xaxis: {
    tickPlacement: 'on',
    categories: WEEK_DAYS
  }
});
