import {TagEnum} from '@shared/types/TagEnum';

export const PPE_TAGS = [
  TagEnum.person_helmet,
  TagEnum.person_vest,
  TagEnum.person_no_helmet,
  TagEnum.person_no_vest,
  TagEnum.person_no_helmet_no_vest,
  TagEnum.person_helmet_vest
];
