interface EntryIconProps {
  className: string;
}

export function EntryIcon({className}: EntryIconProps) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M11.5915 13.9906L14.0034 11.5342M14.0034 11.5342L11.5915 9.01587M14.0034 11.5342H1.56641"
          stroke="#0084FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 8V5H19V19H9V16C9 15.7167 8.90417 15.4792 8.7125 15.2875C8.52083 15.0958 8.28333 15 8 15C7.71667 15 7.47917 15.0958 7.2875 15.2875C7.09583 15.4792 7 15.7167 7 16V19C7 19.55 7.19583 20.0208 7.5875 20.4125C7.97917 20.8042 8.45 21 9 21H19C19.55 21 20.0208 20.8042 20.4125 20.4125C20.8042 20.0208 21 19.55 21 19V5C21 4.45 20.8042 3.97917 20.4125 3.5875C20.0208 3.19583 19.55 3 19 3H9C8.45 3 7.97917 3.19583 7.5875 3.5875C7.19583 3.97917 7 4.45 7 5V8C7 8.28333 7.09583 8.52083 7.2875 8.7125C7.47917 8.90417 7.71667 9 8 9C8.28333 9 8.52083 8.90417 8.7125 8.7125C8.90417 8.52083 9 8.28333 9 8Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
