import {Button} from '@shared/ui/Button';
import {useBoolean} from '@conxai/react-kit';
import {MilestoneAddIcon} from '@shared/ui/MilestoneAddIcon';
import styles from './CreateMilestoneButton.module.scss';
import {CreateMilestoneDialog} from '../CreateMilestoneDialog';

interface Props {
  date: Date;
  onSuccess: (id: string) => void;
}

export function CreateMilestoneButton({date, onSuccess}: Props) {
  const [isCreateMilestoneDialogOpen, , showCreateMilestone, hideCreateMilestone] =
    useBoolean(false);

  return (
    <>
      <Button size="large" className={styles.milestoneAddBtn} onClick={showCreateMilestone}>
        <MilestoneAddIcon className={styles.milestoneAddIcon} />
      </Button>
      {isCreateMilestoneDialogOpen && (
        <CreateMilestoneDialog
          onClose={hideCreateMilestone}
          onSuccess={onSuccess}
          defaultValue={{date}}
          disableDateChange
        />
      )}
    </>
  );
}
