import {useEffect} from 'react';
import {
  DialogContainer,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogCloseButton
} from '@shared/ui/Dialog';
import {useTranslation} from 'react-i18next';
import {BorderButton, Button} from '@shared/ui/Button';
import {CircularLoader} from '@shared/ui/Loader';
import {ToastService} from '@shared/lib/services';
import styles from './InviteUserDialog.module.scss';
import {UserInvitationForm} from '../UserInvitationForm';
import {useInviteUser} from '@entities/user/lib/hooks';
import {UnSavedUser} from '@shared/types/User';

interface Props {
  onClose: () => void;
}

export function InviteUserDialog({onClose}: Props) {
  const {t} = useTranslation(['user']);

  const {inviteUser, isInvited, isInviting} = useInviteUser();

  const handleSubmit = (formValues: UnSavedUser) => {
    inviteUser(formValues.email);
  };

  useEffect(() => {
    if (isInvited) {
      ToastService.success(t('Invitation was successfully sent.'));
      onClose();
    }
  }, [isInvited, onClose, t]);

  return (
    <DialogContainer onClose={onClose} fullWidth open>
      <DialogTitle className={styles.title} variant="h5">
        {t('Invite New User', {ns: 'user'})}
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <UserInvitationForm onSubmit={handleSubmit} />
      </DialogContent>
      <DialogActions className={styles.actions}>
        <BorderButton onClick={onClose}>{t('Cancel', {ns: 'translation'})}</BorderButton>
        <Button
          type="submit"
          form={UserInvitationForm.FORM_ID}
          variant="contained"
          disabled={isInviting}
          endIcon={isInviting ? <CircularLoader size="1em" color="inherit" /> : undefined}
        >
          {t('Send Invitation', {ns: 'user'})}
        </Button>
      </DialogActions>
    </DialogContainer>
  );
}
