import {CircularLoader} from '@shared/ui/Loader';
import {CheckOutlinedIcon, CloseIcon} from '@shared/ui/icons';
import {useTranslation} from 'react-i18next';
import styles from './ReportStatus.module.scss';
import {StatusReportStatuses} from '@shared-app/types';
import {Tooltip} from '@shared/ui/Tooltip';

interface StatusProps {
  status: StatusReportStatuses;
}

export function ReportStatus({status}: StatusProps) {
  const {t} = useTranslation('statusReport');

  return (
    <span className={styles.status}>
      {status === StatusReportStatuses.active && (
        <Tooltip title={`${t(`Processing`)}...`} placement={`top`}>
          <CircularLoader className={styles.progress} size="1em" />
        </Tooltip>
      )}
      {status === StatusReportStatuses.completed && (
        <Tooltip title={t('Finalised')} placement={`top`}>
          <CheckOutlinedIcon className={styles.completed} />
        </Tooltip>
      )}
      {status === StatusReportStatuses.failed && (
        <Tooltip title={t('Failed')} placement={`top`}>
          <CloseIcon className={styles.failed} />
        </Tooltip>
      )}
    </span>
  );
}
