import {ReactNode} from 'react';
import {useAuth} from '../../lib/hooks/useAuth';
import {LoadingPagePlaceholder} from '@shared/ui/LoadingPagePlaceholder';

interface Props {
  children: ReactNode;
}

export function ProtectedLayoutWrapper({children}: Props) {
  const {user} = useAuth();

  if (!user) {
    return <LoadingPagePlaceholder />;
  }

  return children;
}
