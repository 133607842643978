import {useQuery} from '@tanstack/react-query';

import SmartImageService from '../services/SmartImage.service';
import {SmartImage} from '@shared/types/SmartImage';

export default function useSmartImageData(image?: SmartImage) {
  const {data, isFetching, error} = useQuery({
    queryKey: [
      'get-image-data',
      image?.presignedUrls.image_data_relations,
      image?.presignedUrls.image_data
    ],
    queryFn: () =>
      SmartImageService.getImageData(
        image?.presignedUrls.image_data_relations,
        image?.presignedUrls.image_data
      ),
    enabled: !!image
  });

  return {
    data: data,
    isFetching,
    error
  };
}
