import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import {useParams} from 'react-router-dom';
import {useGetFrequencyStatistic} from '@entities/frequencyStatistic';
import {CameraTimelineChart} from './CameraTimelineChart';
import {TimelineCarousel, TimelineCarouselSkeleton} from '@features/timelineCarousel';
import {SmartImageWithTags} from './SmartImageWithTags/SmartImageWithTags';
import styles from './CameraTimeline.module.scss';
import {zonedTimeToUtc} from 'date-fns-tz';
import {SmartImage} from '@shared/types/SmartImage';
import {TimeRange} from '@shared/ui/TimeRange';
import {getHourRangeFromTimeRange, isWithinHourRange} from '@shared-app/lib';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {SmartImageWithTagsSkeleton} from './SmartImageWithTags/SmartImageWithTagsSkeleton';
import {FrequencyStatistic} from '@shared/types/FrequencyStatistic';
import {addMilliseconds, subMilliseconds} from 'date-fns';
import ProjectImageModal from '@widgets/project/projectImageModal';
import {useBoolean} from '@conxai/react-kit';

// -1 due to chart series item has value till end of a hour, not till start of next hour
const CHART_SERIES_INTERVAL_MS = 1 * 60 * 60 * 1000 - 1;

export function CameraTimeline() {
  const {cameraId} = useParams();
  const {query, timeRange, dateRange} = useSmartSearchFilter();
  const {project} = useCurrentProject();
  const timezone = useProjectTimezone();
  const {data, isFetching} = useGetFrequencyStatistic(query, [cameraId], dateRange);
  const [[selectedTime, activeImage], setActiveImageAndDuration] = useState<
    [Date | undefined, SmartImage | undefined]
  >([undefined, undefined]);
  const [shouldShowImageDialog, , showActiveImageDialog, closeActiveImageDialog] = useBoolean();

  const handleTimelineChartSelect = useCallback((seriesStartTime: Date) => {
    setActiveImageAndDuration([seriesStartTime, undefined]);
  }, []);

  const handleActiveImageChange = useCallback((image: SmartImage) => {
    setActiveImageAndDuration([image.dateTime, image]);
  }, []);

  const series = useMemo(
    () => getSeriesPerHour(data, timeRange, timezone),
    [data, timeRange, timezone]
  );

  const activityDetectedRange = useMemo(() => {
    const activityDetectedSeries = series.filter(([, isDetected]) => isDetected === 1);
    if (!activityDetectedSeries.length) return;

    const startDate = activityDetectedSeries.at(0)[0];
    const endDate = addMilliseconds(activityDetectedSeries.at(-1)[0], CHART_SERIES_INTERVAL_MS);

    return {
      startDate,
      endDate
    };
  }, [series]);

  const activeDuration = useMemo(() => {
    if (activeImage || !selectedTime) return;
    return {
      startDate: selectedTime,
      endDate: addMilliseconds(selectedTime, CHART_SERIES_INTERVAL_MS)
    };
  }, [activeImage, selectedTime]);

  useEffect(() => {
    if (!activityDetectedRange) return;

    setActiveImageAndDuration([
      subMilliseconds(activityDetectedRange.endDate, CHART_SERIES_INTERVAL_MS),
      undefined
    ]);
  }, [activityDetectedRange, timeRange, timezone]);

  return (
    <div className={styles.container}>
      <div className={styles.detectionTimeline}>
        {!isFetching && (
          <CameraTimelineChart
            series={series}
            timeRange={timeRange}
            onSelect={handleTimelineChartSelect}
            selectedTime={selectedTime}
          />
        )}
      </div>
      <div className={styles.imageSection}>
        {activeImage ? (
          <SmartImageWithTags activeImage={activeImage} onEnlarge={showActiveImageDialog} />
        ) : (
          <SmartImageWithTagsSkeleton />
        )}
        {shouldShowImageDialog && activeImage ? (
          <ProjectImageModal
            image={activeImage}
            onClose={closeActiveImageDialog}
            cameraName={project.getCameraById(activeImage.cameraId)?.name}
          />
        ) : null}
      </div>
      {activityDetectedRange ? (
        <TimelineCarousel
          cameraId={cameraId}
          onSelect={handleActiveImageChange}
          activeImage={activeImage}
          activeDuration={activeDuration}
          dateRange={activityDetectedRange}
        />
      ) : (
        <TimelineCarouselSkeleton />
      )}
    </div>
  );
}

function getSeriesPerHour(data: FrequencyStatistic[], timeRange: TimeRange, timezone: string) {
  const [zonedStartHour, zonedEndHour] = getHourRangeFromTimeRange(timeRange);

  return data.reduce(
    (hours, statistic) => {
      for (let hour = 0; hour <= statistic.data.length; hour++) {
        const detectedHours = statistic.data[hour];

        if (isWithinHourRange(hour, zonedStartHour, zonedEndHour)) {
          const time = new Date(statistic.date);
          time.setHours(hour, 0, 0, 0);
          const nonzonedTime = zonedTimeToUtc(time, timezone);
          hours.push([nonzonedTime, detectedHours > 0 ? 1 : -1]);
        }
      }

      return hours;
    },
    [] as [Date, 1 | -1][]
  );
}
