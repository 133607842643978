interface Props {
  className?: string;
}

export function CameraIcon({className = ''}: Props) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 21 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4849 0.328125H4.17111C3.25238 0.328125 2.37128 0.717863 1.72164 1.4116C1.072 2.10534 0.707031 3.04625 0.707031 4.02734V11.4258C0.707031 12.4069 1.072 13.3478 1.72164 14.0415C2.37128 14.7353 3.25238 15.125 4.17111 15.125H12.4849C13.4036 15.125 14.2847 14.7353 14.9344 14.0415C15.584 13.3478 15.949 12.4069 15.949 11.4258V4.02734C15.949 3.04625 15.584 2.10534 14.9344 1.4116C14.2847 0.717863 13.4036 0.328125 12.4849 0.328125ZM2.09266 4.02734C2.09266 3.43869 2.31164 2.87414 2.70143 2.4579C3.09121 2.04165 3.61988 1.80781 4.17111 1.80781H12.4849C13.0362 1.80781 13.5648 2.04165 13.9546 2.4579C14.3444 2.87414 14.5634 3.43869 14.5634 4.02734V11.4258C14.5634 12.0144 14.3444 12.579 13.9546 12.9952C13.5648 13.4115 13.0362 13.6453 12.4849 13.6453H4.17111C3.61988 13.6453 3.09121 13.4115 2.70143 12.9952C2.31164 12.579 2.09266 12.0144 2.09266 11.4258V4.02734Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0364 1.9247L14.91 4.79086C14.8141 4.85723 14.7351 4.94789 14.68 5.05477C14.6249 5.16166 14.5954 5.28144 14.5941 5.40345L14.5567 9.26839C14.5553 9.39302 14.5834 9.51601 14.6383 9.62595C14.6933 9.73589 14.7734 9.82922 14.8712 9.89726L19.0337 12.7856C19.1382 12.8584 19.2592 12.8996 19.3839 12.905C19.5086 12.9104 19.6324 12.8797 19.7421 12.8162C19.8518 12.7527 19.9434 12.6587 20.0073 12.5442C20.0711 12.4297 20.1048 12.2989 20.1048 12.1656V2.54617C20.105 2.41277 20.0715 2.28178 20.0078 2.16705C19.9441 2.05233 19.8525 1.95813 19.7427 1.89445C19.633 1.83076 19.5091 1.79995 19.3843 1.80528C19.2595 1.8106 19.1383 1.85187 19.0337 1.9247M18.7205 10.8028L15.9479 8.87775L15.977 5.8148L18.7205 3.91044V10.8028Z"
        fill="currentColor"
      />
    </svg>
  );
}
