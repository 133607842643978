import React, {lazy, ReactNode, Suspense} from 'react';
import {TagEnum} from '@shared/types/TagEnum';
import QuestionMark from '../assets/question_mark.svg';
import {availableIcons} from '@shared/ui/TagIcon/constants/availableIcons';
import styles from './TagIcon.module.scss';
import classNames from 'classnames';

interface TagIconProps {
  stringId: TagEnum;
  className?: string;
}

function TagIcon({stringId, className}: TagIconProps): ReactNode {
  const name = prepareSVGName(stringId);

  const iconProps = {className: classNames(styles.icon, className)};

  if (availableIcons.includes(name)) {
    const LazyIcon = lazy(async () => {
      try {
        return await import(`@shared/ui/TagIcon/assets/${name}.svg`);
      } catch (e) {
        console.log({e});
      }
    });

    return (
      <Suspense>
        <LazyIcon {...iconProps} />
      </Suspense>
    );
  } else {
    return <QuestionMark {...iconProps} style={{opacity: '.1'}} />;
  }
}

export default React.memo(TagIcon);

function prepareSVGName(name: string) {
  return name.replaceAll('_', '-');
}
