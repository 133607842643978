import {Chip} from '@shared/ui/Chip';
import styles from './TagChip.module.scss';
import {TagEnum} from '@shared/types/TagEnum';
import {useTranslationTags} from '@shared/lib/hooks';
import classNames from 'classnames';
import {Tag} from '@shared/types/Tag';
import {WarningIcon} from '@shared/ui/icons';

interface TagChipProp {
  tag: Tag;
  onDelete?: (tagStringId: TagEnum) => void;
  selected?: boolean;
  count?: number;
}

export function TagChip({tag, selected, onDelete, count}: TagChipProp) {
  const {t} = useTranslationTags();

  const classes = classNames(styles.tagChip, styles[`tagChip__${tag.type}`], {
    [styles.tagChip__hasCount]: count !== undefined,
    [styles[`tagChip__${tag.type}_selected`]]: selected
  });

  return (
    <Chip
      onDelete={onDelete}
      label={
        <>
          {t(`tag.${tag.key}`)}
          {count !== undefined && <span className={styles.count}>{count}</span>}
        </>
      }
      className={classes}
      icon={WARNING_TAGS.includes(tag.key) ? <WarningIcon /> : undefined}
    />
  );
}

const WARNING_TAGS = [
  TagEnum.person_helmet_no_vest,
  TagEnum.person_no_helmet_vest,
  TagEnum.person_no_helmet_no_vest
];
