import Logo from '@widgets/header/assets/logo-short.svg';
import Conxai from '@widgets/header/assets/conxai.svg';
import BauwatchLogo from '@widgets/header/assets/bauwatch.png';
import {useAuth} from '@entities/auth';

export function CustomLogo() {
  const {user} = useAuth();

  if (user?.email.endsWith('@bauwatch.com')) {
    return <img height={28} src={BauwatchLogo} alt="bauwatch logo" />;
  }

  return (
    <>
      <Logo width={40} height={40} />
      <Conxai width={118} height={28} style={{marginTop: '5px'}} />
    </>
  );
}
