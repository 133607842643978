import {ComplianceInfo} from '../../types/ComplianceInfo';
import {getTotalNonComplianceHours} from './getTotalNonComplianceHours';

export function getTotalNonComplianceHoursByDate(
  complianceInfo: ComplianceInfo[],
  isRelative: boolean
): [number, number][] {
  return complianceInfo.map(info => {
    return [info.date.getTime(), getTotalNonComplianceHours(info, isRelative)];
  });
}
