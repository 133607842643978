import {useEffect, useState} from 'react';
import {PPEEnabledTagAttrs, PPETagAttrs, TagCard} from '@entities/tag';
import {PPEAttributeSelectPopover} from './PPEAttributeSelectPopover';
import {TagEnum} from '@shared/types/TagEnum';
import {Tag} from '@shared/types/Tag';
import {useDidMount} from '@conxai/react-kit';
import styles from './PPEPersonTag.module.scss';

interface Props {
  className?: string;
  onChange: (attrs: string[]) => void;
  tag: Tag;
  selectedTagId?: TagEnum;
  popoverDescription: string;
}

export function PPEPersonTag({tag, onChange, selectedTagId, popoverDescription}: Props) {
  const [isSelected, setIsSelected] = useState(!!selectedTagId);

  const [enabledAttributes, setEnabledAttributes] = useState<PPEEnabledTagAttrs>(
    selectedTagId ? parseAttributesFromTagId(selectedTagId) : [PPETagAttrs.HELMET, PPETagAttrs.VEST]
  );

  useDidMount(() => {
    if (isSelected) {
      onChange(enabledAttributes);
    }
  }, [onChange, isSelected, enabledAttributes]);

  useEffect(() => {
    setIsSelected(!!selectedTagId);
  }, [selectedTagId]);

  return (
    <div className={styles.container}>
      <TagCard
        className={styles.personTag}
        tag={tag}
        onClickCallback={() => (isSelected ? onChange(enabledAttributes) : setIsSelected(true))}
        isSelected={isSelected}
      />
      <PPEAttributeSelectPopover
        className={styles.popoverTriggerBtn}
        onChange={setEnabledAttributes}
        enabledAttributes={enabledAttributes}
        popoverDescription={popoverDescription}
      />
    </div>
  );
}

function parseAttributesFromTagId(tagId?: TagEnum): PPEEnabledTagAttrs {
  const enabledAttributes: PPEEnabledTagAttrs = [undefined, undefined];
  if (tagId.includes('helmet')) {
    enabledAttributes[0] = PPETagAttrs.HELMET;
  }
  if (tagId.includes('vest')) {
    enabledAttributes[1] = PPETagAttrs.VEST;
  }

  return enabledAttributes;
}
