import {Event, Gate} from '@entities/occupancy';
import {GrossFlowCard} from '@features/occupancy/GrossFlowCard';
import {HourlyEntryExitChart} from '@features/occupancy/HourlyEntryExitChart';
import {useCallback, useMemo, useState} from 'react';
import {getGrossFlowStatistics} from '../lib/utils/getGrossFlowStatistics';
import {OCCUPANCY_GROSS_FLOW_PIE_CHART} from '@shared-app/lib';
import styles from './HourlyEntryExitContainer.module.scss';
import {DateRange} from '@shared/ui/Date';

interface Props {
  events: Event[];
  dateRange: DateRange;
  gates: Gate[];
}

export function HourlyEntryExitContainer({events, dateRange, gates}: Props) {
  const [selectedGateId, setSelectedGateId] = useState<string>();

  const gateEventPercentages = useMemo(
    () => getGrossFlowStatistics(events, gates),
    [events, gates]
  );

  const handleGateChange = useCallback((gateId: string) => {
    setSelectedGateId(prevGateId => (prevGateId === gateId ? undefined : gateId));
  }, []);

  const gateIndex = gateEventPercentages.findIndex(({gateId}) => gateId === selectedGateId);
  const selectedGateColor = OCCUPANCY_GROSS_FLOW_PIE_CHART.LABEL_COLORS[gateIndex];
  const selectedGateName = gates.find(gate => gate.id === selectedGateId)?.name;

  return (
    <div className={styles.container}>
      <GrossFlowCard
        events={events}
        gates={gates}
        onGateSelect={handleGateChange}
        gateEventPercentages={gateEventPercentages}
      />
      <HourlyEntryExitChart
        events={events}
        gates={gates}
        dateRange={dateRange}
        selectedGateId={selectedGateId}
        selectedGateName={selectedGateName}
        selectedGateColor={selectedGateColor}
      />
    </div>
  );
}
