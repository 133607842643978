import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@shared/ui/Typography';
import Chart from 'react-apexcharts';
import {TFunction} from 'i18next';
import {TagEnum} from '@shared/types/TagEnum';
import {ComplianceInfo, getAttributeStatisticsInPercentage} from '@entities/ppeDashboard';
import {formatPercentage} from '@conxai/react-kit';
import {ApexOptions} from 'apexcharts';
import {ATTRIBUTE_STATISTICS_PIE_CHART} from '@shared-app/lib';
import styles from './AttributeStatisticsPieChart.module.scss';

interface Props {
  complianceInfo: ComplianceInfo[];
}

export function AttributeStatisticsPieChart({complianceInfo}: Props) {
  const {t} = useTranslation('ppeDashboard');
  const data = getAttributeStatisticsInPercentage(complianceInfo);
  const dataLabels = getDataLabels(t);
  const chartOptions = useMemo(() => getChartOptions(t), [t]);
  return (
    <div className={styles.container}>
      <Typography classes={styles.title} variant="h4">
        {t('Attributes Statistic')}
      </Typography>
      <div className={styles.chart}>
        <Chart
          series={DATA_ORDER.map(type => data[type])}
          options={chartOptions}
          type={'donut'}
          height={180}
          width={200}
        />
        <div>
          {DATA_ORDER.map(type => (
            <div className={styles.label} key={type}>
              <span
                className={styles.color}
                style={{backgroundColor: ATTRIBUTE_STATISTICS_PIE_CHART.LABEL_COLORS[type]}}
              ></span>
              <span>{dataLabels[type]}</span>
              <span className={styles.value}>{formatPercentage(data[type])}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const DATA_ORDER = [
  TagEnum.person_helmet_vest,
  TagEnum.person_no_helmet_no_vest,
  TagEnum.person_no_helmet,
  TagEnum.person_no_vest
] as const;

function getChartOptions(t: TFunction): ApexOptions {
  return {
    plotOptions: {
      pie: {
        donut: {size: '40%'},
        offsetY: 10
      }
    },
    legend: {
      show: false
    },
    labels: DATA_ORDER.map(type => getDataLabels(t)[type]),
    colors: DATA_ORDER.map(type => ATTRIBUTE_STATISTICS_PIE_CHART.LABEL_COLORS[type]),
    yaxis: {
      labels: {
        formatter: formatPercentage
      }
    },
    dataLabels: {enabled: false}
  };
}

const getDataLabels = (t: TFunction) => ({
  [TagEnum.person_helmet_vest]: t('Wearing PPE'),
  [TagEnum.person_no_helmet_no_vest]: t('No PPE'),
  [TagEnum.person_no_helmet]: t('Missing Helmet'),
  [TagEnum.person_no_vest]: t('Missing Vest')
});
