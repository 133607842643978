import {Expose} from 'class-transformer';
import {TagEnum} from '@shared/types/TagEnum';
import {Tag} from '@shared/types/Tag';

export default class TagModel implements Tag {
  @Expose({name: 'id'})
  public key: TagEnum;

  @Expose({name: 'type'})
  public type: string;
}
