import {Expose, Transform, Type} from 'class-transformer';
import {CameraImageStatistics} from '@shared/types/CameraImageStatistics';

const returnNullIfDateInvalid = ({value}: {value: Date | undefined | null}) =>
  value && !isNaN(value.getTime()) ? value : null;

export class CameraImageStatisticsModel implements CameraImageStatistics {
  @Expose({name: 'camera_uid'})
  public id: string;

  @Expose({name: 'first_image_time'})
  @Type(() => Date)
  @Transform(returnNullIfDateInvalid)
  public firstImageOn: Date;

  @Expose({name: 'last_image_time'})
  @Type(() => Date)
  @Transform(returnNullIfDateInvalid)
  public lastImageOn: Date;

  @Expose({name: 'total_images'})
  public totalImages: number;
}
