import {useEffect, useRef, MouseEvent} from 'react';
import {SmartImageCard} from '@entities/smartImage';
import {DATE_FORMATS} from '@shared/lib/constants';
import {Camera} from '@shared/types/Camera';
import {SmartImage} from '@shared/types/SmartImage';
import styles from './ProjectImageSection.module.scss';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';

interface ProjectImageSection {
  images: SmartImage[];
  onDateChange: (d: Date) => void;
  onImageClick: (i: SmartImage) => (event: MouseEvent<HTMLDivElement>) => void;
  date: string;
  cameraById: {
    [k: string]: Camera;
  };
}

export function ProjectImageSection({
  images,
  onImageClick,
  onDateChange,
  date,
  cameraById
}: ProjectImageSection) {
  const ref = useRef();
  const timezone = useProjectTimezone();

  useEffect(() => {
    const element = ref.current;
    const o = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          onDateChange(new Date(date));
        }
      },
      {rootMargin: '-10% 0px -89% 0px'}
    );
    o.observe(element);

    return () => o.unobserve(element);
  }, [date, onDateChange]);
  return (
    <div ref={ref}>
      <div className={styles.date}>
        {formatInTimeZone(new Date(date), timezone, DATE_FORMATS.DATE_WIDE)}
      </div>
      <div className={styles.grid}>
        {images.map((image: SmartImage) => (
          <div key={image.imageId} onClick={onImageClick(image)}>
            <SmartImageCard image={image} cameraName={cameraById[image.cameraId]?.name} />
          </div>
        ))}
      </div>
    </div>
  );
}
