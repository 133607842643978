import {useElementSize} from '@conxai/react-kit';
import {useActiveTextCues} from './useActiveTextCues';
import {MutableRefObject, useMemo} from 'react';

interface Coordinates {
  ulx: number;
  uly: number;
  lrx: number;
  lry: number;
}

export function useDetectionsBoundingBox(
  videoRef: MutableRefObject<HTMLVideoElement>,
  isLoading: boolean
) {
  const activeCueText = useActiveTextCues(!isLoading ? videoRef.current : undefined);
  const {width, height} = useElementSize(videoRef);

  const boxPositions = useMemo(() => {
    const video = videoRef.current;
    if (!video) return [];

    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;
    if (!videoWidth || !videoHeight) return [];

    const xyScale = [width / videoWidth, height / videoHeight] as const;

    const coordinatesList = cueTextToCoordinates(activeCueText);
    return coordinatesList.map(mapCoordinatesToBoxPositions(...xyScale));
  }, [activeCueText, height, videoRef, width]);

  return boxPositions;
}

function cueTextToCoordinates(text?: string): Coordinates[] {
  try {
    return JSON.parse(text);
  } catch (error) {
    return [];
  }
}

function mapCoordinatesToBoxPositions(xScale: number, yScale: number) {
  return function (coords: Coordinates) {
    return {
      top: yScale * coords.uly,
      left: xScale * coords.ulx,
      width: xScale * (coords.lrx - coords.ulx),
      height: yScale * (coords.lry - coords.uly)
    };
  };
}
