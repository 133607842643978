import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {AxisChart, AxisChartOptions} from '@shared-app/ui';
import {TFunction} from 'i18next';
import {OCCUPANCY_OVER_TIME_CHART} from '@shared-app/lib';
import {Event} from '@entities/occupancy';
import {getOccupancyOverTimeSeries} from './lib/utils/getOccupancyOverTimeSeries';
import {useProjectTimezone} from '@entities/project';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS, ONE_DAY_IN_MS} from '@shared/lib/constants';
import {differenceInMilliseconds} from 'date-fns';
import {renderToString} from 'react-dom/server';
import {Tooltip} from './ui/Tooltip';
import {DateRange} from '@shared/ui/Date';
import {getPrettyXAxisTicksOptions} from './lib/utils/getPrettyXAxisTicksOptions';
import {getCalibrationPointOptions} from './lib/utils/getCalibrationPointOptions';
import styles from './OccupancyOverTimeChart.module.scss';

interface Props {
  events: Event[];
  dateRange: DateRange;
}

export function OccupancyOverTimeChart({events, dateRange}: Props) {
  const {t} = useTranslation('occupancy');
  const timezone = useProjectTimezone();

  const shouldAggregate =
    differenceInMilliseconds(dateRange.endDate, dateRange.startDate) > ONE_DAY_IN_MS;

  const series = useMemo(
    () => getOccupancyOverTimeSeries(events, dateRange, timezone, shouldAggregate),
    [events, dateRange, timezone, shouldAggregate]
  );

  const chartOptions = useMemo(
    () => getChartOptions(t, timezone, dateRange, series, events, shouldAggregate),
    [t, timezone, dateRange, series, events, shouldAggregate]
  );

  const chartClasses = useMemo(() => ({title: styles.title}), []);

  return (
    <div className={styles.container}>
      <AxisChart
        type="line"
        title={t('Occupancy Over Time')}
        series={[{name: t('occupancy'), data: series}]}
        options={chartOptions}
        classes={chartClasses}
      />
    </div>
  );
}

function getChartOptions(
  t: TFunction,
  timezone: string,
  dateRange: DateRange,
  series: [number, number][],
  events: Event[],
  shouldAggregate: boolean
): AxisChartOptions {
  const maxSeriesValue = Math.max(...series.map(([, value]) => value));
  const yAxisMaxValue = maxSeriesValue + Math.ceil(maxSeriesValue / 10);

  let xAxisAnnotations, discreteMarkers;
  if (!shouldAggregate) {
    ({xAxisAnnotations, discreteMarkers} = getCalibrationPointOptions(events, series));
  }

  return {
    stroke: {
      width: 3,
      curve: shouldAggregate ? 'straight' : 'stepline'
    },
    colors: OCCUPANCY_OVER_TIME_CHART.COLORS,
    annotations: {
      xaxis: xAxisAnnotations || []
    },
    markers: {
      discrete: [
        {dataPointIndex: 0, size: 0, seriesIndex: 0},
        ...(discreteMarkers || []),
        {dataPointIndex: series.length - 1, size: 0, seriesIndex: 0}
      ],
      size: shouldAggregate ? 1 : 4,
      colors: OCCUPANCY_OVER_TIME_CHART.MARKER_FILL_COLOR,
      strokeColors: OCCUPANCY_OVER_TIME_CHART.MARKER_STROKE_COLOR,
      strokeWidth: 1,
      hover: {
        sizeOffset: 1
      }
    },
    tooltip: {
      custom: options => renderToString(<Tooltip options={options} timezone={timezone} />)
    },
    xaxis: {
      type: 'datetime',
      ...getPrettyXAxisTicksOptions(dateRange, timezone, shouldAggregate),
      labels: {
        rotateAlways: false,
        style: {
          cssClass: styles.xaxisLabel
        },
        formatter: function (timestampStr: string) {
          const timestamp = Number(timestampStr);
          if (Number.isNaN(timestamp)) return '';
          const date = new Date(timestamp);
          return formatInTimeZone(date, timezone, DATE_FORMATS.TIME_24);
        }
      }
    },
    yaxis: {
      title: <div className={styles.yaxisTitle}>{t('Occupancy')}</div>,
      max: yAxisMaxValue // this was done to get gap between max data point and final grid line. hovering that gap allows us to see tooltip
    }
  };
}
