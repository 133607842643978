import {getHourRangeFromTimeRange} from '@shared-app/lib';
import {SmartImage} from '@shared/types/SmartImage';
import {TimeRange} from '@shared/ui/TimeRange';
import {utcToZonedTime} from 'date-fns-tz';

export function filterImagesWithinTimeRange(
  images: SmartImage[],
  timeRange: TimeRange,
  timezone: string
) {
  const [zonedStartHour, zonedEndHour] = getHourRangeFromTimeRange(timeRange);
  return images.filter(image => {
    const imageZonedHour = utcToZonedTime(image.dateTime, timezone).getHours();
    return zonedStartHour <= imageZonedHour && imageZonedHour < zonedEndHour;
  });
}
