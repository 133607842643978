import {useTranslation} from 'react-i18next';
import {Typography} from '@shared/ui/Typography';
import {Button} from '@shared/ui/Button';
import {StatusReportListTable} from '@features/statusReport/statusReportListTable';
import {GenerateStatusReportDialog} from '@features/generateStatusReportDialog';
import {useBoolean} from '@conxai/react-kit';
import styles from './StatusReportList.module.scss';

export function StatusReportList() {
  const {t} = useTranslation('statusReport');
  const [shouldShowGenerateForm, , showShouldShowGenerateForm, hideShouldShowGenerateForm] =
    useBoolean();

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Typography variant="h2">{t('Report List')}</Typography>
        <Button color="primary" variant="contained" onClick={showShouldShowGenerateForm}>
          {t('New Report')}
        </Button>
      </div>
      <StatusReportListTable />
      {shouldShowGenerateForm && (
        <GenerateStatusReportDialog onClose={hideShouldShowGenerateForm} />
      )}
    </div>
  );
}
