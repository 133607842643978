import {Event} from '@entities/occupancy';

export function getWorkersInsideStatistics(events: Event[]) {
  if (events.length === 0) {
    return {
      minWorkers: 0,
      maxWorkers: 0,
      workersInside: 0,
      latestEventTimestamp: undefined
    };
  }

  const {minWorkers, maxWorkers} = events.reduce(
    (statistics, event) => {
      if (event.count < statistics.minWorkers) statistics.minWorkers = event.count;
      if (event.count > statistics.maxWorkers) statistics.maxWorkers = event.count;

      return statistics;
    },
    {minWorkers: Infinity, maxWorkers: 0, workersInside: 0}
  );

  const lastEvent = events.at(-1);

  return {
    minWorkers,
    maxWorkers,
    workersInside: lastEvent?.count,
    latestEventTimestamp: lastEvent?.timestamp
  };
}
