import React, {useEffect, useState} from 'react';
import styles from './TimeRange.module.scss';
import {Popover, Slider, TextField, Typography} from '@mui/material';
import {type TimeRange} from '../types';
import {useAnchorElement} from '@conxai/react-kit';
import {AccessTimeIcon} from '@shared/ui/icons';
import {DATE_FORMATS} from '@shared/lib/constants';
import {minuteToTime} from '../lib/utils/minuteToTime';

export interface TimeRangeProps {
  initialTimeRange?: TimeRange;
  value?: TimeRange;
  step: number;
  onChange: (val: TimeRange) => void;
  formatter?: (s: number, e: number) => string;
}

export function TimeRange({
  initialTimeRange,
  step,
  onChange,
  value,
  formatter = defaultFormatter
}: TimeRangeProps) {
  {
    const min = 0;
    const max = 1440;

    const [timeRange, setTimeRange] = useState<[number, number]>(
      timeRangeToArray(initialTimeRange || value)
    );
    const {anchor, setAnchor, clearAnchor} = useAnchorElement();

    const open = Boolean(anchor);
    const id = open ? 'time-range-popover' : undefined;

    const onChangeHandler = (_: Event, value: [number, number]) => {
      setTimeRange(value);
    };

    const onSaveHandler = (_: Event, value: [number, number]) => {
      onChange({startTime: value[0], endTime: value[1]});
    };

    useEffect(() => {
      if (!value) return;
      setTimeRange([value.startTime, value.endTime]);
    }, [value]);

    const formattedValue = formatter(timeRange[0], timeRange[1]);

    return (
      <div className={`con-time-range-picker-wrapper`}>
        <span className={styles.wrapperButton} onClick={setAnchor}>
          <TextField
            aria-describedby={id}
            id="outlined-basic"
            label="Time Range"
            variant="filled"
            value={formattedValue}
            InputLabelProps={{shrink: true, variant: 'filled'}}
            InputProps={{
              className: styles.input,
              disableUnderline: true,
              readOnly: true,
              slotProps: {
                input: {
                  size: 15 // this is to reduce default width of input
                }
              },
              endAdornment: <AccessTimeIcon className={styles.endIcon} />
            }}
          />
        </span>
        <Popover
          className={`con-time-range-picker-popover`}
          id={id}
          open={open}
          anchorEl={anchor}
          onClose={clearAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Typography>{formattedValue}</Typography>
          <Slider
            min={min}
            max={max}
            step={step}
            getAriaLabel={() => 'Time frame'}
            value={timeRange}
            onChange={onChangeHandler}
            onChangeCommitted={onSaveHandler}
            valueLabelDisplay={'off'}
            getAriaValueText={val => minuteToTime(val, DATE_FORMATS.TIME_12_SHORT)}
          />
        </Popover>
      </div>
    );
  }

  function timeRangeToArray(timeRange: TimeRange): [number, number] {
    return [timeRange.startTime, timeRange.endTime];
  }
}

function defaultFormatter(start: number, end: number) {
  const formattedValue = `${minuteToTime(start, DATE_FORMATS.TIME_12_SHORT)} - ${minuteToTime(
    end,
    DATE_FORMATS.TIME_12_SHORT
  )}`;

  return formattedValue;
}
