import {useMemo} from 'react';
import {DATE_FORMATS} from '@shared/lib/constants';
import {TextField} from '@shared/ui/TextField';
import {CalendarTodayIcon} from '@shared/ui/icons';
import {useTranslation} from 'react-i18next';
import {UnsavedMilestone} from '@shared/types/Milestone';
import {DialogCloseButton, DialogContainer, DialogContent, DialogTitle} from '@shared/ui/Dialog';
import {DateCalendar} from '@shared/ui/Date';
import {useBoolean} from '@conxai/react-kit';
import styles from './MilestoneForm.module.scss';
import {Formik} from 'formik';
import {TFunction} from 'i18next';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';

interface Props {
  onSubmit: ({name, date}: {name: string; date: Date}) => void;
  defaultValue: Partial<UnsavedMilestone>;
  disableDateChange?: boolean;
}

export function MilestoneForm({onSubmit, defaultValue, disableDateChange}: Props) {
  const {t} = useTranslation(['milestone']);
  const timezone = useProjectTimezone();
  const [isPickerOpen, , showPicker, hidePicker] = useBoolean(false);

  const validate = useMemo(() => getValidator(t), [t]);

  return (
    <Formik initialValues={defaultValue} onSubmit={onSubmit} validate={validate}>
      {({handleChange, handleBlur, handleSubmit, errors, values, setFieldValue}) => (
        <>
          <form id={'milestone-form'} onSubmit={handleSubmit} noValidate>
            <TextField
              onChange={handleChange}
              onBlur={handleBlur}
              name="name"
              className={styles.milestoneName}
              label={t('Milestone name', {ns: 'milestone'})}
              value={values.name}
              error={!!errors.name}
              helperText={errors.name || ' '}
              required
              fullWidth
            />
            <TextField
              onClick={disableDateChange ? undefined : showPicker}
              autoComplete="off"
              name="date"
              label={t('Date')}
              placeholder={t('Select a Date')}
              InputProps={{
                disabled: disableDateChange,
                className: styles.dateInput,
                endAdornment: (
                  <div className={styles.endAdornment}>
                    <CalendarTodayIcon className={styles.endIcon} />
                  </div>
                )
              }}
              value={values.date ? formatInTimeZone(values.date, timezone, DATE_FORMATS.DATE) : ''}
              InputLabelProps={{className: styles.label}}
              error={!!errors.date}
              helperText={(errors as FormErrors).date || ' '}
              disabled={disableDateChange}
            />
          </form>
          {isPickerOpen && (
            <DialogContainer open className={styles.dialog} onClose={hidePicker}>
              <DialogTitle className={styles.title} variant="h5">
                {t('Milestone Date')}
                <DialogCloseButton onClick={hidePicker} />
              </DialogTitle>
              <DialogContent className={styles.content}>
                <DateCalendar
                  className={styles.calendar}
                  onChange={date => {
                    setFieldValue('date', date);
                    hidePicker();
                  }}
                  value={values.date}
                />
              </DialogContent>
            </DialogContainer>
          )}
        </>
      )}
    </Formik>
  );
}

const FORM_ID = 'milestone-form';

MilestoneForm.FORM_ID = FORM_ID;

interface FormErrors extends Partial<Record<keyof UnsavedMilestone, string>> {}

function getValidator(t: TFunction) {
  return function (values: Partial<UnsavedMilestone>) {
    const errors: FormErrors = {};

    if (!values.name) {
      errors.name = t('Please enter milestone name');
    }

    if (!values.date) {
      errors.date = t('Please select milestone date');
    }

    return errors;
  };
}
