import styles from './ProjectsGrid.module.scss';
import {useTranslation} from 'react-i18next';
import {Typography} from '@shared/ui/Typography';

export default function ProjectsGridEmpty() {
  const {t} = useTranslation();

  return (
    <div className={styles.container_empty}>
      <Typography variant={'h2'}>{t('You have no projects')}</Typography>
    </div>
  );
}
