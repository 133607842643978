import {Expose, Transform} from 'class-transformer';
import {FeatureFlag} from '@shared-app/types';
import {FeatureFlagKey} from '@shared-app/types/FeatureFlag';
import {getEnumFromStringValue} from '@conxai/react-kit';

export default class FeatureFlagModel implements FeatureFlag {
  @Expose({name: 'key'})
  @Transform(({value}) => getEnumFromStringValue(FeatureFlagKey, value))
  key: FeatureFlagKey;

  @Expose({name: 'enabled'})
  enabled: boolean;
}
