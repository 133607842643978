import {Indicator} from '@shared-app/types/StatusReportDetails';
import {CheckOutlinedIcon, WarningIcon} from '@shared/ui/icons';
import classNames from 'classnames';
import styles from './FindingIndicator.module.scss';

interface FindingIndicatorProps {
  indicator: Indicator;
  className?: string;
}

export function FindingIndicator({indicator, className}: FindingIndicatorProps) {
  if (typeof indicator === 'number') {
    return <span className={classNames(styles.numberIndicator, className)}>{indicator}</span>;
  }

  if (indicator === 'check') {
    return (
      <span className={styles.checkIcon}>
        <CheckOutlinedIcon />
      </span>
    );
  }

  if (indicator === 'warning') {
    return (
      <span className={styles.warningIcon}>
        <WarningIcon />
      </span>
    );
  }
}
