import {useDeleteSavedSearch} from '@entities/savedSearch/hooks';
import {ToastService} from '@shared/lib/services';
import {SavedSearch} from '@shared/types/SavedSearch';
import {Button} from '@shared/ui/Button';
import {DialogActions, DialogContainer, DialogContent, DialogTitle} from '@shared/ui/Dialog';
import {CircularLoader} from '@shared/ui/Loader';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './DeleteSavedSearchDialog.module.scss';

interface Props {
  savedSearch: SavedSearch;
  onClose: () => void;
}

export function DeleteSavedSearchDialog({savedSearch, onClose}: Props) {
  const {t} = useTranslation('search');
  const {deleteSavedSearch, isDeleting, isDeleted} = useDeleteSavedSearch();

  useEffect(() => {
    if (isDeleted) {
      ToastService.success(t('Saved search deleted successfully', {ns: 'search'}));
      onClose();
    }
  }, [isDeleted, onClose, t]);

  return (
    <DialogContainer onClose={onClose} open>
      <DialogTitle>{t('Delete Saved Search')}</DialogTitle>
      <DialogContent className={styles.container}>
        <div>{t('Are you sure want to delete a search?')}</div>
        <div>{t('This action can not be reverted.', {ns: 'translation'})}</div>
        <div className={styles.name}>{savedSearch.name}</div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={onClose} variant="contained">
          {t('Cancel', {ns: 'translation'})}
        </Button>
        <Button
          onClick={() => deleteSavedSearch(savedSearch.id)}
          variant="outlined"
          color="error"
          disabled={isDeleting}
          endIcon={isDeleting ? <CircularLoader size="1em" color="inherit" /> : undefined}
        >
          {t('Delete', {ns: 'translation'})}
        </Button>
      </DialogActions>
    </DialogContainer>
  );
}
