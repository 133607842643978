import {useEffect} from 'react';
import {
  DialogActions,
  DialogCloseButton,
  DialogContainer,
  DialogContent,
  DialogTitle
} from '@shared/ui/Dialog';
import {Button} from '@shared/ui/Button';
import {DownloadIcon} from '@shared/ui/icons';
import {useTranslation} from 'react-i18next';
import {Typography} from '@shared/ui/Typography';
import {formatInTimeZone} from 'date-fns-tz';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {DATE_FORMATS} from '@shared/lib/constants';
import {StatusReportDetails} from '@shared-app/types/StatusReportDetails';
import {FindingsList} from '@features/statusReport/FindingsList';
import styles from './ReportDialog.module.scss';

interface ReportDialogProps {
  onClose: () => void;
  report: StatusReportDetails;
}

export function ReportDialog({onClose, report}: ReportDialogProps) {
  const {t} = useTranslation('statusReport');
  const {project} = useCurrentProject();
  const timezone = useProjectTimezone();

  useEffect(() => {
    document.title = `${t('Status Report')} ${formatInTimeZone(
      report.reportDate,
      timezone,
      DATE_FORMATS.DATE_TIME_IN_FILE
    )}`;

    return () => {
      document.title = 'Sitelens | Conxai';
    };
  }, [report, t, timezone]);

  return (
    <DialogContainer className={styles.dialogContainer} onClose={onClose} fullWidth open>
      <DialogTitle className={styles.dialogTitle}>
        <div className={styles.titleContent}>
          <Typography classes={styles.title} variant="h4">
            {t('Status Report')}
          </Typography>
          <span className={styles.graybg}>
            {formatInTimeZone(report.reportDate, timezone, DATE_FORMATS.DATE_TIME)}
          </span>
          <div className={styles.source}>{t('Source')}</div>
          <div className={styles.graybg}>
            {project.getCameraById(report.sources.at(0)).name || t('N/A', {ns: 'translation'})}
          </div>
        </div>
        <DialogCloseButton className={styles.closeBtn} onClick={onClose} />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>{t('High Level Summary')}</div>
          <div className={styles.sectionContent}>{report.summary}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>{t('Site Conditions')}</div>
          <FindingsList
            indicatorClassName={styles.numberIndicator}
            findings={report.siteConditions.findings}
          />
        </div>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>{t('Labor')}</div>
          <FindingsList
            indicatorClassName={styles.numberIndicator}
            findings={report.labour.findings}
          />
        </div>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>{t('Equipment')}</div>
          <FindingsList
            indicatorClassName={styles.numberIndicator}
            findings={report.equipment.findings}
          />
        </div>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>{t('Ongoing Activities')}</div>
          <FindingsList
            indicatorClassName={styles.numberIndicator}
            findings={report.ongoingActivities.findings}
          />
        </div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          variant="contained"
          startIcon={<DownloadIcon color="inherit" />}
          onClick={() => window.print()}
        >
          {t('Print', {ns: 'translation'})}
        </Button>
      </DialogActions>
    </DialogContainer>
  );
}
