import {useMutation} from '@shared/lib/hooks';
import {useCurrentProject} from '@entities/project';
import {FeatureFlagService} from '../services/FeatureFlag.service';
import {useTranslation} from 'react-i18next';

export function useUpdateFeatureFlags() {
  const {t} = useTranslation();
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const listQueryOptions = {queryKey: ['feature-flags', projectId]};

  const {
    mutate: updateFeatureFlags,
    isLoading: isSaving,
    isSuccess: isSaved
  } = useMutation<string[]>({
    mutationFn: enabledFlags =>
      FeatureFlagService.updateFlags(projectId, enabledFlags).catch(() => {
        throw new Error(t('Error while updating feature flags'));
      }),
    options: listQueryOptions
  });

  return {
    updateFeatureFlags,
    isSaving,
    isSaved: isSaved
  };
}
