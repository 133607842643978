import {
  DialogActions,
  DialogCloseButton,
  DialogContainer,
  DialogContent,
  DialogTitle
} from '@shared/ui/Dialog';
import {useTranslation} from 'react-i18next';
import {Button} from '@shared/ui/Button';
import {AddCalibrationPointForm} from './AddCalibrationPointForm';
import {useAddCalibrationPoint} from '../../lib/hooks/useAddCalibrationPoint';
import {ToastService} from '@shared/lib/services';
import {useState} from 'react';
import {CircularLoader} from '@shared/ui/Loader';
import styles from './AddCalibrationPointDialog.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  projectId: string;
  regionId: string;
}

export interface AddCalibrationPointFormValues {
  occupancy: number;
  date: Date;
}

export function AddCalibrationPointDialog({open, onClose, onSuccess, projectId, regionId}: Props) {
  const {t} = useTranslation(['occupancy', 'translation']);
  const [isSaving, setSaving] = useState(false);
  const {addCalibrationPoint} = useAddCalibrationPoint();

  const handleSubmit = ({occupancy, date}: AddCalibrationPointFormValues) => {
    setSaving(true);
    const res = addCalibrationPoint(projectId, regionId, date, occupancy);

    if (res) {
      ToastService.success(t('Calibration point was successfully created.'));
      onSuccess();
    } else {
      ToastService.error(t('Calibration point was not created.'));
    }

    setSaving(false);
  };

  const defaultValue = {
    occupancy: undefined,
    date: new Date()
  } as AddCalibrationPointFormValues;

  return (
    <DialogContainer onClose={onClose} fullWidth open={open}>
      <DialogTitle className={styles.title} variant="h5">
        {t('Add Calibration Point', {ns: 'occupancy'})}
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <AddCalibrationPointForm onSubmit={handleSubmit} defaultValue={defaultValue} />
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={onClose}>{t('Cancel', {ns: 'translation'})}</Button>
        <Button
          type="submit"
          form={AddCalibrationPointForm.FORM_ID}
          variant="contained"
          disabled={isSaving}
          endIcon={isSaving ? <CircularLoader size="1em" color="inherit" /> : undefined}
        >
          {t('Add', {ns: 'translation'})}
        </Button>
      </DialogActions>
    </DialogContainer>
  );
}
