import {Badge} from '@mui/material';
import styles from './DetailHeader.module.scss';
import {Typography} from '@shared/ui/Typography';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import {useTranslation} from 'react-i18next';

interface DetailHeaderProps {
  reportDate: Date;
  timezone: string;
}

export function DetailHeader({reportDate, timezone}: DetailHeaderProps) {
  const {t} = useTranslation('statusReport');
  return (
    <div className={styles.header}>
      <Badge color="primary" badgeContent={`BETA`} className={styles.betaBadge}>
        <>
          <Typography variant="h4">{t('Status Report')}</Typography>
          <span className={styles.graybg}>
            {formatInTimeZone(reportDate, timezone, DATE_FORMATS.DATE_TIME)}
          </span>
        </>
      </Badge>
    </div>
  );
}
