import {setZonedTime} from '@shared/lib/utils';
import {DateRange} from '@shared/ui/Date';
import {addHours} from 'date-fns';

export function roundDateRangeToNearHour(dateRange: DateRange, timezone: string) {
  const startDate = setZonedTime(dateRange.startDate, timezone, undefined, 0, 0, 0);
  const endDate = setZonedTime(addHours(dateRange.endDate, 1), timezone, undefined, 0, 0, 0);

  return {
    startDate,
    endDate
  };
}
