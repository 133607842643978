import Skeleton from '@shared/ui/Skeleton';
import styles from './StatusReportListTableSkeleton.module.scss';

export function StatusReportListTableSkeleton() {
  return (
    <div className={styles.container}>
      {Array.from({length: 30}).map((_, index) => (
        <Skeleton key={index} className={styles.skeleton} />
      ))}
    </div>
  );
}
