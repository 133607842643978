import {
  toast,
  Toaster as BaseToaster,
  ToastBar,
  ToasterProps,
  ToastType,
  ToastOptions
} from 'react-hot-toast';
import {IconButton} from '../Button';
import {CloseIcon, ErrorIcon, TaskAltIcon} from '../icons';
import styles from './Toaster.module.scss';
import classNames from 'classnames';

const TOASTER_OPTIONS: Partial<Record<ToastType, ToastOptions>> = {
  success: {
    className: classNames(styles.toastBar, styles.toastBar__success),
    icon: <TaskAltIcon className={classNames(styles.icon, styles.icon__success)} />
  },
  error: {
    className: classNames(styles.toastBar, styles.toastBar__error),
    icon: <ErrorIcon className={classNames(styles.icon, styles.icon__error)} />
  }
};

export function Toaster({containerClassName, ...restProps}: ToasterProps) {
  return (
    <BaseToaster
      position="top-center"
      {...restProps}
      containerClassName={classNames(styles.container, containerClassName)}
      toastOptions={TOASTER_OPTIONS}
    >
      {t => (
        <ToastBar toast={t}>
          {({icon, message}) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <IconButton className={styles.closeBtn} onClick={() => toast.dismiss(t.id)}>
                  <CloseIcon />
                </IconButton>
              )}
            </>
          )}
        </ToastBar>
      )}
    </BaseToaster>
  );
}
