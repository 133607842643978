import Skeleton from '@shared/ui/Skeleton';
import styles from './StatusReportDetailsSkeleton.module.scss';

export function StatusReportDetailsSkeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Skeleton className={styles.title} />
        <Skeleton className={styles.reportDate} />
      </div>
      <div className={styles.header}>
        <Skeleton className={styles.sourcelabel} />
        <Skeleton className={styles.source} />
      </div>
      <div className={styles.summaries}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
      <Skeleton className={styles.findingsTitle} />
      <div className={styles.findingsAndImage}>
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  );
}
