import {useCallback, useEffect} from 'react';
import {
  DialogContainer,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogCloseButton
} from '@shared/ui/Dialog';
import {useTranslation} from 'react-i18next';
import {Button} from '@shared/ui/Button';
import {CircularLoader} from '@shared/ui/Loader';
import {ToastService} from '@shared/lib/services';
import {GenerateStatusReportForm} from './GenerateStatusReportForm';
import {useGenerateStatusReport} from '@entities/statusReport';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import styles from './GenerateStatusReportDialog.module.scss';
import {GenerateStatusReportFormValues} from '../types/GenerateStatusFormValues';
import {getGenerateReportRequestParams} from '../utils/getGenerateReportRequestParams';

interface Props {
  onSuccess?: (id: string) => void;
  onClose: () => void;
  disableDateChange?: boolean;
}

export function GenerateStatusReportDialog({onSuccess, onClose, disableDateChange}: Props) {
  const {t} = useTranslation('statusReport');
  const {project} = useCurrentProject();
  const timezone = useProjectTimezone();

  const {generateReport, isInProgress, isSuccess} = useGenerateStatusReport();

  const handleSubmit = useCallback(
    (formValues: GenerateStatusReportFormValues) => {
      const requestParams = getGenerateReportRequestParams(formValues, project, timezone);
      generateReport(requestParams);
    },
    [generateReport, timezone, project]
  );

  useEffect(() => {
    if (isSuccess) {
      ToastService.success(t('Report is being generated'));
      onClose();
    }
  }, [isSuccess, onClose, t, onSuccess]);

  return (
    <DialogContainer onClose={onClose} fullWidth open>
      <DialogTitle className={styles.title} variant="h5">
        {t('Generate New Report')}
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <GenerateStatusReportForm
          onSubmit={handleSubmit}
          disableDateChange={disableDateChange}
          cameras={project.cameras}
        />
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={onClose}>{t('Cancel', {ns: 'translation'})}</Button>
        <Button
          type="submit"
          form={GenerateStatusReportForm.FORM_ID}
          variant="contained"
          disabled={isInProgress}
          endIcon={isInProgress ? <CircularLoader size="1em" color="inherit" /> : undefined}
        >
          {t('Generate', {ns: 'translation'})}
        </Button>
      </DialogActions>
    </DialogContainer>
  );
}
