import {useEffect} from 'react';
import {
  DialogContainer,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogCloseButton
} from '@shared/ui/Dialog';
import {useTranslation} from 'react-i18next';
import {Button} from '@shared/ui/Button';
import {CircularLoader} from '@shared/ui/Loader';
import {ToastService} from '@shared/lib/services';
import styles from './DeleteUserDialog.module.scss';
import {User} from '@shared/types/User';
import {useRemoveUser} from '@entities/user/lib/hooks';

interface Props {
  user: User;
  onSuccess: () => void;
  onClose: () => void;
}

export function DeleteUserDialog({user, onSuccess, onClose}: Props) {
  const {t} = useTranslation('user');

  const {removeUser, isRemoved, isRemoving} = useRemoveUser();

  const handleDelete = () => {
    removeUser(user.id);
  };

  useEffect(() => {
    if (isRemoved) {
      ToastService.success(t('User was successfully deleted.'));
      onClose();
      onSuccess();
    }
  }, [isRemoved, onClose, t, onSuccess]);

  return (
    <DialogContainer onClose={onClose} fullWidth open>
      <DialogTitle className={styles.title} variant="h5">
        {t('Delete User', {ns: 'user'})}
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <div>{t('Are you sure want to delete User?')}</div>
        <div className={styles.email}>{user.email}</div>
        <div>
          {t(
            'After this action, User will not be able to login into current project, that will not affect access to any other projects.'
          )}
        </div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={onClose} variant="contained">
          {t('Cancel', {ns: 'translation'})}
        </Button>
        <Button
          onClick={handleDelete}
          variant="outlined"
          color="error"
          disabled={isRemoving}
          endIcon={isRemoving ? <CircularLoader size="1em" color="inherit" /> : undefined}
        >
          {t('Delete', {ns: 'translation'})}
        </Button>
      </DialogActions>
    </DialogContainer>
  );
}
