import {Tag} from '@shared/types/Tag';
import Loader from '@shared/ui/Loader';
import styles from './DrawSmartImageMask.module.scss';
import useSmartImageMask from '@features/drawSmartImageMask/lib/hooks/useSmartImageMask';
import {SmartImageMasks} from '@shared/types/SmartImageMasks';

interface Props {
  tag: Tag;
  masks: SmartImageMasks;
}

export function DrawSmartImageMask({tag, masks}: Props) {
  const {imageMask, isLoading} = useSmartImageMask(masks, tag);

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return imageMask ? <img src={imageMask} alt={`mask`} /> : null;
}
