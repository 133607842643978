import {useMemo, useState} from 'react';
import {SummaryCard} from '@features/statusReport/SummaryCard';
import {SummaryChip} from '@features/statusReport/SummaryChip';
import {StatusReportDetails} from '@shared-app/types/StatusReportDetails';
import {WeatherIcon} from '@shared/ui/WeatherIcon';
import {truncate} from '@conxai/react-kit';
import {useTranslation} from 'react-i18next';
import {FindingsList} from '@features/statusReport/FindingsList';
import {ReportImage} from '@features/statusReport/ReportImage';
import {PotentialIssues} from '@features/statusReport/PotentialIssues';
import {getImportantFindings} from '../lib/utils/getImportantFindings';
import {getFindingsTitles} from '../lib/utils/getFindingsTitles';
import {SummaryCardContent} from './SummaryCardContent';
import styles from './ReportSummary.module.scss';

interface ReportSummaryProps {
  report: StatusReportDetails;
}

export function ReportSummary({report}: ReportSummaryProps) {
  const {t} = useTranslation('statusReport');
  const [activeFindings, setActiveFindings] = useState<
    'labour' | 'siteConditions' | 'equipment' | 'ongoingActivities'
  >('siteConditions');

  const findingsTitles = useMemo(() => getFindingsTitles(t), [t]);

  return (
    <>
      <div className={styles.summaryList}>
        <SummaryCard
          isSelected={activeFindings === 'siteConditions'}
          onClick={() => setActiveFindings('siteConditions')}
          title={t('Site Conditions')}
          titleRightContent={
            report.siteConditions.numOfIssues ? (
              <PotentialIssues numOfIssues={report.siteConditions.numOfIssues} />
            ) : undefined
          }
        >
          <div className={styles.siteConditionContent}>
            <div className={styles.weather}>
              <WeatherIcon id={report.siteConditions.weather} />
              {truncate(t(report.siteConditions.weather), 16)}
            </div>
            <div className={styles.summaries}>
              {report.siteConditions.summary.map(summary => (
                <SummaryChip
                  key={summary.message}
                  summary={summary}
                  status={getSiteStatus(summary.message)}
                />
              ))}
            </div>
          </div>
        </SummaryCard>
        <SummaryCard
          isSelected={activeFindings === 'labour'}
          title={t('Labor')}
          onClick={() => setActiveFindings('labour')}
          titleRightContent={
            report.labour.numOfIssues ? (
              <PotentialIssues numOfIssues={report.labour.numOfIssues} />
            ) : undefined
          }
        >
          <SummaryCardContent
            summary={report.labour.summary}
            emptyMessage={t('We have not detected any workers on site')}
          />
        </SummaryCard>
        <SummaryCard
          isSelected={activeFindings === 'equipment'}
          title={t('Equipment')}
          onClick={() => setActiveFindings('equipment')}
          titleRightContent={
            report.equipment.numOfIssues ? (
              <PotentialIssues numOfIssues={report.equipment.numOfIssues} />
            ) : undefined
          }
        >
          <SummaryCardContent
            summary={report.equipment.summary}
            emptyMessage={t('We have not detected any equipment on site')}
          />
        </SummaryCard>
        <SummaryCard
          isSelected={activeFindings === 'ongoingActivities'}
          title={t('Ongoing Activities')}
          onClick={() => setActiveFindings('ongoingActivities')}
          titleRightContent={
            report.ongoingActivities.summary.length ? (
              <div className={styles.numOfIssues}>{report.ongoingActivities.summary.length}</div>
            ) : undefined
          }
        >
          <SummaryCardContent
            summary={report.ongoingActivities.summary}
            emptyMessage={t('We have not detected any activities on site')}
          />
        </SummaryCard>
      </div>
      <div className={styles.findingsTitle}>{findingsTitles[activeFindings]}</div>
      <div className={styles.findingsAndImage}>
        <FindingsList
          className={styles.findings}
          findings={getImportantFindings(report[activeFindings].findings)}
        />
        <ReportImage imageId={report.images[0]} />
      </div>
    </>
  );
}

function getSiteStatus(text: string) {
  if (text === 'Active') {
    return 'active';
  }

  if (text === 'Inactive') {
    return 'inactive';
  }

  return undefined;
}
