import {customerAPI, imageAPI, sitelensAPI} from '@shared/api';
import ProjectModel from '@entities/project/model/Project.model';
import {plainToInstance} from 'class-transformer';
import {Project, ProjectRequest} from '@shared/types/Project';
import {ProjectImageStatistics} from '@shared/types/ProjectImageStatistics';
import {ProjectImageStatisticsModel} from '@entities/project/model/ProjectImageStatistics.model';
import {CameraModel} from '@entities/project';

export class ProjectService {
  public static async getProjects(): Promise<Project[]> {
    const projects = (await customerAPI.get('projects').json()) as Project[];

    return plainToInstance(ProjectModel, projects);
  }

  public static async getProjectStatistics(projectId: string): Promise<ProjectImageStatistics> {
    const projectImageStatistics = await sitelensAPI.get(`${projectId}/statistic/images`).json();

    return plainToInstance(ProjectImageStatisticsModel, projectImageStatistics);
  }

  public static async updateProject(projectId: string, project: ProjectRequest) {
    const updatedProject = (await customerAPI
      .put(`project`, {
        json: {uuid: projectId, name: project.name, image_cover: project.coverImage}
      })
      .json()) as unknown;

    return plainToInstance(ProjectModel, updatedProject);
  }

  public static async deleteCoverImage(projectId: string) {
    (await customerAPI.delete(`projects/${projectId}/cover-image`)) as unknown;
  }

  public static async getProjectCameras(projectId: string) {
    const cameras = (await customerAPI.get(`projects/${projectId}/cameras`).json()) as unknown[];

    return plainToInstance(CameraModel, cameras);
  }

  public static async ingestCameraImage(projectId: string, cameraId: string, image: string) {
    return (await imageAPI.post(`api/v1/image`, {
      json: {image: image.split(',')[1], cameraId, projectId}
    })) as unknown;
  }

  public static async updateCameraName(cameraId: string, name: string) {
    const updatedCamera = (await customerAPI
      .put('camera', {
        json: {name: name, uuid: cameraId}
      })
      .json()) as unknown;

    return plainToInstance(CameraModel, updatedCamera);
  }
}
