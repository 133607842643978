import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {FeatureFlagService} from '../services/FeatureFlag.service';
import {useTranslation} from 'react-i18next';

// taking `projectId` as param instead of using `useCurrentProject` inside because we are using this hook in `ProjectLayout` where project context doesn't exit
export function useGetFeatureFlags(projectId?: string) {
  const {t} = useTranslation();

  const {data, isLoading, isError, error} = useQuery({
    queryKey: ['feature-flags', projectId],
    queryFn: () => FeatureFlagService.getFlags(projectId),
    enabled: !!projectId
  });

  const errorMessage = isError && error.message;
  useEffect(() => {
    if (errorMessage) {
      ToastService.error(t('Error while fetching feature flag list'));
    }
  }, [errorMessage, t]);

  return {
    featureFlags: data || [],
    isLoading
  };
}
