import {IconButton} from '@shared/ui/Button';
import {ChevronLeftIcon, ChevronRightIcon} from '@shared/ui/icons';
import {addDays, isSameDay} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import styles from './DateChange.module.scss';

interface DateChangeProps {
  date: Date;
  onChange: (d: Date) => void;
  timezone: string;
  min: Date;
  max: Date;
}

export function DateChange({date, onChange, timezone, min, max}: DateChangeProps) {
  return (
    <div className={styles.container}>
      <IconButton onClick={() => onChange(addDays(date, -1))} disabled={isSameDay(min, date)}>
        <ChevronLeftIcon className={styles.icon} />
      </IconButton>
      <span className={styles.date}>{utcToZonedTime(date, timezone).toLocaleDateString()}</span>
      <IconButton onClick={() => onChange(addDays(date, 1))} disabled={isSameDay(max, date)}>
        <ChevronRightIcon className={styles.icon} />
      </IconButton>
    </div>
  );
}
