import {roundDateRangeToNearHour, getTimeSeriesPrettyXAxisTicksOptions} from '@shared-app/lib';
import {DateRange} from '@shared/ui/Date';
import {differenceInHours} from 'date-fns';

export function getPrettyXAxisTicksOptions(
  dateRange: DateRange,
  timezone: string,
  shouldAggregate: boolean
) {
  const dateRangeRounded = roundDateRangeToNearHour(dateRange, timezone);
  const {startDate, endDate} = dateRangeRounded;

  if (shouldAggregate) {
    return getTimeSeriesPrettyXAxisTicksOptions(dateRange, timezone);
  }

  return {
    min: startDate.getTime(),
    max: endDate.getTime(),
    tickAmount: differenceInHours(endDate, startDate)
  };
}
