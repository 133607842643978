import {useEffect, useState} from 'react';
import SmartImageService from '@entities/smartImage/lib/services/SmartImage.service';
import {imagePlaceholderUrl} from '@shared/lib/constants';
import {SmartImage} from '@shared/types/SmartImage';

export default function useFullSizeImageUrl(image: SmartImage) {
  const [fullSizeImageUrl, setFullSizeImageUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    SmartImageService.getImageByUrl(image.presignedUrls.image_full)
      .catch(() => imagePlaceholderUrl)
      .then(imageUrl => {
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
          setFullSizeImageUrl(imageUrl);
          setIsLoading(false);
        };
        img.onerror = () => {
          setFullSizeImageUrl(imagePlaceholderUrl);
          setIsLoading(false);
        };
      });
  }, [image.presignedUrls.image_full]);

  return {
    fullSizeImageUrl,
    isLoading
  };
}
