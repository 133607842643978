import {Project} from '@shared/types/Project';
import {SmartImage} from '@shared/types/SmartImage';
import styles from './CameraList.module.scss';
import {SmartImageCard} from '@entities/smartImage';
import {Camera} from '@shared/types/Camera';
import {useTranslation} from 'react-i18next';
import {ImageSearchIcon} from '@shared/ui/icons';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '@shared/lib/constants';

interface CameraListProps {
  images: SmartImage[];
  project: Project;
  selectedCameraIds: string[];
}

export function CameraList({project, images, selectedCameraIds}: CameraListProps) {
  const {t} = useTranslation('project');
  const navigate = useNavigate();

  const cameras = project.cameras.filter(camera => selectedCameraIds.includes(camera.id));

  return <div className={styles.container}>{cameras.map(renderImageCard)}</div>;

  function getCameraLatestImage(cameraId: string) {
    return images.filter(image => image.cameraId === cameraId)[0];
  }

  function renderImageCard(camera: Camera) {
    const latestImage = getCameraLatestImage(camera.id);
    return (
      <div
        key={camera.id}
        className={styles.imageCard}
        role="button"
        onClick={() =>
          navigate(
            `${ROUTES.PROJECT.CAMERAS.TIMELINE.withParams({
              projectId: project.id,
              cameraId: camera.id
            })}${location.search}`
          )
        }
      >
        <div className={styles.cameraName}>{camera.name}</div>
        {latestImage ? (
          <SmartImageCard image={latestImage} cameraName="" />
        ) : (
          <div className={styles.noImage}>
            <ImageSearchIcon className={styles.noImageIcon} />
            {t('No complying photos found')}
          </div>
        )}
      </div>
    );
  }
}
