import {toast} from 'react-hot-toast';

export class ToastService {
  public static success(message: string) {
    toast.success(message);
  }

  public static error(message: string) {
    toast.error(message);
  }
}
