import React, {useMemo} from 'react';
import {Tag} from '@shared/types/Tag';
import {TagChip, useGetTagsByKey} from '@entities/tag';
import {SmartImageMasks} from '@shared/types/SmartImageMasks';
import {TagEnum} from '@shared/types/TagEnum';
import styles from './SmartImageTags.module.scss';

interface Props {
  smartImageMasks: SmartImageMasks;
  activeTag?: Tag;
  setActiveTag: React.Dispatch<React.SetStateAction<Tag>>;
}

export function SmartImageTags({smartImageMasks, activeTag, setActiveTag}: Props) {
  const {tags} = useGetTagsByKey([...smartImageMasks.getMasksTags(), TagEnum.person]);

  const tagCountMap = useMemo(() => smartImageMasks.getMasksTagsCount(), [smartImageMasks]);

  return (
    <div className={styles.tagsList}>
      {Object.entries(tagCountMap).map(([tagKey, count]) => {
        const tag = tags.find(tag => tag.key === tagKey);
        const shouldShowCount = ['development', 'local'].includes(process.env.ENV);
        return tag ? (
          <div key={`selected-image-modal-${tagKey}`} onClick={() => selectTag(tag)}>
            <TagChip
              tag={tag}
              selected={activeTag && activeTag.key === tagKey}
              count={shouldShowCount ? count : undefined}
            />
          </div>
        ) : null;
      })}
    </div>
  );

  function selectTag(tag: Tag) {
    if (!activeTag || activeTag.key !== tag.key) {
      setActiveTag(tag);
    } else {
      setActiveTag(undefined);
    }
  }
}
