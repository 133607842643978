import {Amplify} from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.AWS_COGNITO_APP_CLIENT_ID,
      userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
      loginWith: {
        oauth: {
          domain: process.env.AWS_COGNITO_AUTH_DOMAIN,
          scopes: ['openid email'],
          redirectSignIn: [process.env.APP_URL],
          redirectSignOut: [process.env.APP_URL],
          responseType: 'code'
        },
        username: true
      }
    }
  }
});
