import {Event} from '@entities/occupancy';
import {findIndices} from '@shared/lib/utils';

const FORECASTED_LEFT_DATA_POINTS = 1;

export function getCalibrationPointIndicesInSeries(events: Event[]) {
  return findIndices(events, event => event.type === 0).map(
    index => index + FORECASTED_LEFT_DATA_POINTS
  );
}
