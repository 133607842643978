export function BarChartIcon() {
  return (
    <svg
      width="1.3em"
      height="1.3em"
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M3.5 21C3.21667 21 2.97917 20.9042 2.7875 20.7125C2.59583 20.5208 2.5 20.2833 2.5 20C2.5 19.7167 2.59583 19.4792 2.7875 19.2875C2.97917 19.0958 3.21667 19 3.5 19H21.5C21.7833 19 22.0208 19.0958 22.2125 19.2875C22.4042 19.4792 22.5 19.7167 22.5 20C22.5 20.2833 22.4042 20.5208 22.2125 20.7125C22.0208 20.9042 21.7833 21 21.5 21H3.5ZM5 18C4.58333 18 4.22917 17.8542 3.9375 17.5625C3.64583 17.2708 3.5 16.9167 3.5 16.5V12.5C3.5 12.0833 3.64583 11.7292 3.9375 11.4375C4.22917 11.1458 4.58333 11 5 11C5.41667 11 5.77083 11.1458 6.0625 11.4375C6.35417 11.7292 6.5 12.0833 6.5 12.5V16.5C6.5 16.9167 6.35417 17.2708 6.0625 17.5625C5.77083 17.8542 5.41667 18 5 18ZM10 18C9.58333 18 9.22917 17.8542 8.9375 17.5625C8.64583 17.2708 8.5 16.9167 8.5 16.5V7.5C8.5 7.08333 8.64583 6.72917 8.9375 6.4375C9.22917 6.14583 9.58333 6 10 6C10.4167 6 10.7708 6.14583 11.0625 6.4375C11.3542 6.72917 11.5 7.08333 11.5 7.5V16.5C11.5 16.9167 11.3542 17.2708 11.0625 17.5625C10.7708 17.8542 10.4167 18 10 18ZM15 18C14.5833 18 14.2292 17.8542 13.9375 17.5625C13.6458 17.2708 13.5 16.9167 13.5 16.5V10.5C13.5 10.0833 13.6458 9.72917 13.9375 9.4375C14.2292 9.14583 14.5833 9 15 9C15.4167 9 15.7708 9.14583 16.0625 9.4375C16.3542 9.72917 16.5 10.0833 16.5 10.5V16.5C16.5 16.9167 16.3542 17.2708 16.0625 17.5625C15.7708 17.8542 15.4167 18 15 18ZM20 18C19.5833 18 19.2292 17.8542 18.9375 17.5625C18.6458 17.2708 18.5 16.9167 18.5 16.5V4.5C18.5 4.08333 18.6458 3.72917 18.9375 3.4375C19.2292 3.14583 19.5833 3 20 3C20.4167 3 20.7708 3.14583 21.0625 3.4375C21.3542 3.72917 21.5 4.08333 21.5 4.5V16.5C21.5 16.9167 21.3542 17.2708 21.0625 17.5625C20.7708 17.8542 20.4167 18 20 18Z" />
      </g>
    </svg>
  );
}
