interface Props {
  className?: string;
}

export function MilestoneAddIcon({className = ''}: Props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 31 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.58496 9.61719V11.8394M6.58496 11.8394H19.585L17.4183 16.2839L19.585 20.7283H6.58496M6.58496 11.8394V20.7283M6.58496 29.6172V20.7283"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8313 6.71779H26.3271V10.1059C26.3271 10.2856 26.2533 10.4579 26.1219 10.585C25.9904 10.7121 25.8122 10.7835 25.6263 10.7835C25.4404 10.7835 25.2622 10.7121 25.1307 10.585C24.9993 10.4579 24.9255 10.2856 24.9255 10.1059V6.71779H21.4213C21.2354 6.71779 21.0572 6.6464 20.9257 6.51933C20.7943 6.39225 20.7205 6.21989 20.7205 6.04018C20.7205 5.86047 20.7943 5.68811 20.9257 5.56103C21.0572 5.43396 21.2354 5.36257 21.4213 5.36257H24.9255V1.97449C24.9255 1.79478 24.9993 1.62242 25.1307 1.49534C25.2622 1.36827 25.4404 1.29687 25.6263 1.29688C25.8122 1.29687 25.9904 1.36827 26.1219 1.49534C26.2533 1.62242 26.3271 1.79478 26.3271 1.97449V5.36257H29.8313C30.0172 5.36257 30.1954 5.43396 30.3268 5.56103C30.4583 5.68811 30.5321 5.86047 30.5321 6.04018C30.5321 6.21989 30.4583 6.39225 30.3268 6.51933C30.1954 6.6464 30.0172 6.71779 29.8313 6.71779Z"
        fill="currentColor"
      />
    </svg>
  );
}
