import {Project} from '@shared/types/Project';
import styles from './ProjectSearchExpandable.module.scss';
import {useIsVisible} from '@conxai/react-kit';
import {useEffect, useRef, useState} from 'react';
import {ProjectSearch} from '../ProjectSearch/ProjectSearch';
import {ProjectSearchExpandableActions} from './ProjectSearchExpandableActions';
import classNames from 'classnames';

interface Props {
  project: Project;
  onScrollToTop?: () => void;
  currentDate?: Date;
  alwaysSticky?: boolean;
  onBack?: () => void;
}

export function ProjectSearchExpandable({
  project,
  onScrollToTop,
  currentDate,
  onBack,
  alwaysSticky = false
}: Props) {
  const stickyFiltersTriggerRef = useRef<HTMLDivElement>();
  const shouldShowStickyFilters = !useIsVisible(stickyFiltersTriggerRef, true) || alwaysSticky;
  const filtersRef = useRef<HTMLDivElement>();
  const [filtersHeight, setFiltersHeight] = useState<number>();
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    setIsMinimized(shouldShowStickyFilters);
  }, [shouldShowStickyFilters]);

  useEffect(() => {
    const element = filtersRef.current;
    const observer = new ResizeObserver(([entry]) => {
      setFiltersHeight(entry.target.clientHeight);
    });
    observer.observe(element);
    return () => {
      observer.unobserve(element);
    };
  }, [filtersRef]);

  return (
    <>
      <div className={classNames(styles.container, {[styles.container__absolute]: alwaysSticky})}>
        <div
          className={styles.filtersWrapper}
          style={{
            height: isMinimized ? 0 : Number.isFinite(filtersHeight) ? filtersHeight : undefined
          }}
        >
          <div ref={filtersRef}>
            <ProjectSearch project={project} className={styles.filters} />
          </div>
        </div>
      </div>
      <ProjectSearchExpandableActions
        alwaysSticky={alwaysSticky}
        isMinimized={isMinimized}
        top={!isMinimized ? filtersHeight : undefined} // `undefined` enforces default top
        toggleFilters={() => setIsMinimized(!isMinimized)}
        onScrollToTop={shouldShowStickyFilters ? onScrollToTop : undefined}
        currentDate={shouldShowStickyFilters ? currentDate : undefined}
        onBack={onBack}
      />
      <div
        className={styles.stickyFiltersTrigger}
        ref={stickyFiltersTriggerRef}
        style={{height: 1}}
      ></div>
    </>
  );
}
