import {TagEnum} from '@shared/types/TagEnum';
import ComplianceInfoModel from '../model/ComplianceInfo.model';
import {percentage, sum} from '@conxai/react-kit';
import {AttributeStatistics} from '../../types/AttributeStatistics';

export function getAttributeStatistics(data: ComplianceInfoModel[]): AttributeStatistics {
  const statistics = data.reduce(
    (acc, item) => {
      acc[TagEnum.person_helmet_vest] += sum(item.ppe);
      acc[TagEnum.person_no_vest] += sum(item.noVest);
      acc[TagEnum.person_no_helmet] += sum(item.noHelmet);
      acc[TagEnum.person_no_helmet_no_vest] += sum(item.noPPE);
      return acc;
    },
    {
      [TagEnum.person_helmet_vest]: 0,
      [TagEnum.person_no_helmet_no_vest]: 0,
      [TagEnum.person_no_helmet]: 0,
      [TagEnum.person_no_vest]: 0
    }
  );

  const roundedStatistics = Object.fromEntries(
    Object.entries(statistics).map(([key, value]) => [key, Number(value.toFixed(2))])
  ) as AttributeStatistics;

  return roundedStatistics;
}

export function convertAbsoluteStatisticsToPercentage(statistics: AttributeStatistics) {
  const total = sum(Object.values(statistics));

  const statisticsInPercentage = {
    [TagEnum.person_helmet_vest]: percentage(statistics[TagEnum.person_helmet_vest], total),
    [TagEnum.person_no_helmet_no_vest]: percentage(
      statistics[TagEnum.person_no_helmet_no_vest],
      total
    ),
    [TagEnum.person_no_helmet]: percentage(statistics[TagEnum.person_no_helmet], total),
    [TagEnum.person_no_vest]: percentage(statistics[TagEnum.person_no_vest], total)
  };

  return statisticsInPercentage;
}

export function getAttributeStatisticsInPercentage(data: ComplianceInfoModel[]) {
  const statistics = getAttributeStatistics(data);

  return convertAbsoluteStatisticsToPercentage(statistics);
}
