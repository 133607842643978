import {Project} from '@shared/types/Project';
import {Typography} from '@shared/ui/Typography';
import {LocationOnOutlined} from '@mui/icons-material';
import styles from './ProjectHeader.module.scss';

interface ComponentProps {
  project: Project;
  className?: string;
}

export function ProjectHeader({project, className}: ComponentProps) {
  const locationName = project.settings.location.name;
  return (
    <div className={`${className} ${styles.container}`}>
      <Typography variant={'h2'} classes={styles.name}>
        {project.name}
      </Typography>
      {locationName && (
        <div className={styles.location}>
          <LocationOnOutlined />
          <Typography variant={'subtitle1'}>{locationName}</Typography>
        </div>
      )}
    </div>
  );
}
