export const DATE_FORMATS = {
  DATE: 'dd/MM/yyyy',
  DATE_WIDE: 'EEEE, dd MMM yyyy',
  DATE_ABBR_MONTH: 'dd MMM yyyy',
  DATE_TIME: 'hh:mm a dd/MM/yyyy',
  DATE_TIME_24: 'HH:mm dd/MM/yyyy',
  DATE_TIME_IN_FILE: 'hh_mm_a_dd_MM_yyyy',
  TIME: 'hh:mm a',
  TIME_12_SHORT: 'hh a',
  TIME_24: 'HH:mm',
  TIME_24_WIDE: 'HH:mm:ss',
  DATE_MONTH: 'dd MMM',
  ISO: "yyyy-MM-dd'T'HH:mm:ssXXX" // date-fns already has util: `formatISO` , this was added for `date-fns-tz`
};
