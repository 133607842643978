import {useMemo} from 'react';
import {TextField} from '@shared/ui/TextField';
import {useTranslation} from 'react-i18next';
import styles from './AddCalibrationPointForm.module.scss';
import {Formik} from 'formik';
import {TFunction} from 'i18next';
import {useProjectTimezone} from '@entities/project';
import {DateTimeInput} from '@shared/ui/Date';
import {AddCalibrationPointFormValues} from '@entities/occupancy/ui/AddCalibrationPointDialog/AddCalibrationPointDialog';

interface Props {
  onSubmit: ({occupancy, date}: AddCalibrationPointFormValues) => void;
  defaultValue: AddCalibrationPointFormValues;
}

export function AddCalibrationPointForm({onSubmit, defaultValue}: Props) {
  const {t} = useTranslation(['occupancy', 'translation']);
  const timezone = useProjectTimezone();

  const validate = useMemo(() => getValidator(t), [t]);

  return (
    <Formik initialValues={defaultValue} onSubmit={onSubmit} validate={validate}>
      {({handleChange, handleBlur, handleSubmit, errors, values, setFieldValue}) => (
        <>
          <form className={styles.formContainer} id={FORM_ID} onSubmit={handleSubmit} noValidate>
            <DateTimeInput
              timezone={timezone}
              label={t('Date and time', {ns: 'translation'})}
              value={values.date}
              maxDateTime={defaultValue.date}
              className={styles.calendar}
              onChange={date => {
                setFieldValue('date', date);
              }}
            />
            <TextField
              onChange={handleChange}
              onBlur={handleBlur}
              name="occupancy"
              type={`number`}
              className={styles.occupancy}
              label={t('Occupancy', {ns: 'occupancy'})}
              value={values.occupancy}
              error={!!errors.occupancy}
              helperText={errors.occupancy}
              required
              fullWidth
              inputProps={{min: 0}}
            />
          </form>
        </>
      )}
    </Formik>
  );
}

const FORM_ID = 'add-calibration-point-form';

AddCalibrationPointForm.FORM_ID = FORM_ID;

interface FormErrors extends Partial<Record<keyof AddCalibrationPointFormValues, string>> {}

function getValidator(t: TFunction) {
  return function (values: Partial<AddCalibrationPointFormValues>) {
    const errors: FormErrors = {};

    if (!values.occupancy && values.occupancy !== 0) {
      errors.occupancy = t('Please enter occupancy');
    }

    if (!values.date) {
      errors.date = t('Please select calibration point date');
    }

    return errors;
  };
}
