import {isSelected} from '@entities/smartSearchFilter';
import {TagCard} from '@entities/tag';
import {Tag} from '@shared/types/Tag';

interface Props {
  tag: Tag;
  onClick: (tag: Tag) => void;
}

export function DefaultTagCard({tag, onClick}: Props) {
  return <TagCard onClickCallback={onClick} key={tag.key} tag={tag} isSelected={isSelected(tag)} />;
}
