import React from 'react';
import classNames from 'classnames';
import styles from './Image.module.scss';

interface ImageProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  isLoading?: boolean;
}

export function Image({isLoading, className: classNameProp, ...props}: ImageProps) {
  const className = classNames(classNameProp, styles.img, {[styles.img__loading]: isLoading});
  return <img className={className} {...props} />;
}
