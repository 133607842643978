import {useTranslation} from 'react-i18next';
import {useMutation} from '@shared/lib/hooks';
import {ProjectService, useCurrentProject} from '@entities/project';
import {Camera} from '@shared/types/Camera';
import {PROJECT_LIST_QUERY_KEY} from '@entities/project';
import {Project} from '@shared/types/Project';
import {instanceToInstance} from 'class-transformer';

export function useCameraNameUpdate() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation(['project']);

  const {
    mutate: updateCameraName,
    isLoading: isSaving,
    isSuccess: isSaved
  } = useMutation<{name: string; cameraId: string}, Camera>({
    mutationFn: ({cameraId, name}) => {
      return ProjectService.updateCameraName(cameraId, name).catch(() => {
        throw new Error(t('Error occurred. Please try to update again.'));
      });
    },
    onSuccess: (client, updatedCamera) => {
      const cameras: Camera[] = client.getQueryData(getListQueryKey(projectId));
      const updatedCameras = cameras.reduce((acc, camera) => {
        if (camera.id === updatedCamera.id) {
          acc.push(updatedCamera);
        } else {
          acc.push(camera);
        }
        return acc;
      }, []);
      client.setQueryData(getListQueryKey(projectId), updatedCameras);

      const projects: Project[] = client.getQueryData(PROJECT_LIST_QUERY_KEY);
      const updatedProjects = projects.reduce((acc, project) => {
        if (project.id === projectId) {
          project = instanceToInstance(project, {ignoreDecorators: true});
          project.cameras = updatedCameras;
        }
        acc.push(project);
        return acc;
      }, []);
      client.setQueryData(PROJECT_LIST_QUERY_KEY, updatedProjects);
    },
    options: {skipInvalidation: true}
  });

  return {
    updateCameraName,
    isSaving,
    isSaved: isSaved
  };
}

function getListQueryKey(projectId: string) {
  return ['project-cameras', projectId];
}
