import {Typography} from '@shared/ui/Typography';
import {useTranslation} from 'react-i18next';
import {LastEventTime} from './LastEventTime';
import {useMemo} from 'react';
import {getWorkersInsideStatistics} from '../lib/utils/getWorkersInsideStatistics';
import {Event} from '@entities/occupancy';
import {Tooltip} from '@shared/ui/Tooltip';
import styles from './WorkersInsideCard.module.scss';

interface WorkersInsideCardProps {
  events: Event[];
  isLive: boolean;
}

export function WorkersInsideCard({events, isLive}: WorkersInsideCardProps) {
  const {t} = useTranslation('occupancy');

  const {minWorkers, maxWorkers, workersInside, latestEventTimestamp} = useMemo(
    () => getWorkersInsideStatistics(events),
    [events]
  );

  return (
    <div className={styles.root}>
      <Typography variant="h3">
        {t('Occupancy')}
        {isLive && (
          <Tooltip title={t('Automatically updated every 30 sec')} placement="top">
            <div className={styles.liveChip}>{t('Live')}</div>
          </Tooltip>
        )}
      </Typography>
      <LastEventTime timestamp={latestEventTimestamp} />
      <div className={styles.workersInsideContainer}>
        <Typography classes={styles.workersInsideCountLabel} variant="subtitle1">
          {t('Workers inside')}
        </Typography>
        <span className={styles.workersInsideCount}>{workersInside}</span>
      </div>
      <div>
        <div className={styles.minMaxCounts}>
          <span className={styles.countLabel}>{t('Maximum Workers')}</span>
          <span className={styles.count}>{maxWorkers}</span>
        </div>
        <div className={styles.minMaxCounts}>
          <span className={styles.countLabel}>{t('Minimum Workers')}</span>
          <span className={styles.count}>{minWorkers}</span>
        </div>
      </div>
    </div>
  );
}
