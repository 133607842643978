import {ProjectCard, useGetProjects} from '@entities/project';
import styles from './ProjectsGrid.module.scss';
import {Link} from 'react-router-dom';
import {ROUTES} from '@shared/lib/constants';
import classNames from 'classnames';
import ProjectsGridSkeleton from './ProjectsGridSkeleton';
import ProjectsGridEmpty from '@widgets/projects/projectsGrid/ui/ProjectsGridEmpty';

export default function ProjectsGrid() {
  const {projects, isFetching} = useGetProjects();

  if (isFetching) {
    return <ProjectsGridSkeleton />;
  }

  if (projects.length === 0) {
    return <ProjectsGridEmpty />;
  }

  return (
    <div className={styles.container}>
      {projects.map((project, index) => (
        <Link
          className={classNames(styles.item)}
          key={`project-card-${index}`}
          to={ROUTES.PROJECT.IMAGE.LIST.withParams({projectId: project.id})}
        >
          <ProjectCard project={project} />
        </Link>
      ))}
    </div>
  );
}
