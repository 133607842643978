import styles from './TimelineChart.module.scss';
import classNames from 'classnames';
import {ChartTooltip} from '@shared/ui/TimelineChart/types/ChartTooltip';
import React, {useEffect, useRef, useState} from 'react';

interface Props {
  tooltip: ChartTooltip;
  containerWidth: number;
}

function TimelineTooltip({tooltip, containerWidth}: Props) {
  const ref = useRef<HTMLDivElement>();

  const [placement, setPlacement] = useState(Placement.BOTTOM_RIGHT);

  const left = tooltip.x;
  const top = tooltip.y + tooltip.height;

  useEffect(() => {
    if (!ref.current) return;

    const width = ref.current.clientWidth;

    const updatedPlacement =
      width + left > containerWidth ? Placement.BOTTOM_LEFT : Placement.BOTTOM_RIGHT;

    setPlacement(updatedPlacement);
  }, [left, containerWidth]);

  return (
    <div
      ref={ref}
      style={{left, top}}
      className={classNames(styles.tooltip, styles[`tooltip_${placement}`], {
        [styles.tooltip_active]: tooltip.show !== undefined && tooltip.show
      })}
    >
      {tooltip.title}
    </div>
  );
}

enum Placement {
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left'
}

export default React.memo(TimelineTooltip);
