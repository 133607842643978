import {Expose, Type} from 'class-transformer';
import {User} from '@shared/types/User';

export class UserModel implements User {
  @Expose({name: 'uuid'})
  public id: string;

  @Expose({name: 'created_at'})
  @Type(() => Date)
  public createdAt: Date;

  @Expose({name: 'email'})
  public email: string;

  @Expose({name: 'confirmed'})
  public confirmed: boolean;
}
