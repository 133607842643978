import {useState} from 'react';
import {Tag} from '@shared/types/Tag';
import {DATE_FORMATS} from '@shared/lib/constants';
import {SmartImage} from '@shared/types/SmartImage';
import styles from './SmartImageWithTags.module.scss';
import {useTranslation} from 'react-i18next';
import {Button} from '@shared/ui/Button';
import {useBoolean} from '@conxai/react-kit';
import {CreateMilestoneDialog} from '@entities/milestone/ui';
import useSmartImageData from '@entities/smartImage/lib/hooks/useSmartImageData';
import {formatInTimeZone} from 'date-fns-tz';
import {SmartImageTags} from '@features/smartImageTags';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {DownloadSmartImage} from '@features/downloadSmartImage';
import {SmartImageFullSize} from '@features/smartImageFullSize';

interface Props {
  image: SmartImage;
  cameraName?: string;
}

export function SmartImageWithTags({image, cameraName}: Props) {
  const {t} = useTranslation('milestone');
  const {project} = useCurrentProject();
  const timezone = useProjectTimezone();
  const [isCreateMilestoneDialogOpen, , showCreateMilestone, hideCreateMilestone] =
    useBoolean(false);

  const {data, isFetching} = useSmartImageData(image);

  const [activeTag, setActiveTag] = useState<Tag>();

  return (
    <div className={styles.container}>
      <SmartImageFullSize activeTag={activeTag} image={image} masks={data} />
      <div className={styles.dateAndMilestoneBtn}>
        <div>
          <span className={styles.time}>{t('Timestamp')}: </span>
          {formatInTimeZone(image.dateTime, timezone, DATE_FORMATS.DATE_TIME)}
        </div>
        <Button variant="contained" size="small" onClick={showCreateMilestone}>
          {t('Create A Milestone')}
        </Button>
        {isCreateMilestoneDialogOpen && (
          <CreateMilestoneDialog
            onClose={hideCreateMilestone}
            defaultValue={{date: image.dateTime}}
            disableDateChange
          />
        )}
      </div>
      {image.caption && <div>{image.caption}</div>}
      {!isFetching && (
        <div className={styles.tags}>
          <div className={styles.tagsLabel}>
            {t('Tags')}:
            <SmartImageTags
              setActiveTag={setActiveTag}
              smartImageMasks={data}
              activeTag={activeTag}
            />
          </div>
        </div>
      )}
      <div className={styles.actions}>
        <DownloadSmartImage projectName={project.name} cameraName={cameraName} image={image} />
      </div>
    </div>
  );
}
