import {SavedSearchService} from '../services/SavedSearch.service';
import {UnsavedSearch} from '@shared/types/SavedSearch';
import {makeListQueryKey} from './utils/savedSearch';
import {useMutation} from '@shared/lib/hooks';
import {useCurrentProject} from '@entities/project';
import {useTranslation} from 'react-i18next';

export function useCreateSavedSearch() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation();
  const listQueryOptions = {queryKey: makeListQueryKey(projectId)};

  const {
    mutate: createSavedSearch,
    isLoading: isSaving,
    isSuccess: saved
  } = useMutation<UnsavedSearch>({
    mutationFn: ({name, data}) =>
      SavedSearchService.createSavedSearch(projectId, name, data).catch(() => {
        throw new Error(t('Error occurred. Please try to save again.'));
      }),
    options: listQueryOptions
  });

  return {
    createSavedSearch,
    isSaving,
    saved
  };
}
