import {useTranslation} from 'react-i18next';
import {Button} from '@shared/ui/Button';
import {DATE_FORMATS} from '@shared/lib/constants';
import {
  ArrowUpwardIcon,
  KeyboardArrowUpIcon,
  KeyboardArrowDownIcon,
  ChevronLeftIcon
} from '@shared/ui/icons';
import {ButtonGroup} from '@mui/material';
import classNames from 'classnames';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';
import styles from './ProjectSearchExpandableActions.module.scss';

interface ActionsProps {
  toggleFilters: () => void;
  top: number;
  currentDate?: Date;
  onScrollToTop?: () => void;
  isMinimized: boolean;
  onBack?: () => void;
  alwaysSticky?: boolean;
}

export function ProjectSearchExpandableActions({
  toggleFilters,
  top,
  onScrollToTop,
  currentDate,
  isMinimized,
  onBack,
  alwaysSticky
}: ActionsProps) {
  const {t} = useTranslation();
  const timezone = useProjectTimezone();
  return (
    <div
      className={classNames(styles.actionsContainer, {
        [styles.actionsContainer__expanded]: !isMinimized,
        [styles.actionsContainer__absolute]: alwaysSticky
      })}
      style={{top: top}}
    >
      <div className={classNames(styles.actions, {[styles.actionsMinimized]: isMinimized})}>
        {onBack && (
          <Button startIcon={<ChevronLeftIcon />} onClick={onBack}>
            {t('Back')}
          </Button>
        )}
        <div className={styles.date}>
          {isMinimized && currentDate
            ? formatInTimeZone(currentDate, timezone, DATE_FORMATS.DATE_WIDE)
            : null}
        </div>
        <div>
          <ButtonGroup variant="text" className={styles.actionButtons}>
            {onScrollToTop && (
              <Button
                className={styles.scrollToTopBtn}
                onClick={onScrollToTop}
                startIcon={<ArrowUpwardIcon className={styles.buttonIcon} />}
              >
                {t('Scroll To Top')}
              </Button>
            )}
            <Button
              onClick={toggleFilters}
              startIcon={
                isMinimized ? (
                  <KeyboardArrowDownIcon className={styles.buttonIcon} />
                ) : (
                  <KeyboardArrowUpIcon className={styles.buttonIcon} />
                )
              }
            >
              {isMinimized ? t('Show Filters') : t('Hide Filters')}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
}
