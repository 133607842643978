import {DateRange} from '@shared/ui/Date';
import {useQuery} from '@tanstack/react-query';
import FrequencyStatisticService from '../services/FrequencyStatistic.service';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {SearchQuery} from '@entities/smartSearchFilter';

export function useGetFrequencyStatistic(
  query: SearchQuery,
  cameras: string[],
  dateRange: DateRange
) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const timezone = useProjectTimezone();
  const {data, isFetching, isFetched} = useQuery({
    queryKey: ['get-frequency-statistic', projectId, cameras, dateRange, query, timezone],
    queryFn: () =>
      FrequencyStatisticService.getStatistic(projectId, {cameras, dateRange, query}, timezone),
    placeholderData: []
  });

  return {
    data: data ?? [],
    isFetching,
    isFetched
  };
}
