import {RefObject, useEffect, useState} from 'react';
import {useIsVisible} from '@conxai/react-kit';

export function useOnVisibleImageLoad<T extends Element>(imageUrl: string, ref: RefObject<T>) {
  const [isLoading, setIsLoading] = useState(true);
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (isVisible) {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        setIsLoading(false);
      };
    }
  }, [isVisible]);

  return {
    isLoading
  };
}
