import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {MilestoneService} from '../services/Milestone.service';
import {makeListQueryKey} from './utils/milestone';
import {useCurrentProject} from '@entities/project';

export function useGetMilestones(disabled?: boolean) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {data, isLoading, isError, error} = useQuery({
    queryKey: makeListQueryKey(projectId),
    queryFn: () => MilestoneService.getMilestones(projectId),
    enabled: !disabled
  });

  const errorMessage = isError && error.message;
  useEffect(() => {
    if (errorMessage) {
      ToastService.error(errorMessage);
    }
  }, [errorMessage]);

  const sortedMilestones = data?.sort(
    (milestone1, milestone2) => milestone2.date.getTime() - milestone1.date.getTime()
  );

  return {
    milestones: sortedMilestones || [],
    isLoading
  };
}
