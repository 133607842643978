import {FrequencyStatistic} from '@shared/types/FrequencyStatistic';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {TimeRange} from '@shared/ui/TimeRange';
import {useMemo} from 'react';
import {formatInterval} from '@shared/lib/utils';
import {getColumnChartTickAmount} from '../lib/utils';
import {DATE_FORMATS} from '@shared/lib/constants';
import {getHourRangeFromTimeRange, getSumBetweenIndices} from '@shared-app/lib';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';

export interface FrequencyColumnsChartProps {
  data: FrequencyStatistic[];
  timeRange: TimeRange;
}

export function FrequencyColumnsChart({data, timeRange}: FrequencyColumnsChartProps) {
  const timezone = useProjectTimezone();
  const series = useMemo(() => {
    const [startHour, endHour] = getHourRangeFromTimeRange(timeRange);
    return [
      {
        name: '',
        data: data.map(item => getSumBetweenIndices(item.data, startHour, endHour))
      }
    ];
  }, [data, timeRange]);

  const maxHoursInSeries = useMemo(
    () => Math.max(...series.map(({data}) => Math.max(...data))),
    [series]
  );

  return (
    <div>
      <Chart
        series={series}
        options={getOptions(data, maxHoursInSeries, timezone)}
        type={'bar'}
        height={300}
      />
    </div>
  );
}

function getOptions(
  data: FrequencyStatistic[],
  yAxisMaxValue: number,
  timezone: string
): ApexOptions {
  return {
    chart: {
      id: 'frequency-statistic-columns-chart',
      selection: {
        enabled: true,
        type: 'x'
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '80%'
      }
    },
    yaxis: {
      title: {text: 'Detected time (hours)'},
      ...getColumnChartTickAmount(yAxisMaxValue),
      labels: {
        minWidth: 20,
        formatter: (hours: number) =>
          formatInterval(hours * 60 * 60 * 1000, {
            xMinutes: '{{count}}m',
            xHours: '{{count}}h'
          })
      },
      min: 0
    },
    xaxis: {
      tickPlacement: 'on',
      categories: data.map(statistic =>
        formatInTimeZone(statistic.date, timezone, DATE_FORMATS.DATE)
      )
    },
    dataLabels: {
      enabled: false
    }
  };
}
