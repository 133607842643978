import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import {FrequencyTimelineChart, useGetFrequencyStatistic} from '@entities/frequencyStatistic';
import {FrequencyColumnChartWrapper} from '@entities/frequencyStatistic/ui/FrequencyColumnChartWrapper';
import {FrequencyHeatmapChartWrapper} from '@entities/frequencyStatistic/ui/FrequencyHeatmapChartWrapper';
import ProjectChartsSkeleton from './ProjectChartsSkeleton';
import styles from './ProjectCharts.module.scss';

export function ProjectCharts() {
  const {query, cameras = [], timeRange, dateRange} = useSmartSearchFilter();
  const {data, isFetching} = useGetFrequencyStatistic(query, cameras, dateRange);

  if (isFetching) {
    return <ProjectChartsSkeleton />;
  }

  return (
    <div className={styles.container}>
      <FrequencyTimelineChart data={data} timeRange={timeRange} />
      <FrequencyColumnChartWrapper data={data} timeRange={timeRange} />
      <FrequencyHeatmapChartWrapper data={data} timeRange={timeRange} />
    </div>
  );
}
