import {SmartImage} from '@shared/types/SmartImage';
import {ProjectImagesGridSkeleton} from '@widgets/project/projectImagesGrid';
import {Typography} from '@shared/ui/Typography';
import styles from './ProjectImagesGrid.module.scss';
import {Project} from '@shared/types/Project';
import {areCameraIdsInitialized, useSmartSearchFilter} from '@entities/smartSearchFilter';
import {TimeRange} from '@shared/ui/TimeRange';
import {useGetSmartSearchImages} from '@entities/smartImage';
import {useRef} from 'react';
import {CameraList} from '@features/cameraList';
import {CameraListViewSwitch, ViewType} from '@features/cameraListViewSwitch';
import {ProjectImageList} from './ProjectImageList';
import {useIsVisible} from '@conxai/react-kit';
import {PPE_TAGS} from '@shared-app/lib/constants/ppeTags';

interface Props {
  setActiveImage: (image: SmartImage | undefined) => void;
  project: Project;
  onDateChange: (date: Date) => void;
}

export function ProjectImages({setActiveImage, project, onDateChange}: Props) {
  const {cameras: selectedCameras, timeRange} = useSmartSearchFilter();
  const haveCameraIdsInitiazed = areCameraIdsInitialized();

  if (!haveCameraIdsInitiazed || selectedCameras.length === 0) {
    return (
      <div className={styles.container}>
        <Typography variant={'h4'}>Please select at least one camera</Typography>
      </div>
    );
  }

  return (
    <CameraAndImageList
      setActiveImage={setActiveImage}
      project={project}
      timeRange={timeRange}
      onDateChange={onDateChange}
    />
  );
}

interface ProjectImagesProps {
  timeRange: TimeRange;
  project: Project;
  setActiveImage: (image: SmartImage | undefined) => void;
  onDateChange: (date: Date) => void;
}

function CameraAndImageList({setActiveImage, project, onDateChange}: ProjectImagesProps) {
  const {cameras, dateRange, timeRange, query, viewType, setViewType} = useSmartSearchFilter();
  const infiniteScrollTriggerElementRef = useRef();
  const hasScrolledToEnd = useIsVisible(infiniteScrollTriggerElementRef);
  const {images, isFetching, allImages, hasMoreImages} = useGetSmartSearchImages({
    hasScrolledToEnd,
    cameras,
    dateRange,
    timeRange,
    query
  });

  const arePPETagsSelected = query.tags.some(tag => PPE_TAGS.includes(tag.key));

  return (
    <div className={styles.cameraAndImagesContainer}>
      <CameraListViewSwitch viewType={viewType} onChange={setViewType} />
      {viewType === ViewType.CAMERAS ? (
        !isFetching && (
          <CameraList images={allImages} project={project} selectedCameraIds={cameras} />
        )
      ) : (
        <ProjectImageList
          onDateChange={onDateChange}
          project={project}
          setActiveImage={setActiveImage}
          hasMoreImages={hasMoreImages}
          images={images}
          isFetching={isFetching}
          dateRange={dateRange}
          isPPEWarningMessageApplicable={arePPETagsSelected}
        />
      )}
      <div style={{minHeight: 1}} ref={infiniteScrollTriggerElementRef}>
        {isFetching && <ProjectImagesGridSkeleton />}
      </div>
    </div>
  );
}
