import {useEffect, useMemo, useRef, useState} from 'react';
import {IconButton} from '@shared/ui/Button';
import {ChevronLeftIcon, ChevronRightIcon} from '@shared/ui/icons';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import {DATE_FORMATS} from '@shared/lib/constants';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';
import {SmartImage} from '@shared/types/SmartImage';
import {useDayPaginatedSmartImages} from '@entities/smartImage';
import {Window, WindowRef} from '@shared/ui';
import {TimelineCarouselSkeleton} from './TimelineCarouselSkeleton';
import {CarouselImageWrapper} from './CarouselImageWrapper';
import styles from './TimelineCarousel.module.scss';
import {DateRange} from '@shared/ui/Date';

interface ImageCarouselProps {
  cameraId: string;
  onSelect: (image: SmartImage) => void;
  activeImage?: SmartImage;
  activeDuration?: DateRange;
  dateRange: DateRange;
}

export function TimelineCarousel({
  cameraId,
  onSelect,
  activeImage,
  activeDuration,
  dateRange
}: ImageCarouselProps) {
  const timezone = useProjectTimezone();
  const {query, timeRange} = useSmartSearchFilter();

  const carouselWindowRef = useRef<WindowRef>();

  const [leftMostImageIndex, setLeftMostImageIndex] = useState<number>();
  const [rightMostImageIndex, setRightMostImageIndex] = useState<number>();

  const cameras = useMemo(() => [cameraId], [cameraId]);
  const {
    images,
    isFetching,
    fetchPreviousDayImages,
    fetchNextDayImages,
    fetchForDate,
    isFetchingPrev,
    isFetchingNext
  } = useDayPaginatedSmartImages({
    cameras: cameras,
    dateRange,
    timeRange,
    query
  });

  useEffect(() => {
    if (!activeDuration || images.length === 0) return;

    const latestImageIndexInSelectedTime = images.findLastIndex(image => {
      return image.dateTime >= activeDuration.startDate && image.dateTime < activeDuration.endDate;
    });

    if (latestImageIndexInSelectedTime === -1) {
      fetchForDate(activeDuration.startDate);
      return;
    }

    onSelect(images[latestImageIndexInSelectedTime]);
    carouselWindowRef.current.scrollToItem(latestImageIndexInSelectedTime, 'center');
  }, [images, onSelect, activeDuration, fetchForDate]);

  const leftDate =
    leftMostImageIndex !== undefined ? images[leftMostImageIndex]?.dateTime : undefined;
  const rightDate =
    rightMostImageIndex !== undefined ? images[rightMostImageIndex]?.dateTime : undefined;

  if (isFetching && !images.length) {
    return <TimelineCarouselSkeleton />;
  }

  return (
    <>
      <div className={styles.dates}>
        <span>
          {leftDate && formatInTimeZone(leftDate, timezone, DATE_FORMATS.DATE_ABBR_MONTH)}
        </span>
        <span>
          {rightDate && formatInTimeZone(rightDate, timezone, DATE_FORMATS.DATE_ABBR_MONTH)}
        </span>
      </div>

      <div className={styles.carousel}>
        <IconButton
          className={styles.navButton}
          color="primary"
          onClick={() => carouselWindowRef.current.scrollByItems(-3)}
          disabled={leftMostImageIndex === 0}
        >
          <ChevronLeftIcon className={styles.navIcon} />
        </IconButton>
        <Window
          className={styles.carouselWindow}
          ref={carouselWindowRef}
          data={images}
          itemSize={200}
          height={132}
          getItemKey={getImageId}
          onStartEdgeReach={fetchPreviousDayImages}
          onEndEdgeReach={fetchNextDayImages}
          isStartLoading={isFetchingPrev}
          isEndLoading={isFetchingNext}
          onStartItemChange={setLeftMostImageIndex}
          onEndItemChange={setRightMostImageIndex}
        >
          {({item, style}) => (
            <CarouselImageWrapper
              key={item.id}
              image={item}
              style={style}
              onClick={() => onSelect(item)}
              isSelected={activeImage?.id === item.id}
              timezone={timezone}
            />
          )}
        </Window>
        <IconButton
          className={styles.navButton}
          color="primary"
          onClick={() => carouselWindowRef.current.scrollByItems(3)}
          disabled={rightMostImageIndex === images.length - 1}
        >
          <ChevronRightIcon className={styles.navIcon} />
        </IconButton>
      </div>
    </>
  );
}

function getImageId(image: SmartImage) {
  return image.id;
}
