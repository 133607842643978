import {SelectDate} from './SelectDate';
import {useTranslation} from 'react-i18next';
import {useGetMilestones} from '@entities/milestone/lib/hooks';
import {useCallback} from 'react';
import {addDays, differenceInDays} from 'date-fns';
import {getDateWeekAfter} from '@shared/lib/utils';
import styles from './SelectDateRange.module.scss';
import {useCurrentProject} from '@entities/project';
import {DateRange} from '@shared/ui/Date';
import {MilestoneRange} from '@shared/types/Search';

interface Props {
  maxDateRange: number;
  onChange: (dateRange: DateRange, milestoneRange: MilestoneRange) => void;
  dateRange: DateRange;
  milestoneRange: MilestoneRange;
  allowMilestoneCreation?: boolean;
  timezone: string;
}

export default function SelectDateRange({
  onChange,
  dateRange,
  milestoneRange,
  maxDateRange,
  allowMilestoneCreation,
  timezone
}: Props) {
  const {t} = useTranslation();
  const {startDate, endDate} = dateRange;
  const {startMilestoneId, endMilestoneId} = milestoneRange;

  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {milestones, isLoading: milestonesLoading} = useGetMilestones();

  const handleStartDateChange = useCallback(
    (startDate: Date, startMilestoneId?: string) => {
      const [newEndDate, newEndMilestoneId] = isEndDateValid(startDate, endDate, maxDateRange)
        ? [endDate, endMilestoneId]
        : [getDateWeekAfter(startDate), undefined];

      onChange(
        {startDate: startDate, endDate: newEndDate},
        {startMilestoneId: startMilestoneId, endMilestoneId: newEndMilestoneId}
      );
    },
    [endDate, maxDateRange, endMilestoneId, onChange]
  );

  const handleEndDateChange = useCallback(
    (endDate: Date, milestoneId?: string) => {
      onChange({startDate, endDate: endDate}, {startMilestoneId, endMilestoneId: milestoneId});
    },
    [onChange, startDate, startMilestoneId]
  );

  return (
    <div className={styles.container}>
      <SelectDate
        dialogTitle={t('Select Start Date')}
        label={t('Start Date')}
        value={startDate}
        onChange={handleStartDateChange}
        projectId={projectId}
        milestones={milestones}
        milestonesLoading={milestonesLoading}
        selectedMilestoneId={startMilestoneId}
        maxDate={new Date()}
        allowMilestoneCreation={allowMilestoneCreation}
        timezone={timezone}
      />
      <span className={styles.separator}>–</span>
      <SelectDate
        dialogTitle={t('Select End Date')}
        label={t('End Date')}
        value={endDate}
        onChange={handleEndDateChange}
        projectId={projectId}
        milestones={milestones}
        milestonesLoading={milestonesLoading}
        selectedMilestoneId={endMilestoneId}
        minDate={startDate}
        maxDate={addDays(startDate, maxDateRange - 1)}
        allowMilestoneCreation={allowMilestoneCreation}
        timezone={timezone}
      />
    </div>
  );
}

const isEndDateValid = (startDate: Date, endDate: Date, maxDateRange: number) => {
  const daysDifference = differenceInDays(endDate, startDate);
  return daysDifference <= maxDateRange && daysDifference >= 0;
};
