import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {SearchQuery} from '@entities/smartSearchFilter';
import {SmartImage} from '@shared/types/SmartImage';
import {DateRange} from '@shared/ui/Date';
import {TimeRange} from '@shared/ui/TimeRange';
import {useQuery} from '@tanstack/react-query';
import {addDays, startOfDay, subDays} from 'date-fns';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import SmartImageService from '../services/SmartImage.service';
import {filterImagesWithinTimeRange} from '../utils/filterImagesWithinTimeRange';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import {filterImagesWithinDateRange} from '../utils/filterImagesWithinDateRange';

interface Params {
  cameras: string[];
  dateRange: DateRange;
  timeRange: TimeRange;
  query: SearchQuery;
}

export function useDayPaginatedSmartImages({cameras, dateRange, timeRange, query}: Params) {
  const timezone = useProjectTimezone();

  // onload we fetch `endDate`'s data
  // so scrolling left should fetch previous day of `endDate` for the firstime
  const leftMostFetchDateRef = useRef<Date>(dateRange.endDate);
  const rightMostFetchDateRef = useRef<Date>(dateRange.endDate);

  const [[dateToFetch, prevOrNext], setDateToFetch] = useState<[Date, string | undefined]>([
    dateRange.endDate,
    undefined
  ]);

  const setDateToFetchSafe = useCallback(
    (setter: ([Date, string]: [Date, string]) => [Date, string]) => {
      setDateToFetch(state => {
        const [date, prevOrNext] = setter(state);
        if (date < dateRange.startDate) {
          return [dateRange.startDate, prevOrNext];
        }
        if (date >= dateRange.endDate) {
          return [dateRange.endDate, prevOrNext];
        }
        return [date, prevOrNext];
      });
    },
    [dateRange]
  );

  const [imagesByDate, setImagesByDate] = useState<Record<string, SmartImage[]>>({});

  const {data, isFetching, isLoading} = useImagesOfDate({cameras, date: dateToFetch, query});

  const fetchForDate = useCallback(
    (date: Date) => {
      leftMostFetchDateRef.current = date;
      rightMostFetchDateRef.current = date;
      setDateToFetchSafe(() => [date, undefined]);
    },
    [setDateToFetchSafe]
  );

  const filteredImages = useMemo(() => {
    const dateRangeFilteredImages = filterImagesWithinDateRange(data || [], dateRange);
    return filterImagesWithinTimeRange(dateRangeFilteredImages, timeRange, timezone);
  }, [timeRange, dateRange, data, timezone]);

  const dateToFetchStr = formatInTimeZone(dateToFetch, timezone, DATE_FORMATS.DATE);
  useEffect(() => {
    setImagesByDate(completeData => ({
      ...completeData,
      [dateToFetchStr]: filteredImages
    }));
  }, [filteredImages, dateToFetchStr, timezone]);

  const flattenImages = useMemo(
    () =>
      getContinuityImages(dateRange, imagesByDate, dateToFetch, timezone)
        .flat(1)
        .sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime()),
    [dateRange, imagesByDate, dateToFetch, timezone]
  );

  const fetchPreviousDayImages = useCallback(() => {
    setDateToFetchSafe(([dateToFetch]) => {
      const previousDayDate = subDays(leftMostFetchDateRef.current || dateToFetch, 1);
      return [previousDayDate, 'prev'];
    });
  }, [setDateToFetchSafe]);

  const fetchNextDayImages = useCallback(() => {
    setDateToFetchSafe(([dateToFetch]) => {
      const previousDayDate = addDays(rightMostFetchDateRef.current || dateToFetch, 1);
      return [previousDayDate, 'next'];
    });
  }, [setDateToFetchSafe]);

  const isFetchingPrev = isFetching && prevOrNext === 'prev';
  const isFetchingNext = isFetching && prevOrNext === 'next';

  useEffect(() => {
    if (isFetchingPrev) {
      leftMostFetchDateRef.current = dateToFetch;
    }
    if (isFetchingNext) {
      rightMostFetchDateRef.current = dateToFetch;
    }
  }, [isFetchingPrev, isFetchingNext, dateToFetch]);

  return {
    images: flattenImages,
    fetchPreviousDayImages,
    fetchNextDayImages,
    isFetchingPrev: isFetching && prevOrNext === 'prev',
    isFetchingNext: isFetching && prevOrNext === 'next',
    fetchForDate,
    isFetching: isLoading
  };
}

function getContinuityImages(
  dateRange: DateRange,
  imagesByDate: Record<string, SmartImage[]>,
  activeDate: Date,
  timezone: string
): SmartImage[][] {
  let startDate = dateRange.startDate;
  const activeDateStr = formatInTimeZone(activeDate, timezone, DATE_FORMATS.DATE);
  let continueDaysData = [];
  let hasReachedActiveDate = false;

  while (startOfDay(startDate) <= startOfDay(dateRange.endDate)) {
    const startDateStr = formatInTimeZone(startDate, timezone, DATE_FORMATS.DATE);
    if (imagesByDate[startDateStr] || startDateStr === activeDateStr) {
      continueDaysData.push(imagesByDate[startDateStr] || []);
    } else {
      if (hasReachedActiveDate) {
        break;
      }
      continueDaysData = [];
    }

    if (startDateStr === activeDateStr) {
      hasReachedActiveDate = true;
    }

    startDate = addDays(startDate, 1);
  }

  if (!hasReachedActiveDate) return [];

  return continueDaysData;
}

function useImagesOfDate({
  cameras,
  date,
  query
}: {
  cameras: string[];
  date: Date;
  query: SearchQuery;
}) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const timezone = useProjectTimezone();
  const {data, isFetching, isLoading} = useQuery<SmartImage[]>({
    queryKey: ['day-smart-search-result', projectId, cameras, date, query, timezone],

    queryFn: () => {
      return cameras.length > 0
        ? SmartImageService.getImages(
            projectId,
            {
              cameras,
              dateRange: {
                startDate: date,
                endDate: date
              },
              offset: 0,
              limit: 24 * 60 * 60,
              query
            },
            timezone
          )
        : [];
    }
  });

  return {
    data,
    isFetching,
    isLoading
  };
}
