import {Expose, Transform, Type} from 'class-transformer';
import {
  Equipment,
  Finding,
  FindingsSummary,
  Labour,
  OngoingActivities,
  SiteConditions,
  Weather,
  StatusReportDetails
} from '@shared-app/types/StatusReportDetails';

class FindingModel implements Finding {
  @Expose({name: 'message'})
  message: string;

  @Expose({name: 'indicator'})
  indicator: number | null | ('check' | 'warning');

  @Expose({name: 'child_findings'})
  @Type(() => FindingModel)
  children: Finding[];
}

class FindingsSummaryModel implements FindingsSummary {
  @Expose({name: 'message'})
  message: string;

  @Expose({name: 'value'})
  value: number | null;
}

class SiteConditionsModel implements SiteConditions {
  @Expose({name: 'weather'})
  weather: Weather;

  @Expose({name: 'findings_summary'})
  @Type(() => FindingsSummaryModel)
  summary: FindingsSummary[];

  @Expose({name: 'findings'})
  @Type(() => FindingModel)
  findings: Finding[];

  @Expose({name: 'n_issues'})
  numOfIssues: number;
}

class LabourModel implements Labour {
  @Expose({name: 'findings_summary'})
  @Type(() => FindingsSummaryModel)
  summary: FindingsSummary[];

  @Expose({name: 'findings'})
  @Type(() => FindingModel)
  findings: Finding[];

  @Expose({name: 'n_issues'})
  numOfIssues: number;
}

class EquipmentModel implements Equipment {
  @Expose({name: 'findings_summary'})
  @Type(() => FindingsSummaryModel)
  summary: FindingsSummary[];

  @Expose({name: 'findings'})
  @Type(() => FindingModel)
  findings: Finding[];

  @Expose({name: 'n_issues'})
  numOfIssues: number;
}

class OngoingActivitiesModel implements OngoingActivities {
  @Expose({name: 'findings_summary'})
  @Type(() => FindingsSummaryModel)
  summary: FindingsSummary[];

  @Expose({name: 'findings'})
  @Type(() => FindingModel)
  findings: Finding[];
}

export class StatusReportDetailsModel implements StatusReportDetails {
  @Expose({name: 'summary'})
  public summary: string;

  @Expose({name: 'sources'})
  public sources: string[];

  @Expose({name: 'images'})
  public images: string[];

  @Expose({name: 'report_date'})
  @Transform(({value}) => value.end)
  public reportDate: Date;

  @Expose({name: 'site_conditions'})
  @Type(() => SiteConditionsModel)
  public siteConditions: SiteConditions;

  @Expose({name: 'labor'})
  @Type(() => LabourModel)
  public labour: Labour;

  @Expose({name: 'equipment'})
  @Type(() => EquipmentModel)
  public equipment: Equipment;

  @Expose({name: 'ongoing_activities'})
  @Type(() => OngoingActivitiesModel)
  public ongoingActivities: OngoingActivities;
}
