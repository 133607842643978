import {useEffect} from 'react';
import {
  DialogContainer,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogCloseButton
} from '@shared/ui/Dialog';
import {useTranslation} from 'react-i18next';
import {Button} from '@shared/ui/Button';
import {CircularLoader} from '@shared/ui/Loader';
import {ToastService} from '@shared/lib/services';
import {useDeleteStatusReport} from '../../hooks/useDeleteStatusReport';
import {StatusReport} from '@shared-app/types';
import styles from './DeleteStatusReportDialog.module.scss';

interface Props {
  report: StatusReport;
  onSuccess: () => void;
  onClose: () => void;
}

export function DeleteStatusReportDialog({report, onSuccess, onClose}: Props) {
  const {t} = useTranslation(['statusReport', 'translation']);
  const {deleteReport, isDeleting, isDeleted} = useDeleteStatusReport();

  const handleDelete = () => {
    deleteReport(report.id);
  };

  useEffect(() => {
    if (isDeleted) {
      ToastService.success(t('Status report was successfully deleted.'));
      onClose();
      onSuccess();
    }
  }, [isDeleted, onClose, t, onSuccess]);

  return (
    <DialogContainer onClose={onClose} fullWidth open>
      <DialogTitle className={styles.title} variant="h5">
        {t('Confirm report deletion')}
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <div>{t('Are you sure want to delete status report ?')}</div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={onClose} variant="contained">
          {t('Cancel', {ns: 'translation'})}
        </Button>
        <Button
          onClick={handleDelete}
          variant="outlined"
          color="error"
          disabled={isDeleting}
          endIcon={isDeleting ? <CircularLoader size="1em" color="inherit" /> : undefined}
        >
          {t('Delete', {ns: 'translation'})}
        </Button>
      </DialogActions>
    </DialogContainer>
  );
}
