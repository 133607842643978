import MultipleSelectCheckmarks from '@shared/ui/MultipleSelectCheckmarks';
import {useTranslation} from 'react-i18next';
import SelectOption from '@shared/types/SelectOption';
import {TFunction} from 'i18next';

interface CameraSelectProps {
  options: SelectOption[];
  selected: string[];
  onChange?: (values: string[]) => void;
  enableSelectAll?: boolean;
  readOnly?: boolean;
  className?: string;
}

export function CameraSelect({
  options,
  selected,
  onChange,
  readOnly,
  enableSelectAll,
  className
}: CameraSelectProps) {
  const {t} = useTranslation('search');

  return (
    <MultipleSelectCheckmarks
      onChange={onChange}
      options={options}
      selected={selected}
      label={t('Cameras')}
      renderValue={selected => renderValue(t, selected, options)}
      enableSelectAll={enableSelectAll}
      readOnly={readOnly}
      className={className}
    />
  );
}

function renderValue(
  t: TFunction,
  selected: string[],
  options: SelectOption[]
): string | React.ReactElement {
  if (selected.length === options.length) {
    return t('Cameras (All)');
  }
  return t('Cameras ({{selected}} from {{total}})', {
    selected: selected.length,
    total: options.length
  });
}
