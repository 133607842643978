import {FrequencyStatistic} from '@shared/types/FrequencyStatistic';
import {groupBy} from '@conxai/react-kit';
import {getSumBetweenIndices} from '@shared-app/lib';

export const getDetectionHoursPerWeek = (
  data: FrequencyStatistic[],
  startHour: number,
  endHour: number
) => {
  const groupedHoursByWeek = groupBy(data, ({weekday}) => String(weekday));

  const avgDetectionHoursPerWeek = Array.from<number>({length: 7}).map((_, weekday) => {
    const weekData = groupedHoursByWeek[weekday] || [];
    return weekData.reduce(
      (avgHours, {data}) =>
        avgHours + getSumBetweenIndices(data, startHour, endHour) / weekData.length,
      0
    );
  });

  // `0` represents Sunday, but we need to show chart from monday
  // So, rotating array to left by 1 element
  avgDetectionHoursPerWeek.push(avgDetectionHoursPerWeek.shift());

  return avgDetectionHoursPerWeek;
};
