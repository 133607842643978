import {useProjectTimezone} from '@entities/project';
import {useOriginalImageUrl} from '@entities/smartImage';
import {DATE_FORMATS} from '@shared/lib/constants';
import {ToastService} from '@shared/lib/services';
import {downloadURI, joinTruthyValues} from '@conxai/react-kit';
import {SmartImage} from '@shared/types/SmartImage';
import {Button} from '@shared/ui/Button';
import {CircularLoader} from '@shared/ui/Loader';
import {SaveIcon} from '@shared/ui/icons';
import {instanceToInstance} from 'class-transformer';
import {formatInTimeZone} from 'date-fns-tz';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface DownloadSmartImageProps {
  image: SmartImage;
  projectName: string;
  cameraName: string;
}

export function DownloadSmartImage({image, projectName, cameraName}: DownloadSmartImageProps) {
  const {t} = useTranslation('project');
  const timezone = useProjectTimezone();
  const [imageToDownload, setImageToDownload] = useState<SmartImage>();
  const {imageUrl, isLoading, isError} = useOriginalImageUrl(imageToDownload);

  const downloadName = joinTruthyValues(
    [
      projectName,
      cameraName,
      formatInTimeZone(image.dateTime, timezone, DATE_FORMATS.DATE_TIME_IN_FILE)
    ],
    '-'
  );

  useEffect(() => {
    if (imageUrl && !isLoading) {
      downloadURI(imageUrl, `${downloadName}.jpg`);
    }
  }, [imageUrl, downloadName, isLoading]);

  useEffect(() => {
    if (isError) {
      ToastService.error(t('Error occurred. Please try again.', {ns: 'translation'}));
    }
  }, [isError, t]);

  return (
    <Button
      size="small"
      startIcon={isLoading ? <CircularLoader size="1em" color="inherit" /> : <SaveIcon />}
      disabled={isLoading}
      onClick={() => setImageToDownload(instanceToInstance(image, {ignoreDecorators: true}))}
    >
      {t('Download', {ns: 'translation'})}
    </Button>
  );
}
