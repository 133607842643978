import {IconButton} from '@shared/ui/Button';
import {
  ChangeEvent,
  MouseEvent,
  MutableRefObject,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import {useVideo} from '../hooks/useVideo';
import {FullscreenExitIcon, FullscreenIcon, PauseIcon, PlayIcon} from '@shared/ui/icons';
import {intervalToDuration} from 'date-fns';
import styles from './Video.module.scss';

interface VideoProps {
  className?: string;
  onFullscreen: () => void;
}

const VideoWithRef = forwardRef(
  ({className, onFullscreen}: VideoProps, videoRef: MutableRefObject<HTMLVideoElement>) => {
    const [isFullscreened, setIsFullscreened] = useState(false);
    const {status, seek, currentTime, duration, playPause} = useVideo(videoRef);

    const handleFullscreen = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (!document.fullscreenElement) {
        onFullscreen();
      } else {
        document.exitFullscreen();
      }
    };

    const handleVideoDoubleClick = useCallback(
      (event: MouseEvent<HTMLVideoElement>) => {
        event.preventDefault();
        onFullscreen();
      },
      [onFullscreen]
    );

    const handleSeekChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        seek(Number(value));
      },
      [seek]
    );

    const handleFullscreenChange = useCallback(() => {
      setIsFullscreened(!!document.fullscreenElement);
    }, []);

    useEffect(() => {
      document.addEventListener('fullscreenchange', handleFullscreenChange);

      return () => {
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
      };
    }, [handleFullscreenChange]);

    return (
      <figure className={styles.figure}>
        <video
          onDoubleClick={handleVideoDoubleClick}
          onClick={playPause}
          className={className}
          ref={videoRef}
        ></video>
        <figcaption>
          <div className={styles.controls}>
            <div>
              <IconButton className={styles.controlButton} onClick={playPause} size="small">
                {status === 'paused' ? <PlayIcon /> : <PauseIcon />}
              </IconButton>
              <span className={styles.elapsed}>
                {formatDurationInSec(currentTime)} / {formatDurationInSec(duration)}
              </span>
            </div>

            <IconButton className={styles.controlButton} onClick={handleFullscreen} size="small">
              {isFullscreened ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </div>
          <input
            className={styles.progress}
            type="range"
            max={duration}
            min={0}
            onChange={handleSeekChange}
            value={currentTime}
            step="any"
          />
        </figcaption>
      </figure>
    );
  }
);

VideoWithRef.displayName = 'VideoWithRef';

const VideoWithRefMemoized = memo(VideoWithRef);
VideoWithRefMemoized.displayName = 'Video';

export {VideoWithRefMemoized as Video};

function formatDurationInSec(timeInSec: number) {
  const duration = intervalToDuration({start: 0, end: timeInSec * 1000});
  const formatted = [duration.hours, duration.minutes, duration.seconds]
    .filter((value, i) => i > 0 || !!value) // return hours only if present, but return minutes and secs always
    .map(num => String(num).padStart(2, '0'))
    .join(':');

  return formatted;
}
