import {groupBy, sum} from '@conxai/react-kit';
import {ComplianceInfo} from '../../types/ComplianceInfo';

export const getWeeklyComplianceHours = (data: ComplianceInfo[]) => {
  const groupedHoursByWeek = groupBy(data, ({weekday}) => String(weekday));

  const hoursPerWeek = Array.from<number>({length: 7}).map((_, weekday) => {
    const weekData = groupedHoursByWeek[weekday] || [];
    const weeklyHours = weekData.map(info => sum(info.ppe));
    return sum(weeklyHours);
  });

  // `0` represents Sunday, but we need to show chart from monday
  // So, rotating array to left by 1 element
  hoursPerWeek.push(hoursPerWeek.shift());

  return hoursPerWeek;
};
