import {sitelensAPI} from '@shared/api';
import {plainToInstance} from 'class-transformer';
import {SavedSearchModel} from '../model/SavedSearch.model';
import {Search} from '@shared/types/Search';

export class SavedSearchService {
  public static async getSavedSearches(projectId: string) {
    const savedSearches = (await sitelensAPI.get(`${projectId}/saved-search`).json()) as unknown[];

    return plainToInstance(SavedSearchModel, savedSearches);
  }

  public static async createSavedSearch(projectId: string, name: string, searchParams: Search) {
    const {dateFrom, dateTo, milestoneFrom, milestoneTo, relativeDateRange, timeFrom, timeTo} =
      searchParams.timeRange;
    const savedSearch = (await sitelensAPI
      .post(`${projectId}/saved-search`, {
        json: {
          name,
          saved_search: {
            tags_in: searchParams.includedTags,
            tags_out: searchParams.excludedTags,
            cameras: searchParams.cameras,
            query: searchParams.query,
            time_range: {
              relative_date_range: relativeDateRange,
              from: dateFrom,
              to: dateTo,
              milestone_from: milestoneFrom,
              milestone_to: milestoneTo,
              time_from: timeFrom,
              time_to: timeTo
            }
          }
        }
      })
      .json()) as unknown;

    return plainToInstance(SavedSearchModel, savedSearch);
  }

  public static async deleteSavedSearch(projectId: string, savedSearchId: string) {
    return await sitelensAPI.delete(`${projectId}/saved-search/${savedSearchId}`);
  }
}
