import Skeleton from '@shared/ui/Skeleton';
import styles from './FiltersSkeleton.module.scss';

export function FiltersSkeleton() {
  return (
    <div className={styles.filters}>
      <Skeleton className={styles.skeleton} />
      <Skeleton className={styles.skeleton} />
      <Skeleton className={styles.skeleton} />
    </div>
  );
}
