import {ImageSearchOutlined} from '@mui/icons-material';
import {useParams} from 'react-router-dom';
import styles from './SideNav.module.scss';
import {ROUTES} from '@shared/lib/constants';
import {useTranslation} from 'react-i18next';
import {BarChartIcon, PPEIcon, SettingsOutlinedIcon} from '@shared/ui/icons';
import {OccupancyIcon, StatusReportIcon} from '@shared-app/ui/icons';
import {Badge} from '@mui/material';
import {SideNavItem} from './SideNavItem';
import {FeatureFlag} from '@shared-app/types';
import {FeatureFlagKey} from '@shared-app/types/FeatureFlag';

interface SideNavProps {
  featureFlags: FeatureFlag[];
}

export default function SideNav({featureFlags}: SideNavProps) {
  const {t} = useTranslation();
  const {projectId} = useParams();

  const featureFlagMap = featureFlags.reduce(
    (acc, {key, enabled}) => ({...acc, [key]: enabled}),
    {} as Record<FeatureFlagKey, boolean>
  );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {featureFlagMap[FeatureFlagKey.TAGS] !== false && (
          <SideNavItem
            to={ROUTES.PROJECT.IMAGE.LIST.withParams({projectId})}
            icon={<ImageSearchOutlined fontSize={'large'} />}
            label={t('Search')}
          />
        )}
        {featureFlagMap[FeatureFlagKey.TAGS] !== false && (
          <SideNavItem
            to={ROUTES.PROJECT.DASHBOARD.ROOT.withParams({projectId})}
            icon={<BarChartIcon />}
            label={t(`Statistics`)}
          />
        )}

        {featureFlagMap[FeatureFlagKey.SAFETY] !== false && (
          <SideNavItem
            to={ROUTES.PROJECT.PPE_DASHBOARD.ROOT.withParams({projectId})}
            icon={<PPEIcon />}
            label={t(`Safety`)}
          />
        )}
        {featureFlagMap[FeatureFlagKey.STATUS_REPORT] !== false && (
          <Badge color="primary" badgeContent={`BETA`}>
            <SideNavItem
              to={ROUTES.PROJECT.STATUS_REPORT.LIST.withParams({projectId})}
              icon={<StatusReportIcon />}
              label={t(`Report`)}
            />
          </Badge>
        )}

        {featureFlagMap[FeatureFlagKey.OCCUPANCY] !== false && (
          <SideNavItem
            to={ROUTES.PROJECT.OCCUPANCY.ROOT.withParams({projectId})}
            icon={<OccupancyIcon className={styles.occupancyIcon} />}
            label={t('Occupancy')}
          />
        )}

        <SideNavItem
          to={ROUTES.PROJECT.SETTINGS.ROOT.withParams({projectId})}
          icon={<SettingsOutlinedIcon fontSize="large" />}
          label={t(`Settings`)}
        />
      </div>
    </div>
  );
}
