import classNames from 'classnames';
import Skeleton from '@shared/ui/Skeleton';
import styles from './SmartImageWithTagsSkeleton.module.scss';

export function SmartImageWithTagsSkeleton() {
  return (
    <div className={styles.container}>
      <Skeleton className={classNames(styles.skeleton, styles.imageSkeleton)} />
      <Skeleton className={styles.skeleton} />
    </div>
  );
}
