import {StateCreator} from 'zustand';
import {TimeRange} from '@shared/ui/TimeRange';
import {DateRange} from '@shared/ui/Date';
import {TIME_RANGE_WORKING_HOURS} from '@shared/lib/constants';
import {getDateWeekAgo, getDateToday} from '@conxai/react-kit';
import {MilestoneRange} from '@shared/types/Search';
import {PresetKey} from '@shared/ui/Date';

export interface ProjectFilterStore {
  cameras?: string[];
  dateRangePreset?: PresetKey;
  timeRange: TimeRange;
  dateRange: DateRange;
  milestoneRange: MilestoneRange;
  currentSavedSearchId?: string;
  lastSavedSearchId?: string;
}

export interface ProjectFilterStoreActions {
  setDateRangePreset: (preset: PresetKey, dateRange: DateRange) => void;
  setCameras: (cameras: string[]) => void;
  setDateRange: (dateRange: DateRange, milestoneRange: MilestoneRange) => void;
  setTimeRange: (timeRange: TimeRange) => void;
  reset: () => void;
}

export const getInitialState = (): ProjectFilterStore => ({
  cameras: undefined, // `undefined` represents init/reset, `[]` represents not having selected cameras
  timeRange: TIME_RANGE_WORKING_HOURS,
  milestoneRange: {
    startMilestoneId: undefined,
    endMilestoneId: undefined
  },
  dateRangePreset: undefined,
  dateRange: {
    startDate: getDateWeekAgo(),
    endDate: getDateToday()
  }
});

export const createProjectFilterStore: StateCreator<
  ProjectFilterStore & ProjectFilterStoreActions,
  [],
  [],
  ProjectFilterStore & ProjectFilterStoreActions
> = (set: (updater: (() => Partial<ProjectFilterStore>) | ProjectFilterStore) => void) => ({
  ...getInitialState(),
  setCameras: (cameras: string[]) => {
    return set(() => ({
      cameras
    }));
  },
  setDateRange: (dateRange: DateRange, milestoneRange: MilestoneRange) => {
    return set(() => ({
      dateRange,
      milestoneRange,
      dateRangePreset: undefined
    }));
  },
  setDateRangePreset: (dateRangePreset: PresetKey, dateRange: DateRange) => {
    return set(() => ({
      dateRangePreset,
      dateRange,
      milestoneRange: {startMilestoneId: undefined, endMilestoneId: undefined}
    }));
  },
  setTimeRange: (timeRange: TimeRange) => {
    return set(() => ({
      timeRange
    }));
  },

  reset: () => {
    set(getInitialState());
  }
});
