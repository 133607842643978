import {useTranslation} from 'react-i18next';
import styles from './EventList.module.scss';
import {Event, Gate} from '@entities/occupancy';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import {useProjectTimezone} from '@entities/project';
import {useMemo} from 'react';
import {groupMapBy} from '@conxai/react-kit';
import {EntryIcon, ExitIcon} from '@shared/ui/icons';
import classNames from 'classnames';

interface EventListProps {
  events: Event[];
  gates: Gate[];
  setEventToPlay: (e: Event) => void;
  eventBeingPlayed?: Event;
}

export function EventListTable({events, gates, setEventToPlay, eventBeingPlayed}: EventListProps) {
  const {t} = useTranslation('occupancy');
  const timezone = useProjectTimezone();

  const gatesById = useMemo(
    () =>
      groupMapBy(
        gates,
        gate => gate.id,
        gates => gates[0]
      ),
    [gates]
  );

  return (
    <table>
      <thead>
        <tr>
          <th>{t('Timestamp')}</th>
          <th>{t('Gate')}</th>
        </tr>
      </thead>
      <tbody>
        {events.map(event => {
          const isRowSelected =
            eventBeingPlayed && getEventKey(event) === getEventKey(eventBeingPlayed);
          return (
            <tr
              key={getEventKey(event)}
              role="button"
              onClick={() => setEventToPlay(event)}
              className={classNames({
                [styles.selected]: isRowSelected
              })}
            >
              <td className={styles.time}>
                {event.type === 1 ? <EntryIcon className={styles.entryIcon} /> : <ExitIcon />}
                {formatInTimeZone(event.timestamp, timezone, DATE_FORMATS.TIME_24_WIDE)}
              </td>
              <td>{gatesById[event.gateId]?.name || event.gateId}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function getEventKey(event: Event) {
  return event.timestamp.toISOString() + event.gateId + event.type + event.count;
}
