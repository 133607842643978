import {Finding} from '@shared-app/types/StatusReportDetails';

function isImportantFinding({indicator}: Finding) {
  return indicator !== 'check';
}

export function getImportantFindings(findings: Finding[]) {
  return findings
    .filter(isImportantFinding)
    .map(finding => ({...finding, children: finding.children.filter(isImportantFinding)}));
}
