import {FrequencyStatistic} from '@shared/types/FrequencyStatistic';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {useMemo} from 'react';
import {TimeRange} from '@shared/ui/TimeRange';
import {getDetectionHoursPerWeek} from '../lib/utils/getDetectionHoursPerWeek';
import {padAround} from '@conxai/react-kit';
import {WEEK_DAYS} from '@shared/lib/constants';
import {formatHours} from '../lib/utils/formatHours';
import {getHourRangeFromTimeRange, formatTime} from '@shared-app/lib';

interface Props {
  data: FrequencyStatistic[];
  timeRange: TimeRange;
}

export function WeekdayFrequencyHeatmapChart({data, timeRange}: Props) {
  const series: ApexAxisChartSeries = useMemo(() => {
    const [startHour, endHour] = getHourRangeFromTimeRange(timeRange);

    return Array.from({length: endHour - startHour}).map((_, hourIndex) => {
      const hour = startHour + hourIndex;
      const detectionHoursPerWeek = getDetectionHoursPerWeek(data, hour, hour + 1);

      return {
        name: formatTime(hour),
        data: padAround(detectionHoursPerWeek, REQUIRED_SERIES_LENGTH, 0)
      };
    });
  }, [data, timeRange]);

  return (
    <div>
      <Chart
        series={series}
        options={CHART_OPTIONS}
        type={'heatmap'}
        height={series.length * 30 + 150}
      />
    </div>
  );
}

// we will add empty values to data so that chart can align in center with smaller boxes
// Just showing 7 boxes in full-width, makes each box very bigger
const REQUIRED_SERIES_LENGTH = 15;

const CHART_OPTIONS: ApexOptions = {
  chart: {
    id: 'weekday-frequency-statistic-heatmap-chart',
    toolbar: {
      show: false
    }
  },
  yaxis: {
    title: {text: 'Detected time (hours)'},
    min: 0,
    max: 1,
    reversed: true,
    labels: {
      formatter: (val, opts) => {
        if (opts?.series) {
          return formatHours(val);
        }
        return String(val);
      }
    }
  },
  colors: ['#008FFB'],
  xaxis: {
    categories: padAround(WEEK_DAYS, REQUIRED_SERIES_LENGTH, '')
  },
  dataLabels: {
    enabled: false
  }
};
