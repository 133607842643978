import styles from './TaxonomyPage.module.scss';
import {Typography} from '@shared/ui/Typography';
import {TagChipWithPopover, tags, useGetTagsGroupedByType} from '@entities/tag';
import {SyntheticEvent, useState} from 'react';
import {Tab, Tabs} from '@shared/ui/Tabs';
import {useTranslationTags} from '@shared/lib/hooks';
import {Tag} from '@shared/types/Tag';
import sortArrayOfObjectsByProperty from '@shared/lib/utils/sortArrayOfObjectsByProperty';

export function TaxonomyPage() {
  const {tagsGroupedByType} = useGetTagsGroupedByType(tags);
  const [value, setValue] = useState(0);
  const {t} = useTranslationTags();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(parseInt(newValue));
  };

  const curTagsGroup = tagsGroupedByType[value];

  const tagsByLetters = curTagsGroup ? groupTagsByFirstLetter(curTagsGroup.tags) : [];

  return (
    <div className={styles.container}>
      <Typography variant={'h3'}>Taxonomy Manager</Typography>

      <Tabs className={styles.tabs} value={value} onChange={handleChange}>
        {tagsGroupedByType.map((tagGroup, index) => {
          return (
            <Tab
              className={styles.tab}
              key={`taxonomy-tab-${index}`}
              label={t(`type.${tagGroup.type}`)}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>

      <div className={styles.content}>
        {tagsByLetters.map(tagsByLetter => {
          return (
            <div className={styles.content__item} key={`taxonomy-letter-${tagsByLetter.letter}`}>
              <div className={styles.content__item_letter}>{tagsByLetter.letter.toUpperCase()}</div>
              <div className={styles.content__item_tags}>
                {tagsByLetter.tags.map(tag => (
                  <TagChipWithPopover key={`taxonomy-${tag.key}`} tag={tag} />
                ))}
              </div>
            </div>
          );
        })}
        <div></div>
      </div>
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

interface TagsByLetterGroup {
  [key: string]: TagsByLetter;
}

interface TagsByLetter {
  letter: string;
  tags: Tag[];
}

function groupTagsByFirstLetter(tags: Tag[]): TagsByLetter[] {
  if (tags.length === 0) {
    return [];
  }

  const tagsGroupedByLetter = tags.reduce((acc: TagsByLetterGroup, tag: Tag) => {
    const firstLetter: string = tag.key.charAt(0);

    if (!acc[firstLetter]) {
      acc[firstLetter] = {
        letter: firstLetter,
        tags: []
      };
    }

    acc[firstLetter].tags.push(tag);

    return acc;
  }, {});

  const tagsGroupedByLetterArray = Object.values(tagsGroupedByLetter);

  return sortArrayOfObjectsByProperty(tagsGroupedByLetterArray, 'letter');
}
