import {TagEnum} from '@shared/types/TagEnum';
import {ComplianceInfo} from '../../types/ComplianceInfo';
import {
  convertAbsoluteStatisticsToPercentage,
  getAttributeStatistics
} from './getAttributeStatistics';
import {getObjectEntries, percentage, sum} from '@conxai/react-kit';
import {AttributeStatistics} from '../../types/AttributeStatistics';

export function getSummary(complianceInfo: ComplianceInfo[]): {
  statistics: AttributeStatistics;
  statisticsInPercentage: AttributeStatistics;
  mostCommonIssueType: keyof AttributeStatistics;
  mostCommonIssueNonComplPercentage: number;
  total: number;
} {
  const statistics = getAttributeStatistics(complianceInfo);
  const statisticsInPercentage = convertAbsoluteStatisticsToPercentage(statistics);

  const totalNonComplianceHours =
    statistics[TagEnum.person_no_helmet] +
    statistics[TagEnum.person_no_vest] +
    statistics[TagEnum.person_no_helmet_no_vest];
  const [mostCommonIssueType, mostCommonIssuePercentage] = getObjectEntries({
    [TagEnum.person_no_helmet]: statistics.person_no_helmet,
    [TagEnum.person_no_vest]: statistics.person_no_vest,
    [TagEnum.person_no_helmet_no_vest]: statistics.person_no_helmet_no_vest
  }).sort(([, a], [, b]) => b - a)[0];
  const mostCommonIssueNonComplPercentage = percentage(
    mostCommonIssuePercentage,
    totalNonComplianceHours
  );

  return {
    total: Number(sum(Object.values(statistics)).toFixed(2)),
    statistics,
    statisticsInPercentage,
    mostCommonIssueNonComplPercentage,
    mostCommonIssueType
  };
}
