import {useState} from 'react';
import {ColumnDef, Table} from '@shared/ui/Table';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {DeleteIcon} from '@shared/ui/icons';
import {IconButton} from '@shared/ui/Button';
import styles from './UserSettings.module.scss';
import {Typography} from '@shared/ui/Typography';
import {useUsers} from '@entities/user/lib/hooks';
import {User} from '@shared/types/User';
import {DeleteUserDialog, InviteUserButton} from '@entities/user/ui';
import {UserListSkeleton} from './UserListSkeleton';
import classNames from 'classnames';

export function UserSettingsPage() {
  const {t} = useTranslation('user');

  const {users, isLoading} = useUsers();
  const [userBeingRemoved, setUserBeingRemoved] = useState<User>();

  const handleUserRemove = (user: User) => {
    setUserBeingRemoved(user);
  };

  const handleDeleteUserDialogClose = () => setUserBeingRemoved(undefined);

  const columns = makeColumns(t, handleUserRemove);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h2">{t('All Users')}</Typography>
        <InviteUserButton />
      </div>
      {isLoading && <UserListSkeleton />}
      {!isLoading && users.length === 0 && <div>{t('No users were found.')}</div>}
      {users.length > 0 && (
        <Table
          rows={users}
          loading={isLoading}
          columns={columns}
          disableRowSelectionOnClick
          hideFooter
          hideFooterSelectedRowCount
          hideFooterPagination
        />
      )}
      {userBeingRemoved && (
        <DeleteUserDialog
          user={userBeingRemoved}
          onSuccess={handleDeleteUserDialogClose}
          onClose={handleDeleteUserDialogClose}
        />
      )}
    </div>
  );
}

function makeColumns(t: TFunction, onRemove: (user: User) => void): ColumnDef[] {
  return [
    {
      field: 'email',
      headerName: t('Email'),
      flex: 8
    },
    {
      field: 'confirmed',
      headerName: t('Status'),
      headerClassName: styles.statusHeader,
      flex: 3,
      renderCell: ({row}: {row: User}) => <ConfirmationStatus isConfirmed={row.confirmed} />
    },
    {
      field: '',
      renderCell: ({row}) => <Actions onRemove={() => onRemove(row)} />,
      flex: 1,
      align: 'center',
      sortable: false
    }
  ];
}

interface ConfirmationStatusProps {
  isConfirmed: boolean;
}

function ConfirmationStatus({isConfirmed}: ConfirmationStatusProps) {
  const {t} = useTranslation('user');
  return (
    <div
      className={classNames(styles.confirmationStatus, {
        [styles.confirmationStatus__pending]: !isConfirmed
      })}
    >
      {isConfirmed ? t('Confirmed') : t('Pending')}
    </div>
  );
}

function Actions({onRemove}: {onRemove: () => void}) {
  return (
    <IconButton onClick={onRemove} color="primary">
      <DeleteIcon className={styles.deleteIcon} />
    </IconButton>
  );
}
