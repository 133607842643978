import {useLocation} from 'react-router-dom';
import {ROUTES} from '@shared/lib/constants';

export function useIsSmartSearchEnabled() {
  const {pathname} = useLocation();
  const isSmartSearchEnabled =
    ROUTES.PROJECT.DASHBOARD.ROOT.isMatch(pathname) ||
    ROUTES.PROJECT.IMAGE.LIST.isMatch(pathname) ||
    ROUTES.PROJECT.CAMERAS.TIMELINE.isMatch(pathname);

  return {isSmartSearchEnabled};
}
