import styles from './Header.module.scss';
import {HeaderLogo, Profile} from '@widgets/header';

export default function Header() {
  return (
    <div className={styles.container}>
      <HeaderLogo />
      <Profile />
    </div>
  );
}
