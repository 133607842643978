import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  isBefore,
  isSameDay,
  isSameMonth,
  isValid,
  isWithinInterval,
  max,
  min,
  parseISO,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import {DateRange, PresetKey} from './types';
import {TFunction} from 'i18next';

export const identity = <T>(x: T) => x;

// Date
export const getDaysInMonth = (date: Date, locale?: Locale) => {
  const startWeek = startOfWeek(startOfMonth(date), {locale});
  const endWeek = endOfWeek(endOfMonth(date), {locale});
  const days = [];
  for (let curr = startWeek; isBefore(curr, endWeek); ) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};

export const isStartOfRange = ({startDate}: DateRange, day: Date) =>
  (startDate && isSameDay(day, startDate)) as boolean;

export const isEndOfRange = ({endDate}: DateRange, day: Date) =>
  (endDate && isSameDay(day, endDate)) as boolean;

export const inDateRange = ({startDate, endDate}: DateRange, day: Date) =>
  (startDate &&
    endDate &&
    (isWithinInterval(day, {start: startDate, end: endDate}) ||
      isSameDay(day, startDate) ||
      isSameDay(day, endDate))) as boolean;

export const isRangeSameDay = ({startDate, endDate}: DateRange) => {
  if (startDate && endDate) {
    return isSameDay(startDate, endDate);
  }
  return false;
};

type Falsy = false | null | undefined | 0 | '';

export const parseOptionalDate = (date: Date | string | Falsy, defaultValue: Date) => {
  if (date) {
    const parsed = date instanceof Date ? date : parseISO(date);
    if (isValid(parsed)) return parsed;
  }
  return defaultValue;
};

export const getValidatedMonths = (range: DateRange, minDate: Date, maxDate: Date) => {
  const {startDate, endDate} = range;
  if (startDate && endDate) {
    const newStart = max([startDate, minDate]);
    const newEnd = min([endDate, maxDate]);

    return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd];
  }
  return [startDate, endDate];
};

export const getPresetLabels = (t: TFunction) => ({
  [PresetKey.TODAY]: t('Today'),
  [PresetKey.YESTERDAY]: t('Yesterday'),
  [PresetKey.THIS_WEEK]: t('Current Week'),
  [PresetKey.LAST_WEEK]: t('Last Week'),
  [PresetKey.LAST_TWO_WEEKS]: t('Last Two Weeks'),
  [PresetKey.LAST_SEVEN_DAYS]: t('Last 7 Days'),
  [PresetKey.THIS_MONTH]: t('Current Month'),
  [PresetKey.LAST_MONTH]: t('Last Month'),
  [PresetKey.THIS_YEAR]: t('This Year'),
  [PresetKey.LAST_YEAR]: t('Last Year')
});
