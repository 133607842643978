export function getColumnChartTickAmount(yAxisMaxValue: number) {
  // y-axis value and number of ticks to show on y-axis
  const maxAndTicks = [
    [2, 4],
    [4, 4],
    [6, 6],
    [8, 4],
    [10, 5],
    [12, 6],
    [16, 4],
    [20, 5],
    [24, 6]
  ].find(([max]) => yAxisMaxValue <= max);

  if (!maxAndTicks) return undefined;

  return {max: maxAndTicks[0], tickAmount: maxAndTicks[1]};
}
