import {useTranslation} from 'react-i18next';
import {UserService} from '../services/User.service';
import {makeListQueryKey} from './utils/user';
import {useMutation} from '@shared/lib/hooks';
import {useCurrentProject} from '@entities/project';

export function useInviteUser() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation(['user']);
  const listQueryOptions = {queryKey: makeListQueryKey(projectId)};

  const {
    mutate: inviteUser,
    isLoading: isInviting,
    isSuccess: isInvited
  } = useMutation<string>({
    mutationFn: email =>
      UserService.inviteUser(projectId, email).catch(() => {
        throw new Error(t('Error occurred. Please try to send new invitation.'));
      }),
    options: listQueryOptions
  });

  return {
    inviteUser,
    isInviting,
    isInvited
  };
}
