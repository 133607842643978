import {reportAPI} from '@shared/api';
import {plainToInstance} from 'class-transformer';
import {StatusReportModel} from '../model/StatusReport.model';
import {StatusReportDetailsModel} from '../model/StatusReportDetails.model';
import {subHours} from 'date-fns';

export class StatusReportService {
  public static async getReports(projectId: string) {
    const reports = (await reportAPI.get(`projects/${projectId}/reports`).json()) as unknown[];

    return plainToInstance(StatusReportModel, reports);
  }

  public static async getReportDetails(projectId: string, reportId: string) {
    const report = (await reportAPI.get(`projects/${projectId}/reports/${reportId}`).json()) as {
      report: unknown;
    };
    return plainToInstance(StatusReportDetailsModel, report.report);
  }

  public static async generateReport(
    projectId: string,
    {cameraId, date, name}: {cameraId: string; date: Date; name: string}
  ) {
    const report = (await reportAPI
      .post(`projects/${projectId}/reports`, {
        json: {cameras: [cameraId], time_range: [{from: subHours(date, 1), to: date}], name}
      })
      .json()) as unknown;

    return plainToInstance(StatusReportModel, report);
  }

  public static async deleteStatusReport(projectId: string, reportId: string) {
    return await reportAPI.delete(`projects/${projectId}/reports/${reportId}`).json();
  }
}
