import {intervalToDuration, formatDuration} from 'date-fns';

type FormatDistanceTokenAlias =
  | 'lessThanXSeconds'
  | 'xSeconds'
  | 'halfAMinute'
  | 'lessThanXMinutes'
  | 'xMinutes'
  | 'aboutXHours'
  | 'xHours'
  | 'xDays'
  | 'aboutXWeeks'
  | 'xWeeks'
  | 'aboutXMonths'
  | 'xMonths'
  | 'aboutXYears'
  | 'xYears'
  | 'overXYears'
  | 'almostXYears';

export const formatInterval = (
  milliseconds: number,
  formatDistanceLocale: Partial<Record<FormatDistanceTokenAlias, string>>
) => {
  const duration = intervalToDuration({start: 0, end: milliseconds});

  return formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    locale: {
      formatDistance: (token: FormatDistanceTokenAlias, count) =>
        formatDistanceLocale[token]?.replace('{{count}}', count) || ''
    }
  }).trim();
};
