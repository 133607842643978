import {FormLabel as MuiFormLabel, FormLabelProps} from '@mui/material';
import classNames from 'classnames';
import styles from './FormControl.module.scss';

interface Props extends FormLabelProps {
  children: React.ReactNode;
}

export function FormLabel({className, children, ...restProps}: Props) {
  return (
    <MuiFormLabel className={classNames(styles.root, className)} {...restProps}>
      {children}
    </MuiFormLabel>
  );
}
