import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DialogContainer, DialogContent} from '@shared/ui/Dialog';
import {DateCalendar} from '@shared/ui/Date';
import {useBoolean} from '@conxai/react-kit';
import styles from './GenerateStatusReportForm.module.scss';
import {Formik} from 'formik';
import {TFunction} from 'i18next';
import {GenerateStatusReportFormValues} from '../../types/GenerateStatusFormValues';
import {Camera} from '@shared/types/Camera';
import {Select} from '@shared/ui';
import {MenuItem} from '@shared/ui/Menu';
import {addHours} from 'date-fns';
import {setZonedTime} from '@shared/lib/utils';
import {CalendarTodayIcon} from '@shared/ui/icons';
import {DATE_FORMATS} from '@shared/lib/constants';
import {TextField} from '@shared/ui/TextField';
import {FormControl} from '@shared/ui/Form';
import {FormHelperText, InputLabel} from '@mui/material';
import {useProjectTimezone} from '@entities/project';
import {formatInTimeZone} from 'date-fns-tz';
import {HourSelect} from '@shared/ui/HourSelect';

interface Props {
  onSubmit: (p: GenerateStatusReportFormValues) => void;
  disableDateChange?: boolean;
  cameras: Camera[];
}

export function GenerateStatusReportForm({onSubmit, disableDateChange, cameras}: Props) {
  const {t} = useTranslation(['statusReport']);

  const timezone = useProjectTimezone();
  const [isPickerOpen, , showPicker, hidePicker] = useBoolean(false);

  const validate = useMemo(() => getValidator(t), [t]);

  const now = new Date();

  const handleHourChange =
    (setFieldValue: (k: keyof GenerateStatusReportFormValues, v: unknown) => void) =>
    (date: Date) => {
      setFieldValue('date', date);
    };

  const nextHourDate = addHours(now, 1);
  const initialSelectedDate = setZonedTime(nextHourDate, timezone, undefined, 0, 0, 0);

  return (
    <Formik
      initialValues={{
        cameraId: '',
        date: initialSelectedDate
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({handleChange, handleBlur, handleSubmit, errors, values, setFieldValue}) => (
        <>
          <form className={styles.form} id={FORM_ID} onSubmit={handleSubmit} noValidate>
            <FormControl
              className={styles.cameraControl}
              variant="outlined"
              placeholder={t('Select Camera')}
              fullWidth
            >
              <InputLabel className={styles.cameraLabel}>{t('Select Camera')}</InputLabel>
              <Select
                className={styles.cameraSelect}
                placeholder={t('Select Camera')}
                label={t('Select Camera')}
                onChange={handleChange}
                onBlur={handleBlur}
                name="cameraId"
                value={values.cameraId}
                required
                fullWidth
              >
                {cameras.map(camera => (
                  <MenuItem key={camera.id} value={camera.id}>
                    {camera.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!(errors as FormErrors).cameraId}>
                {(errors as FormErrors).cameraId || ' '}
              </FormHelperText>
            </FormControl>
            <div className={styles.datetime}>
              <TextField
                onClick={disableDateChange ? undefined : showPicker}
                autoComplete="off"
                name="date"
                label={t('Date')}
                placeholder={t('Select Date')}
                InputProps={{
                  disabled: disableDateChange,
                  className: styles.dateInput,
                  endAdornment: (
                    <div className={styles.endAdornment}>
                      <CalendarTodayIcon className={styles.endIcon} />
                    </div>
                  )
                }}
                value={
                  values.date ? formatInTimeZone(values.date, timezone, DATE_FORMATS.DATE) : ''
                }
                InputLabelProps={{className: styles.label}}
                error={!!errors.date}
                helperText={(errors as FormErrors).date || ' '}
                disabled={disableDateChange}
              />
              <FormControl variant="outlined">
                <HourSelect
                  className={styles.hourSelect}
                  onChange={handleHourChange(setFieldValue)}
                  onBlur={handleBlur}
                  value={values.date}
                  timezone={timezone}
                  checkOptionDisabled={date => date > initialSelectedDate}
                  required
                />
              </FormControl>
            </div>
          </form>
          {isPickerOpen && (
            <DialogContainer open className={styles.dialog} onClose={hidePicker}>
              <DialogContent className={styles.content}>
                <DateCalendar
                  className={styles.calendar}
                  onChange={date => {
                    // `date > initialSelectedDate` can happen if today's disabled hour selected from yesterday and coming back to today
                    if (date > initialSelectedDate) {
                      date = initialSelectedDate;
                    }
                    setFieldValue('date', date);
                    hidePicker();
                  }}
                  timezone={timezone}
                  maxDate={initialSelectedDate}
                  value={values.date}
                />
              </DialogContent>
            </DialogContainer>
          )}
        </>
      )}
    </Formik>
  );
}

const FORM_ID = 'generate-status-report-form';

GenerateStatusReportForm.FORM_ID = FORM_ID;

interface FormErrors extends Partial<Record<keyof GenerateStatusReportFormValues, string>> {}

function getValidator(t: TFunction) {
  return function (values: Partial<GenerateStatusReportFormValues>) {
    const errors: FormErrors = {};

    if (!values.cameraId) {
      errors.cameraId = t('Please select camera');
    }

    if (!values.date) {
      errors.date = t('Please select date');
    }

    return errors;
  };
}
