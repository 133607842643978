import {useEffect} from 'react';
import {Project} from '@shared/types/Project';
import {useSmartSearchFilter, areCameraIdsInitialized} from '@entities/smartSearchFilter';
import styles from './SelectCamera.module.scss';
import {CameraSelect} from '@entities/project';
import classNames from 'classnames';

interface SelectCameraProps {
  project: Project;
  className?: string;
}

export default function SelectCamera({project, className}: SelectCameraProps) {
  const {cameras = [], setCameras} = useSmartSearchFilter();
  const haveCameraIdsInitiazed = areCameraIdsInitialized();

  const cameraIds = project.getCameraIds();

  useEffect(() => {
    if (!haveCameraIdsInitiazed) {
      setCameras(cameraIds);
    }
  }, [haveCameraIdsInitiazed, cameraIds]);

  return (
    <CameraSelect
      options={project.getCameraOptions()}
      selected={cameras}
      onChange={setCameras}
      enableSelectAll={true}
      className={classNames(styles.select, className)}
    />
  );
}
