import {useCurrentProject} from '@entities/project';
import {SavedSearchService} from '../services/SavedSearch.service';
import {makeListQueryKey} from './utils/savedSearch';
import {useMutation} from '@shared/lib/hooks';
import {useTranslation} from 'react-i18next';

export function useDeleteSavedSearch() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('search');
  const listQueryOptions = {queryKey: makeListQueryKey(projectId)};

  const {
    mutate: deleteSavedSearch,
    isLoading: isDeleting,
    isSuccess: isDeleted
  } = useMutation({
    mutationFn: (savedSearchId: string) =>
      SavedSearchService.deleteSavedSearch(projectId, savedSearchId).catch(() => {
        throw new Error(t('Error occurred. Please try to delete again.'));
      }),
    options: listQueryOptions
  });

  return {
    deleteSavedSearch,
    isDeleting,
    isDeleted
  };
}
