import {CSSProperties, useRef} from 'react';
import {useOnVisibleThumbnailUrl, usePlaceholderThumbnailUrl} from '@entities/smartImage';
import {SmartImage} from '@shared/types/SmartImage';
import classNames from 'classnames';
import styles from './CarouselImage.module.scss';
import {Image} from '@shared/ui';

export interface CarouselImageProps {
  image: SmartImage;
  onClick: () => void;
  style?: CSSProperties;
  isSelected?: boolean;
}

export function CarouselImage({image, onClick, isSelected}: CarouselImageProps) {
  const ref = useRef<HTMLDivElement>();

  const {placeholderThumbnailUrl} = usePlaceholderThumbnailUrl(image);
  const {thumbnailUrl, isLoading} = useOnVisibleThumbnailUrl(ref, image);

  return (
    <div
      className={classNames(styles.imageCard, {[styles.imageCard__selected]: isSelected})}
      ref={ref}
      onClick={onClick}
    >
      <Image
        src={isLoading ? placeholderThumbnailUrl : thumbnailUrl}
        alt={image.caption}
        isLoading={isLoading}
      />
    </div>
  );
}
