import {DataGrid, DataGridProps} from '@mui/x-data-grid';
import styles from './Table.module.scss';

export function Table(props: DataGridProps) {
  return (
    <DataGrid
      className={styles.table}
      classes={{columnHeader: styles.columnHeader, cell: styles.cell, sortIcon: styles.sortIcon}}
      disableColumnMenu
      showCellVerticalBorder
      {...props}
    />
  );
}
