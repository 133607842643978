import classNames from 'classnames';
import Skeleton from '@shared/ui/Skeleton';
import styles from './TimelineCarouselSkeleton.module.scss';

export function TimelineCarouselSkeleton() {
  return (
    <>
      <div className={styles.dates}>
        <Skeleton className={styles.skeleton} height={'3rem'} width={'8rem'} animation={'wave'} />
        <Skeleton className={styles.skeleton} height={'3rem'} width={'8rem'} animation={'wave'} />
      </div>
      <div className={styles.list}>
        {Array.from({length: 10}).map((_, index) => (
          <div key={index} className={classNames(styles.item)}>
            <Skeleton className={styles.skeleton} height={'11.2rem'} animation={'wave'} />
          </div>
        ))}
      </div>
    </>
  );
}
