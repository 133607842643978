import {useCallback} from 'react';
import {useUpdateProject} from './useUpdateProject';
import {Project} from '@shared/types/Project';
import {fileToBase64} from '@conxai/react-kit';
import {ToastService} from '@shared/lib/services';
import {useTranslation} from 'react-i18next';
import {instanceToInstance} from 'class-transformer';

export function useUploadCoverImage(project: Project) {
  const {t} = useTranslation('project');

  const {update, isUpdated, isUpdating} = useUpdateProject(updateCacheWithNewCoverImage);

  const uploadCoverImage = useCallback(
    (file: File) => {
      fileToBase64(file)
        .then(contentBase64 => {
          const updatedProject = instanceToInstance(project, {ignoreDecorators: true});
          updatedProject.coverImage = contentBase64;
          update(updatedProject);
        })
        .catch(err => {
          console.error(err);
          ToastService.error(t('Error occurred. Please try to upload an image again.'));
        });
    },
    [update, t, project]
  );

  return {uploadCoverImage, isUploaded: isUpdated, isUploading: isUpdating};
}

function updateCacheWithNewCoverImage(prevProject: Project, nextProject: Project) {
  const updatedProject = instanceToInstance(prevProject, {ignoreDecorators: true});
  updatedProject.coverImage = nextProject.coverImage;
  return updatedProject;
}
