import {useQuery} from '@tanstack/react-query';

import SmartImageService from '../services/SmartImage.service';

export default function useSmartImage(imageId: string) {
  const {data, isFetching, error} = useQuery({
    queryKey: ['get-image', imageId],
    queryFn: () => SmartImageService.getImage(imageId)
  });

  return {
    data: data,
    isFetching,
    error
  };
}
