import {useCallback, useEffect, useState} from 'react';

export function useActiveTextCues(video: HTMLVideoElement) {
  const [activeCueText, setActiveCueText] = useState<string>();

  const handleCueChange = useCallback((event: Event) => {
    const videoTrack = event.target as TextTrack;
    const activeCues = [...videoTrack.activeCues];
    const texts = activeCues.map((cue: VTTCue) => cue.text);
    setActiveCueText(texts[0]);
  }, []);

  useEffect(() => {
    if (!video) return;

    const videoTrack = video.textTracks[0];
    videoTrack.mode = 'hidden';
    videoTrack.addEventListener('cuechange', handleCueChange);

    return () => {
      videoTrack.removeEventListener('cuechange', handleCueChange);
      setActiveCueText(undefined);
    };
  }, [handleCueChange, video]);

  return activeCueText;
}
