import {useMemo} from 'react';
import {TagsGroupedByType} from '../types';
import {Tag} from '@shared/types/Tag';

export function useGetTagsGroupedByType(tags: Tag[]) {
  const tagsGroupedByType = useMemo(() => groupTagsByType(tags), [tags]);
  return {
    tagsGroupedByType
  };
}

function groupTagsByType(tags: Tag[]) {
  const tagsGroupedObj = tags.reduce((acc: TagsGroupedByType, tag) => {
    if (!acc[tag.type]) {
      acc[tag.type] = {
        type: tag.type,
        tags: []
      };
    }

    acc[tag.type].tags.push(tag);

    return acc;
  }, {});

  return Object.values(tagsGroupedObj);
}
