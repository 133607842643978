import {useTranslation} from 'react-i18next';
import {Event} from '@entities/occupancy';
import {useMemo} from 'react';
import {useProjectTimezone} from '@entities/project';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import {Circle} from '@mui/icons-material';
import styles from './ShowLatestCalibrationPoint.module.scss';

interface ComponentProps {
  events: Event[];
}

export function ShowLatestCalibrationPoint({events}: ComponentProps) {
  const {t} = useTranslation('occupancy');
  const timezone = useProjectTimezone();

  const latestCalibrationPoint = useMemo(() => getLatestCalibrationPoint(events), [events]);

  return (
    <div className={styles.container}>
      {!latestCalibrationPoint ? (
        t(`No information about last calibration point found.`)
      ) : (
        <>
          <Circle fontSize={`small`} /> {t(`Last calibration`)}:{' '}
          {formatInTimeZone(latestCalibrationPoint.timestamp, timezone, DATE_FORMATS.DATE_TIME_24)}
        </>
      )}
    </div>
  );

  function getLatestCalibrationPoint(events: Event[]) {
    return events.findLast(event => event.type === 0);
  }
}
