import {TimeRange, TimeRangePicker} from '@shared/ui/TimeRange';

interface Props {
  value: TimeRange;
  onChange: (value: TimeRange) => void;
}

export function SelectTimeFrame({value, onChange}: Props) {
  return <TimeRangePicker value={value} step={60} onChange={onChange} />;
}
