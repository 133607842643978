import {useEffect} from 'react';
import {
  DialogContainer,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogCloseButton
} from '@shared/ui/Dialog';
import {useTranslation} from 'react-i18next';
import {Button} from '@shared/ui/Button';
import {CircularLoader} from '@shared/ui/Loader';
import {ToastService} from '@shared/lib/services';
import {useCreateMilestone} from '@entities/milestone/lib/hooks';
import styles from './CreateMilestoneDialog.module.scss';
import {MilestoneForm} from '../MilestoneForm';
import {Milestone, UnsavedMilestone} from '@shared/types/Milestone';

interface Props {
  defaultValue: Partial<UnsavedMilestone>;
  onSuccess?: (id: string) => void;
  onClose: () => void;
  disableDateChange?: boolean;
}

export function CreateMilestoneDialog({
  defaultValue,
  onSuccess,
  onClose,
  disableDateChange
}: Props) {
  const {t} = useTranslation(['milestone', 'translation']);

  const {createMilestone, isSaving, isSaved, savedMilestone} = useCreateMilestone();

  const handleSubmit = (unSavedMilestone: Omit<Milestone, 'id'>) => {
    createMilestone(unSavedMilestone);
  };

  useEffect(() => {
    if (isSaved) {
      ToastService.success(t('Milestone was successfully created.'));
      onClose();
      onSuccess?.(savedMilestone.id);
    }
  }, [isSaved, onClose, t, savedMilestone, onSuccess]);

  return (
    <DialogContainer onClose={onClose} fullWidth open>
      <DialogTitle className={styles.title} variant="h5">
        {t('Create a new milestone', {ns: 'milestone'})}
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <MilestoneForm
          onSubmit={handleSubmit}
          defaultValue={defaultValue}
          disableDateChange={disableDateChange}
        />
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={onClose}>{t('Cancel', {ns: 'translation'})}</Button>
        <Button
          type="submit"
          form={MilestoneForm.FORM_ID}
          variant="contained"
          disabled={isSaving}
          endIcon={isSaving ? <CircularLoader size="1em" color="inherit" /> : undefined}
        >
          {t('Create', {ns: 'translation'})}
        </Button>
      </DialogActions>
    </DialogContainer>
  );
}
