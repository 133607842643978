import {formatToUTCPattern, setZonedTime} from '@shared/lib/utils';
import {MenuItem} from '@shared/ui/Menu';
import {Select, SelectChangeEvent, SelectProps} from '@shared/ui/Select';
import {utcToZonedTime} from 'date-fns-tz';

export interface HourSelectProps extends Omit<SelectProps, 'onChange'> {
  onChange?: (timeAsMS: Date) => void;
  timezone?: string;
  checkOptionDisabled: (h: Date) => boolean;
  variant?: 'filled' | 'outlined' | 'standard' | 'rounded';
  value: Date;
}

export function HourSelect({
  checkOptionDisabled,
  onChange,
  timezone,
  variant,
  value,
  ...restProps
}: HourSelectProps) {
  const handleChange = (event: SelectChangeEvent<number>) => {
    const hour = Number(event.target.value);
    onChange?.(setZonedTime(value, timezone, hour, 0, 0, 0));
  };

  const zonedValue = timezone ? utcToZonedTime(value, timezone) : value;
  const hour = zonedValue.getHours();
  return (
    <Select
      displayEmpty
      fullWidth
      onChange={handleChange}
      variant={variant}
      value={hour}
      {...restProps}
    >
      {Array.from({length: 24}).map((_, hour) => {
        const valueWithHour = setZonedTime(value, timezone, hour, 0, 0, 0);
        const durationInMS = hour * 60 * 60 * 1000;

        return (
          <MenuItem key={hour} value={hour} disabled={checkOptionDisabled(valueWithHour)}>
            {formatToUTCPattern(new Date(durationInMS), 'hh a')}
          </MenuItem>
        );
      })}
    </Select>
  );
}
