import {useCallback, useMemo, useState} from 'react';
import {
  DateCalendarProps,
  DateView,
  LocalizationProvider,
  DateCalendar as MuiDateCalendar,
  PickersDay as MuiPickersDay,
  PickersDayProps
} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import classNames from 'classnames';
import {Paper} from '@shared/ui/Paper';
import {Popper} from '@shared/ui/Popper';
import {useAnchorElement} from '@conxai/react-kit';
import {isSameDate} from '@shared/lib/utils';
import styles from './DateCalendar.module.scss';
import {List, ListItem} from '@shared/ui/List';
import {PickerSelectionState} from '@mui/x-date-pickers/internals';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import {getDefaultOptions, Locale} from 'date-fns';

interface Event {
  name: string;
  date: Date;
}
interface Props extends DateCalendarProps<Date> {
  events?: Event[];
}

export function DateCalendar({
  className,
  value,
  onChange,
  timezone,
  maxDate,
  minDate,
  events = [],
  ...restProps
}: Props) {
  const {anchor: eventPopverAnchor, setAnchor, clearAnchor} = useAnchorElement();
  const [hoveredDate, setHoveredDate] = useState<Date>();
  const locale = (getDefaultOptions() as {locale: Locale}).locale;

  const handleDayMouseEnter = (event: React.MouseEvent<HTMLButtonElement>, date: Date) => {
    setAnchor(event);
    setHoveredDate(date);
  };

  const handleChange = useCallback(
    (value: Date, selectionState?: PickerSelectionState, selectedView?: DateView) => {
      if (timezone) {
        value = zonedTimeToUtc(value, timezone);
      }
      onChange(value, selectionState, selectedView);
    },
    [onChange, timezone]
  );

  const eventsToShow = hoveredDate
    ? events.filter(event => isSameDate(event.date, hoveredDate))
    : [];

  const zonedValue = useMemo(
    () => (timezone ? utcToZonedTime(value, timezone) : value),
    [value, timezone]
  );

  const maxDateZoned = useMemo(
    () => (timezone && maxDate ? utcToZonedTime(maxDate, timezone) : maxDate),
    [maxDate, timezone]
  );

  const minDateZoned = useMemo(
    () => (timezone && minDate ? utcToZonedTime(minDate, timezone) : minDate),
    [minDate, timezone]
  );

  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
      <MuiDateCalendar
        className={classNames(styles.calendar, className)}
        {...restProps}
        onChange={handleChange}
        value={zonedValue}
        maxDate={maxDateZoned}
        minDate={minDateZoned}
        slots={{day: Day}}
        slotProps={{
          day: {
            // @ts-expect-error
            events: events,
            onMouseEnter: handleDayMouseEnter,
            onMouseLeave: clearAnchor
          }
        }}
      />

      <Popper
        open={!!eventPopverAnchor && !!eventsToShow.length}
        anchorEl={eventPopverAnchor}
        className={styles.eventListPopper}
      >
        <Paper elevation={2} className={styles.eventNamePaper}>
          <List className={styles.eventList} dense>
            {eventsToShow.map(event => (
              <ListItem key={event.date.toString()} className={styles.eventName}>
                {event.name}
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popper>
    </LocalizationProvider>
  );
}

interface DayProps extends PickersDayProps<Date> {
  events: Event[];
}

function Day({events, className, ...restProps}: DayProps) {
  const hasEvent = !!events.filter(event => isSameDate(restProps.day, event.date)).length;
  const updatedClassName = classNames(className, {[styles.dayWithEvent]: hasEvent});
  return <MuiPickersDay {...restProps} className={updatedClassName} />;
}
