import {Expose, Transform, Type} from 'class-transformer';
import {SavedSearch} from '@shared/types/SavedSearch';
import {MilestoneRange, SearchQuery, Search, SearchTimeRange} from '@shared/types/Search';
import {DateRange, PresetKey} from '@shared/ui/Date';
import {TimeRange} from '@shared/ui/TimeRange';

const returnNullIfDateInvalid = ({value}: {value: Date | undefined | null}) =>
  value && !isNaN(value.getTime()) ? value : null;

const returnEmptyArrayIfNull = ({value}: {value: string[] | null}) => value || [];

export class SearchTimeRangeModel implements SearchTimeRange {
  @Expose({name: 'milestone_from'})
  milestoneFrom: string | null;

  @Expose({name: 'milestone_to'})
  milestoneTo: string | null;

  @Expose({name: 'from'})
  @Transform(returnNullIfDateInvalid)
  @Type(() => Date)
  dateFrom: Date | null;

  @Expose({name: 'to'})
  @Transform(returnNullIfDateInvalid)
  @Type(() => Date)
  dateTo: Date | null;

  @Expose({name: 'time_from'})
  timeFrom: number;

  @Expose({name: 'time_to'})
  timeTo: number;

  @Expose({name: 'relative_date_range'})
  relativeDateRange?: PresetKey;

  getDateRange(): DateRange {
    return {
      startDate: this.dateFrom,
      endDate: this.dateTo
    };
  }

  getHourRange(): TimeRange {
    return {
      startTime: this.timeFrom,
      endTime: this.timeTo
    };
  }

  getMilestoneRange(): MilestoneRange {
    return {
      startMilestoneId: this.milestoneFrom,
      endMilestoneId: this.milestoneTo
    };
  }
}

class SearchModel implements Search {
  @Expose({name: 'tags_in'})
  @Transform(returnEmptyArrayIfNull)
  includedTags: string[];

  @Expose({name: 'tags_out'})
  @Transform(returnEmptyArrayIfNull)
  excludedTags: string[];

  @Expose({name: 'cameras'})
  @Transform(returnEmptyArrayIfNull)
  cameras: string[];

  @Expose({name: 'query'})
  query: SearchQuery;

  @Expose({name: 'time_range'})
  @Type(() => SearchTimeRangeModel)
  timeRange: SearchTimeRange;
}

export class SavedSearchModel implements SavedSearch {
  @Expose({name: 'id'})
  public id: string;

  @Expose({name: 'name'})
  public name: string;

  @Expose({name: 'created_at'})
  public createdAt: Date;

  @Expose({name: 'saved_search'})
  @Type(() => SearchModel)
  public data: Search;
}
