import React, {lazy, Suspense} from 'react';
import {WeatherIconKey} from '../lib/types/WeatherIconKey';

interface WeatherIconProps {
  id: WeatherIconKey;
}

function WeatherIconInner({id}: WeatherIconProps) {
  const LazyIcon = lazy(async () => {
    try {
      return await import(`../assets/${id}.svg`);
    } catch (e) {
      return await import('../assets/question_mark.svg');
    }
  });

  return (
    <Suspense>
      <LazyIcon />
    </Suspense>
  );
}

export const WeatherIcon = React.memo(WeatherIconInner);
