import {ButtonProps, Button as MuiButton} from '@mui/material';
import classNames from 'classnames';
import styles from './Button.module.scss';

export {IconButton} from '@mui/material';
export type {ButtonProps};

export function Button({className, ...restProps}: ButtonProps) {
  return (
    <MuiButton {...restProps} className={classNames(styles.root, className)} disableElevation />
  );
}
