import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {useStatusReportDetails} from '@entities/statusReport';
import {useParams} from 'react-router-dom';
import {StatusReportDetailsSkeleton} from './StatusReportDetailsSkeleton';
import {ReportSummary} from '@widgets/statusReport/ReportSummary';
import {DetailHeader} from '@features/statusReport/DetailHeader';
import {ReportDialog} from '@features/statusReport/ReportDialog';
import {useBoolean} from '@conxai/react-kit';
import {DetailSubHeader} from '@features/statusReport/DetailSubHeader';
import styles from './StatusReportDetails.module.scss';

export function StatusReportDetailsPage() {
  const timezone = useProjectTimezone();
  const {reportId} = useParams();
  const {project} = useCurrentProject();
  const {report, isFetching} = useStatusReportDetails(reportId);
  const [shouldShowReportDialog, , showReportDialog, hideReportDialog] = useBoolean();

  if (isFetching || !report) {
    return <StatusReportDetailsSkeleton />;
  }

  return (
    <div className={styles.container}>
      <DetailHeader reportDate={report.reportDate} timezone={timezone} />
      <DetailSubHeader project={project} report={report} onViewReport={showReportDialog} />
      <ReportSummary report={report} />
      {shouldShowReportDialog && <ReportDialog onClose={hideReportDialog} report={report} />}
    </div>
  );
}
