import {Expose, Type} from 'class-transformer';
import {FixedSizeArray} from '@shared/types/FixedSizeArray';
import {FrequencyStatistic} from '@shared/types/FrequencyStatistic';

export default class FrequencyStatisticModel implements FrequencyStatistic {
  @Expose({name: 'date'})
  @Type(() => Date)
  public date: Date;

  @Expose({name: 'weekday'})
  weekday: number;

  @Expose({name: 'calendar_week'})
  calendarWeek: number;

  @Expose({name: 'data'})
  data: FixedSizeArray<24, number>;
}
