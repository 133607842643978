import {FixedSizeArray} from '@shared/types/FixedSizeArray';

export const getSumBetweenIndices = (
  array: FixedSizeArray<24, number>,
  start: number,
  end: number
) => {
  return array.reduce((total, value, index) => {
    if (index >= start && index < end) {
      total += value;
    }
    return total;
  }, 0);
};
