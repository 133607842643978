import {Expose, Type} from 'class-transformer';
import {ComplianceInfo} from '../../types/ComplianceInfo';

export default class ComplianceInfoModel implements ComplianceInfo {
  @Expose({name: 'date'})
  @Type(() => Date)
  public date: Date;

  @Expose({name: 'weekday'})
  public weekday: 0 | 1 | 2 | 3 | 4 | 5 | 6;

  @Expose({name: 'calendar_week'})
  public calendarWeek: number;

  @Expose({name: 'ppe'})
  public ppe: number[];

  @Expose({name: 'no_ppe'})
  public noPPE: number[];

  @Expose({name: 'no_helmet'})
  public noHelmet: number[];

  @Expose({name: 'no_vest'})
  public noVest: number[];
}
