import {percentage} from '@conxai/react-kit';
import {ComplianceInfo} from '../../types/ComplianceInfo';
import {getAttributeStatistics} from './getAttributeStatistics';

export function getTotalNonComplianceHours(info: ComplianceInfo, isRelative: boolean) {
  const statistics = getAttributeStatistics([info]);
  const nonComplianceTotal =
    statistics.person_no_vest + statistics.person_no_helmet + statistics.person_no_helmet_no_vest;
  const total = nonComplianceTotal + statistics.person_helmet_vest;

  return isRelative ? percentage(nonComplianceTotal, total) : nonComplianceTotal;
}
