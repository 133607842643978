import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfYear,
  startOfWeek
} from 'date-fns';

// eslint-disable-next-line no-unused-vars
import {Preset, PresetKey} from './types';
import {setEndDayHours, setStartDayHours} from '@shared/lib/utils';

export const PRESET_FACTORY_MAP: Record<PresetKey, (d: Date, l?: Locale) => Preset> = {
  [PresetKey.TODAY]: (date: Date) => ({
    label: 'Today',
    startDate: setStartDayHours(date),
    endDate: setEndDayHours(date)
  }),
  [PresetKey.YESTERDAY]: (date: Date) => ({
    label: 'Yesterday',
    startDate: addDays(setStartDayHours(date), -1),
    endDate: addDays(setEndDayHours(date), -1)
  }),
  [PresetKey.THIS_WEEK]: (date: Date, locale?: Locale) => ({
    label: 'Current Week',
    startDate: startOfWeek(setStartDayHours(date), {locale}),
    endDate: endOfWeek(setEndDayHours(date), {locale})
  }),
  [PresetKey.LAST_WEEK]: (date: Date, locale?: Locale) => ({
    label: 'Last Week',
    startDate: startOfWeek(addWeeks(setStartDayHours(date), -1), {locale}),
    endDate: endOfWeek(addWeeks(setEndDayHours(date), -1), {locale})
  }),
  [PresetKey.LAST_TWO_WEEKS]: (date: Date, locale?: Locale) => ({
    label: 'Last Two Weeks',
    startDate: startOfWeek(addWeeks(setStartDayHours(date), -2), {locale}),
    endDate: endOfWeek(addWeeks(setEndDayHours(date), -1), {locale})
  }),
  [PresetKey.LAST_SEVEN_DAYS]: (date: Date) => ({
    label: 'Last 7 Days',
    startDate: addWeeks(setStartDayHours(date), -1),
    endDate: setEndDayHours(date)
  }),
  [PresetKey.THIS_MONTH]: (date: Date) => ({
    label: 'Current Month',
    startDate: startOfMonth(setStartDayHours(date)),
    endDate: endOfMonth(setEndDayHours(date))
  }),
  [PresetKey.LAST_MONTH]: (date: Date) => ({
    label: 'Last Month',
    startDate: startOfMonth(addMonths(setStartDayHours(date), -1)),
    endDate: endOfMonth(addMonths(setEndDayHours(date), -1))
  }),
  [PresetKey.THIS_YEAR]: (date: Date) => ({
    label: 'This Year',
    startDate: startOfYear(setStartDayHours(date)),
    endDate: endOfYear(setEndDayHours(date))
  }),
  [PresetKey.LAST_YEAR]: (date: Date) => ({
    label: 'Last Year',
    startDate: startOfYear(addYears(setStartDayHours(date), -1)),
    endDate: endOfYear(addYears(setEndDayHours(date), -1))
  })
};

export const getDefaultPresetsUsingKeys = (keys: PresetKey[], date: Date, locale?: Locale) =>
  keys.map(key => PRESET_FACTORY_MAP[key](date, locale));

export const getDefaultPresets = (date: Date, locale?: Locale) =>
  getDefaultPresetsUsingKeys(
    [
      PresetKey.TODAY,
      PresetKey.YESTERDAY,
      PresetKey.THIS_WEEK,
      PresetKey.LAST_WEEK,
      PresetKey.LAST_SEVEN_DAYS,
      PresetKey.THIS_MONTH,
      PresetKey.LAST_MONTH,
      PresetKey.THIS_YEAR,
      PresetKey.LAST_YEAR
    ],
    date,
    locale
  );
