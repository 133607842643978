import {toISOStringWithOffset} from '@shared/lib/utils';
import {groupBy, setStartDayHours} from '@conxai/react-kit';

import {SmartImage} from '@shared/types/SmartImage';

export function getImagesGroupedByDate(images: SmartImage[], timezone: string) {
  return groupBy(images, image =>
    toISOStringWithOffset(setStartDayHours(image.dateTime), timezone)
  );
}
