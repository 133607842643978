import {Event} from '@entities/occupancy';
import {getCalibrationPointIndicesInSeries} from './getCalibrationPointIndicesInSeries';
import {OCCUPANCY_OVER_TIME_CHART} from '@shared-app/lib';

export function getCalibrationPointOptions(events: Event[], series: [number, number][]) {
  const calibrationPointIndices = getCalibrationPointIndicesInSeries(events);

  const xAxisAnnotations = calibrationPointIndices.map(index => ({
    x: series[index][0],
    x2: null,
    strokeDashArray: 5,
    borderColor: OCCUPANCY_OVER_TIME_CHART.CALIBRATION_POINT
  }));

  const discreteMarkers = calibrationPointIndices.map(dataPointIndex => ({
    dataPointIndex,
    seriesIndex: 0,
    size: 4,
    fillColor: OCCUPANCY_OVER_TIME_CHART.CALIBRATION_POINT,
    strokeColor: OCCUPANCY_OVER_TIME_CHART.CALIBRATION_POINT
  }));

  return {
    xAxisAnnotations,
    discreteMarkers
  };
}
