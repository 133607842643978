import {Expose} from 'class-transformer';
import {ImageDimensions} from '@shared/types/ImageDimentions';

export default class OriginalSmartImageSizeModel implements ImageDimensions {
  @Expose({name: 'width'})
  public width: number;

  @Expose({name: 'height'})
  public height: number;
}
