import styles from './ProjectCard.module.scss';
import {Typography} from '@shared/ui/Typography';
import {BusinessOutlined} from '@mui/icons-material';
import {Project} from '@shared/types/Project';
import {useRef} from 'react';
import {useOnVisibleImageLoad} from '@shared/lib/hooks';
import {imagePlaceholderUrl} from '@shared/lib/constants';
import classNames from 'classnames';

interface ProjectCardProps {
  project: Project;
}

export function ProjectCard({project}: ProjectCardProps) {
  const ref = useRef<HTMLDivElement>();
  const {isLoading} = useOnVisibleImageLoad(project.coverImage, ref);

  const imgClasses = classNames(styles.cover__img, {
    [styles.cover__img_final]: !isLoading
  });

  return (
    <div className={styles.card} ref={ref}>
      <figure className={styles.cover}>
        <img
          src={isLoading ? imagePlaceholderUrl : project.coverImage}
          className={imgClasses}
          alt={project.name}
        />
      </figure>

      <div className={styles.description}>
        <div>
          <BusinessOutlined className={styles.description__icon} />
        </div>
        <div>
          <Typography classes={styles.description__item} variant={'h4'}>
            {project.name}
          </Typography>
          <Typography classes={styles.description__item} variant={'subtitle2'}>
            {project.settings.location.name}
          </Typography>
        </div>
      </div>
    </div>
  );
}
