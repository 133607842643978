import {useTranslation} from 'react-i18next';
import {useMutation} from '@shared/lib/hooks';
import {ProjectService, useCurrentProject} from '@entities/project';
import {fileToBase64} from '@conxai/react-kit';

export function useCameraImageIngest() {
  const {t} = useTranslation(['project']);
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {
    mutate: ingestImage,
    isLoading: isSaving,
    isSuccess: isSaved
  } = useMutation<{image: File; cameraId: string}>({
    mutationFn: ({cameraId, image}) => {
      return fileToBase64(image).then(base64Image => {
        return ProjectService.ingestCameraImage(projectId, cameraId, base64Image).catch(() => {
          throw new Error(t('Error occurred. Please try to create again.'));
        });
      });
    },
    options: {skipInvalidation: true}
  });

  return {
    ingestImage,
    isSaving,
    isSaved: isSaved
  };
}
