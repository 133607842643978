import {EventService} from '@entities/occupancy';
import {AuthService, ToastService} from '@shared/lib/services';
import Hls from 'hls.js';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

export function useEventVideo(cameraId?: string, videoId?: string) {
  const {t} = useTranslation('occupancy');
  const [isFetching, setIsFetching] = useState(true);
  const videoRef = useRef<HTMLVideoElement>();
  const hlsRef = useRef<Hls>();

  useEffect(() => {
    if (!videoId || !cameraId || !videoRef.current) return;

    setIsFetching(true);

    EventService.getVideoUrl(cameraId, videoId)
      .then(videoUrl => {
        return initPlayer().then(hls => {
          hlsRef.current = hls;
          hlsRef.current.attachMedia(videoRef.current);
          hlsRef.current.loadSource(videoUrl);
        });
      })
      .catch(() => {
        ToastService.error(t('Failed to play event video'));
      });
  }, [cameraId, t, videoId]);

  const handleVideoLoaded = useCallback(() => {
    setIsFetching(false);
  }, []);

  useEffect(() => {
    if (!videoRef.current || !videoId) return;
    const videoEl = videoRef.current;

    videoEl.addEventListener('loadedmetadata', handleVideoLoaded);

    return () => {
      videoEl.removeEventListener('loadedmetadata', handleVideoLoaded);
    };
  }, [handleVideoLoaded, videoId]);

  return {ref: videoRef, isFetching};
}

async function initPlayer() {
  const {idToken} = await AuthService.currentSession();
  return new Hls({
    maxBufferSize: 1 * 1000 * 1000,
    xhrSetup: (xhr, url) => {
      const playerURL = new URL(url);
      // skip adding headers to s3 urls
      if (playerURL.pathname.endsWith('.m3u8') || playerURL.pathname.endsWith('.vtt')) {
        xhr.setRequestHeader('Authorization', `Bearer ${idToken.toString()}`);
      }
    }
  });
}
