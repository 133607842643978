import {create} from 'zustand';
import {devtools, persist} from 'zustand/middleware';
import {createSearchParamStorage} from '@shared-app/lib';
import {ROUTES} from '@shared/lib/constants';
import {DateRangeRelative} from '@shared/ui/Date';

export interface OccupancyFiltersState {
  regionId: string;
  dateRange: DateRangeRelative;
}

export interface OccupancyFiltersActions {
  setRegionId: (regionId: string) => void;
  setDateRange: (r: DateRangeRelative) => void;
  reset: () => void;
}

const getInitialState = (): OccupancyFiltersState => ({
  regionId: '',
  dateRange: {startDate: new Date(), endDate: new Date()}
});

const isFiltersSearchParamStorageEnabled = (pathname: string) =>
  ROUTES.PROJECT.OCCUPANCY.ROOT.isMatch(pathname);

const storageOptions = {
  name: 'filters',
  storage: createSearchParamStorage(isFiltersSearchParamStorageEnabled)
};

export const useOccupancyFilters = create<OccupancyFiltersState & OccupancyFiltersActions>()(
  persist(
    devtools(
      set => ({
        ...getInitialState(),
        setRegionId: (regionId: string) => set({regionId}),
        setDateRange: (dateRange: DateRangeRelative) => set({dateRange}),
        reset: () => set(getInitialState())
      }),
      {name: 'occupancy-filters'}
    ),
    storageOptions
  )
);
