import classNames from 'classnames';
import styles from './SmartSearchNavItem.module.scss';
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material';
import {useSmartSearchFilterPanel} from '@features/smartSearchFilter';
import {useEffect, useState} from 'react';
import {useIsSmartSearchEnabled} from '@entities/smartSearchFilter';

export function SmartSearchNavItem() {
  const {isSmartSearchEnabled} = useIsSmartSearchEnabled();
  const {
    show: opened,
    toggleVisibility: toggleSmartSearch,
    setVisibility: setSmartSearchVisibility
  } = useSmartSearchFilterPanel();

  const [withAnimation, setWithAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(function () {
      setWithAnimation(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setSmartSearchVisibility(isSmartSearchEnabled);
  }, [isSmartSearchEnabled, setSmartSearchVisibility]);

  return (
    <div
      className={classNames(
        styles.navItem,
        {
          [styles.navItem__disabled]: !isSmartSearchEnabled
        },
        {[styles.navItem__opened]: opened},
        {[styles.navItem__closed]: !opened},
        {[styles.navItem__animated]: withAnimation}
      )}
      onClick={isSmartSearchEnabled ? toggleSmartSearch : undefined}
    >
      {opened ? <ArrowBackIos /> : <ArrowForwardIos />}
    </div>
  );
}
