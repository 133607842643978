import {Project} from '@shared/types/Project';
import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

interface State {
  project: Project;
  setCurrentProject: (project: Project) => void;
}

export const useCurrentProject = create<State>()(
  devtools(set => ({
    project: undefined,
    setCurrentProject: (project: Project) => {
      return set(() => ({project}));
    }
  }))
);
