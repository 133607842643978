import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {useCurrentProject} from '@entities/project';
import {EventService} from '../services/EventService.service';
import {useTranslation} from 'react-i18next';
import {DateRangeRelative} from '@shared/ui/Date';
import {ONE_MINUTE_IN_MS} from '@shared/lib/constants';

export function useEvents({
  regionId,
  dateRangeRelative,
  timezone,
  isLive
}: {
  regionId?: string;
  dateRangeRelative: DateRangeRelative;
  timezone: string;
  isLive: boolean;
}) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('occupancy');
  const {data, isFetching, isError, error, refetch} = useQuery({
    queryKey: ['events', projectId, regionId, dateRangeRelative, timezone],
    queryFn: () => EventService.getEvents(projectId, regionId, {dateRangeRelative, timezone}),
    enabled: !!regionId,
    refetchInterval: () => (isLive ? ONE_MINUTE_IN_MS / 2 : false)
  });

  const errorMessage = isError && error.message;

  useEffect(() => {
    if (errorMessage) {
      ToastService.error(t('Failed to fetch list of events'));
    }
  }, [errorMessage, t]);

  const sortedEvents = data?.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()) || [];

  return {
    events: sortedEvents,
    isFetching: isFetching && !data?.length,
    isError,
    refetchEvents: refetch
  };
}
