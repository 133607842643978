import {TagEnum} from '@shared/types/TagEnum';
import {TagTypeEnum} from '@shared/types/TagTypeEnum';
import {Tag} from '@shared/types/Tag';

export const tags: Tag[] = [
  {key: TagEnum.car, type: TagTypeEnum.vehicle},
  {key: TagEnum.concrete_bucket, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.concrete_mixer, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.concrete_pump_boom, type: TagTypeEnum.vehicle},
  {key: TagEnum.concrete_pump_hose, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.concrete_pump_truck, type: TagTypeEnum.vehicle},
  {key: TagEnum.construction_forklift, type: TagTypeEnum.equipment},
  {key: TagEnum.dump, type: TagTypeEnum.equipment},
  {key: TagEnum.excavator, type: TagTypeEnum.equipment},
  {key: TagEnum.helmet, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.loader, type: TagTypeEnum.equipment},
  {key: TagEnum.mobile_crane, type: TagTypeEnum.hoisting},
  {key: TagEnum.person, type: TagTypeEnum.person},
  {key: TagEnum.pickup, type: TagTypeEnum.equipment},
  {key: TagEnum.portable_toilet, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.safety_vests, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.truck, type: TagTypeEnum.vehicle},
  {key: TagEnum.van, type: TagTypeEnum.vehicle},
  {key: TagEnum.waste_container, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.person_helmet, type: TagTypeEnum.person},
  {key: TagEnum.person_vest, type: TagTypeEnum.person},
  {key: TagEnum.person_no_helmet, type: TagTypeEnum.person},
  {key: TagEnum.person_no_vest, type: TagTypeEnum.person},
  {key: TagEnum.frame_formwork, type: TagTypeEnum.structures},
  {key: TagEnum.rebar, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.slab_formwork, type: TagTypeEnum.structures},
  {key: TagEnum.wet_concrete, type: TagTypeEnum.structures},
  {key: TagEnum.person_helmet_vest, type: TagTypeEnum.person},
  {key: TagEnum.person_ppe_compliant, type: TagTypeEnum.person},
  {key: TagEnum.person_ppe_violation, type: TagTypeEnum.person},
  {key: TagEnum.person_helmet_no_vest, type: TagTypeEnum.person},
  {key: TagEnum.person_no_helmet_vest, type: TagTypeEnum.person},
  {key: TagEnum.person_no_helmet_no_vest, type: TagTypeEnum.person},
  {key: TagEnum.bulldozer, type: TagTypeEnum.equipment},
  {key: TagEnum.compactor, type: TagTypeEnum.equipment},
  {key: TagEnum.dump_truck, type: TagTypeEnum.vehicle},
  {key: TagEnum.grader, type: TagTypeEnum.equipment},
  {key: TagEnum.mixer_truck, type: TagTypeEnum.vehicle},
  {key: TagEnum.pickup_truck, type: TagTypeEnum.vehicle},
  {key: TagEnum.scraper, type: TagTypeEnum.equipment},
  {key: TagEnum.telescopic_handler, type: TagTypeEnum.equipment},
  {key: TagEnum.tower_crane, type: TagTypeEnum.hoisting},
  {key: TagEnum.tractor, type: TagTypeEnum.equipment},
  {key: TagEnum.conex, type: TagTypeEnum.structures},
  {key: TagEnum.dumpster, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.pylon, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.rebar_bundle, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.safety_vest, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.silo, type: TagTypeEnum.structures},
  {key: TagEnum.waste_bin, type: TagTypeEnum.objectsAndTools},
  {key: TagEnum.guardrail, type: TagTypeEnum.structures},
  {key: TagEnum.scaffolding, type: TagTypeEnum.structures},
  {key: TagEnum.formwork_slab, type: TagTypeEnum.structures},
  {key: TagEnum.concrete_wet, type: TagTypeEnum.structures},
  {key: TagEnum.formwork_wall, type: TagTypeEnum.structures},
  {key: TagEnum.rebars, type: TagTypeEnum.structures}
];
