import {formatInTimeZone} from 'date-fns-tz';
import {CarouselImage, type CarouselImageProps} from './CarouselImage';
import styles from './CarouselImageWrapper.module.scss';
import {DATE_FORMATS} from '@shared/lib/constants';

export function CarouselImageWrapper({
  style,
  timezone,
  ...props
}: CarouselImageProps & {timezone: string}) {
  const {image} = props;
  return (
    <div className={styles.wrapper} style={style}>
      <CarouselImage {...props} />
      <span className={styles.time}>
        {formatInTimeZone(image.dateTime, timezone, DATE_FORMATS.TIME)}
      </span>
    </div>
  );
}
