import ky from 'ky';
import {AuthService} from '@shared/lib/services';

export const api = ky.create({
  timeout: 30000,
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  },
  hooks: {
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401) {
          await AuthService.logout();
        }
      },
      async (request, options, response) => {
        if (response.status === 403) {
          const message = 'You do not have permission to view the requested page';
          throw new Error(message);
        }
      },
      async (request, options, response) => {
        if (response.status === 500) {
          throw new Error(response.statusText);
        }
      }
    ]
  }
});

export const apiWithAuth = api.extend({
  hooks: {
    beforeRequest: [
      async request => {
        const {idToken} = await AuthService.currentSession();

        request.headers.set('Authorization', `Bearer ${idToken.toString()}`);
      }
    ]
  }
});
