import {List, ListItem, ListItemButton} from '@shared/ui/List';
import {DialogCloseButton, DialogContainer, DialogContent, DialogTitle} from '@shared/ui/Dialog';
import {useCallback, useState} from 'react';
import {DATE_FORMATS} from '@shared/lib/constants';
import {useTranslation} from 'react-i18next';
import {CreateMilestoneButton, MilestoneList} from '@entities/milestone/ui';
import {useBoolean} from '@conxai/react-kit';
import {DateCalendar} from '@shared/ui/Date';
import {TextField} from '@shared/ui/TextField';
import {CalendarTodayIcon} from '@shared/ui/icons';
import styles from './SelectDate.module.scss';
import {Milestone} from '@shared/types/Milestone';
import {InputAdornment} from '@mui/material';
import {formatInTimeZone} from 'date-fns-tz';

interface Props {
  label: string;
  dialogTitle: string;
  value?: Date;
  onChange: (value: Date, milestoneId?: string) => void;
  projectId: string;
  selectedMilestoneId?: string;
  milestones?: Milestone[];
  milestonesLoading: boolean;
  minDate?: Date;
  /**
   * `maxDate` is inclusive in the range selection
   */
  maxDate?: Date;
  allowMilestoneCreation?: boolean;
  timezone: string;
}

export function SelectDate({
  label,
  value,
  onChange,
  dialogTitle,
  milestones,
  selectedMilestoneId,
  milestonesLoading,
  minDate,
  maxDate,
  allowMilestoneCreation,
  timezone
}: Props) {
  const {t} = useTranslation(['milestone', 'translation']);
  const [isPickerOpen, , showPicker, hidePicker] = useBoolean(false);

  const [selectedTabKey, setSelectedTabKey] = useState(
    selectedMilestoneId ? TabKey.MILESTONE : TabKey.CUSTOM_DATE
  );

  const handleDateChange = useCallback(
    (date: Date, milestoneId?: string) => {
      onChange(date, milestoneId);
      hidePicker();
    },
    [onChange, hidePicker]
  );

  const handleCustomDateChange = (date: Date) => {
    // TODO: `new Date(date.toDateString())` might cause unncessary issues when timezone involved
    const updatedDate = timezone ? date : new Date(date.toDateString());
    handleDateChange(updatedDate);
  };

  const handleMilestoneChange = (milestone: Milestone) => {
    handleDateChange(milestone.date, milestone.id);
  };

  const handleMilestoneCreateSuccess = useCallback(
    (milestoneId: string) => {
      handleDateChange(value, milestoneId);
    },
    [value, handleDateChange]
  );

  const selectedMilestone = milestones.find(milestone => milestone.id === selectedMilestoneId);

  const formattedValue = formatInTimeZone(value, timezone, DATE_FORMATS.DATE);

  const milestoneName =
    selectedMilestone?.name.length > 15 ? `${selectedMilestone.name.substring(0, 15)}...` : '';

  return (
    <div className={styles.container}>
      <TextField
        className={styles.textfield}
        label={label}
        onClick={showPicker}
        variant="filled"
        InputProps={{
          disableUnderline: true,
          className: styles.input,
          readOnly: true,
          endAdornment: (
            <InputAdornment className={styles.endAdornment} position="end">
              {selectedMilestone && (
                <span className={styles.endAdornmentDate}>{formattedValue}</span>
              )}
              <CalendarTodayIcon className={styles.endIcon} />
            </InputAdornment>
          )
        }}
        value={milestoneName || formattedValue}
        InputLabelProps={{className: styles.label, shrink: true}}
      />
      {allowMilestoneCreation && (
        <CreateMilestoneButton date={value} onSuccess={handleMilestoneCreateSuccess} />
      )}
      {isPickerOpen && (
        <DialogContainer open className={styles.dialog} onClose={hidePicker}>
          <DialogTitle className={styles.title} variant="h5">
            {dialogTitle}
            <DialogCloseButton onClick={hidePicker} />
          </DialogTitle>
          <DialogContent className={styles.content}>
            <List className={styles.leftMenu} disablePadding>
              <ListItem disableGutters disablePadding>
                <ListItemButton
                  className={styles.menuButton}
                  selected={selectedTabKey === TabKey.CUSTOM_DATE}
                  onClick={() => setSelectedTabKey(TabKey.CUSTOM_DATE)}
                >
                  {t('Custom date', {ns: 'translation'})}
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <ListItemButton
                  className={styles.menuButton}
                  selected={selectedTabKey === TabKey.MILESTONE}
                  onClick={() => setSelectedTabKey(TabKey.MILESTONE)}
                >
                  {t('Milestones', {ns: 'milestone'})}
                </ListItemButton>
              </ListItem>
            </List>
            <div className={styles.tabPanels}>
              {selectedTabKey === TabKey.CUSTOM_DATE && (
                <DateCalendar
                  className={styles.calendar}
                  value={value}
                  onChange={handleCustomDateChange}
                  events={milestones}
                  minDate={minDate}
                  maxDate={maxDate}
                  timezone={timezone}
                />
              )}
              {selectedTabKey === TabKey.MILESTONE && (
                <MilestoneList
                  selectedMilestoneId={selectedMilestoneId}
                  milestones={milestones}
                  loading={milestonesLoading}
                  onSelect={handleMilestoneChange}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              )}
            </div>
          </DialogContent>
        </DialogContainer>
      )}
    </div>
  );
}

enum TabKey {
  CUSTOM_DATE = 0,
  MILESTONE = 1
}
