import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {StatusReportService} from '../services/StatusReport.service';
import {useCurrentProject} from '@entities/project';
import {makeReportListQueryKey} from './utils/makeReportListQueryKey';
import {StatusReport, StatusReportStatuses} from '@shared-app/types';

export function useStatusReports() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {data, isLoading, isError, error} = useQuery({
    queryKey: makeReportListQueryKey(projectId),
    queryFn: () => StatusReportService.getReports(projectId),
    refetchInterval: query => {
      const data: StatusReport[] | undefined = query.state.data;
      if (data?.some(report => report.status === StatusReportStatuses.active)) {
        return 2000;
      }
      return false;
    }
  });

  useEffect(() => {
    if (isError) {
      ToastService.error(error.message);
    }
  }, [isError, error]);

  return {
    reports: data || [],
    isFetching: isLoading
  };
}
