import styles from './DashboardPage.module.scss';
import {ProjectCharts} from '@widgets/project/projectCharts';
import {ProjectSearch} from '@widgets/project/projectSearch';
import {useCurrentProject} from '@entities/project';

export function DashboardPage() {
  const {project} = useCurrentProject();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ProjectSearch project={project} />

        <div>
          <ProjectCharts />
        </div>
      </div>
    </div>
  );
}
