import {Menu, MenuItem} from '@shared/ui/Menu';
import {Button} from '@shared/ui/Button';
import {Avatar} from '@shared/ui/Avatar';
import {ExpandMoreIcon} from '@shared/ui/ExpandMoreIcon';
import {useAuth} from '@entities/auth';
import {useAnchorElement} from '@conxai/react-kit';
import {useState} from 'react';
import {CircularProgress} from '@mui/material';
import classNames from 'classnames';
import styles from './Profile.module.scss';

export function Profile() {
  const {logout, user} = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const {anchor, setAnchor, clearAnchor} = useAnchorElement();

  const handleLogout = async () => {
    setIsLoggingOut(true);
    await logout();
    setIsLoggingOut(false);
    clearAnchor();
  };

  const open = Boolean(anchor);

  const userInitials = user?.email[0].toUpperCase();

  return (
    <>
      <Button
        className={classNames(styles.avatarButton, {[styles.avatarButton__active]: open})}
        onClick={setAnchor}
        endIcon={<ExpandMoreIcon className={styles.expand} />}
      >
        <Avatar className={styles.avatar}>{userInitials}</Avatar>
      </Button>
      <Menu id="profile-menu" anchorEl={anchor} open={open} onClose={clearAnchor}>
        <MenuItem onClick={handleLogout} disabled={isLoggingOut}>
          Logout
          <CircularProgress
            color="inherit"
            size="1em"
            className={classNames(styles.loggingOutIcon, {
              [styles.loggingOutIcon__visible]: isLoggingOut
            })}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
