export function CloudUploadIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M5 15.0016C4.5875 15.0016 4.23438 14.8547 3.94063 14.5609C3.64688 14.2672 3.5 13.9141 3.5 13.5016V12.0016C3.5 11.7891 3.57187 11.6109 3.71562 11.4672C3.85938 11.3234 4.0375 11.2516 4.25 11.2516C4.4625 11.2516 4.64062 11.3234 4.78437 11.4672C4.92813 11.6109 5 11.7891 5 12.0016V13.5016H14V12.0016C14 11.7891 14.0719 11.6109 14.2156 11.4672C14.3594 11.3234 14.5375 11.2516 14.75 11.2516C14.9625 11.2516 15.1406 11.3234 15.2844 11.4672C15.4281 11.6109 15.5 11.7891 15.5 12.0016V13.5016C15.5 13.9141 15.3531 14.2672 15.0594 14.5609C14.7656 14.8547 14.4125 15.0016 14 15.0016H5ZM8.75 5.88906L7.34375 7.29531C7.19375 7.44531 7.01562 7.51719 6.80937 7.51094C6.60312 7.50469 6.425 7.42656 6.275 7.27656C6.1375 7.12656 6.06563 6.95156 6.05938 6.75156C6.05313 6.55156 6.125 6.37656 6.275 6.22656L8.975 3.52656C9.05 3.45156 9.13125 3.39844 9.21875 3.36719C9.30625 3.33594 9.4 3.32031 9.5 3.32031C9.6 3.32031 9.69375 3.33594 9.78125 3.36719C9.86875 3.39844 9.95 3.45156 10.025 3.52656L12.725 6.22656C12.875 6.37656 12.9469 6.55156 12.9406 6.75156C12.9344 6.95156 12.8625 7.12656 12.725 7.27656C12.575 7.42656 12.3969 7.50469 12.1906 7.51094C11.9844 7.51719 11.8062 7.44531 11.6562 7.29531L10.25 5.88906V11.2516C10.25 11.4641 10.1781 11.6422 10.0344 11.7859C9.89063 11.9297 9.7125 12.0016 9.5 12.0016C9.2875 12.0016 9.10938 11.9297 8.96562 11.7859C8.82188 11.6422 8.75 11.4641 8.75 11.2516V5.88906Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
