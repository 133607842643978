import {ChangeEvent} from 'react';
import {FormControl, FormLabel, FormControlLabel} from '@shared/ui/Form';
import {useTranslation} from 'react-i18next';
import styles from './AbsoluteRelativeSelect.module.scss';
import {getEnumFromStringValue} from '@conxai/react-kit';
import {Radio, RadioGroup} from '@shared/ui/Radio';

interface Props {
  onChange: (v: MeasuredAs) => void;
  value: MeasuredAs;
}

export function AbsoluteRelativeSelect({onChange, value}: Props) {
  const {t} = useTranslation('ppeDashboard');

  const handleChange = (event: ChangeEvent, valueStr: string) => {
    const selectedValue = getEnumFromStringValue(MeasuredAs, valueStr);
    if (selectedValue) {
      onChange(selectedValue);
    }
  };

  return (
    <FormControl className={styles.formControl}>
      <FormLabel className={styles.label}>{t('Measured as')}</FormLabel>
      <RadioGroup onChange={handleChange} value={value} row>
        <FormControlLabel
          value="absolute"
          control={<Radio className={styles.radio} />}
          label={t('Absolute', {ns: 'translation'})}
        />
        <FormControlLabel
          value="relative"
          control={<Radio />}
          label={t('Relative', {ns: 'translation'})}
        />
      </RadioGroup>
    </FormControl>
  );
}

export enum MeasuredAs {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative'
}
