import classNames from 'classnames';
import styles from './SummaryCard.module.scss';

interface SummaryCardProps {
  isSelected?: boolean;
  title: string;
  titleRightContent?: JSX.Element;
  children: React.ReactElement | React.ReactElement[];
  onClick: () => void;
}

export function SummaryCard({
  title,
  titleRightContent,
  children,
  onClick,
  isSelected
}: SummaryCardProps) {
  return (
    <div
      className={classNames(styles.card, {[styles.card__selected]: isSelected})}
      onClick={onClick}
    >
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {titleRightContent}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
