import {ComplianceInfo} from '../../types/ComplianceInfo';
import {TimeRange} from '@shared/ui/TimeRange';
import {formatTime, getHourRangeFromTimeRange} from '@shared-app/lib';
import {percentage} from '@conxai/react-kit';

export function getHourlyNonComplianceByDate(
  complianceInfo: ComplianceInfo[],
  timeRange: TimeRange,
  isRelative: boolean
) {
  const [startHour, endHour] = getHourRangeFromTimeRange(timeRange);

  const series = Array.from({length: endHour - startHour}).map((_, hour) => {
    const detectionDurationOfHourPerDay = complianceInfo.map(
      ({noHelmet, noPPE, noVest, ppe, date}) => {
        const total = noHelmet[hour] + noPPE[hour] + noVest[hour] + ppe[hour];
        const totalNonCompliance = noHelmet[hour] + noPPE[hour] + noVest[hour];
        return [
          date.getTime(),
          isRelative ? percentage(totalNonCompliance, total) : totalNonCompliance
        ] as [number, number];
      }
    );
    return {
      name: formatTime(startHour + hour),
      data: detectionDurationOfHourPerDay
    };
  });

  return series;
}
