import {TagEnum} from '@shared/types/TagEnum';
import {TagTypeEnum} from '@shared/types/TagTypeEnum';
import {Tag} from '@shared/types/Tag';

export const searchableTags: Tag[] = [
  //Person
  {key: TagEnum.person_helmet, type: TagTypeEnum.person},
  {key: TagEnum.person_vest, type: TagTypeEnum.person},
  {key: TagEnum.person_no_helmet, type: TagTypeEnum.person},
  {key: TagEnum.person_no_vest, type: TagTypeEnum.person},

  //Equipment
  {
    key: TagEnum.bulldozer,
    type: TagTypeEnum.equipment
  },
  {
    key: TagEnum.compactor,
    type: TagTypeEnum.equipment
  },
  {
    key: TagEnum.excavator,
    type: TagTypeEnum.equipment
  },
  {
    key: TagEnum.grader,
    type: TagTypeEnum.equipment
  },
  {
    key: TagEnum.loader,
    type: TagTypeEnum.equipment
  },
  {
    key: TagEnum.tractor,
    type: TagTypeEnum.equipment
  },
  {
    key: TagEnum.telescopic_handler,
    type: TagTypeEnum.equipment
  },

  //Hoisting
  {
    key: TagEnum.mobile_crane,
    type: TagTypeEnum.hoisting
  },
  {
    key: TagEnum.tower_crane,
    type: TagTypeEnum.hoisting
  },

  //Objects and Tools
  {
    key: TagEnum.concrete_bucket,
    type: TagTypeEnum.objectsAndTools
  },
  {
    key: TagEnum.dumpster,
    type: TagTypeEnum.objectsAndTools
  },
  {
    key: TagEnum.waste_bin,
    type: TagTypeEnum.objectsAndTools
  },
  {
    key: TagEnum.portable_toilet,
    type: TagTypeEnum.objectsAndTools
  },
  {
    key: TagEnum.pylon,
    type: TagTypeEnum.objectsAndTools
  },
  {
    key: TagEnum.rebar_bundle,
    type: TagTypeEnum.objectsAndTools
  },

  //Structures
  {
    key: TagEnum.concrete_wet,
    type: TagTypeEnum.structures
  },
  {
    key: TagEnum.conex,
    type: TagTypeEnum.structures
  },
  {
    key: TagEnum.silo,
    type: TagTypeEnum.structures
  },

  //Vehicle
  {
    key: TagEnum.car,
    type: TagTypeEnum.vehicle
  },
  {
    key: TagEnum.dump_truck,
    type: TagTypeEnum.vehicle
  },
  {
    key: TagEnum.mixer_truck,
    type: TagTypeEnum.vehicle
  },
  {
    key: TagEnum.truck,
    type: TagTypeEnum.vehicle
  },
  {
    key: TagEnum.pickup_truck,
    type: TagTypeEnum.vehicle
  }
];
