import Skeleton from '@shared/ui/Skeleton';
import styles from './GeneralSettingsPageSkeleton.module.scss';

export function GeneralSettingsPageSkeleton() {
  return (
    <div>
      <Skeleton width={'25rem'} height={'5rem'} />
      <div className={styles.projectContainer}>
        <div>
          {['30rem', '20rem', '25rem', '20rem', '22rem', '32rem', '26rem'].map((width, index) => (
            <Skeleton key={index} width={width} height={'3rem'} />
          ))}
        </div>
        <div className={styles.coverImageContainer}>
          <Skeleton height={'5rem'} />
          <Skeleton className={styles.image} height={'100%'} />
        </div>
      </div>
    </div>
  );
}
