import {WarningIcon} from '@shared/ui/icons';
import styles from './PotentialIssues.module.scss';
import {useTranslation} from 'react-i18next';

interface PotentialIssuesProps {
  numOfIssues: number;
}

export function PotentialIssues({numOfIssues}: PotentialIssuesProps) {
  const {t} = useTranslation('statusReport');
  return (
    <div className={styles.potentialIssues}>
      <WarningIcon className={styles.warningIcon} />
      <span>{t('Potential Issues')}</span>
      <div className={styles.numOfIssues}>{numOfIssues}</div>
    </div>
  );
}
