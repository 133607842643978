import {groupBy, percentage} from '@conxai/react-kit';
import {ComplianceInfo} from '../../types/ComplianceInfo';
import {getAttributeStatistics} from './getAttributeStatistics';

export const getWeeklyNonComplianceHours = (data: ComplianceInfo[], isRelative: boolean) => {
  const groupedHoursByWeek = groupBy(data, ({weekday}) => String(weekday));

  const hoursPerWeek = Array.from<number>({length: 7}).map((_, weekday) => {
    const weekData = groupedHoursByWeek[weekday] || [];

    const statistics = getAttributeStatistics(weekData);
    const nonComplianceTotal =
      statistics.person_no_vest + statistics.person_no_helmet + statistics.person_no_helmet_no_vest;
    const total = nonComplianceTotal + statistics.person_helmet_vest;

    return isRelative ? percentage(nonComplianceTotal, total) : nonComplianceTotal;
  });

  // `0` represents Sunday, but we need to show chart from monday
  // So, rotating array to left by 1 element
  hoursPerWeek.push(hoursPerWeek.shift());

  return hoursPerWeek;
};
