import {roundDateRangeToNearHour} from './roundDateRangeToNearHour';
import {ONE_DAY_IN_MS, ONE_HOUR_IN_MS} from '@shared/lib/constants';
import {roundToNearest} from '@shared/lib/utils';
import {DateRange} from '@shared/ui/Date';
import {addMilliseconds, differenceInMilliseconds} from 'date-fns';

function getXAxisLabelGap(timeframeInMS: number) {
  if (timeframeInMS <= ONE_DAY_IN_MS) {
    return ONE_HOUR_IN_MS;
  } else if (timeframeInMS <= 7 * ONE_DAY_IN_MS) {
    return 3 * ONE_HOUR_IN_MS;
  } else if (timeframeInMS <= 15 * ONE_DAY_IN_MS) {
    return 6 * ONE_HOUR_IN_MS;
  } else if (timeframeInMS <= 30 * ONE_DAY_IN_MS) {
    return 12 * ONE_HOUR_IN_MS;
  } else {
    return ONE_DAY_IN_MS;
  }
}

export function getTimeSeriesPrettyXAxisTicksOptions(dateRange: DateRange, timezone: string) {
  const dateRangeRounded = roundDateRangeToNearHour(dateRange, timezone);
  const {startDate, endDate} = dateRangeRounded;

  const chartTimeframe = differenceInMilliseconds(endDate, startDate);
  const xAxisLabelGap = getXAxisLabelGap(chartTimeframe);
  const roundedTimeframe = roundToNearest(chartTimeframe, xAxisLabelGap);
  const endDateRounded = addMilliseconds(startDate, roundedTimeframe);
  const tickAmount = roundedTimeframe / xAxisLabelGap;

  return {
    min: startDate.getTime(),
    max: endDateRounded.getTime(),
    tickAmount
  };
}
