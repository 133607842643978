import {TagGroup} from '@entities/tag/lib/types';
import {Tag} from '@shared/types/Tag';
import styles from './TagGroup.module.scss';
import {useTranslation} from 'react-i18next';
import {DefaultTagCard} from './DefaultTagCard';

interface Props {
  tagsGrouped: TagGroup;
  onTagClick: (tag: Tag) => void;
  renderTag?: typeof defaultTagRenderer;
}

export function TagGroup({tagsGrouped, onTagClick, renderTag = defaultTagRenderer}: Props) {
  const {t} = useTranslation('tags');

  const {tags} = tagsGrouped;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>{t(`type.${tagsGrouped.type}`)}</div>
      </div>
      <div className={styles.content}>{tags.map(tag => renderTag(tag, onTagClick))}</div>
    </div>
  );
}

export function defaultTagRenderer(tag: Tag, onTagClick: (tag: Tag) => void) {
  return <DefaultTagCard onClick={onTagClick} key={tag.key} tag={tag} />;
}
