import {useMemo} from 'react';
import {TimeRange} from '@shared/ui/TimeRange';
import {ComplianceInfo} from '@entities/ppeDashboard';
import {AxisChart, AxisChartOptions} from '@shared-app/ui';
import {useTranslation} from 'react-i18next';
import {getHourlyNonComplianceByDate} from '@entities/ppeDashboard';
import {NON_COMPLIANCE_HEATMAP} from '@shared-app/lib';
import {DATE_FORMATS} from '@shared/lib/constants';
import styles from './NonComplianceFrequencyByTimeHeatmap.module.scss';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';

interface Props {
  complianceInfo: ComplianceInfo[];
  timeRange: TimeRange;
  isRelative: boolean;
}

export function NonComplianceFrequencyByTimeHeatmap({
  complianceInfo,
  isRelative,
  timeRange
}: Props) {
  const {t} = useTranslation('ppeDashboard');
  const timezone = useProjectTimezone();

  const series = useMemo(
    () => getHourlyNonComplianceByDate(complianceInfo, timeRange, isRelative),
    [complianceInfo, timeRange, isRelative]
  );

  if (complianceInfo.length >= 21) {
    return (
      <div className={styles.chooseLessDays}>
        {t('Please select a period of less than 3 weeks to view this chart')}
      </div>
    );
  }

  return (
    <AxisChart
      title={t('PPE Non-Compliance Frequency by Time')}
      series={series}
      options={getOptions(timezone)}
      type="heatmap"
    />
  );
}

function getOptions(timezone: string): AxisChartOptions {
  return {
    plotOptions: {
      heatmap: {
        radius: 0
      }
    },
    colors: NON_COMPLIANCE_HEATMAP.COLORS,
    xaxis: {
      labels: {
        formatter: function (timestamp: unknown) {
          // the first value is NaN for somereason
          if (Number.isNaN(timestamp)) {
            return '';
          }
          return formatInTimeZone(new Date(timestamp as number), timezone, DATE_FORMATS.DATE_MONTH);
        }
      }
    }
  };
}
