import {BorderButton, Button} from '@shared/ui/Button';
import {DeleteIcon} from '@shared/ui/icons';
import {useTranslation} from 'react-i18next';
import {useDeleteCoverImage} from '@entities/project/lib/hooks';
import {CircularLoader} from '@shared/ui/Loader';
import {Project} from '@shared/types/Project';
import {DialogActions, DialogContainer, DialogContent, DialogTitle} from '@shared/ui/Dialog';
import {useBoolean} from '@conxai/react-kit';
import styles from './ProjectCoverImage.module.scss';

interface Props {
  project: Project;
}

export function ProjectCoverImage({project}: Props) {
  const {t} = useTranslation('project');
  const {deleteCoverImage, isDeleting} = useDeleteCoverImage(project);
  const [shouldShowConfirmation, , showConfirmation, hideConfirmation] = useBoolean();

  return (
    <div className={styles.container}>
      <img src={project.coverImage} alt="cover image" />
      <BorderButton
        startIcon={isDeleting ? <CircularLoader size="1em" color="inherit" /> : <DeleteIcon />}
        className={styles.deleteButton}
        onClick={showConfirmation}
      >
        {t('Delete', {ns: 'translation'})}
      </BorderButton>

      <DialogContainer onClose={hideConfirmation} open={shouldShowConfirmation}>
        <DialogTitle variant="h5">{t('Delete Cover Image')}</DialogTitle>
        <DialogContent>
          <div>{t('Are you sure want to delete Cover Image?')}</div>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={hideConfirmation} variant="contained">
            {t('Cancel', {ns: 'translation'})}
          </Button>
          <Button
            onClick={deleteCoverImage}
            variant="outlined"
            color="error"
            disabled={isDeleting}
            endIcon={isDeleting ? <CircularLoader size="1em" color="inherit" /> : undefined}
          >
            {t('Delete', {ns: 'translation'})}
          </Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
