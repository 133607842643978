import {groupBy, percentage, sortBy} from '@conxai/react-kit';
import {Event, Gate} from '@entities/occupancy';

export function getGrossFlowStatistics(events: Event[], gates: Gate[]) {
  const totalEvents = events.length;
  const eventsByGateId = groupBy(events, event => event.gateId);

  const gateEventPercetanges = gates.map(({id: gateId}) => {
    const gateEvents = gateId in eventsByGateId ? eventsByGateId[gateId].length : 0;
    const totalPercentage = percentage(gateEvents, totalEvents);
    return {gateId, totalPercentage};
  });

  const sortedGateEventPercentages = sortBy(
    gateEventPercetanges,
    ({totalPercentage}) => totalPercentage,
    'desc'
  );

  return sortedGateEventPercentages;
}
