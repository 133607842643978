import {useGetProjects} from '@entities/project';
import {useMemo} from 'react';
import {useProjectCameras} from './useGetCameras';

export function useGetProject(id: string) {
  const {projects, isFetching, isError} = useGetProjects();

  const project = useMemo(() => projects.find(project => project.id === id), [id, projects]);

  const {
    cameras,
    isError: isCamerasError,
    isFetching: areCamerasFetching
  } = useProjectCameras(project?.id);

  if (project) {
    project.cameras = cameras;
  }

  return {
    project,
    isFetching: isFetching || areCamerasFetching,
    isError: isError || isCamerasError
  };
}
