import {DateRange, DateRangeRelative} from '@shared/ui/Date';
import {differenceInDays, getDefaultOptions, set} from 'date-fns';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';

export function isSameDate(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function isDateBefore(date: Date, before: Date) {
  return differenceInDays(date, before) < 0;
}

export function isDateAfter(date: Date, after: Date) {
  return differenceInDays(date, after) > 0;
}

export function getDateWeekAfter(date: Date) {
  const weekAfterDate = new Date(date);
  weekAfterDate.setDate(weekAfterDate.getDate() + 7);
  return weekAfterDate;
}

export const setStartDayHours = (date: Date): Date => {
  const startDayDate = new Date(date.getTime());
  startDayDate.setHours(0, 0, 0, 0);

  return startDayDate;
};

export const setEndDayHours = (date: Date): Date => {
  const endDayDate = new Date(date.getTime());
  endDayDate.setHours(23, 59, 59, 999);

  return endDayDate;
};

function getLang() {
  if (navigator.languages != undefined) return navigator.languages[0];
  return navigator.language;
}

export const getDateLocale = async () => {
  try {
    return (await import(`date-fns/locale/${getLang()}/index`)).default;
  } catch {
    return (getDefaultOptions() as {locale: Locale}).locale;
  }
};

export const setZonedTime = (
  date: Date,
  timezone: string,
  hours?: number,
  minutes?: number,
  seconds?: number,
  ms?: number
) => {
  const zonedDate = utcToZonedTime(date, timezone);
  const updatedZonedDate = set(zonedDate, {hours, minutes, seconds, milliseconds: ms});
  return zonedTimeToUtc(updatedZonedDate, timezone);
};

export const setZonedStartOfDay = (date: Date, timezone: string) => {
  return setZonedTime(date, timezone, 0, 0, 0, 0);
};

export const setZonedEndOfDay = (date: Date, timezone: string) => {
  return setZonedTime(date, timezone, 23, 59, 59, 999);
};

const dateRelativeToDate = (date: Date | 'now'): Date => {
  return date === 'now' ? new Date() : date;
};

export const dateRangeRelativeToDateRange = (dateRangeRel: DateRangeRelative): DateRange => {
  return {
    startDate: dateRelativeToDate(dateRangeRel.startDate),
    endDate: dateRelativeToDate(dateRangeRel.endDate)
  };
};
