import {entryexitAPI} from '@shared/api';

export class CalibrationPointService {
  static async add(projectId: string, regionId: string, date: Date, count: number) {
    const res = await entryexitAPI.post(`${projectId}/regions/${regionId}/calibration-point`, {
      json: {
        calibration_count: count,
        timestamp: date.toISOString()
      }
    });

    return res.ok;
  }
}
