import {List, ListItem, ListItemButton} from '@mui/material';
import {DATE_FORMATS} from '@shared/lib/constants';
import styles from './MilestoneList.module.scss';
import {useTranslation} from 'react-i18next';
import {SearchIcon} from '@shared/ui/icons';
import {CloseIcon} from '@shared/ui/CloseIcon';
import {TextField} from '@shared/ui/TextField';
import {useState} from 'react';
import {Milestone} from '@shared/types/Milestone';
import {MilestoneListSkeleton} from './MilestoneListSkeleton';
import {isDateAfter, isDateBefore} from '@shared/lib/utils';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';

interface Props {
  selectedMilestoneId?: string;
  milestones: Milestone[];
  loading: boolean;
  onSelect: (milestone: Milestone) => void;
  minDate?: Date;
  maxDate?: Date;
}

export function MilestoneList({
  selectedMilestoneId,
  milestones,
  loading,
  onSelect,
  minDate,
  maxDate
}: Props) {
  const {t} = useTranslation();
  const [searchText, setSearchText] = useState('');
  const timezone = useProjectTimezone();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearchText(searchText);
  };

  const clearSearch = () => {
    setSearchText('');
  };

  const filteredMilestones = milestones.filter(({name}) =>
    name.toLowerCase().includes(searchText.trim().toLowerCase())
  );

  if (loading) {
    return <MilestoneListSkeleton />;
  }

  return (
    <div className={styles.container}>
      <TextField
        className={styles.search}
        InputLabelProps={{className: styles.label}}
        onChange={handleSearch}
        InputProps={{
          endAdornment: <CloseIcon className={styles.endAdornment} onClick={clearSearch} />
        }}
        placeholder={t('Start typing to filter milestones')}
        value={searchText}
        label={
          <span className={styles.labelContent}>
            <SearchIcon className={styles.labelIcon} />
            {t('Milestone')}
          </span>
        }
      />
      <List className={styles.milestoneList}>
        {filteredMilestones.length === 0 && (
          <div className={styles.emptyMilestones}>{t('No milestones found')}</div>
        )}
        {filteredMilestones.map(milestone => (
          <ListItem key={milestone.id} className={styles.listItem} disablePadding>
            <ListItemButton
              className={styles.listItemButton}
              selected={milestone.id === selectedMilestoneId}
              onClick={() => onSelect(milestone)}
              disabled={isMilestoneDisabled(milestone.date)}
            >
              <div className={styles.milestoneName}>{milestone.name}</div>
              <div className={styles.date}>
                {formatInTimeZone(milestone.date, timezone, DATE_FORMATS.DATE)}
              </div>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  function isMilestoneDisabled(date: Date) {
    if (minDate && maxDate) return isDateBefore(date, minDate) || isDateAfter(date, maxDate);

    if (minDate) return isDateBefore(date, minDate);

    if (maxDate) return isDateAfter(date, maxDate);

    return false;
  }
}
