import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {useCurrentProject} from '@entities/project';
import {RegionService} from '../services/RegionService.service';
import {useTranslation} from 'react-i18next';

export function useRegions() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('occupancy');
  const {data, isLoading, isError, error} = useQuery({
    queryKey: ['regions', projectId],
    queryFn: () => RegionService.getRegions(projectId)
  });

  const errorMessage = isError && error.message;
  useEffect(() => {
    if (errorMessage) {
      ToastService.error(t('Failed to fetch regions'));
    }
  }, [errorMessage, t]);

  return {
    regions: data || [],
    isFetching: isLoading
  };
}
