import {useMemo} from 'react';
import {TextField} from '@shared/ui/TextField';
import {useTranslation} from 'react-i18next';
import {UnSavedUser} from '@shared/types/User';
import styles from './UserInvitationForm.module.scss';
import {TFunction} from 'i18next';
import {Formik} from 'formik';

interface Props {
  onSubmit: (formValues: UnSavedUser) => void;
}

export function UserInvitationForm({onSubmit}: Props) {
  const {t} = useTranslation(['user']);

  const validate = useMemo(() => getValidate(t), [t]);

  return (
    <Formik initialValues={{email: ''}} onSubmit={onSubmit} validate={validate}>
      {({handleChange, handleBlur, handleSubmit, values, errors}) => (
        <form id={FORM_ID} onSubmit={handleSubmit} noValidate>
          <TextField
            className={styles.emailField}
            onChange={handleChange}
            onBlur={handleBlur}
            name="email"
            type="email"
            label={t('Email')}
            value={values.email}
            error={!!errors.email}
            helperText={errors.email || ' '}
            required
            fullWidth
          />
          <div className={styles.helpText}>
            {t(
              'The invitation will be send per e-mail, if user does not have a Conxai account, registration will be required.'
            )}
          </div>
        </form>
      )}
    </Formik>
  );
}

const FORM_ID = 'invitation-form';

UserInvitationForm.FORM_ID = FORM_ID;

interface FormValues extends Partial<UnSavedUser> {}
interface FormErrors extends Partial<Record<keyof UnSavedUser, string>> {}

const getFieldValidations = (
  t: TFunction
): Record<keyof UnSavedUser, (email: string) => string | undefined> => {
  return {
    email: (email: string) => {
      const isValid = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email || '');
      if (!isValid) {
        return t('Please enter a valid e-mail address');
      }
      return undefined;
    }
  };
};

function getValidate(t: TFunction) {
  const validateFnMap = getFieldValidations(t);
  return function (values: FormValues) {
    const errors: FormErrors = {};
    const emailError = validateFnMap.email(values.email);
    if (emailError) {
      errors.email = emailError;
    }
    return errors;
  };
}
