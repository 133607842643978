import {Outlet} from 'react-router-dom';
import Header from './Header/Header';
import styles from './Layout.module.scss';
import React from 'react';
import {ProtectedLayoutWrapper} from '@entities/auth';

export default function Layout() {
  return (
    <ProtectedLayoutWrapper>
      <div className={styles.container}>
        <Header />
        <div className={styles.columns}>
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectedLayoutWrapper>
  );
}
