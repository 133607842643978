import {useElementSize} from '@conxai/react-kit';
import {SmartImage} from '@shared/types/SmartImage';
import {SmartImageMasks} from '@shared/types/SmartImageMasks';
import {Tag} from '@shared/types/Tag';
import classNames from 'classnames';
import styles from './SmartImageWithEnlarge.module.scss';
import {SmartImageFullSize} from '@features/smartImageFullSize';

interface SmartImageWithEnlargeProps {
  className?: string;
  masks: SmartImageMasks;
  activeTag?: Tag;
  activeImage?: SmartImage;
  onEnlarge: () => void;
}

export function SmartImageWithEnlarge({
  className,
  masks,
  activeTag,
  activeImage,
  onEnlarge
}: SmartImageWithEnlargeProps) {
  const {ref: imageCardRef, width, height} = useElementSize<HTMLDivElement>();
  const isContainerHasMoreWidth = width / height > 16 / 9;
  return (
    <div
      className={classNames(styles.imageCard, className, {
        [styles.imageCard__aligncenter]: isContainerHasMoreWidth
      })}
      ref={imageCardRef}
    >
      <SmartImageFullSize
        masks={masks}
        activeTag={activeTag}
        image={activeImage}
        onEnlarge={onEnlarge}
      />
    </div>
  );
}
