import {DATE_FORMATS} from '@shared/lib/constants';
import {useTranslation} from 'react-i18next';
import {Typography} from '@shared/ui/Typography';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import styles from './GeneralSettingsPage.module.scss';
import {useProjectImageStatistics} from '@entities/project';
import {GeneralSettingsPageSkeleton} from './GeneralSettingsPageSkeleton';
import {ProjectCoverImage} from '@features/projectCoverImage';
import {ProjectCoverImageUpload} from '@features/projectCoverImageUpload';
import {formatInTimeZone} from 'date-fns-tz';

export function GeneralSettingsPage() {
  const {project} = useCurrentProject();
  const timezone = useProjectTimezone();
  const {data: projectImageStatistics, isFetching: isFetchingProjectImageStatistics} =
    useProjectImageStatistics();

  const {t} = useTranslation(['project']);

  if (isFetchingProjectImageStatistics) {
    return <GeneralSettingsPageSkeleton />;
  }

  return (
    <div className={styles.container}>
      <Typography classes={styles.heading} variant="h2">
        {t('General Information')}
      </Typography>
      <div>
        <div className={styles.projectInfo}>
          <div className={styles.label}>{t('Project Name')}:</div>
          <div>{project.name}</div>

          <div className={styles.label}>{t('Project Location')}:</div>
          <div>{project.settings.location.name}</div>

          <div className={styles.label}>{t('Time Zone')}:</div>
          <div>{project.settings.location.timezone}</div>

          <div className={styles.label}>{t('Created')}:</div>
          <div>
            {project.createdOn
              ? formatInTimeZone(project.createdOn, timezone, DATE_FORMATS.DATE_TIME)
              : undefined}
          </div>

          <div className={styles.label}>{t('Start Date')}:</div>
          <div>
            {project.settings.startedOn
              ? formatInTimeZone(project.settings.startedOn, timezone, DATE_FORMATS.DATE_TIME)
              : undefined}
          </div>

          <div className={styles.label}>{t('Total Processed Photos')}:</div>
          <div>
            {projectImageStatistics?.totalProcessedImages
              ? projectImageStatistics?.totalProcessedImages
              : t('No photos received')}
          </div>
        </div>
        <div>
          <Typography classes={styles.coverImageHeading} variant="h3">
            {t('Project Cover Image')}
          </Typography>
          <div className={styles.coverImageContainer}>
            {project.coverImage ? (
              <ProjectCoverImage project={project} />
            ) : (
              <ProjectCoverImageUpload project={project} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
