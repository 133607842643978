import {SmartImage} from '@shared/types/SmartImage';
import styles from './ProjectImagesGrid.module.scss';
import {DateRange} from '@shared/ui/Date';
import {isBefore} from 'date-fns';
import {ErrorIcon} from '@shared/ui/icons';
import {DATE_FORMATS} from '@shared/lib/constants';
import {ProjectImagesSection} from './ProjectImagesSection';
import {Project} from '@shared/types/Project';
import {useTranslation} from 'react-i18next';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';

interface ProjectImageListProps {
  isFetching: boolean;
  images: SmartImage[];
  dateRange: DateRange;
  hasMoreImages: boolean;
  onDateChange: (v: Date) => void;
  project: Project;
  setActiveImage: (v: SmartImage) => void;
  isPPEWarningMessageApplicable: boolean;
}

export function ProjectImageList({
  isFetching,
  images,
  dateRange,
  hasMoreImages,
  onDateChange,
  setActiveImage,
  isPPEWarningMessageApplicable,
  project
}: ProjectImageListProps) {
  const {t} = useTranslation('project');
  const timezone = useProjectTimezone();
  return (
    <>
      {!isFetching && images.length === 0 && (
        <div className={styles.empty}>{t('No images for this camera')}</div>
      )}
      {images.length > 0 && (
        <>
          {isBefore(dateRange.startDate, PPE_AVAILABLE_DATE) && isPPEWarningMessageApplicable && (
            <div className={styles.ppeFeatureNotAvailable}>
              <ErrorIcon className={styles.ppeNotAvailableIcon} />
              {t(
                'PPE person detection is only available starting from {{date}}. Before this date, all persons will be shown as any person.',
                {
                  ns: 'search',
                  date: formatInTimeZone(PPE_AVAILABLE_DATE, timezone, DATE_FORMATS.DATE),
                  interpolation: {escapeValue: false}
                }
              )}
            </div>
          )}
          <ProjectImagesSection
            setActiveImage={setActiveImage}
            project={project}
            images={images}
            onDateChange={onDateChange}
          />

          {!hasMoreImages && <div className={styles.empty}>{t('No more images to show')}</div>}
        </>
      )}
    </>
  );
}

const PPE_AVAILABLE_DATE = new Date('2024-05-10T00:00:00.000Z');
