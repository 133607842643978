import {useRef} from 'react';
import classNames from 'classnames';
import {DrawSmartImageMask} from '@features/drawSmartImageMask';
import {Tag} from '@shared/types/Tag';
import {SmartImage} from '@shared/types/SmartImage';
import {usePlaceholderThumbnailUrl, useFullSizeImageUrl} from '@entities/smartImage';
import styles from './SmartImageFullSize.module.scss';
import {SmartImageMasks} from '@shared/types/SmartImageMasks';
import {Image} from '@shared/ui';
import {IconButton} from '@shared/ui/Button';
import {OpenInIcon} from '@shared/ui/icons';

interface Props {
  image: SmartImage;
  activeTag?: Tag;
  masks: SmartImageMasks;
  onEnlarge?: () => void;
}

export function SmartImageFullSize({image, activeTag, masks, onEnlarge}: Props) {
  const {placeholderThumbnailUrl} = usePlaceholderThumbnailUrl(image);

  const {fullSizeImageUrl, isLoading} = useFullSizeImageUrl(image);

  const container = useRef<HTMLDivElement>();

  const classes = classNames(styles.image__img, {
    [styles.image__img_loading]: isLoading
  });

  return (
    <div className={styles.container}>
      <div className={styles.image__outer}>
        <div className={styles.image__inner} ref={container}>
          <span className={classNames(classes, styles.imageWrapper)}>
            <Image
              src={isLoading ? placeholderThumbnailUrl : fullSizeImageUrl}
              alt={image.caption}
              isLoading={isLoading}
              className={classes}
            />
            {onEnlarge && !isLoading && (
              <IconButton
                color="primary"
                className={styles.openInButton}
                size="large"
                onClick={onEnlarge}
              >
                <OpenInIcon />
              </IconButton>
            )}
          </span>

          <div className={styles.image__mask}>
            <DrawSmartImageMask masks={masks} tag={activeTag} />
          </div>
        </div>
      </div>
    </div>
  );
}
