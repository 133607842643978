interface Props {
  selectedIndex: number;
  index: number;
  children: React.ReactNode;
  className?: string;
}

export function TabPanel({className, selectedIndex, index, children}: Props) {
  return (
    <div className={className} role="tabpanel" hidden={selectedIndex !== index}>
      {selectedIndex === index && children}
    </div>
  );
}
