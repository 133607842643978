import {useEffect, useState} from 'react';
import {Tag} from '@shared/types/Tag';
import {SmartImageMasks} from '@shared/types/SmartImageMasks';

const workerInstance = new Worker(new URL('../workers/masks.worker.ts', import.meta.url));

export default function useSmartImageMask(smartImageMasks: SmartImageMasks, tag: Tag) {
  const [imageMask, setImageMask] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    workerInstance.postMessage({
      smartImageMasks
    });
  }, [smartImageMasks]);

  useEffect(() => {
    if (!tag) {
      setImageMask(undefined);
    }
    if (window.Worker && tag) {
      setIsLoading(true);

      workerInstance.postMessage({
        type: tag.type,
        id: smartImageMasks.getMaskTagIdByKey(tag.key)
      });

      workerInstance.onmessage = (
        event: MessageEvent<{
          blob: string;
        }>
      ) => {
        if (event.data.blob) {
          setImageMask(event.data.blob);
          setIsLoading(false);
        }
      };
    }
  }, [tag]);

  return {
    imageMask,
    isLoading
  };
}
