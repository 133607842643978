import Skeleton from '@shared/ui/Skeleton';
import styles from './OccupancyChartsSkeleton.module.scss';

export function OccupancyChartsSkeleton() {
  return (
    <div className={styles.container}>
      <Skeleton className={styles.skeleton} />
      <Skeleton className={styles.skeleton} />
      <Skeleton className={styles.skeleton} />
      <Skeleton className={styles.skeleton} />
    </div>
  );
}
