import React from 'react';

export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}

// eslint-disable-next-line no-unused-vars
export type Setter<T> = React.Dispatch<React.SetStateAction<T>> | ((value: T) => void);

export enum NavigationAction {
  // eslint-disable-next-line no-unused-vars
  Previous = -1,

  // eslint-disable-next-line no-unused-vars
  Next = 1
}

export type Preset = {
  startDate: Date;
  endDate: Date;
  label: string;
};

export enum PresetKey {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'this_week',
  LAST_WEEK = 'last_week',
  LAST_TWO_WEEKS = 'last_two_weeks',
  LAST_SEVEN_DAYS = 'last_seven_days',
  THIS_MONTH = 'current_month',
  LAST_MONTH = 'last_month',
  THIS_YEAR = 'this_year',
  LAST_YEAR = 'last_year'
}

export type GetDisabledDate = (current: Date, dateRange: DateRange) => boolean;

export type GetDateRangePresets = (locale: Locale) => Preset[];
