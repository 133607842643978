import {Expose, Type} from 'class-transformer';
import {TagEnum} from '@shared/types/TagEnum';
import {SmartImage} from '@shared/types/SmartImage';
import {PresignedUrls} from '@shared/types/PresignedUrls';

export default class SmartImageModel implements SmartImage {
  @Expose({name: 'uuid'})
  public id: string;

  @Expose({name: 'image_id'})
  public imageId: string;

  @Expose({name: 'caption'})
  public caption: string;

  @Expose({name: 'context'})
  public context: string;

  @Expose({name: 'time'})
  @Type(() => Date)
  public dateTime: Date;

  @Expose({name: 'tags'})
  public tags: TagEnum[];

  @Expose({name: 'camera_id'})
  public cameraId: string;

  @Expose({name: 'presigned_urls'})
  public presignedUrls: PresignedUrls;
}
