import {useQuery} from '@tanstack/react-query';
import {useCurrentProject} from '../state/useCurrentProject';
import {ProjectService} from '../services/Project.service';
import {useEffect} from 'react';
import {ToastService} from '@shared/lib/services';

export function useProjectImageStatistics() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {data, isFetching, isError, error} = useQuery({
    queryKey: ['projects', projectId, 'statistics'],
    queryFn: () => ProjectService.getProjectStatistics(projectId)
  });

  const errorMessage = isError && error.message;

  useEffect(() => {
    if (errorMessage) {
      ToastService.error(errorMessage);
    }
  }, [errorMessage]);

  return {
    data,
    isFetching,
    isError
  };
}
