import {Typography} from '@shared/ui/Typography';
import {PPEAttributeSwitch} from './PPEAttributeSwitch';
import {IconButton, Popover} from '@mui/material';
import {SettingsIcon} from '@shared/ui/icons';
import {TagEnum} from '@shared/types/TagEnum';
import {useAnchorElement} from '@conxai/react-kit';
import {useTranslation} from 'react-i18next';
import styles from './PPEAttributeSelectPopover.module.scss';
import {PPEEnabledTagAttrs, PPETagAttrs} from './lib/types';

interface Props {
  onChange: (v: PPEEnabledTagAttrs) => void;
  className?: string;
  enabledAttributes: PPEEnabledTagAttrs;
  popoverDescription: string;
}

export function PPEAttributeSelectPopover({
  enabledAttributes,
  onChange,
  className,
  popoverDescription
}: Props) {
  const {t} = useTranslation('tags');
  const {anchor, setAnchor, clearAnchor} = useAnchorElement();

  return (
    <>
      <IconButton className={className} onClick={setAnchor} size="small">
        <SettingsIcon className={styles.variantSelectIcon} />
      </IconButton>
      <Popover
        className={styles.popover}
        anchorEl={anchor}
        open={!!anchor}
        onClose={clearAnchor}
        classes={{paper: styles.popover}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div className={styles.popoverContent}>
          <Typography variant="subtitle1">{t('PPE Settings')}</Typography>
          <span className={styles.description}>{popoverDescription}</span>
          <div className={styles.options}>
            <PPEAttributeSwitch
              onChange={handleHelmetSwitchChange}
              tagId={TagEnum.helmet}
              label={t('Helmet')}
              enabled={!!enabledAttributes[0]}
            />
            <PPEAttributeSwitch
              tagId={TagEnum.safety_vests}
              label={t('Safety Vest')}
              onChange={handleVestSwitchChange}
              enabled={!!enabledAttributes[1]}
            />
          </div>
        </div>
      </Popover>
    </>
  );

  function handleHelmetSwitchChange(selected: boolean) {
    onChange(selected ? [PPETagAttrs.HELMET, enabledAttributes[1]] : [undefined, PPETagAttrs.VEST]);
  }

  function handleVestSwitchChange(selected: boolean) {
    onChange(selected ? [enabledAttributes[0], PPETagAttrs.VEST] : [PPETagAttrs.HELMET, undefined]);
  }
}
