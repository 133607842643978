import React from 'react';
import {SavedSearch} from '@shared/types/SavedSearch';
import styles from './SavedSearchCard.module.scss';
import {Button} from '@shared/ui/Button';
import {DeleteIcon, RestoreIcon, SearchIcon} from '@shared/ui/icons';
import classNames from 'classnames';

interface Props {
  savedSearch: SavedSearch;
  onDelete: (id: string) => void;
  onApply: (savedSearch: SavedSearch) => void;
  isSelected?: boolean;
  isLastUsed?: boolean;
}

export function SavedSearchCard({savedSearch, onDelete, onApply, isSelected, isLastUsed}: Props) {
  const handleDelete = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onDelete(savedSearch.id);
  };

  const handleApply = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onApply(savedSearch);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.container__selected]: isSelected,
        [styles.container__lastUsed]: isLastUsed
      })}
    >
      <Button
        className={styles.applyBtn}
        color="info"
        startIcon={<SearchIcon />}
        endIcon={isLastUsed ? <RestoreIcon /> : undefined}
        onClick={handleApply}
        fullWidth
      >
        <div className={styles.name}>{savedSearch.name}</div>
      </Button>
      <Button className={styles.deleteBtn} color="primary" onClick={handleDelete}>
        <DeleteIcon />
      </Button>
    </div>
  );
}
