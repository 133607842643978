import {Button} from '@shared/ui/Button';
import {useBoolean} from '@conxai/react-kit';
import {InviteUserIcon} from '@shared/ui/icons';
import {InviteUserDialog} from '../InviteUserDialog';
import {useTranslation} from 'react-i18next';

export function InviteUserButton() {
  const {t} = useTranslation('user');
  const [isInviteUserDialogOpen, , showInviteUserDialog, hideInviteUserDialog] = useBoolean(false);

  return (
    <>
      <Button startIcon={<InviteUserIcon />} variant="contained" onClick={showInviteUserDialog}>
        {t('Invite New User')}
      </Button>
      {isInviteUserDialogOpen && <InviteUserDialog onClose={hideInviteUserDialog} />}
    </>
  );
}
