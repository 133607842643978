import {api, sitelensAPI} from '@shared/api';
import {plainToInstance} from 'class-transformer';
import SmartImageModel from '../../model/SmartImage.model';
import {SmartImage} from '@shared/types/SmartImage';
import {DateRange} from '@shared/ui/Date';
import {getDataURIFromBlob} from '@conxai/react-kit';
import {MasksResponse} from '../types/MasksResponse';
import {SearchQuery} from '@entities/smartSearchFilter';
import {getZonedDateRangeISOWithOffset, makeTagQueryForSearch} from '@shared-app/lib';
import SmartImageMasksModel from '@entities/smartImage/model/SmartImageMasks.model';
import {ToastService} from '@shared/lib/services';
import {isMarksResponse} from '../utils/isMasksResponseGuard';
import {MasksLegacyResponse} from '@entities/smartImage/lib/types/MasksLegacyResponse';
import {apiWithAuth} from '@shared/api/api';

export default class SmartImageService {
  public static async getImages(
    projectId: string,
    {
      cameras,
      dateRange,
      offset = 0,
      limit = 0,
      query
    }: {
      cameras: string[];
      dateRange: DateRange;
      offset?: number;
      limit?: number;
      query: SearchQuery;
    },
    timezone: string
  ): Promise<SmartImage[]> {
    const {operator, tags} = query;
    const {startDate, endDate} = getZonedDateRangeISOWithOffset(dateRange, timezone);
    const images = (await sitelensAPI
      .post(`${projectId}/smart-search?offset=${offset}&limit=${limit}`, {
        json: {
          tags_out: [],
          query: {[operator]: tags.map(makeTagQueryForSearch)},
          cameras,
          time_range: [
            {
              from: startDate,
              to: endDate
            }
          ]
        }
      })
      .json()) as unknown[];

    return plainToInstance(SmartImageModel, images);
  }

  public static async getImage(imageId: string): Promise<SmartImage> {
    const image = await sitelensAPI.get(`images/${imageId}`).json();

    return plainToInstance(SmartImageModel, image);
  }

  public static async getMasks(url: string) {
    const response = (await api(url).json()) as MasksResponse | MasksLegacyResponse;

    const data = isMarksResponse(response)
      ? masksResponseToData(response)
      : masksLegacyResponseToData(response);

    return plainToInstance(SmartImageMasksModel, data, {
      excludeExtraneousValues: true
    });

    function masksResponseToData(response: MasksResponse) {
      return {
        annotations: response.annotations,
        image: response.images[0],
        categories: response.categories
      };
    }

    function masksLegacyResponseToData(response: MasksLegacyResponse) {
      return {
        annotations: response.annotation[0].map(annotation => {
          return {
            ...annotation,
            bbox: [0, 0, annotation.segmentation.size[1], annotation.segmentation.size[0]]
          };
        }),
        image: response.images[0],
        categories: response.categories,
        isLegacy: true
      };
    }
  }

  public static async getMaskUrl(dateRelationsUrl: string, dataLegacyUrl: string): Promise<string> {
    try {
      const res = await apiWithAuth.get(dateRelationsUrl);
      return getDataURIFromBlob(await res.blob());
    } catch (error) {
      // Temporary backward compatibility with old format
      if (error.response && error.response.status === 404) {
        const res = await apiWithAuth.get(dataLegacyUrl);
        return getDataURIFromBlob(await res.blob());
      } else {
        ToastService.error(error.message);
        console.error('An error occurred:', error.message);
      }
    }
  }

  public static async getImageData(dateRelationsUrl: string, dataLegacyUrl: string) {
    return this.getMasks(await this.getMaskUrl(dateRelationsUrl, dataLegacyUrl));
  }

  public static async getImageByUrl(url: string): Promise<string> {
    const res = await apiWithAuth.get(url);

    // need data uri so that we can download cross-origin image instead of opening in tab
    // this can be removed if we serve image from same orign
    return getDataURIFromBlob(await res.blob());
  }
}
