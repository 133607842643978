import {sitelensAPI} from '@shared/api';
import {plainToInstance} from 'class-transformer';
import {MilestoneModel} from '@entities/milestone';
import {Milestone, UnsavedMilestone} from '@shared/types/Milestone';

export class MilestoneService {
  public static async getMilestones(projectId: string) {
    const milestones = (await sitelensAPI.get(`${projectId}/milestones`).json()) as unknown[];

    return plainToInstance(MilestoneModel, milestones);
  }

  public static async createMilestone(projectId: string, payload: Omit<Milestone, 'id'>) {
    const milestone = (await sitelensAPI
      .post(`${projectId}/milestones`, {
        json: {
          name: payload.name,
          date: payload.date.toISOString()
        }
      })
      .json()) as unknown;

    return plainToInstance(MilestoneModel, milestone);
  }

  public static async deleteMilestone(projectId: string, milestoneId: string) {
    return await sitelensAPI.delete(`${projectId}/milestones/${milestoneId}`).json();
  }

  public static async editMilestone(
    projectId: string,
    milestoneId: string,
    payload: UnsavedMilestone
  ) {
    await sitelensAPI
      .post(`${projectId}/milestones/${milestoneId}`, {
        json: {
          name: payload.name,
          date: payload.date.toISOString()
        }
      })
      .json();
  }
}
